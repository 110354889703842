import { Button } from "antd";
import styled from "styled-components";

export const StyledButtonSubmitNewsArchive = styled(Button)`
  display: flex;
  padding: 8px 22px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: ${(props) => props.theme.colorButtonBoost};
  border: none !important;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);

  /* Text */
  color: white !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &:not(:disabled):hover {
    background: ${(props) => props.theme.colorHoverButtonBoost};
  }
`;

export const StyledButtonCardNewsArchive = styled(
  StyledButtonSubmitNewsArchive,
)`
  padding: 6px 12px;
  border: 1px solid
    ${(props) =>
      props.type === "ADD"
        ? props.theme.colorButtonBoost
        : props.theme.colorButtonDelete} !important;

  background: ${(props) =>
    props.type === "ADD"
      ? props.theme.bgButtonAdd
      : props.theme.bgButtonDelete} !important;

  color: ${(props) =>
    props.type === "ADD"
      ? props.theme.colorButtonBoost
      : props.theme.colorButtonDelete} !important;

  &:hover {
    background: ${(props) =>
      props.type === "ADD"
        ? props.theme.colorButtonBoost
        : props.theme.colorButtonBoomb} !important;

    color: white !important;

    svg path {
      fill: white;
    }
  }
`;
