import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  queryFilterRedux: "",
  socialRedux: {},
  typeRedux: {},
  otherSortRedux: {},
  optionSortSelectRedux: "",
  optionSortListRedux: [],
};

export const filterDataSlice = createSlice({
  name: "filterData",
  initialState,
  reducers: {
    setInitialFilterData: () => initialState,
    setFiledFilterData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setDefaultFilterData: (state) => {
      state.queryFilterRedux = "";
      state.socialRedux = {};
      state.typeRedux = {};
      state.otherSortRedux = {};
      state.optionSortSelectRedux = "";
      state.optionSortListRedux = [];
    },
  },
});

export const {
  setInitialFilterData,
  setFiledFilterData,
  setDefaultFilterData,
} = filterDataSlice.actions;

export default filterDataSlice.reducer;
