import React from "react";
import SubmissionContentOwner from "../features/submissionContentOwner";
import MainLayout from "../common/layouts/main";

const SubmissionOwner = () => {
  return (
    <MainLayout>
      <SubmissionContentOwner />
    </MainLayout>
  );
};

export default SubmissionOwner;
