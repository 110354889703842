import styled from "styled-components";
import { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import EditKeyword from "../features/keyword/editKeyword";
import MainLayout from "../common/layouts/main";
import ListKeyword from "../features/keyword/listKeyword";
import InputKeyword from "../features/keyword/inputKeyword";
import keywordProviders from "../features/keyword/provider/keywordProvider";

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    padding: 16px;
    text-align: center;
    .ant-btn {
      width: 180px;
    }
    :nth-child(2) {
      background: #7d5de8;
      text-align: center;
    }
  }
`;

const StyleMainkeywordPage = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  background: ${(props) => props.theme.backgroundDashBoard};
  height: calc(100dvh - ${(props) => props.theme.navbarHeight} - 60px);
  gap: 24px;

  .input-keyword-background {
    background: ${(props) => props.theme.white};
    padding: 16px;
    border-radius: 10px;
    height: fit-content;
  }

  .ant-form {
    width: max-content;
  }
`;

const KeywordHeader = styled.span`
  font-size: 24px;
  font-weight: 700;
  padding: 16px;
  color: ${(props) => props.theme.colorTextButton};
  background: ${(props) => props.theme.backgroundDashBoard};
`;

const MainKeyword = () => {
  const keywordProvider = keywordProviders();
  const [formKeyword] = Form.useForm();
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idDelete, setIdDelete] = useState(0);

  const getData = async () => {
    const res = await keywordProvider.getListKeyword();
    if (res) {
      setData(res);
    }
  };

  const handleAdd = async (value) => {
    const payload = {
      name: value,
    };
    const res = await keywordProvider.addListKeyword(payload);
    if (res === 200) getData();
  };

  // SET KEYWORD TO INPUT FIELD
  const setEditInput = (item) => {
    setIsEdit(true);
    setEditValue(item);
  };

  // FN CALL EDIT
  const handleEdit = async () => {
    const payload = {
      id: editValue.id,
      name: editValue.name,
    };
    const res = await keywordProvider.editListKeyword(payload);
    if (res === 200) {
      getData();
      setIsEdit(false);
      setEditValue({});
    }
  };

  const openModalConfirmDelete = (id) => {
    setIsOpenModal(true);
    setIdDelete(id);
  };

  const handleDelete = async () => {
    const payload = {
      id: idDelete,
    };
    const res = await keywordProvider.deleteListKeyword(payload);
    if (res === 200) {
      getData();
      setIsOpenModal(false);
      setIdDelete(0);
    }
  };

  const handleCancelDelete = () => {
    setIsOpenModal(false);
    setIdDelete(0);
  };

  useEffect(() => {
    getData();
    setIdDelete(0);
    setIsOpenModal(false);
    setEditValue({});
  }, []);

  return (
    <MainLayout>
      <KeywordHeader>Campaigns</KeywordHeader>
      <StyleMainkeywordPage>
        <ListKeyword
          data={data}
          setEditInput={setEditInput}
          openModalConfirmDelete={openModalConfirmDelete}
        />
        <InputKeyword
          formKeyword={formKeyword}
          handleAdd={handleAdd}
          setData={setData}
        />
      </StyleMainkeywordPage>
      <StyledModal
        open={isEdit}
        okText="Confirm"
        onOk={() => handleEdit()}
        onCancel={() => setIsEdit(false)}
      >
        <EditKeyword editValue={editValue} setEditValue={setEditValue} />
      </StyledModal>
      <StyledModal
        open={isOpenModal}
        okText="Confirm"
        onOk={handleDelete}
        onCancel={handleCancelDelete}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          Confirm Delete
        </div>
      </StyledModal>
    </MainLayout>
  );
};

export default MainKeyword;
