/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { myNewWindow } from "../../../../../common/utils/helper";
import missionProvider from "../../../provider/missionProvider";
import { PaginationQuery } from "../../../../../common/utils/queryStr";
import { tapOwnerEngagement } from "../../../../../common/utils/filterDatas";
import EngagementProvider from "../engagementProvider";
import { setFiledEngagement } from "../engagementSlice";

const useFetchData = () => {
  const engagementProvider = EngagementProvider();
  const { id = null } = useParams();
  const limit = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { queryFilterRedux = "" } = useSelector((state) => state.filterData);
  const parseQuery = queryString.parse(queryFilterRedux);
  const [tabActive, setTabActive] = useState("1");
  const missionApi = missionProvider();
  const [detail, setDetail] = useState(null);

  const { engagementList = [], engagementPaginationQuery = "" } = useSelector(
    (state) => state.engagement,
  );

  const [selectTabIndex, setSelectTabIndex] = useState(1);

  const [dataCardEngagement, setDataCardEngagement] = useState(null);
  const [dataDetailEngagement, setDataDetailEngagement] = useState(null);

  const getSubMissionDetail = async (page) => {
    const query = {
      ...parseQuery,
      type: "reply",
      order_by: "asc",
      sort_by: "created_date",
      limit,
      page: page || 1,
    };
    // const path = queryString.stringify(query);
    const res = await missionApi.getSubMissionContent({
      id,
      query: queryString.stringify(query),
    });
    setDetail(res);
  };

  const query = [queryFilterRedux, engagementPaginationQuery]
    .filter(Boolean)
    .join("&");

  const queryByTab = (queryLabel, index) => {
    return `?type=${tapOwnerEngagement[
      Math.max(0, Number(index) - 1)
    ].label.toLowerCase()}&${queryLabel}`.replace("quote", "share_quote");
  };

  const queryByMission = (queryLabel, mission) => {
    try {
      return `?type=${mission.toLowerCase()}&${queryLabel}`;
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchEngagementById = async () => {
    try {
      const res = await engagementProvider.getEngagementById(id);
      setDataCardEngagement(res);
    } catch (error) {
      return error;
    }
  };

  const fetchSubmissionLikeShareQuoteEngagementById = async () => {
    try {
      const res =
        await engagementProvider.getSubmissionLikeShareQuoteEngagementById(
          id,
          queryByMission(query, dataCardEngagement?.mission),
        );

      setDataDetailEngagement(res);
    } catch (error) {
      return error;
    }
  };
  const fetchSubmissionReplyEngagementById = async () => {
    try {
      const res = await engagementProvider.getSubmissionReplyEngagementById(
        id,
        queryByMission(query, dataCardEngagement?.mission),
      );
      setDataDetailEngagement(res);
    } catch (error) {
      return error;
    }
  };

  const fetchByTab = () => {
    if (dataCardEngagement?.mission === "reply") {
      fetchSubmissionReplyEngagementById();
    } else {
      fetchSubmissionLikeShareQuoteEngagementById();
    }
  };

  let win = null;
  async function handleCloseWindowMission() {
    if (win.location !== "about:blank") {
      // await fetchByTab();
    }
  }
  const handleOpenWindow = async (contentId) => {
    win = myNewWindow(`/my-engagements/detail/${contentId}`, "");
    win.onunload = handleCloseWindowMission;
  };

  const onChangeTabs = async (key) => {
    setTabActive(key);
    // await getSubMissionDetail();
  };

  // const left = window.screen.width / 2 - 800 / 2;
  // const top = window.screen.height / 2 - 688 / 2;
  // const handleOpenWindow = async (contentId) => {
  //   const newWindow = window.open(
  //     `/my-engagements/detail/${contentId}`,
  //     "",
  //     `width=${800}, height=${688}, top=${top}, left=${left}`,
  //   );
  //   if (newWindow) {
  //     // get & set Data
  //     await fetchByTab();
  //     newWindow.onbeforeunload = async () => {
  //       // await onUnload();
  //       await fetchByTab();
  //     };
  //   }
  // };

  useEffect(() => {
    // fetchEngagementById();
    if (queryFilterRedux) {
      if (!("platform" in parseQuery)) {
        if (dataCardEngagement) {
          if (dataCardEngagement?.mission === "reply") {
            getSubMissionDetail();
          } else {
            fetchSubmissionLikeShareQuoteEngagementById();
          }
        }
      }
    }
  }, [queryFilterRedux]);

  useEffect(() => {
    if (queryFilterRedux) {
      if (!("platform" in parseQuery)) {
        if (dataCardEngagement) {
          if (dataCardEngagement?.mission === "reply") {
            getSubMissionDetail();
          } else {
            fetchSubmissionLikeShareQuoteEngagementById();
          }
        }
      }
    }
    return () => {};
  }, [dataCardEngagement?.mission]);

  useEffect(() => {
    // setTabActive("1");
    fetchEngagementById();
    return () => {};
  }, []);

  // useEffect(() => {
  //   setCurrentPage(1);
  //   dispatch(
  //     setFiledEngagement({
  //       key: "engagementPaginationQuery",
  //       value: PaginationQuery(1, limit),
  //     }),
  //   );
  // }, [queryFilterRedux]);

  useEffect(() => {
    // fetchByTab();
  }, [
    queryFilterRedux,
    engagementPaginationQuery,
    id,
    selectTabIndex,
    // currentPage,
  ]);

  return {
    dataCardEngagement,
    dataDetailEngagement,
    data: detail?.data || [],
    total: detail?.total || 0,
    actual: detail?.actual || 0,
    targets: detail?.targets || 0,
    selectTabIndex,
    setSelectTabIndex,
    handleOpenWindow,
    currentPage,
    setCurrentPage,
    limit,
    engagementPaginationQuery,
    tabActive,
    setTabActive,
    onChangeTabs,
    detail,
    setDetail,
    getSubMissionDetail,
  };
};

export default useFetchData;
