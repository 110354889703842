import React from "react";
import styled from "styled-components";

const StyledCreateText = styled.span`
  color: ${(props) => props.theme.colorTertiary};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
`;

const CreateBy = ({ createdBy }) => {
  const cutText = (text) => {
    if (text.length > 4) {
      return `${text.substring(0, 4)}...`;
    }
    return text;
  };
  return <StyledCreateText>Create By {cutText(createdBy)}</StyledCreateText>;
};

export default CreateBy;
