/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { Flex, Typography } from "antd";
import moment from "moment";
import SocialButton from "../SocialButton";

const { Text } = Typography;

const StyleCardPost = styled(Flex)`
  padding: 8px;
  background-color: ${(props) => props.theme.white};
  /* border-bottom: 1px solid ${(props) => props?.theme?.actionBorder}; */
  /* font-weight: 700; */
  font-weight: 400;
  font-size: 14px;
`;
const StyleContent = styled(Flex)`
  .content {
    display: -webkit-box;
    overflow: hidden;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    text-decoration: none;
    line-height: 22px;
    color: ${(props) => props?.theme?.colorTextButton};
    padding: 3px 2px;
    line-height: 29px;
  }

  .text-date {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  .date {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextButton};
    font-weight: 400;
    /* font-weight: 700; */
  }

  .textCreateDate {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  .createDate {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextButton};
    font-weight: 400;
  }

  .text-cancel {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorRejected};
  }
`;

export const CustomCreateDate = styled(Text)`
  font-size: 14px;
  color: ${(props) => props.theme.colorComplete};
  font-weight: 400;
`;

export const StyleMission = styled(Flex)`
  span {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextDesc} !important;
  }
`;

const CardPost = (props) => {
  const {
    missionType = "",
    startDate = "",
    endDate = "",
    content = "",
    createDate = "",
    updateDate = "",
    cancelDate = "",
    completeDate = "",
  } = props;

  return (
    <StyleCardPost gap="30px">
      <StyleContent vertical>
        <span className="content">{content}</span>

        <Flex
          vertical
          wrap="wrap"
          justify="space-between"
          align="start"
          style={{
            paddingTop: "8px",
            paddingLeft: "2px",
          }}
        >
          <StyleMission gap="8px" align="center">
            <span>Missions :</span>
            <SocialButton type={missionType} />
          </StyleMission>

          <Flex gap={4} align="center" wrap="wrap">
            <span className="text-date">Created :</span>
            <span className="date">
              {moment(startDate).format("YYYY-MM-DD HH:mm")}
            </span>
          </Flex>

          <Flex gap={4} align="center" wrap="wrap">
            <span className="text-date">Work on :</span>
            <span className="date">
              {moment(startDate).format("YYYY-MM-DD HH:mm")} to{" "}
              {moment(endDate).format("YYYY-MM-DD HH:mm")}
            </span>

            {cancelDate && (
              <Text type="danger">
                (Cancelled : {moment(cancelDate).format("YYYY-MM-DD HH:mm")})
              </Text>
            )}

            {!completeDate && !cancelDate && moment().isAfter(endDate) && (
              <Text type="danger">
                (Expired : {moment(endDate).format("YYYY-MM-DD HH:mm")})
              </Text>
            )}

            {completeDate && (
              <CustomCreateDate>
                (Completed : {moment(completeDate).format("YYYY-MM-DD HH:mm")})
              </CustomCreateDate>
            )}
          </Flex>
        </Flex>
      </StyleContent>

      {/* <StyleTextTarget
        textColor={textColor}
        align="center"
        justify="center"
        gap="30px"
      >
        <Flex vertical gap={4} align="center">
          <span className="status">{status}</span>
          <span className="date-status">2024-01-30 15:30</span>
        </Flex>
        <span className="target">
          {targetPost}/{totalPost}
        </span>
      </StyleTextTarget> */}
    </StyleCardPost>
  );
};

export default CardPost;
