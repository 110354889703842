import { useAxios } from "../../../common/hooks/useProviderAxios";
import mockArchive from "../mockDataNewsArchive";

const host = process.env.REACT_APP_API_ENDPOINT;

const newsArchiveProvider = () => {
  const { axios } = useAxios();

  const getNewsArchive = async () => {
    try {
      if (mockArchive) return mockArchive;
      const url = `${host}/news-archive`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  const uploadNewsArchive = async (payload) => {
    const { body } = payload;
    try {
      const url = `${host}/news-archive`;
      const res = await axios.post(url, {
        body,
      });
      return res.status;
    } catch (error) {
      return error;
    }
  };

  return {
    getNewsArchive,
    uploadNewsArchive,
  };
};

export default newsArchiveProvider;
