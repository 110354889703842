/* eslint-disable no-unused-expressions */
import { LeftOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";

const GoBackButton = ({ isDefaultGoBack = true, customPath = "" }) => {
  const navigate = useNavigate();

  return (
    <Flex
      gap="6px"
      align="center"
      style={{
        minHeight: "20px",
        cursor: "pointer",
      }}
      onClick={() => {
        isDefaultGoBack ? navigate(-1) : navigate(customPath);
      }}
    >
      <LeftOutlined
        style={{
          fontSize: "10px",
        }}
      />
      <p
        style={{
          minHeight: "14px",
          minWidth: "fit-content",
        }}
      >
        Back
      </p>
    </Flex>
  );
};

export default GoBackButton;
