import React from "react";
import { Flex, Modal } from "antd";
import styled from "styled-components";
import IconWarning from "../../../assets/icons/icon-warning-circle.svg";

const StyleModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0px !important;
  }

  .ant-modal-footer {
    padding-top: 40px;

    .ant-btn-default {
      border-radius: 2px;
      font-size: 14px;
      font-weight: 700;
      color: ${(props) => props?.theme?.blackAlpha(0.85)} !important;
      box-shadow: none;
      border-color: ${(props) => props?.theme?.actionBorder} !important;
    }

    .ant-btn-primary {
      font-size: 14px;
      font-weight: 700;
      border-radius: 2px;
      background-color: ${(props) => props?.theme?.colorMagenta} !important;
      box-shadow: none;
    }
  }
`;

const StyleContent = styled(Flex)`
  img {
    width: 22px;
  }
`;

const StyleTextHeader = styled.span`
  font-size: 16px;
  color: ${(props) => props?.theme?.blackAlpha(0.85)};
  font-weight: 700;
`;

const StyleTextDesc = styled.span`
  font-size: 14px;
  color: ${(props) => props?.theme?.blackAlpha(0.85)};
`;

const ModalConfirm = (props) => {
  const {
    type = "",
    title = "",
    subTitle = "",
    callbackBtnLeft = null,
    callbackBtnRight = null,
    isCentered = true,
    textBtnLeft = "",
    textBtnRight = "",
    isClosable = false, // click outside for closing
    isShowClose = false,
    isModalOpen = true,
  } = props;

  return (
    <StyleModal
      open={isModalOpen}
      centered={isCentered}
      okText={textBtnRight}
      cancelText={textBtnLeft}
      onOk={() => callbackBtnRight()}
      onCancel={() => callbackBtnLeft()}
      closable={isClosable}
      mask={false}
      maskClosable={isShowClose}
    >
      <StyleContent align="flex-start" gap="16px">
        {type === "warning" && <img src={IconWarning} alt="icon-modal" />}
        <Flex vertical gap="10px">
          <StyleTextHeader>{title}</StyleTextHeader>
          <StyleTextDesc>{subTitle}</StyleTextDesc>
        </Flex>
      </StyleContent>
    </StyleModal>
  );
};

export default ModalConfirm;
