import React from "react";
import { Radio, Flex, Image } from "antd";
import styled from "styled-components";
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import { keysIcons } from "../../utils/keyDatas";

const IconSocial = {
  facebook: {
    text: "Facebook",
    icon: keysIcons.facebook,
  },
  instagram: {
    text: "Instagram",
    icon: keysIcons.instagram,
  },
  twitter: {
    text: "X (Twitter)",
    icon: keysIcons.twitter,
  },
  tiktok: {
    text: "Tiktok",
    icon: keysIcons.tiktok,
  },
  pantip: {
    text: "Pantip",
    icon: keysIcons.pantip,
  },
  internet: {
    text: "Web Internet",
    icon: keysIcons.internet,
  },
  youtube: {
    text: "Youtube",
    icon: keysIcons.youtube,
  },
};

const BadgeComp = styled.div`
  padding: 5px;
  border-radius: 5px;
  width: 126px;
  font-family: Sarabun;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${(props) => props.theme.white};
  margin-right: 5px;
  background-color: ${(props) => props.theme.background[props.platform]};
`;

const GroupCustom = styled(Radio.Group)`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
`;

const GroupRadioSocial = ({ onChange, active, value }) => {
  return (
    <GroupCustom onChange={(e) => onChange(e.target.value)} value={value}>
      {Object.keys(IconSocial).map((key) => (
        <Radio
          key={key}
          value={key}
          disabled={active && active !== key}
          // style={{
          //   opacity: `${active && active !== key ? 0.1 : 1}`,
          // }}
        >
          <BadgeComp platform={key}>
            <Flex justify="start" align="center" gap={5} wrap="wrap">
              <Image
                src={IconSocial[key].icon}
                height={24}
                width={24}
                preview={false}
              />
              {IconSocial[key].text}
            </Flex>
          </BadgeComp>
        </Radio>
      ))}
    </GroupCustom>
  );
};

GroupRadioSocial.propTypes = {
  onChange: PropTypes.func,
  active: PropTypes.string,
  value: PropTypes.string,
};

GroupRadioSocial.defaultProps = {
  onChange: () => {},
  active: undefined,
  value: "",
};

export default GroupRadioSocial;

// exam
/* <GroupRadioSocial
        // active="instagram"
        onChange={(e) => onChange(e)}
        value={"facebook"}
      /> */
