/* eslint-disable no-unused-vars */
import { useAxios } from "../../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const engagementProvider = () => {
  const { axios } = useAxios();

  const getEngagement = async (query) => {
    try {
      const url = [`${host}/missions/all?type=engage`, query]
        .filter(Boolean)
        .join("&");
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log(`error:`, error);
      throw error;
    }
  };
  const getEngagementById = async (id) => {
    try {
      const url = [`${host}/missions/${id}`].filter(Boolean).join("");
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log(`error:`, error);
      throw error;
    }
  };

  const getSubmissionLikeShareQuoteEngagementById = async (id, query = "") => {
    /* Type Like Share Quote */
    try {
      const url = [`${host}/sub-mission-posts/mission/${id}/owner`, query]
        .filter(Boolean)
        .join("");
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log(`error:`, error);
      throw error;
    }
  };

  const getSubmissionReplyEngagementById = async (id, query = "") => {
    /* Type Reply */
    try {
      const url = [`${host}/sub-mission-contents/mission/${id}/owner`, query]
        .filter(Boolean)
        .join("");
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log(`error:`, error);
      throw error;
    }
  };

  const getSubMissionWriter = async (query) => {
    try {
      const url = [`${host}/sub-mission-contents/writer`, query]
        .filter(Boolean)
        .join("");
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log(`error:`, error);
      throw error;
    }
  };

  return {
    getEngagement,
    getEngagementById,
    getSubmissionLikeShareQuoteEngagementById,
    getSubmissionReplyEngagementById,
    getSubMissionWriter,
  };
};

export default engagementProvider;
