/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Card, Flex, Row, Col } from "antd";
import { styled } from "styled-components";
import PieChart from "../PieChart";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const ColStyle = styled(Col)`
  margin: -5px -10px;
`;

const Legend = styled(Flex)`
  flex-wrap: wrap;
  .legend-cicle {
    width: 9px;
    height: 9px;
    border-radius: 200px;
  }

  .platform {
    color: #444450;
    font-family: Sarabun;
    font-size: 12px;
    font-style: normal;
  }
`;

const TrackingChart = ({ data, name }) => {
  const platformLegend = [
    {
      platform: "facebook",
      color: "#3D7DE5",
    },
    {
      platform: "twitter",
      color: "#1BBBFF",
    },
    {
      platform: "tiktok",
      color: "#49474C",
    },
    {
      platform: "internet",
      color: "#0EA67F",
    },
    {
      platform: "pantip",
      color: "#7F36DB",
    },
    {
      platform: "youtube",
      color: "#FF2643",
    },
  ];

  const totalTrendCount = (dataTrend) => {
    let total = 0;

    for (let i = 0; i < dataTrend.length; i++) {
      total += dataTrend[i].trendCount;
    }
    return total.toString();
  };

  return (
    <Flex gap={28} vertical>
      <Title>Social Data</Title>

      <Row
        style={{
          justifyContent: "center",
        }}
      >
        {data &&
          data?.map((e, index) => (
            <ColStyle span={8} key={index}>
              <PieChart
                data={e.data}
                trendName={e.trendName}
                idChart={index}
                total={totalTrendCount(e.data)}
                name={name}
              />
            </ColStyle>
          ))}
      </Row>
      <Legend justify="center" gap={16} align="center">
        {platformLegend.map((e, index) => (
          <Flex gap={8} align="center">
            <div
              className="legend-cicle"
              style={{
                background: e.color,
              }}
            />
            <span className="platform">{e.platform}</span>
          </Flex>
        ))}
      </Legend>
    </Flex>
  );
};

export default TrackingChart;
