/* eslint-disable no-unused-vars */
import { Button, Flex } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useProviderAuth";
import IconSentiment from "../../../assets/icons/icon-sentiment.svg";
import IconProfile from "../../../assets/icons/icon-profile.svg";
import AuthProvider from "../../../features/login/provider/authProvider";
import IconHamburger from "../../../assets/icons/icon-hamburger.svg";
// import mockNavbarSelect from "./mockNavbarSelect";
// import SelectComponent from "../SelectComponent";

const StyleNavbarHeader = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.textColor};
`;

const StyleNavbar = styled.div`
  height: ${(props) => props.theme.navbarHeight};
  padding: 0 16px;
  border-bottom: 1px solid ${(props) => props.theme.colorBorderCardSocial};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// const BackgroundSelect = styled.span`
//   display: flex;
//   padding: 4px 8px;
//   align-items: center;
//   gap: 16px;
//   border-radius: 10px;
//   background: #f4f4f4;
// `;

const Navbar = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const authProvider = AuthProvider();
  const { signout, accessToken } = useAuth();
  const isAccess = accessToken !== undefined;

  // const onChageSelect = (e) => {
  //   console.log("on change select Navbar :>> ", e);
  // };

  const handleLogout = async () => {
    // await authProvider.userLogout();
    signout(() => navigate("/login"));
  };

  const handleClickLogo = () => {
    if (accessToken === undefined) {
      navigate("/login");
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <StyleNavbar>
      <Flex gap="8px">
        {isAccess && (
          <Button
            onClick={() => toggleSidebar()}
            type="text"
            size="small"
            style={{
              padding: 0,
            }}
          >
            <img src={IconHamburger} alt="icon hamburger" />
          </Button>
        )}

        <Button
          type="text"
          size="small"
          style={{
            background: "transparent",
          }}
          onClick={() => handleClickLogo()}
        >
          <Flex gap="8px" align="center">
            <img src={IconSentiment} alt="icon sentiment analysis" />
            <StyleNavbarHeader textColor="#FF2643">DATISTA</StyleNavbarHeader>
            <StyleNavbarHeader textColor="#8F79D7">
              Sentiment Analysis
            </StyleNavbarHeader>
            {/* <StyleNavbarHeader textColor="#8F79D7">Analysis</StyleNavbarHeader> */}
          </Flex>
        </Button>

        {/* <Flex align="center">
          <BackgroundSelect>
            <span>Tracking</span>
            <SelectComponent
              defaultValue={mockNavbarSelect[0]}
              options={mockNavbarSelect}
              onChange={onChageSelect}
            />
          </BackgroundSelect>
        </Flex> */}
      </Flex>
      {accessToken ? (
        <Flex align="center" gap="10px">
          <img src={IconProfile} alt="icon profile" />
          {/* <Button
            type="text"
            size="small"
            style={{
              background: "transparent",
            }}
            onClick={() => handleLogout()}
          >
            ออกจากระบบ
          </Button> */}
        </Flex>
      ) : (
        <Flex align="center" gap="10px">
          {/* <img src={IconProfile} alt="icon profile" />
          <Button
            type="text"
            size="small"
            style={{
              background: "transparent",
            }}
            onClick={() => navigate("/login")}
          >
            เข้าสู่ระบบ
          </Button> */}
        </Flex>
      )}
    </StyleNavbar>
  );
};

export default Navbar;
