import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const submitEngagementProvider = () => {
  const { axios } = useAxios();

  const getMissionContentDetail = async (missionId = null) => {
    // eslint-disable-next-line no-useless-catch, no-async-promise-executor
    try {
      const url = `${host}/missions/${missionId}`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      throw error;
    }
  };

  const submitEngagement = async (missionId = null, payload = null) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host}/sub-mission-posts/mission/${missionId}/engagement`;
      const res = await axios.post(url, payload);
      return res;
    } catch (error) {
      throw error;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getSocailAccount = async ({ platform, missionId }) => {
    try {
      const url = `${host}/social-accounts/mission/${missionId}`;
      const res = await axios.get(url, {
        params: {
          platform,
          order_by: "desc",
          limit: 20,
          page: 1,
        },
      });
      return res?.data ? res?.data : null;
    } catch (error) {
      return error;
    }
  };

  return {
    submitEngagement,
    getMissionContentDetail,
    getSocailAccount,
  };
};
export default submitEngagementProvider;
