/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// eslint-disable-next-line import/no-duplicates
import { Table, Flex, Button, Modal, Anchor } from "antd";
// eslint-disable-next-line import/no-duplicates
import { Tooltip } from "antd";
import styled from "styled-components";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";
import { myNewWindow } from "../../common/utils/helper";
import { IconWarning, StyledButtonStatus } from "../missions/MyPostsList";
import SortSocialMedia from "./SortSocialMedia";
import { ButtonStatus } from "../flowJob/HeaderWindowDetail";
import ModalConfirm from "../../common/components/ModalConfirm";
import SocialMediaProvider from "./provider/socialMediaProvider";
import IconSocial from "../../common/components/IconSocial";
import EmptyDefault from "../../common/components/EmptyDefault";

const StyleTableSocialMedia = styled(Flex)`
  // height: 100vh;
  padding: 10px;

  .render-empty {
    align-items: center;
    justify-content: center;
    height: 468px;
  }

  .ant-table-wrapper {
    height: 100%;
    overflow: hidden;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .ant-table {
          max-height: 90%;
          overflow: hidden;
        }
      }
    }
  }

  .ant-table-content {
    overflow: auto;
  }

  tbody.ant-table-tbody {
    background-color: ${(props) => props.theme.white};
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset; //Change the existing color to 'unset'
  }
`;

const ButtonCustom = styled(Button)`
  /* background-color: ${(props) => props.theme.colorButtonBoost}; */
  /* color: ${(props) => props.theme.white}; */
  font-weight: 700;
  width: 120px;
  height: 35px;
  font-size: 16px;
  /* &:where(.css-dev-only-do-not-override-14yae4w).ant-btn-default:not(
      :disabled
    ):not(.ant-btn-disabled):hover {
    color: white;
    border-color: ${(props) => props.theme.colorHoverButtonBoost};
    background: ${(props) => props.theme.colorHoverButtonBoost};
  } */
`;

const StyledEditButton = styled(Button)`
  &&& {
    width: 80px;
    /* background-color: white; */
    color: #722ed1;
    font-weight: 500;
    border: 0.5px solid; /* Make sure to specify a solid border */
    /* box-shadow: 0px 4px 8px rgba(114, 46, 209, 0.2); */
    width: 88px;
  }
`;
// const StyledCancelButton = styled(Button)`
//   &&& {
//     background-color: #ed1e46;
//     border-color: #ed1e46;
//     color: white;
//     font-weight: 500;
//     width: 88px;
//   }
// `;

const StyledTextAnchor = styled(Anchor)`
  .ant-anchor-link > .ant-anchor-link-title {
    color: ${(props) => props.theme.colorTextButton};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: #448ef7 !important;
  }
`;
const StyledCancelButton = styled(Button)`
  &&& {
    /* background-color: #ed1e46; */
    /* border-color: #ed1e46; */
    /* color: white; */
    font-weight: 500;
    width: 88px;
  }

  /* &:hover {
    color: ${(props) => props.theme.white} !important;
    background-color: ${(props) =>
    props.theme.colorHoverButtonBoost} !important;
    border-color: ${(props) => props.theme.colorHoverButtonBoost} !important;
  } */
`;

const TableSocialMedia = () => {
  const socialMediaProvider = SocialMediaProvider();
  const [data, setData] = useState(false);
  const [total, setTotal] = useState();
  const [sort, setSort] = useState("desc");
  const [limitList, setLimitList] = useState(50);
  const [pageList, setPageList] = useState(1);
  const [newWindow, setNewWindow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalDelete, setModalDelete] = useState({
    isOpen: false,
    id: null,
  });
  const [modal, contextHolder] = Modal.useModal();

  const reOrderData = (listData) => {
    if (listData) {
      try {
        const groupByStatus = listData.reduce((total, current) => {
          total[current.status] = total[current.status]
            ? [...total[current.status]]
            : [];
          total[current.status] = [...total[current.status], current];
          return total;
        }, {});
        const orderedKeys = ["approve", "request", "reject"];

        // Create a new object with the keys in the desired order
        const reorderedObject = [];

        const sortByDate = (option, dates) => {
          if (option === "asc")
            return dates.sort(
              (a, b) => new Date(a?.created_date) - new Date(b?.created_date),
            );
          if (option === "desc")
            return dates.sort(
              (a, b) => new Date(b?.created_date) - new Date(a?.created_date),
            );

          return dates;
        };

        orderedKeys.forEach((key) => {
          // eslint-disable-next-line no-prototype-builtins
          if (groupByStatus.hasOwnProperty(key)) {
            const sortByCreateDate = sortByDate(sort, groupByStatus[key]);
            sortByCreateDate.forEach((item) => reorderedObject.push(item));
          }
        });
        return reorderedObject;
      } catch (error) {
        console.error(error);
        return [];
      }
    }
    return [];
  };

  const fetchData = async () => {
    try {
      const params = {
        // order_by: sort,
        // limit: limitList,
        // page: pageList,
      };
      const res = await socialMediaProvider.getListSocialAll(params);
      setTotal(res.total);
      setData(reOrderData(res.data));
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleDeleteSocial = async (id) => {
    await socialMediaProvider.deleteSocial(id);
    fetchData();
  };

  const onChangePagination = (page, limit) => {
    setLimitList(limit);
    setPageList(page);
    setCurrentPage(page);
  };

  const warningAlert = () => {
    modal.confirm({
      title: "At least one social media must be activated",
      icon: <IconWarning />,
      centered: true,
      content: "",
      okText: "OK",
      cancelText: "Cancel",
    });
  };

  const confirm = (id) => {
    if (data && data.length === 1) warningAlert();
    else
      modal.confirm({
        title: "Are you sure you want to do this?",
        content: "Would you like to confirm?",
        icon: <IconWarning />,
        centered: true,
        okText: "OK",
        onOk: () => handleDeleteSocial(id, "cancel"),
        cancelText: "Cancel",
      });
  };

  // function ปิด window
  function handleCloseWindow() {
    if (newWindow?.location !== "about:blank") {
      fetchData();
    }
  }

  const handleOpenWindow = (contentId) => {
    if (newWindow) {
      newWindow.close();
    }
    const windowRef = myNewWindow(`/create-social/${contentId}`, "");
    windowRef.onunload = handleCloseWindow;
    setNewWindow(windowRef);
  };

  useEffect(() => {
    fetchData();
  }, [sort, limitList, pageList]);

  const columns = [
    {
      title: "No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => (
        <span>{(currentPage - 1) * limitList + index + 1}</span>
      ),
    },
    {
      title: "Social",
      dataIndex: "platform",
      render: (platform) => <IconSocial type={platform} />,
    },
    {
      title: "Profile URL",
      dataIndex: "profile_url",
      render: (_, record, index) => {
        const displayURL =
          record?.profile_url?.length > 40
            ? `${record.profile_url.substring(0, 40)}`
            : record.profile_url;
        return (
          // <StyledTextAnchor
          //   gap="8px"
          //   onClick={(e) => e.stopPropagation()}
          //   items={[
          //     {
          //       key: `a-${index + 1}`,
          //       href: record?.profile_url,
          //       title: displayURL,
          //       target: "_blank",
          //     },
          //   ]}
          // />
          <a href={record?.profile_url} target="_blank" rel="noreferrer">
            {displayURL}
          </a>
        );
      },
    },
    {
      title: "User ID",
      dataIndex: "profile_id",
      render: (text, record) => {
        return (
          <Tooltip placement="top" title={record?.profile_id}>
            {record?.profile_id && record?.profile_id.length > 10 ? (
              <span>{record?.profile_id.substring(0, 10).concat()}</span>
            ) : (
              <span>{record?.profile_id}</span>
            )}
          </Tooltip>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => {
        return (
          <Tooltip placement="top" title={record?.name}>
            {record?.name && record?.name.length > 10 ? (
              <span>{record?.name.substring(0, 9).concat()}</span>
            ) : (
              <span>{record?.name}</span>
            )}
          </Tooltip>
        );
      },
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      align: "center",
      render: (text, record) => (
        <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>
      ),
    },
    {
      title: "Verify",
      dataIndex: "verify",
      align: "center",
      render: (text, record) => {
        const cardStatus = {
          request: {
            label: "Submitted",
            color: "colorWaiting",
          },
          approve: {
            label: "Approved",
            color: "colorApproved",
          },
          reject: {
            label: "Rejected",
            color: "colorRejected",
          },
        };
        return (
          <StyledButtonStatus color={cardStatus[record?.status]?.color}>
            {cardStatus[record?.status]?.label}
          </StyledButtonStatus>
        );
      },
    },
    {
      title: " ",
      dataIndex: "id",
      render: (text, record, index) => (
        <Flex
          gap={16}
          justify="center"
          style={{
            padding: "16px",
          }}
        >
          {!["approve", "reject"].includes(record?.status) ? (
            <StyledEditButton
              type="cancel"
              onClick={() => handleOpenWindow(record?.id)}
              size="large"
            >
              Edit
            </StyledEditButton>
          ) : (
            <div
              style={{
                width: "88px",
              }}
            />
          )}

          <StyledCancelButton
            type="reject"
            onClick={() => confirm(record?.id)}
            size="large"
          >
            Cancel
          </StyledCancelButton>
        </Flex>
      ),
    },
  ];

  const handleSortChange = (value) => {
    setSort(value);
  };

  return (
    <StyleTableSocialMedia vertical gap={16}>
      <h1
        style={{
          fontSize: "18px",
          minHeight: "32px",
          minWidth: "fit-content",
        }}
      >
        My Social Media
      </h1>
      <SortSocialMedia onChange={handleSortChange} value={sort} counts={total}>
        <ButtonCustom onClick={() => handleOpenWindow("add")} type="confirm">
          Add
        </ButtonCustom>
      </SortSocialMedia>
      <Table
        dataSource={data || []}
        columns={columns || []}
        locale={{
          emptyText: <EmptyDefault />,
        }}
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: 1,
          pageSize: 50,
          total,
          showLessItems: false,
          showSizeChanger: false,
          showQuickJumper: false,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => onChangePagination(page, pageSize),
        }}
      />
      {contextHolder}
    </StyleTableSocialMedia>
  );
};

export default TableSocialMedia;
