import queryString from "query-string";
import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const jobEngagementProvider = () => {
  const { axios } = useAxios();

  const getListJobDoneEngagement = async (params) => {
    const { status = "complete", limit = 50, page = 1, filter = "" } = params;

    const qsParam = {
      limit,
      page,
      status,
    };

    const queryStringParam = queryString.stringify(qsParam);

    try {
      const url = `${host}/sub-mission-posts/influencer?${queryStringParam}&${filter}`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  const getListJobReadyEngagement = async (params) => {
    const { limit = 50, page = 1, filter = "" } = params;

    const qsParam = {
      limit,
      page,
    };

    const queryStringParam = queryString.stringify(qsParam);

    try {
      const url = `${host}/missions/available?${queryStringParam}&${filter}`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  const getListJobAllEngagement = async (params) => {
    const { limit = 50, page = 1, filter = "", status } = params || {};

    const qsParam = {
      limit,
      page,
    };

    if (status) qsParam.status = status;

    const queryStringParam = queryString.stringify(qsParam);

    try {
      const url = `${host}/missions/engage?${queryStringParam}&${filter}`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  const getJobEngagements = async (params) => {
    const { limit = 50, page = 1, filter = "", status } = params || {};

    const qsParam = {
      limit,
      page,
    };

    if (status) qsParam.status = status;

    const queryStringParam = queryString.stringify(qsParam);

    try {
      const url = `${host}/missions/jobs/engage?${queryStringParam}&${filter}`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      return error;
    }
  };

  const getMissionContentDetail = async ({ missionId = null }) => {
    // eslint-disable-next-line no-useless-catch, no-async-promise-executor
    try {
      const url = `${host}/missions/${missionId}`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      throw error;
    }
  };

  const getSocailAccount = async ({ platform, missionId }) => {
    try {
      const url = `${host}/social-accounts/mission/${missionId}`;
      const res = await axios.get(url, {
        params: {
          platform,
          order_by: "desc",
          limit: 20,
          page: 1,
        },
      });
      return res?.data ? res?.data : null;
    } catch (error) {
      return error;
    }
  };

  return {
    getListJobDoneEngagement,
    getListJobReadyEngagement,
    getListJobAllEngagement,
    getJobEngagements,
    getMissionContentDetail,
    getSocailAccount,
  };
};
export default jobEngagementProvider;
