/* eslint-disable object-curly-newline */
const socialDefault = {
  facebook: {
    isCheck: true,
    isDisable: false,
    type: "platform",
  },
  tiktok: {
    isCheck: true,
    isDisable: false,
    type: "platform",
  },
  twitter: {
    isCheck: true,
    isDisable: false,
    type: "platform",
  },
  instagram: {
    isCheck: true,
    isDisable: false,
    type: "platform",
  },
  pantip: {
    isCheck: true,
    isDisable: false,
    type: "platform",
  },
  internet: {
    isCheck: true,
    isDisable: false,
    type: "platform",
  },
  youtube: {
    isCheck: true,
    isDisable: false,
    type: "platform",
  },
  // around: {
  //   isCheck :true,
  //   iDisable :false,
  //   type: "platform",
  // },
};

const socialOptions = {
  facebook: {
    isCheck: true,
    isDisable: false,
    type: "platforms",
  },
  tiktok: {
    isCheck: true,
    isDisable: false,
    type: "platforms",
  },
  twitter: {
    isCheck: true,
    isDisable: false,
    type: "platforms",
  },
  instagram: {
    isCheck: true,
    isDisable: false,
    type: "platforms",
  },
  pantip: {
    isCheck: true,
    isDisable: false,
    type: "platforms",
  },
  internet: {
    isCheck: true,
    isDisable: false,
    type: "platforms",
  },
  youtube: {
    isCheck: true,
    isDisable: false,
    type: "platforms",
  },
  // around: {
  //   isCheck :true,
  //   iDisable :false,
  //   type: "platform",
  // },
};

const typeAll = {
  missionCopywriter: {
    post: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    reply: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
  },
  submissionCopywriter: {
    post: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    reply: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
  },
  missionCopywriterAndInfluencer: {
    post: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    reply: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
  },
  submissionCopywriterAndInfluencer: {
    post: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    reply: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
  },
  post_replyCopywriterAndInfluencer: {
    post: {
      isCheck: true,
      isDisable: false,
      type: "type",
    },
    reply: {
      isCheck: true,
      isDisable: false,
      type: "type",
    },
  },
  like_share_quoteCopywriterAndInfluencer: {
    like: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    share: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    share_quote: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
  },
  myPostsOwner: {
    running: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    completed: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    cancelled: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
  },
  myPostsDetailOwner: {
    waiting: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    approved: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    done: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    cancelled: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    reject: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
  },
  myPostsDetailWriter: {
    submitted: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    approved: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    rejected: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
  },
  myPostsDetailInfluencer: {
    running: {
      // approve owner
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    done: {
      // complete owner
      isCheck: true,
      isDisable: false,
      type: "status",
    },
  },
  myEngageDetail: {
    running: {
      // approve owner
      isCheck: false,
      isDisable: true,
      type: "status",
    },
    done: {
      // complete owner
      isCheck: true,
      isDisable: false,
      type: "status",
    },
  },
  engagementsOwner: {
    reply: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    like: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    share: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    share_quote: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    running: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    completed: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    cancelled: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
  },
  engagementsDetailOwner: {
    waiting: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    approved: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    done: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    cancelled: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
    reject: {
      isCheck: true,
      isDisable: false,
      type: "status",
    },
  },
  like_share_quoteEngagementsDetailOwner: {
    waiting: {
      isCheck: false,
      isDisable: true,
      type: "status",
    },
    approved: {
      isCheck: false,
      isDisable: true,
      type: "status",
    },
    done: {
      isCheck: false,
      isDisable: true,
      type: "status",
    },
    cancelled: {
      isCheck: false,
      isDisable: true,
      type: "status",
    },
    reject: {
      isCheck: false,
      isDisable: true,
      type: "status",
    },
  },
  submissionOwner: {
    post: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
    reply: {
      isCheck: true,
      isDisable: false,
      type: "target",
    },
  },
  socialMediaReview: {},
};

const optionSortDefaultList = [
  {
    label: "Oldest",
    value: "asc",
    type: "order_by",
  },
  {
    label: "Latest",
    value: "desc",
    type: "order_by",
  },
];

const optionSortDefaultSelect = "desc";

const tapOwnerEngagement = [
  {
    key: "1",
    label: "Reply",
  },
  {
    key: "2",
    label: "Like",
  },
  {
    key: "3",
    label: "Share",
  },
  {
    key: "4",
    label: "Quote",
  },
];

const tapSubmissionWriterAndInfluencer = [
  {
    key: "1",
    label: "All",
  },
  {
    key: "2",
    label: "Submitted",
  },
  {
    key: "3",
    label: "Approved",
  },
  // {
  //   key: "4",
  //   label: "Done",
  // },
  {
    key: "4",
    label: "Rejected",
  },
];

const cardStatus = {
  cardEngagement: {
    active: { label: "Running", color: "colorRunning" },
    complete: { label: "Completed", color: "colorDone" },
    cancel: { label: "Cancelled", color: "colorCanceled" },
  },
  cardEngagementReply: {
    pending: { label: "Submitted", color: "colorWaiting" },
    approve: { label: "Approved", color: "colorDone" },
    complete: { label: "Approved", color: "colorDone" },
    reject: { label: "Rejected", color: "colorRejected" },
    error: { label: "Rejected", color: "colorCanceled" },
    cancel: { label: "Rejected", color: "colorCanceled" },
  },
  // Waiting : งานที่กำลังรอ Owner กด Approve or Reject
  // Approved : งานที่ Owner กด Approve รอ Post
  // Done : งานที่รอ Post แล้ว
  // Rejected : งานที่ Owner กด Reject
  // Cancel : งานที่ Owner กด ปุ่ม Stop Engages

  cardEngagementReplyOwner: {
    pending: { label: "Submitted", color: "colorWaiting" },
    approve: { label: "Done", color: "colorDone" },
    complete: { label: "Done", color: "colorDone" },
    reject: { label: "Rejected", color: "colorRejected" },
    error: { label: "Rejected", color: "colorCanceled" },
    cancel: { label: "Rejected", color: "colorCanceled" },
  },
};

export {
  socialDefault,
  optionSortDefaultList,
  optionSortDefaultSelect,
  typeAll,
  tapOwnerEngagement,
  tapSubmissionWriterAndInfluencer,
  cardStatus,
  socialOptions,
};
