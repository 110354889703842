/* eslint-disable import/prefer-default-export */
/* eslint-disable no-plusplus */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */
import { formatTime } from "../../../common/utils/format";

const LinkRenderer = ({ cell }) => {
  if (cell.type === "link" && cell.value) window.open(cell.value, "_blank");
  // return (
  //   <Tooltip title={cell.value} color="#108ee9" trigger="click">
  //     <a>{cell.value}</a>
  //   </Tooltip>
  // );
};

const convertDataToSpreadsheetFormat = (data) => {
  const spreadsheetData = [];

  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    const rowData = [];
    const row = data[rowIndex];

    for (let colIndex = 0; colIndex < row.length; colIndex++) {
      const cell = row[colIndex];
      const cellData = {
        value: cell,
      };

      if (rowIndex === 0) {
        cellData.className = "header-row";
        cellData.readOnly = true;
      }

      if (rowIndex !== 0) {
        cellData.readOnly = true;
        cellData.className = "body-cell";
        if (colIndex === 3) {
          cellData.className = "link-cell";
          cellData.type = "link";
          cellData.DataEditor = LinkRenderer;
        }
        if (colIndex === 9) {
          cellData.value = formatTime(cellData.value);
        }
      }

      rowData.push(cellData);
    }

    spreadsheetData.push(rowData);
  }

  return spreadsheetData;
};

export { convertDataToSpreadsheetFormat };
