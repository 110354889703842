/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Card, Flex, Row, Col } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import CardSentiment from "./CardSentiment";
import CardProvider from "../../provider/cardProvider";
import TitleCampaign from "../TitleCampaign";

const SentimentTab = ({ data, showColumn }) => {
  const cardProvider = CardProvider();
  const [dataShow, setDataShow] = useState([]);
  const { filter, sortby } = useSelector((state) => state.socialCard);
  const fetchData = async () => {
    try {
      const filterPlatform = Object.keys(filter).filter((key) => filter[key]);

      const platformString = filterPlatform
        .map((platform) => `platform=${platform}`)
        .join("&");

      const params = `?&${platformString}&sort=${sortby}&approved=1`;

      const res = await cardProvider.getSocialCards(params);
      if (res) {
        const currentData = res.slice(0, 3);
        setDataShow(currentData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, sortby, showColumn]);

  return (
    <Row gutter={[16, 16]}>
      {data &&
        data.map((items, index) => (
          <Col span={showColumn} key={index}>
            <Card>
              <Flex vertical gap={10}>
                <TitleCampaign
                  title={items.campaign_name}
                  date={moment(items.created_date).format("DD MMM YYYY")}
                />
                <Flex vertical gap={10}>
                  {dataShow &&
                    dataShow.map((e) => (
                      <CardSentiment
                        platform={e.platform}
                        pinned={Boolean(e.pinned)}
                        title={e.title}
                        positiveValue={e.positive_trend}
                        negativeValue={e.negative_trend}
                      />
                    ))}
                </Flex>
              </Flex>
            </Card>
          </Col>
        ))}
    </Row>
  );
};

export default SentimentTab;
