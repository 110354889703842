/* eslint-disable no-undef */
import { useAxios } from "../../../../common/hooks/useProviderAxios";
// import { mockCampaign } from "./mockCampaign";

const host = process.env.REACT_APP_API_ENDPOINT;

const campaignProvider = () => {
  const { axios } = useAxios();

  const getAllcampaign = async (params) => {
    try {
      // if (mockCampaign) return mockCampaign;
      const url = `${host}/getNews${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get campaign", error);
      return [];
    }
  };

  return {
    getAllcampaign,
  };
};

export default campaignProvider;
