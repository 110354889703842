import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const socialMediaBotProvider = () => {
  const { axios } = useAxios();

  const getSocialMediaBotVerified = async ({ filter }) => {
    try {
      const url = `${host}/social-accounts/bot?${filter}`;
      const response = await axios.get(url);
      return response?.data;
    } catch (error) {
      return error;
    }
  };

  const getSocialMediaBotVerifiedDetail = async (id) => {
    try {
      const url = `${host}/social-accounts/status/bot/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get social-accounts Error");
      return [];
    }
  };
  const createSocialBot = async (body) => {
    try {
      const url = `${host}/social-accounts/bot`;
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      if (error.code === "ER_BAD_FIELD_ERROR") {
        throw error;
      }
      throw error;
    }
  };
  const getSocialBotById = async (id) => {
    try {
      const url = `${host}/social-accounts/bot/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };
  const editSocialBot = async (id, body) => {
    try {
      const url = `${host}/social-accounts/${id}`;
      const res = await axios.patch(url, body);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  const updateStatusSocialMediaBot = async () => {
    try {
      const url = `${host}/social-accounts/status/bot`;
      const res = await axios.patch(url, {
        status: "approve",
      });
      return res.data;
    } catch (error) {
      console.log("update Error:", error);
      return [];
    }
  };

  const deleteVerified = async (id) => {
    try {
      const url = `${host}/social-accounts/${id}`;
      await axios.delete(url);
      return true;
    } catch (error) {
      console.log("Delete error:", error);
      return error.response;
    }
  };

  return {
    getSocialMediaBotVerified,
    getSocialMediaBotVerifiedDetail,
    createSocialBot,
    editSocialBot,
    getSocialBotById,
    deleteVerified,
    updateStatusSocialMediaBot,
  };
};

export default socialMediaBotProvider;
