/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable import/no-extraneous-dependencies */
import { Anchor, Flex } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import styled from "styled-components";
import { statusContent } from "../../../common/utils/keyDatas";
import GroupSocial from "../../../common/components/GroupSocial";
import SocialButton from "../../../common/components/SocialButton";

/* 

<CardEngagements
  data={{
    title:
      "Table Title Table Title Table Title Table Title Table Title Table Title Table Title Table Title Table Title Table Title Table Title Table Title ",
    href: "http://localhost:3000/dashboard",
    targetLink: "_blank",
    groupSocial: ["facebook"],
    textTitle: "textTitle",
    socialButtonTypeList: ["Reply", "share"],
    textPreview: "textPreview",
    created: {
      startDate: "01/31/24",
      endDate: "01/31/24",
    },
    workOn: {
      startDate: "01/31/24",
      endDate: "01/31/24",
    },
    statusDateTime: "01/31/24",
    status: "Canceled",
    type: "Reply",
    replies: "replies",
    likes: "likes",
    shares: "shares",
    quotes: "quotes",
    views: "views",
  }}
/>

*/

const StyledCardEngagements = styled(Flex)`
  .ant-anchor {
    .ant-anchor-link-title {
      white-space: unset;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
`;

const StyledText = styled(Flex)`
  width: ${(props) => props.width || "unset"};
  color: ${(props) => props.theme.colorTextButton};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

const StyledCardStatus = styled.div`
  color: ${(props) => props.theme[props.color]};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  width: max-content;
  text-align: center;
  span {
    font-size: 9px;
    font-weight: 400;
    line-height: 17px;
  }
`;

// eslint-disable-next-line no-unused-vars
export const CardStatus = (status = "", statusDateTime = "") => {
  return (
    <StyledCardStatus color={statusContent[status]?.color}>
      {statusContent[status]?.label}
      {status === "cancel" && (
        <div>
          <br />
          <span>{dayjs(statusDateTime).format("YYYY-MM-DD HH:mm")}</span>
        </div>
      )}
    </StyledCardStatus>
  );
};

export const ComponentStatus = ({
  status = "",
  statusDateTime = "",
  width = "100px",
}) => {
  return (
    <Flex
      justify="center"
      style={{
        alignSelf: "center",
        minWidth: "unset" || width,
      }}
    >
      {CardStatus(status, statusDateTime)}
    </Flex>
  );
};

export const ComponentTarget = ({ status = "-", width = "100px" }) => {
  return (
    <Flex
      justify="center"
      style={{
        alignSelf: "center",
        width,
      }}
    >
      <StyledText gap="8px">{status}</StyledText>
    </Flex>
  );
};

const CardEngagements = ({ data = {} }) => {
  const {
    groupSocial = [],
    statusDateTime = "",
    created = {
      startDate: "",
      endDate: "",
    },
    workOn = {
      startDate: "",
      endDate: "",
    },
    socialButtonTypeList = [],
    status = "",
    title = "",
    href = "",
    targetLink = "_blank",
  } = data || {};

  return (
    <StyledCardEngagements justify="space-between" align="center" gap="16px">
      <Flex
        gap="32px"
        justify="space-between"
        style={{
          width: "100%",
        }}
      >
        <Flex gap="25px" flex={1}>
          {/* Col1 */}
          <Flex
            justify="center"
            align="center"
            style={{
              minWidth: "80px",
            }}
          >
            {/* {groupSocial} */}
            <GroupSocial list={groupSocial} />
          </Flex>

          {/* Col2 */}
          <Flex gap="16px" align="flex-start" vertical>
            <Flex gap="16px" align="flex-start" vertical>
              <StyledText gap="8px">
                <Anchor
                  style={{
                    textDecoration: "underline",
                    whiteSpace: "wrap",
                  }}
                  items={[
                    {
                      key: `a-${title}`,
                      href,
                      title,
                      target: targetLink,
                    },
                  ]}
                />
              </StyledText>
            </Flex>
            {/* ----------------- 2024-05-24 19:15 ----------------- */}
            <Flex
              vertical
              gap="2px"
              style={{
                width: "100%",
              }}
            >
              <Flex
                style={{
                  width: "100%",
                }}
              >
                <StyledText
                  gap="8px"
                  align="center"
                  style={{
                    width: "max-content",
                  }}
                >
                  Missions :
                  {socialButtonTypeList.length > 0 && (
                    <Flex gap="4px">
                      {[...socialButtonTypeList].map((item, index) => {
                        return (
                          <Flex key={`${index + item}`}>
                            <SocialButton type={item} />
                          </Flex>
                        );
                      })}
                    </Flex>
                  )}
                </StyledText>
              </Flex>
              <Flex align="center">
                {!!created && (
                  <StyledText gap="8px" width="max-content">
                    Created :{" "}
                    {dayjs(created.startDate).format("YYYY-MM-DD HH:mm")}
                    {created.endDate &&
                      ` - ${dayjs(created.endDate).format("YYYY-MM-DD HH:mm")}`}
                  </StyledText>
                )}
              </Flex>
              <Flex align="center">
                {!!workOn && (
                  <StyledText gap="8px" width="max-content">
                    Work on :{" "}
                    {dayjs(workOn.startDate).format("YYYY-MM-DD HH:mm")}
                    {created.endDate &&
                      ` to ${dayjs(workOn.endDate).format("YYYY-MM-DD HH:mm")}`}
                  </StyledText>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {!true && (
          <Flex
            gap="16px"
            align="center"
            justify="center"
            style={{
              alignSelf: "center",
            }}
          >
            {/* Col3 */}
            <ComponentTarget status="Type" />

            {/* Col3 */}

            {/* Col4 */}
            <ComponentTarget status="Replies" />

            {/* Col5 */}
            <ComponentTarget status="Likes" />

            {/* Col6 */}
            <ComponentTarget status="Shares" />

            {/* Col7 */}
            <ComponentTarget status="Quotes" />

            {/* Col8 */}
            <ComponentTarget status="Views" />
          </Flex>
        )}
      </Flex>
    </StyledCardEngagements>
  );
};

export default CardEngagements;
