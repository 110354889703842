/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import { Flex, Row, Table } from "antd";
import "dayjs/locale/en";
import styled from "styled-components";
import moment from "moment";
import { myNewWindow } from "../../../common/utils/helper";
import {
  myPostInfluencerStatus,
  myPostInfluencerStatusCancel,
  myPostListMissionStatus,
  myPostListMissionStatusCancel,
} from "../../../common/utils/keyDatas";
import ButtonIcon from "../../../common/components/ButtonIcon";
import EmptyDefault from "../../../common/components/EmptyDefault";
import MainLayout from "../../../common/layouts/main";
import FilterSocial from "../../dashboard/filterPlatform";
import CardEngagements, {
  ComponentStatus,
  ComponentTarget,
} from "../CardEngagements";
import FilterData from "../../../common/components/FilterData";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
  socialDefault,
} from "../../../common/utils/filterDatas";
import CardPostMission from "./CardPostMission";
import { StyledButtonStatus } from "../MyPostsList";

const StyledTablePostMission = styled(Flex)`
  padding: 16px 0;
  background: ${(props) => props.theme.bgTablePage};
`;

const StyledTableContainer = styled(Table)`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.bgTablePage};

  .ant-table-cell-scrollbar {
    overflow: hidden;
  }
`;
/* Mock */

const filterTable = (record, value) => {
  return (
    String(record).toLowerCase().indexOf(String(value).toLowerCase()) === 0
  );
};

const TablePostMission = (props) => {
  const {
    detail,
    setDetail,
    getSubMissionDetail,
    currentPage,
    limit,
    setCurrentPage,
    newsTitle,
    roleUser = "",
    missionStatus,
  } = props;
  const {
    total,
    data,
    mission_end_date: endDate,
    mission_completed_date: completeDate,
    mission_canceled_date: cancelDate,
  } = detail || {};
  const columnTable = [
    {
      align: "left",
      width: "auto",
      title: "List",
      dataIndex: "list",
      key: "list",
      render: (_, items) => (
        <CardPostMission
          data={{
            ...items,
            newsTitle,
            roleUser,
          }}
          onUnload={getSubMissionDetail}
        />
      ),
    },
    {
      //   align: "center",
      width: "100px",
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, items) => (
        <StyledButtonStatus
          color={
            missionStatus === "cancel" ||
            missionStatus === "complete" ||
            completeDate ||
            cancelDate ||
            moment().isAfter(endDate)
              ? roleUser === "influencer"
                ? myPostInfluencerStatusCancel[items.status]?.color
                : myPostListMissionStatusCancel[items.status]?.color
              : roleUser === "influencer"
                ? myPostInfluencerStatus[items.status]?.color
                : myPostListMissionStatus[items.status]?.color
          }
        >
          {roleUser === "influencer"
            ? myPostInfluencerStatus[items.status]?.label
            : myPostListMissionStatus[items.status]?.label}
        </StyledButtonStatus>
      ),
    },
  ];

  const onChangePagination = async (page, pageSize) => {
    setCurrentPage(page);
    await getSubMissionDetail(page);
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {};

  let win = null;
  async function handleCloseWindowMission() {
    if (win.location !== "about:blank") {
      await getSubMissionDetail();
    }
  }
  const onRowClick = async (event, rec) => {
    win = myNewWindow(
      roleUser === "influencer"
        ? `post-detail/influencer/${rec.id}`
        : `post-detail/${rec.id}`,
      rec.id,
    );
    win.onunload = handleCloseWindowMission;
  };

  return (
    <StyledTablePostMission vertical gap="16px" justify="space-between">
      <StyledTableContainer
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRowClick(event, record);
            },
          };
        }}
        locale={{
          emptyText: <EmptyDefault />,
          filterConfirm: <ButtonIcon label="Confirm" size={null} />,
          filterReset: (
            <ButtonIcon
              label="Reset"
              type="default"
              size={null}
              primary={false}
            />
          ),
        }}
        onChange={onChangeTable}
        columns={columnTable}
        dataSource={data}
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: 1,
          current: currentPage,
          pageSize: limit,
          total,
          showLessItems: false,
          showSizeChanger: false,
          showQuickJumper: false,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => onChangePagination(page, pageSize),
        }}
      />
    </StyledTablePostMission>
  );
};

export default TablePostMission;
