import React, { useState } from "react";

import { Input, Button, message } from "antd";
// import { SendOutlined } from "@ant-design/icons";

import { createTrends } from "../../controllers/trends";

const { TextArea } = Input;

const InsertTrends = () => {
  const [trends, setTrends] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "บันทึกข้อมูลสำเร็จ",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "เกิดข้อผิดพลาดกับการบันทึกข้อมูล",
    });
  };

  //   const loading = () => {
  //     messageApi.open({
  //       type: "loading",
  //       content: "กำลังทำการบันทึกข้อมูล",
  //       duration: 0,
  //     });
  //   };

  const onInsert = (e) => {
    const data = e.target.value;
    setTrends(data);
  };

  const sendData = async () => {
    const res = await createTrends(trends);
    if (res) {
      success();
      setTrends("");
    } else {
      error();
    }
  };

  return (
    <div
      style={{
        padding: "50px",
        backgroundColor: "#222",
        height: "100vh",
      }}
    >
      <div
        style={{
          color: "#fff",
          paddingBottom: "10px",
        }}
      >
        กรอกข้อมูลที่นี่
      </div>

      <TextArea
        rows={25}
        placeholder="กรอกข้อมูล Trends For You ลงในนี้"
        onChange={onInsert}
        value={trends}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "10px",
        }}
      >
        <Button
          type="primary"
          //   icon={<SendOutlined />}
          size="large"
          onClick={sendData}
        >
          ส่ง
        </Button>
      </div>

      {contextHolder}
    </div>
  );
};

export default InsertTrends;
