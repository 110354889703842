import React from "react";
import TableSocialMedia from "../features/socialMedia";
import MainLayout from "../common/layouts/main";

const SocialMedia = () => {
  return (
    <MainLayout>
      <TableSocialMedia />
    </MainLayout>
  );
};

export default SocialMedia;
