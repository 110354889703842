import dayjs from "dayjs";

const gen = [
  {
    label: "B",
    value: "Baby",
  },
  {
    label: "X",
    value: "X",
  },
  {
    label: "Y",
    value: "Y",
  },
  {
    label: "Z",
    value: "Z",
  },
  {
    label: "Alpha",
    value: "Alpha",
  },
];

const gender = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

const typeInfluencer = [
  {
    label: "Basic",
    value: "organic",
  },
  {
    label: "WEB Cookie",
    value: "web_cookie",
  },
  {
    label: "API",
    value: "api",
  },
];

const category = [
  {
    label: "Beauty",
    value: 1,
  },
  {
    label: "Fashion",
    value: 2,
  },
  {
    label: "Photographer",
    value: 3,
  },
  {
    label: "Blogger Vlogger",
    value: 4,
  },
  {
    label: "Food beverage",
    value: 5,
  },
  {
    label: "Sports fitness",
    value: 6,
  },
  {
    label: "Entertainment",
    value: 7,
  },
  {
    label: "Gamer",
    value: 8,
  },
  {
    label: "Technology",
    value: 9,
  },
  {
    label: "Environment",
    value: 10,
  },
  {
    label: "Parenting",
    value: 11,
  },
  {
    label: "Travel",
    value: 12,
  },
];

const initialFormValue = {
  requirement: "",
  total: "1",
  socialMedia: "facebook",
  startDate: dayjs(new Date()),
  startTime: dayjs(),
  stopDate: "",
  stopTime: "",
  gen: ["Baby", "X", "Y", "Z", "Alpha"],
  gender: ["male", "female"],
  category: [],
  audience: true,
  config: ["organic", "web_cookie", "api"],
};

export { gen, gender, category, initialFormValue, typeInfluencer };
