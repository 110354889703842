import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import FilterTrendLatest from "../features/updateTrend/filterTrend";
import MainLayout from "../common/layouts/main";
import CardsTrend from "../features/updateTrend/CardsTrend";
import UpdateTrendProvider from "../features/updateTrend/provider/updateTrendProvider";
import { StyledPagination } from "../common/components/ThemeStyledComponent";
import { setIsOpenInsertModal } from "../features/InsertTrend/insertTrendSlice";
import InsertTrend from "../features/InsertTrend";

const TrendBackground = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
  gap: 16px;
  height: calc(100dvh - 50px);
  background: ${(props) => props.theme.backgroundDashBoard};

  overflow-y: auto;

  .pagination-layout {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 0px;
    /* gap: 11px; */
    padding-bottom: 30px;
  }
`;

const TiltleTrend = styled.div`
  display: flex;
  padding: var(--Padding-All-P8, 8px) 16px;
  align-items: center;
  gap: 18px;
  align-self: stretch;
  background: #f4f4f4;

  .text-title {
    color: #2a2c2d;
    font-family: Sarabun;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.15px;
  }
`;

const UpdateTrendLatest = () => {
  const dispatch = useDispatch();
  const updateTrendProvider = UpdateTrendProvider();
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTrendData, setCurrentTrendData] = useState([]);
  const pageSize = 4;

  const fetchData = async () => {
    try {
      const res = await updateTrendProvider.getUpdateListTrend();
      if (res) {
        console.log("res", res);
        setNewsData(res);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = newsData.slice(startIndex, endIndex);
    setCurrentTrendData(currentData);
  }, [newsData, currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onOpenModal = () => {
    dispatch(
      setIsOpenInsertModal({
        value: true,
      }),
    );
  };

  return (
    <MainLayout>
      <TrendBackground id="list-trend">
        <TiltleTrend>
          <span className="text-title">เทรนด์ล่าสุด</span>
        </TiltleTrend>
        <FilterTrendLatest onOpenModal={onOpenModal} />
        <CardsTrend data={currentTrendData} />
        <div className="pagination-layout">
          {Boolean(newsData.length > 4) && (
            <StyledPagination
              current={currentPage}
              total={newsData.length}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger={false}
              style={{
                marginTop: "20px",
                textAlign: "center",
              }}
            />
          )}
        </div>
      </TrendBackground>
      <InsertTrend />
    </MainLayout>
  );
};

export default UpdateTrendLatest;
