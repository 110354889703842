/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import { Flex } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import styled from "styled-components";
import { myNewWindow } from "../../../../../common/utils/helper";
import IconSocial from "../../../../../common/components/IconSocial";
import ProfileCard from "../../../../../common/components/ProfileCard";
import SocialButton from "../../../../../common/components/SocialButton";
import GroupSocial from "../../../../../common/components/GroupSocial";

/* 

<CardEngagementShareAndLikeAndQuote
  data={{
    dateTime: "01/31/24",
    socialType: "facebook",
    socialButtonType: "Like",
    profileName: "profile Name",
    profileRole: "owner",
    // status: "Done",
  }}
/>

*/

const StyledCardEngagementShareAndLikeAndQuote = styled(Flex)`
  /* padding: 1rem; */
  cursor: pointer;
`;

const StyledTextDateTime = styled(Flex)`
  color: ${(props) => props.theme.colorTextButton};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

const StyledCardStatus = styled(Flex)`
  color: ${(props) => props.theme[props.color]};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
`;

export const CardStatus = (status = "") => {
  switch (status.toLowerCase()) {
    case "Waiting":
      return <StyledCardStatus color="colorWaiting">{status}</StyledCardStatus>;
      break;
    case "Approved":
      return (
        <StyledCardStatus color="colorApproved">{status}</StyledCardStatus>
      );
      break;
    case "Done":
      return <StyledCardStatus color="colorDone">{status}</StyledCardStatus>;
      break;
    case "Rejected":
      return (
        <StyledCardStatus color="colorRejected">{status}</StyledCardStatus>
      );
      break;
    default:
      break;
  }
};

const CardEngagementShareAndLikeAndQuote = ({ data = {} }) => {
  const {
    dateTime = "",
    groupSocial = [],
    socialType = "",
    socialButtonType = "",
    profileName = "",
    profileRole = "",
    id = "",
  } = data || {};

  let win = null;
  async function handleCloseWindowMission() {
    if (win.location !== "about:blank") {
      // await fetchSubmission();
    }
  }
  const handleOpenWindow = async (contentId) => {
    win = myNewWindow(
      `/my-engagements/${socialButtonType}/detail/${contentId}`,
      "",
    );
    win.onunload = handleCloseWindowMission;
  };

  return (
    <StyledCardEngagementShareAndLikeAndQuote
      justify="space-between"
      align="center"
      gap="16px"
      onClick={() => handleOpenWindow(id)}
    >
      <Flex gap="16px" align="center">
        <Flex
          style={{
            minWidth: "80px",
          }}
        >
          {groupSocial.length > 0 && <GroupSocial list={groupSocial} />}
          {socialType.length > 0 && <IconSocial type={socialType} />}
        </Flex>
        <Flex gap="32px" align="center">
          <Flex
            style={{
              minWidth: "150px",
              width: "180px",
            }}
          >
            <ProfileCard label={profileName} roleUser={profileRole} />
          </Flex>
          {socialButtonType && (
            <StyledTextDateTime
              align="center"
              gap="4px"
              wrap="wrap"
              style={{
                minWidth: "150px",
              }}
            >
              Missions : <SocialButton type={socialButtonType} />
            </StyledTextDateTime>
          )}
        </Flex>
      </Flex>

      <Flex gap="32px" align="center">
        {/* ----------------- 2024-05-24 19:15 ----------------- */}
        {dateTime && (
          <StyledTextDateTime
            style={{
              minWidth: "230px",
            }}
          >
            Submission Date : {dayjs(dateTime).format("YYYY-MM-DD HH:mm")}
          </StyledTextDateTime>
        )}
      </Flex>
    </StyledCardEngagementShareAndLikeAndQuote>
  );
};

export default CardEngagementShareAndLikeAndQuote;
