import { Select } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { setKeySocialCard } from "../../dashboard/socialCard/socialCardSlice";
import { stateSelectCardFilter } from "../../listTrend/filterList";

const SelectSocialNewsArchive = () => {
  const dispatch = useDispatch();
  const { sortby } = useSelector((state) => state.socialCard);

  const handleChange = (value) => {
    dispatch(
      setKeySocialCard({
        key: "sortby",
        value,
      }),
    );
  };

  return (
    <Select
      style={{
        width: "140px",
      }}
      onChange={handleChange}
      options={stateSelectCardFilter}
      value={sortby}
    />
  );
};

export default SelectSocialNewsArchive;
