import styled from "styled-components";
import { Flex } from "antd";
import { keysIcons } from "../../utils/keyDatas";

const IconSocial = ({ type = "" }) => {
  const StyledIconSocial = styled(Flex)`
    img {
      width: 18px;
      height: 18px;
      object-fit: cover;
      border-radius: 50%;
      background-color: ${(props) => props.theme.white};
    }
  `;

  return (
    <StyledIconSocial justify="flex-start" align="center" gap="8px">
      {!!type && <img src={keysIcons[type.toLowerCase()]} alt="imageProfile" />}
    </StyledIconSocial>
  );
};

export default IconSocial;
