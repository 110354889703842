import { Checkbox, Form } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../common/hooks/useProviderAuth";
import missionProvider from "../missions/provider/missionProvider";
import { gen, gender, typeInfluencer } from "./mockData";

// const StyledCheckboxAudience = styled(Checkbox)`
//   color: ${(props) => props?.theme?.colorTextButton};
//   font-size: 16px;
//   font-weight: 700;
//   width: fit-content;
// `;

const StyleListCategory = styled(Checkbox.Group)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 700px;

  .ant-checkbox-wrapper {
    width: calc((700px - ${8 * (4 - 1)}px) / ${4});
  }
`;

const SubTitle1 = styled.span`
  color: ${(props) => props.theme.colorTextButton};
  /* CMS/body2 */
  /* font-family: Sarabun; */
  font-style: normal;
  font-size: 16px !important;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15px;
  font-weight: bold;
`;

const TextRed = styled.span`
  color: ${(props) => props.theme.infoinfoRed};
  font-size: 16px;
`;

const Audience = (props) => {
  const { form } = props;
  const { user } = useAuth();
  // const typeInfluencer1 = Form.useWatch("typeInfluencer", form);
  const category = Form.useWatch("audience", form) || [];
  const [categories, setCategories] = useState([]);
  const categoriesApi = missionProvider();
  const checkboxComp = (value, key) => {
    return value.map((item) => (
      <Checkbox
        key={item?.label}
        value={item?.value}
        checked={key === "category" && category.includes(item.value)}
        defaultChecked={key === "category" && category.includes(item.value)}
      >
        {item?.label}
      </Checkbox>
    ));
  };

  const getCategories = async () => {
    const res = await categoriesApi.getCategories();
    form.setFieldsValue({
      category: res.map((item) => item.id),
    });
    setCategories(
      res.map((item) => ({
        ...item,
        label: item.name_en,
        value: item.id,
      })),
    );
  };

  useEffect(() => {
    getCategories();
    return () => {};
  }, []);

  return (
    <>
      {/* <Form.Item name="audience" valuePropName="checked">
        <StyledCheckboxAudience defaultChecked>Audience</StyledCheckboxAudience>
      </Form.Item> */}
      {/* <SubTitle1>Audience</SubTitle1> */}

      {
        <>
          {/* <Form.Item label="Audience" colon={false}> */}
          <SubTitle1>Audience</SubTitle1>
          {/* </Form.Item> */}
          {/* ============= Gen ============= */}
          <Form.Item
            colon={false}
            className="sub-content"
            name="gen"
            label="Gen"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Checkbox.Group>{checkboxComp(gen)}</Checkbox.Group>
          </Form.Item>

          {/* ============= Gender ============= */}
          <Form.Item
            colon={false}
            className="sub-content"
            name="gender"
            label="Gender"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Checkbox.Group>{checkboxComp(gender)}</Checkbox.Group>
          </Form.Item>

          {/* ============= Category ============= */}
          <Form.Item
            className="sub-content"
            name="category"
            label="Influencer Category"
            colon={false}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyleListCategory>
              {checkboxComp(categories, "category")}
            </StyleListCategory>
          </Form.Item>

          {/* ============= Type of Influencer ============= */}
          {(user?.allowBot && (
            <Form.Item
              colon={false}
              className="sub-content"
              name="posterTypes"
              label={
                <span>
                  <span>Type of Influencer</span>{" "}
                  <TextRed>(Administrator only)</TextRed>
                </span>
              }
              labelFontSize={50}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Checkbox.Group>{checkboxComp(typeInfluencer)}</Checkbox.Group>
            </Form.Item>
          )) ||
            null}
        </>
      }
    </>
  );
};

export default Audience;
