const FirstLabel = (label = "", icSplit = 2) => {
  return label
    .split(" ", icSplit)
    .map((item) => item.toUpperCase().substring(0, 1))
    .join("");
};

const FirstUpperLabel = (label = "") => {
  return label.charAt(0).toUpperCase() + label.slice(1);
};

export { FirstLabel, FirstUpperLabel };
