// eslint-disable-next-line import/no-extraneous-dependencies
// import queryString from "query-string";
import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const SubmissionOwnerProvider = () => {
  const { axios } = useAxios();

  const getSubmissionOwner = async (params) => {
    try {
      const url = `${host}/sub-mission-contents/owner?${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  const getSubmissionOwnerById = async (id) => {
    try {
      const url = `${host}/sub-mission-contents/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return [];
    }
  };

  const updateSubmissionStatusSubmit = async (params) => {
    try {
      const url = `${host}/sub-mission-contents/${params.id}/${params.status}`;
      const res = await axios.patch(url);
      return res.data;
    } catch (error) {
      console.log("error", error);
      throw error;
      // return [];
    }
  };

  const getSubMissionContent = async (params) => {
    try {
      const url = `${host}/sub-mission-contents/mission/${params.id}/owner?${params.query}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  const getEngagementById = async (id) => {
    try {
      const url = [`${host}/missions/${id}`].filter(Boolean).join("");
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log(`error:`, error);
      throw error;
    }
  };

  return {
    getSubmissionOwner,
    getSubmissionOwnerById,
    updateSubmissionStatusSubmit,
    getSubMissionContent,
    getEngagementById,
  };
};

export default SubmissionOwnerProvider;
