/* eslint-disable import/prefer-default-export */
import { Input } from "antd";
import styled from "styled-components";

export const ThemeStyledInput = styled(Input)`
  &:hover {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
  &:focus {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
`;
