import React, { useEffect } from "react";
import { Chart } from "@antv/g2";
import { formatNumberLowercase } from "../../../../common/utils/format";

const PieChart = (props) => {
  const { data, idChart, trendName, total, name } = props;

  const calculatePercent = (event) => {
    const maxCount = Math.max(...event.map((item) => item.trendCount));
    const percent = maxCount * 0.02;
    return percent;
  };

  useEffect(() => {
    const chart = new Chart({
      container: `pie-chart-${idChart}-${name}`,
      height: 160,
      // width: 500,
      autoFit: true,
    });

    chart.coordinate({
      type: "theta",
      outerRadius: 1.1,
      innerRadius: 0.45,
    });

    chart
      .interval()
      .data(data)
      .transform({
        type: "stackY",
      })
      .encode("y", "trendCount")
      .encode("color", "platform")
      .scale("color", {
        relations: [
          ["twitter", "#1BBBFF"],
          ["facebook", "#3D7DE5"],
          ["tiktok", "#49474C"],
          ["internet", "#0EA67F"],
          ["pantip", "#7F36DB"],
          ["youtube", "#FF2643"],
        ],
      })

      .label({
        text: (d) =>
          d.trendCount > calculatePercent(data) ? d.trendCount : "",
        fontSize: 11,
        offset: 1,
      })
      // .label({
      //   text: "trendCount",
      //   fontWeight: "bold",
      //   offset: 1,
      // })
      .legend(false)
      .tooltip((e) => ({
        name: e.platform,
        value: e.trendCount,
      }));

    chart
      .text()
      .style(
        "text",
        trendName.length > 10 ? `${trendName.slice(0, 7)}...` : trendName,
      )
      .style("x", "50%")
      .style("y", "50%")
      .style("dy", -15)
      // .style("dy", 25)
      .style("fontSize", 9)
      .style("fill", "#212023")
      .style("textAlign", "center")
      .tooltip(() => ({
        color: "#fff",
        name: trendName,
        value: total,
      }));

    chart
      .text()
      .style("text", formatNumberLowercase(total))
      .style("x", "50%")
      .style("y", "50%")
      .style("dy", 5)
      .style("fontSize", 18)
      .style("fontWeight", "bold")
      .style("fill", "#212023")
      .style("textAlign", "center")
      .tooltip(() => ({
        color: "#fff",
        name: trendName,
        value: total,
      }));

    chart.render();
  }, [data]);

  return <div id={`pie-chart-${idChart}-${name}`} />;
};

export default PieChart;
