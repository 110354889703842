/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable object-curly-newline */
import styled, { css } from "styled-components";
import { Button, Flex, Form, Radio, Modal, Checkbox } from "antd";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { useParams } from "react-router-dom";
import { IconWarning } from "../../missions/MyPostsList";
import GroupRadioSocial from "../../../common/components/GroupRadioSocial";
import ModalConfirm from "../../../common/components/ModalConfirm";
import Audience from "./audience";
import { initialFormValue } from "./mockData";
import SocialMediaProvider from "../provider/socialMediaProvider";
import { useAuth } from "../../../common/hooks/useProviderAuth";
import IconRankInfluEndUser from "../../../assets/icons/icon-rank-influencer-endUser.svg";
import IconRankInfluNano from "../../../assets/icons/icon-rank-influencer-nano.svg";
import IconRankInfluMicro from "../../../assets/icons/icon-rank-influencer-micro.svg";
import IconRankInfluMidTier from "../../../assets/icons/icon-rank-influencer-midTier.svg";
import IconRankInfluMacro from "../../../assets/icons/icon-rank-influencer-macro.svg";
import IconRankInfluMega from "../../../assets/icons/icon-rank-influencer-mega.svg";
import SocialMediaBotProvider from "../../socialMediaBot/provider/socialMediaBotProvider";

export const StyleInputForm = styled.div`
  h2 {
    padding-bottom: 16px;
    border-bottom: 1px solid ${(props) => props.theme.colorBorderCardSocial};
  }
  width: 100%;
  height: calc(100vh - 73px);
  overflow-y: auto;
  height: calc(100dvh - 50px);
  overflow-y: scroll;
  padding-bottom: 60px;
  .job {
    .ant-form-item-explain-error {
      padding-left: 40px;
    }
  }

  .ant-form-item-label > label {
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: Sarabun !important;
  }

  .ant-form-item-required {
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: Sarabun !important;
  }

  .label {
    font-family: Sarabun;
    font-size: 16px;
    font-weight: 700;
  }

  .sub-label {
    color: ${(props) => props.theme.colorTertiary};
    font-family: Sarabun;
    font-size: 12px;
    font-weight: 400;
  }

  .ant-radio-group {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const StyleFooter = styled.div`
  border-top: solid 1px ${(props) => props?.theme?.actionBorder};
  padding: 20px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: white;

  .ant-form-item {
    margin-bottom: 0px;
  }
`;

const StyleTitleLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;

  .title {
    font-size: 16px;
  }
  .description {
    font-size: 14px;
    color: #bebebe;
  }
`;

const StyleFormItem1 = styled(Form.Item)`
  .ant-form-item-control-input-content {
    justify-content: center;
    display: flex;
  }
`;

const StyleButtonFooter = styled(Button)`
  width: 180px;
  height: 100%;
  padding: 6px 16px;
  font-size: 16px;
  font-weight: 700;
`;

const StyleButton = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  border-top: 1px solid ${(props) => props?.theme?.actionBorder};
  background-color: ${(props) => props?.theme?.white};

  .ant-btn-cancel {
    padding: 6px 16px;
    height: 100%;
    width: 180px;
    font-size: 16px;
    font-weight: 700;
    /* color: ${(props) => props?.theme?.colorTextDesc}; */
  }

  .ant-btn-primary {
    font-size: 16px;
    font-weight: 700;
    /* color: ${(props) => props?.theme?.white}; */
    padding: 6px 16px;
    height: 100%;
    width: 180px;
  }
`;

const StyledTextArea = styled(TextArea)`
  &:hover {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
  &:focus {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
`;
const TierContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  height: fit-content;
  overflow-x: auto;
`;
const TierItem = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  padding: 5px;
  border-radius: 4px;
  background: ${(props) =>
    props.active
      ? "linear-gradient(112.27deg, #D196FF 2.36%, #7D5DE8 82.93%)"
      : "#f0f0f0"};
  color: ${(props) => (props.active ? "white" : "rgba(42, 44, 45, 0.25)")};
  cursor: not-allowed;
  ${(props) =>
    props.active &&
    css`
      &:hover {
        background-color: #7261c4;
      }
    `}
  span {
    font-weight: ${(props) => (props.active ? "bold" : "normal")};
  }

  .tier-label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
  }

  .tier-range {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => (props.active ? "white" : "rgba(42, 44, 45, 0.25)")};
  }

  .icon {
    width: 34px;
    height: 34px;
    margin-right: 8px;
  }

  .tier-range-small {
    font-size: 10px;
  }
`;

const tiers = [
  {
    label: "End User",
    range: "(Below 1K)",
    value: "endUser",
    min: 0,
    max: 1000,
    icon: IconRankInfluEndUser,
  },
  {
    label: "Nano",
    range: "(1K-10K)",
    value: "nanoUser",
    min: 1001,
    max: 10000,
    icon: IconRankInfluNano,
  },
  {
    label: "Micro",
    range: "(10K-50K)",
    value: "microUser",
    min: 10001,
    max: 50000,
    icon: IconRankInfluMicro,
  },
  {
    label: "Mid-Tier",
    range: "(50K-100K)",
    value: "midTierUser",
    min: 50001,
    max: 100000,
    icon: IconRankInfluMidTier,
  },
  {
    label: "Macro",
    range: "(100K-1M)",
    value: "macroUser",
    min: 100001,
    max: 1000000,
    icon: IconRankInfluMacro,
  },
  {
    label: "Mega",
    range: "(1M+)",
    value: "topStarUser",
    min: 1000001,
    max: Infinity,
    icon: IconRankInfluMega,
  },
];

const TierOfInfluencer = ({ followerCount }) => {
  return (
    <TierContainer>
      {tiers.map((tier) => (
        <TierItem
          key={tier.value}
          active={followerCount >= tier.min && followerCount <= tier.max}
        >
          {followerCount >= tier.min && followerCount <= tier.max && (
            <img src={tier.icon} alt={`${tier.label} icon`} className="icon" />
          )}
          <div>
            <span className="tier-label">{tier.label}</span>
            <span
              className={`tier-range ${
                ["endUser", "midTierUser"].includes(tier.value) &&
                followerCount >= tier.min &&
                followerCount <= tier.max
                  ? "tier-range-small"
                  : ""
              }`}
            >
              {tier.range}
            </span>
          </div>
        </TierItem>
      ))}
    </TierContainer>
  );
};

const WindowAddSocialMedia = () => {
  const socialMediaProvider = SocialMediaProvider();
  const socialMediaBotProvider = SocialMediaBotProvider();
  const { socialId } = useParams();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const [modal, contextHolder] = Modal.useModal();
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [formSubmitSocialMedia] = Form.useForm();
  const socialMedia = Form.useWatch("socialMedia");
  const [modalAlertType, setModalAlertType] = useState("confirm");
  const [checkboxValue, setCheckboxValue] = useState(["web_cookie"]);
  const [followerCount, setFollowerCount] = useState("follower");
  const categoryWatch = Form.useWatch("category", form);
  const alertModal = (message, content) => {
    modal.warning({
      title: message,
      content,
      icon: <IconWarning />,
      okText: "Ok",
      onOk: () => window.close(),
      centered: true,
    });
  };

  const checkPermissionUserRole = (permissionsRole) => {
    if (user?.roles)
      return user?.roles?.some((role) => permissionsRole.includes(role));
    return false;
  };

  const createSocial = async (val) => {
    try {
      const body = {
        platform: val.socialMedia,
        social_name: val.name,
        profile_id: val.userId,
        profile_url: val.url,
        // category_id: val.category,
        // audience_gender: val.gender,
        // audience_generation: val.gen,
        follower: parseFloat(val.follower.replace(/,/g, "")),
      };

      if (checkPermissionUserRole(["superadmin", "admin"])) {
        body.category_id = val.category;
        body.audience_gender = val.gender;
        body.audience_generation = val.gen;
      }

      if (socialId === "add") {
        const res = await socialMediaProvider.createSocial(body);
        if (res.social_request_id) {
          window.close();
        }
      } else {
        const res = await socialMediaProvider.editSocial(socialId, body);
        if (res?.code === 422) {
          if (res?.message === "Permission denied") {
            alertModal("An error has occurred.", "Please try again.");
          }
          if (res?.message === "Profile url already") {
            form.setFields([
              {
                name: "url",
                errors: ["Your data is already exists"],
              },
            ]);
          }
          if (res?.message === "Profile id already") {
            form.setFields([
              {
                name: "userId",
                errors: ["Your data is already exists"],
              },
            ]);
          }
        }
        if (res.status === "success") {
          window.close();
        }
      }
    } catch (error) {
      if (error.code === 422 && error.message === "Profile id already") {
        form.setFields([
          {
            name: "userId",
            errors: ["Your data is already exists"],
          },
        ]);
      } else if (
        error.code === 422 &&
        error.message === "Profile url already"
      ) {
        form.setFields([
          {
            name: "url",
            errors: ["Your data is already exists"],
          },
        ]);
      } else {
        alertModal(error.message, "");
      }
    }
  };

  const createSocialBot = async (val) => {
    try {
      const configBody = {
        organic: {
          allow: false,
        },
        api: {
          allow: val.config.includes("api"),
          key: val.api ? val.api : "",
          token: val.apiToken ? val.apiToken : "",
        },
        web_cookie: {
          allow: val.config.includes("web_cookie"),
          cookie: val.web_cookie ? val.web_cookie : "",
        },
      };

      const body = {
        platform: val.socialMedia,
        social_name: val.name,
        profile_id: val.userId,
        profile_url: val.url,
        category_id: val.category,
        audience_gender: val.gender,
        audience_generation: val.gen,
        follower: parseFloat(val.follower.replace(/,/g, "")),
        config: configBody,
      };

      if (socialId === "add") {
        const res = await socialMediaBotProvider.createSocialBot(body);
        if (res.social_request_id) {
          window.close();
        }
      } else {
        const res = await socialMediaBotProvider.editSocialBot(socialId, body);
        if (res.status === "success") {
          window.close();
        }
        if (res?.code === 422) {
          if (res?.message === "Permission denied") {
            alertModal("An error has occurred.", "Please try again.");
          }
          if (res?.message === "Profile url already") {
            form.setFields([
              {
                name: "url",
                errors: ["Your data is already exists"],
              },
            ]);
          }
          if (res?.message === "Profile id already") {
            form.setFields([
              {
                name: "userId",
                errors: ["Your data is already exists"],
              },
            ]);
          }
          if (res?.message === "Web cookie already") {
            form.setFields([
              {
                name: "web_cookie",
                errors: ["Your data is already exists"],
              },
            ]);
          }
          if (res?.message === "Api key already") {
            form.setFields([
              {
                name: "api",
                errors: ["Your data is already exists"],
              },
            ]);
          }
          if (res?.message === "Api token already") {
            form.setFields([
              {
                name: "apiToken",
                errors: ["Your data is already exists"],
              },
            ]);
          }
        }
      }
    } catch (error) {
      if (error.code === 422 && error.message === "Profile id already") {
        form.setFields([
          {
            name: "userId",
            errors: ["Your data is already exists"],
          },
        ]);
      } else if (
        error.code === 422 &&
        error.message === "Profile url already"
      ) {
        form.setFields([
          {
            name: "url",
            errors: ["Your data is already exists"],
          },
        ]);
      } else if (error.code === 422 && error.message === "Web cookie already") {
        form.setFields([
          {
            name: "web_cookie",
            errors: ["Your data is already exists"],
          },
        ]);
      } else if (error.code === 422 && error.message === "Api key already") {
        form.setFields([
          {
            name: "api",
            errors: ["Your data is already exists"],
          },
        ]);
      } else if (error.code === 422 && error.message === "Api token already") {
        form.setFields([
          {
            name: "apiToken",
            errors: ["Your data is already exists"],
          },
        ]);
      } else {
        alertModal(error.message, "");
      }
    }
  };
  const handleSubmit = (id) => {
    if (user?.roles.includes("superadmin")) {
      modal.confirm({
        title: "Are you sure you want to do this?",
        content: "Would you like to confirm?",
        icon: <IconWarning />,
        centered: true,
        okText: "OK",
        onOk: () => createSocialBot(id),
        cancelText: "Cancel",
      });
    } else {
      modal.confirm({
        title: "Are you sure you want to do this?",
        content: "Would you like to confirm?",
        icon: <IconWarning />,
        centered: true,
        okText: "OK",
        onOk: () => createSocial(id),
        cancelText: "Cancel",
      });
    }
  };

  const setEditValue = (state) => {
    for (const [key, val] of Object.entries(state)) {
      form.setFieldValue(key, val);
    }
  };
  const replaceNullWithEmpty = (obj) => {
    for (const key in obj) {
      if (obj[key] === null) {
        obj[key] = "";
      } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        replaceNullWithEmpty(obj[key]);
      }
    }
  };
  const fetchData = async () => {
    try {
      const res = await socialMediaProvider.getSocialById(socialId);

      if (res) {
        replaceNullWithEmpty(res);

        const state = {
          socialMedia: res.platform,
          name: res.name,
          userId: res.profile_id,
          url: res.profile_url,
          category: res.categories?.map((item) => item.category_id) || [],
          gender: res.audience_gender || "",
          gen: res.audience_generation || "",
          follower: res.follower ? res.follower.toLocaleString() : "",
          config: res.config
            ? Object.keys(res.config).filter((key) => res.config[key].allow)
            : [],
          api: res.config?.api?.key || "",
          apiToken: res.config?.api?.token || "",
          web_cookie: res.config?.web_cookie?.cookie || "",
        };
        setCheckboxValue(
          res.config
            ? Object.keys(res.config).filter((key) => res.config[key].allow)
            : [],
        );
        setFollowerCount(res.follower || 0);

        setEditValue(state);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  useEffect(() => {
    if (socialId !== "add") {
      fetchData();
    }
    const followerInput = document.getElementById("followerInput");

    followerInput.addEventListener("paste", (event) => {
      const pastedData = event.clipboardData.getData("Text");

      if (isNaN(parseFloat(pastedData))) {
        event.preventDefault();
      }
    });
  }, [socialId]);

  const validateWhitespace = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(new Error("Please enter letters."));
    }
    return Promise.resolve();
  };

  return (
    <StyleInputForm>
      <div id="input-form" style={{ padding: "16px" }}>
        <h2 className="input-form-header">
          {socialId === "add" ? "Social media Details" : "Social media Details"}
        </h2>
      </div>

      <Form
        form={form}
        initialValues={initialFormValue}
        labelCol={{
          span: 3,
        }}
        style={{
          width: "100%",
          maxWidth: "1000px",
          margin: "auto",
          padding: "16px",
        }}
        onFinish={handleSubmit}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          labelCol={{ style: { width: 200 } }}
          colon={false}
          label="Social media"
          name="socialMedia"
        >
          <GroupRadioSocial
            active={socialId !== "add" && form.getFieldValue("socialMedia")}
          />
        </Form.Item>
        {/* Audience */}
        {checkPermissionUserRole(["superadmin", "admin"]) && (
          <Form.Item>
            <Audience form={form} />
          </Form.Item>
        )}
        <Form.Item
          colon={false}
          labelCol={{ style: { width: 200 } }}
          label={<span className="label">Profile URL</span>}
          rules={[
            {
              required: true,
              message: "",
            },
            {
              type: "url",
              required: true,
              message: "The domain you entered is invalid.",
            },
            { validator: validateWhitespace },
          ]}
          name="url"
        >
          <StyledTextArea placeholder="Please fill in" />
        </Form.Item>
        <Form.Item
          colon={false}
          labelCol={{ style: { width: 200 } }}
          label={
            <div>
              <span className="label">User ID </span>
              <span className="sub-label">(Limit 50 Characters)</span>
            </div>
          }
          name="userId"
          rules={[
            {
              required: true,
              message: "Please enter a user ID",
            },
            { validator: validateWhitespace },
          ]}
        >
          <StyledTextArea placeholder="Please fill in" maxLength="50" />
        </Form.Item>
        <Form.Item
          colon={false}
          labelCol={{ style: { width: 200 } }}
          name="name"
          label={
            <div>
              <span className="label">Name </span>
              <span className="sub-label">(Limit 50 Characters)</span>
            </div>
          }
          rules={[
            {
              required: true,
              message: "Please enter a name",
            },
            { validator: validateWhitespace },
          ]}
        >
          <StyledTextArea placeholder="Please fill in" maxLength="50" />
        </Form.Item>
        <Form.Item
          colon={false}
          labelCol={{
            style: {
              width: 200,
            },
          }}
          name="tierInfluencer"
          label={
            <div>
              {user?.roles?.includes("superadmin") && (
                <span
                  className="title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  Tier of Influencer
                </span>
              )}
            </div>
          }
        >
          <Form.Item
            name="follower"
            label={
              <StyleTitleLabel>
                <span
                  className="title"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Follower
                </span>
                <span className="sub-label">(Limit 10 Characters)</span>
              </StyleTitleLabel>
            }
            rules={[
              {
                required: true,
                message: "Please input your follower!",
              },
              {
                validator: validateWhitespace,
              },
            ]}
          >
            <StyledTextArea
              id="followerInput"
              placeholder="Please fill in"
              maxLength="10"
              onChange={(e) => setFollowerCount(e.target.value)}
              onKeyPress={(e) => {
                const regex = /^[0-9\b]+$/;
                if (!regex.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                const text = e.clipboardData.getData("text/plain");
                if (text.length <= 10 && /^[0-9]+$/.test(text)) {
                  setFollowerCount(text);
                  setTimeout(() => {
                    e.target.value = text;
                  }, 0);
                }
              }}
            />
          </Form.Item>
          {user?.roles?.includes("superadmin") && (
            <TierOfInfluencer followerCount={followerCount} />
          )}
        </Form.Item>
        {/* ส่วนของ type of influ  Superadmin เท่านั้น */}
        {user?.roles?.includes("superadmin") && (
          <Form.Item
            colon={false}
            labelCol={{ style: { width: 200 } }}
            name="config"
            label={
              <div>
                <span className="label">Type of Influencer </span>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Please select a type",
              },
            ]}
          >
            <Checkbox.Group
              onChange={(e) => setCheckboxValue(e)}
              value={checkboxValue}
              defaultValue={["web_cookie"]}
            >
              <Checkbox disabled> Basic </Checkbox>
              <Checkbox value="web_cookie"> WEB Cookie </Checkbox>
              <Checkbox value="api"> API </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        )}
        {user?.roles?.includes("superadmin") && (
          <>
            <Form.Item
              colon={false}
              labelCol={{ style: { width: 200 } }}
              name="web_cookie"
              label={
                <div>
                  <span className="label">Web Cookie </span>
                </div>
              }
              style={{
                display: checkboxValue?.includes("web_cookie")
                  ? "block"
                  : "none",
              }}
              rules={[
                {
                  required: !!checkboxValue?.includes("web_cookie"),
                  message: "Please enter a Web cookie",
                },
                { validator: validateWhitespace },
              ]}
            >
              <StyledTextArea placeholder="Please fill in" />
            </Form.Item>

            <>
              <Form.Item
                colon={false}
                labelCol={{ style: { width: 200 } }}
                name="api"
                label={
                  <div>
                    <span className="label">Key </span>
                  </div>
                }
                style={{
                  display: checkboxValue?.includes("api") ? "block" : "none",
                }}
                rules={[
                  {
                    required: !!checkboxValue?.includes("api"),
                    message: "Please enter a API Key",
                  },
                  { validator: validateWhitespace },
                ]}
              >
                <StyledTextArea placeholder="Please fill in" />
              </Form.Item>
              <Form.Item
                colon={false}
                labelCol={{ style: { width: 200 } }}
                name="apiToken"
                style={{
                  display: checkboxValue?.includes("api") ? "block" : "none",
                }}
                label={
                  <div>
                    <span className="label">Token </span>
                  </div>
                }
                rules={[
                  {
                    required: !!checkboxValue?.includes("api"),
                    message: "Please enter a API Token",
                  },
                  { validator: validateWhitespace },
                ]}
              >
                <StyledTextArea placeholder="Please fill in" />
              </Form.Item>
            </>
          </>
        )}
        <StyleButton align="center" justify="center" gap={16}>
          <Button type="cancel" onClick={() => window.close()}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Confirm
          </Button>
        </StyleButton>
      </Form>
      {contextHolder}
    </StyleInputForm>
  );
};

export default WindowAddSocialMedia;
