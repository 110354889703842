import React from "react";
import TableSubmissionContent from "./tableSubmissionContent";

const SubmissionContentOwner = () => {
  return (
    <div>
      <TableSubmissionContent />
    </div>
  );
};

export default SubmissionContentOwner;
