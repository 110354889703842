/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Anchor, Button, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { myNewWindow } from "../../common/utils/helper";
import FilterData from "../../common/components/FilterData";
import ModalConfirm from "../../common/components/ModalConfirm";
import {
  optionSortDefaultList,
  socialDefault,
} from "../../common/utils/filterDatas";
import EmptyDefault from "../../common/components/EmptyDefault";
import IconSocial from "../../common/components/IconSocial";
import SocailmediaProviderReview from "./provider/socialMedaiReviewProvider";

const StyleSocialMediaReview = styled.div`
  padding: 20px;
  .ant-anchor-link {
    text-decoration: none !important;
  }
`;

const StyleTable = styled(Table)`
  margin-top: 20px;
`;

const StyleBtn = styled(Button)`
  font-size: 16px;
  font-weight: 700;
  width: fit-content;
  height: 35px;
`;

const StyledStatusText = styled.span`
  color: ${(props) => props.theme.colorMagenta};
  weight: 700;
  size: 12px;
`;

const StyledTextAnchor = styled(Anchor)`
  .ant-anchor-link > .ant-anchor-link-title {
    color: ${(props) => props.theme.colorTextButton};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
    color: #448ef7 !important;
  }
`;

const SocialMediaReview = () => {
  const { queryFilterRedux } = useSelector((state) => state.filterData) || {};
  const socailmediaProviderReview = SocailmediaProviderReview();
  const newWindow = useRef(null);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [modalConfirmType, setModalConfirmType] = useState("confirm");
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [content, setContent] = useState([]);
  const limit = 50;

  const getListSocialMediaReview = async () => {
    try {
      const response = await socailmediaProviderReview.getListSocialMediaReview(
        {
          filter: queryFilterRedux,
        },
      );
      if (response) {
        setContent(response?.data);
        setTotal(response?.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    await getListSocialMediaReview();
  };

  useEffect(() => {
    if (queryFilterRedux) {
      getListSocialMediaReview();
    }
  }, [queryFilterRedux]);

  const modalConfirmDetails = {
    confirm: {
      title: "Do you want to do this!",
      subTitle: "",
      callbackBtnRight: () => setIsModalConfirmOpen(false),
      callbackBtnLeft: () => setIsModalConfirmOpen(false),
    },
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  // function ปิด window
  function handleCloseWindow() {
    if (
      newWindow?.current?.location &&
      newWindow?.current?.location !== "about:blank"
    ) {
      // eslint-disable-next-line no-use-before-define
      fetchData();
    }
  }

  // function เปิด window
  const handleOpenWindow = (record) => {
    if (newWindow?.current) newWindow?.current?.close();
    const windowRef = myNewWindow(
      `social-media-review/detail/${record?.id}/add`,
      "_blank",
    );
    windowRef.onunload = handleCloseWindow;
    newWindow.current = windowRef;
  };

  const columns = [
    {
      align: "left",
      width: "50px",
      title: "No",
      dataIndex: "No",
      key: "No",
      // eslint-disable-next-line no-unused-vars
      render: (_, record, index) => <span>{index + 1}</span>,
    },
    {
      align: "left",
      width: "50px",
      title: "Social",
      dataIndex: "platform",
      key: "platform",
      // eslint-disable-next-line no-unused-vars
      render: (_, record) => <IconSocial type={record?.platform} />,
    },
    {
      align: "left",
      width: "250px",
      title: "Profile URL",
      dataIndex: "profileUrl",
      key: "profileUrl",
      render: (_, record, index) => {
        const displayURL =
          record?.profile_url?.length > 40
            ? `${record.profile_url.substring(0, 40)}...`
            : record.profile_url;
        return (
          <StyledTextAnchor
            gap="8px"
            onClick={(e) => e.stopPropagation()}
            items={[
              {
                key: `a-${index + 1}`,
                href: record?.profile_url,
                title: displayURL,
                target: "_blank",
              },
            ]}
          />
        );
      },
    },
    {
      align: "left",
      width: "100px",
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      render: (_, record) => (
        <Tooltip title={record.profile_id}>
          <span>
            {record?.profile_id.length > 10
              ? `${record.profile_id.substring(0, 10)}`
              : record.profile_id}
          </span>
        </Tooltip>
      ),
    },
    {
      align: "left",
      width: "100px",
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Tooltip title={record.name}>
          <span>
            {record?.name.length > 10
              ? `${record.name.substring(0, 10)}`
              : record.name}
          </span>
        </Tooltip>
      ),
    },
    {
      align: "center",
      width: "200px",
      title: "Submitted Date",
      dataIndex: "submittedDate",
      key: "submittedDate",
      render: (_, record) => (
        <span>{dayjs(record?.requested_date).format("YYYY-MM-DD HH:mm")}</span>
      ),
    },
    {
      align: "center",
      width: "100px",
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        let status = "Submitted";
        switch (record?.status) {
          case "request":
            status = "Submitted";
            break;
          default:
            status = "Submitted";
            break;
        }
        return <StyledStatusText>{status}</StyledStatusText>;
      },
    },
    {
      align: "center",
      width: "150px",
      title: "",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <StyleBtn
          type="primary"
          disabled={
            record?.status === "done" ||
            dayjs(record?.end_date).isBefore(dayjs())
          }
          onClick={() => handleOpenWindow(record)}
        >
          Approve
        </StyleBtn>
      ),
    },
  ];

  return (
    <StyleSocialMediaReview>
      {/* Filter */}
      <FilterData
        title="Jobs"
        labelSocial="Social Media : "
        labelDetail={`Total ${total || 0} Items`}
        labelSort="Sorting :"
        labelFilter="Show :"
        callBackQueryFilter={() => {}}
        filter={{
          social: socialDefault,
          optionSortList: optionSortDefaultList,
          optionSortSelect: "asc",
          typeShow: "socialMediaReview",
        }}
      />

      {/* Table */}
      <StyleTable
        style={{
          height: "max-content",
        }}
        locale={{
          emptyText: <EmptyDefault />,
        }}
        columns={columns}
        dataSource={content}
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: currentPage,
          pageSize: limit,
          total,
          showLessItems: false,
          showSizeChanger: false,
          showQuickJumper: false,
          hideOnSinglePage: false,
          onChange: (page) => onChangePagination(page),
        }}
      />

      {/* modal confirm */}
      <ModalConfirm
        type="warning"
        title={modalConfirmDetails[modalConfirmType]?.title}
        subTitle={modalConfirmDetails[modalConfirmType]?.subTitle}
        callbackBtnRight={
          modalConfirmDetails[modalConfirmType]?.callbackBtnRight
        }
        callbackBtnLeft={modalConfirmDetails[modalConfirmType]?.callbackBtnLeft}
        textBtnLeft="Cancel"
        textBtnRight="Confirm"
        isModalOpen={isModalConfirmOpen}
      />
    </StyleSocialMediaReview>
  );
};

export default SocialMediaReview;
