/* eslint-disable no-unused-vars */
// eslint-disable-next-line object-curly-newline
import { Button, Card, Switch, Flex, Tooltip, Statistic, Modal } from "antd";
import styled from "styled-components";
// import { IconWarningFn } from "../../../missions/MyPostsList";
import { IconWarning } from "../../../missions/MyPostsList";
import missionProvider from "../../../missions/provider/missionProvider";
import {
  formatNumberUppercase,
  calculatedTime,
  formatTime,
} from "../../../../common/utils/format";
import IconPin from "../../../../assets/icons/icon-pin.svg";
import IconUnPin from "../../../../assets/icons/icon-unpin.svg";
import IconWarningYellow from "../../../../assets/icons/icon-warning.svg";
import iconX from "../../../../assets/icons/icon-x.svg";
import iconFaceBook from "../../../../assets/icons/icon-facebook.svg";
import iconPantip from "../../../../assets/icons/icon-pantip.svg";
import iconTikTok from "../../../../assets/icons/icon-tiktok.svg";
import iconInternet from "../../../../assets/icons/icon-internet.svg";
import IconBomb from "../../../../assets/icons/icon-bomb.svg";
import IconBoost from "../../../../assets/icons/icon-boost.svg";
import IconEngages from "../../../../assets/icons/icon-engages.svg";
import IconBombCancel from "../../../../assets/icons/icon-bomb-cancel.svg";
import IconBoostCancel from "../../../../assets/icons/icon-boost-cancel.svg";
import IconEngagesCancel from "../../../../assets/icons/icon-engages-stop.svg";
import IconClock from "../../../../assets/icons/icon-clock.svg";
// import StackBarChart from "./StackBarChart";
import StackBarImpact from "./StackBarImpact";
import CardProvider from "../../provider/cardProvider";
import ButtonBooster from "../../../../common/components/ButtonBooster";

const Stage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  justify-content: center;
  align-items: center;

  .loader {
    width: fit-content;
    position: absolute;
    font-weight: bold;
    top: -20px;
    /* font-family: monospace; */
    font-family: "Sarabun";
    font-size: 14px;
    color: ${(props) => {
      return props.color === "Bomb"
        ? props.theme.colorButtonBoomb
        : props.theme.colorButtonBoost;
    }};
    clip-path: inset(0 7px 0 0);
    animation: l4 1s steps(4) infinite;
  }
  .loader:before {
    content: "กำลัง ${(props) => props.color} ...";
  }
  @keyframes l4 {
    to {
      clip-path: inset(0 -1ch 0 0);
    }
  }
`;

const StyledCard = styled(Card)`
  /* min-height: ${(props) => props.theme.heightCardSocial}; */
  border-color: ${(props) =>
    props.warned
      ? props.theme.colorBorderCardSocialRed
      : props.theme.colorBorderCardSocial};

  .ant-card-body {
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
  }

  .right {
    min-width: 140px;
  }

  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }

  .button-group {
    width: 100%;
  }

  @media (max-width: 1250px) {
    .card-top {
      flex-direction: column;
      gap: 16px;
    }
    .card-bottom {
      flex-direction: column;
      gap: 24px;

      .left {
        gap: 0 !important;

        .reach-amount {
          justify-content: space-around;
        }
      }

      .right {
        .button-group {
          justify-content: space-evenly;
        }
      }
    }
  }

  .running {
    color: ${(props) => props.theme.colorTertiary};
    text-align: right;

    font-family: Sarabun;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
  }
`;

const StyledButton = styled(Button)`
  width: 110px;
  height: 44px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid ${(props) => props.theme.colorBorderCardSocial};
  font-size: 16px;
  font-weight: 700;

  .ant-btn-icon {
    margin-inline-end: 0 !important;

    img {
      width: 26px;
      height: 26px;
    }
  }
`;

const StyledButtonBomb = styled(StyledButton)`
  background: ${(props) =>
    props.bombed ? props.theme.colorButtonBoomb : props.theme.white};
  color: ${(props) =>
    props.bombed ? props.theme.white : props.theme.colorTextButton};

  &:not(:disabled):hover {
    border-color: ${(props) =>
      props.bombed ? props.theme.white : props.theme.colorButtonBoomb};
    background: ${(props) =>
      props.bombed
        ? props.theme.colorHoverButtonBomb
        : props.theme.white} !important;
    color: ${(props) =>
      props.bombed
        ? props.theme.white
        : props.theme.colorTextButton} !important;
  }
`;

const StyledButtonBoost = styled(StyledButton)`
  background: ${(props) =>
    props.boosted ? props.theme.colorButtonBoost : props.theme.white};
  color: ${(props) =>
    props.boosted ? props.theme.white : props.theme.colorTextButton};

  &:not(:disabled):hover {
    border-color: ${(props) =>
      props.boosted ? props.theme.white : props.theme.colorButtonBoost};
    background: ${(props) =>
      props.boosted
        ? props.theme.colorHoverButtonBoost
        : props.theme.white} !important;
    color: ${(props) =>
      props.boosted
        ? props.theme.white
        : props.theme.colorTextButton} !important;
  }
`;

const StyleCardTitle = styled.span`
  font-family: "Sarabun";
  font-size: 18px;
  font-weight: 700;
`;

const StyleCardDescription = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  color: ${(props) => props.theme.colorTextDesc};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

const StyleSwitch = styled(Switch)`
  background: ${(props) =>
    props.warned
      ? props.theme.colorMagenta
      : props.theme.colorToggleDisable} !important;

  &:hover {
    background: ${(props) => props.theme.colorMagenta} !important;
  }
`;

const StyleEngagement = styled(Statistic)`
  text-transform: capitalize;
  margin-top: 10px;

  .ant-statistic-title {
    font-size: 14px;
    font-weight: 400;
    color: #6b6b6b;
    margin: 0;
  }

  .ant-statistic-content {
    line-height: 20px;
  }

  .ant-statistic-content-value {
    font-size: 24px;
    font-weight: 700;
    color: #000;
  }

  .ant-statistic-content-value-int {
    font-size: 24px;
    font-weight: 700;
    color: #000;
  }
`;

const Cards = (props) => {
  const {
    data,
    fetchData,
    page = "dashboard",
    ButtonComp = () => null,
  } = props;
  const cardProvider = CardProvider();
  const [modal, contextHolder] = Modal.useModal();

  const handleToggle = async () => {
    await cardProvider.setWarningSocialCards(data.id);
    fetchData();
    // setDatas((prev) => [
    //   ...prev.map((item) =>
    //     item.id === data.id
    //       ? {
    //           ...item,
    //           warned: res.warned,
    //         }
    //       : item,
    //   ),
    // ]);
  };

  const handleStopEngage = async (id) => {
    const res = await cardProvider.stopBoostSocialCards(id);
    if (res.message === "success") {
      await fetchData();
      return "success";
    }
    return null;
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure you want to do this?",
      content: "Would you like to confirm?",
      icon: <IconWarning />,
      centered: true,
      okText: "OK",
      onOk: () => handleStopEngage(id),
      cancelText: "Cancel",
    });
  };

  let win = null;
  async function handleCloseWindowMission() {
    if (win.location !== "about:blank") {
      await fetchData();
    }
  }
  const handleClickButton = async () => {
    const windowWidth = 800;
    const windowHeight = 688;
    const leftPosition = window.screen.width / 2 - (windowWidth / 2 + 10);
    const topPosition = window.screen.height / 2 - (windowHeight / 2 + 50);
    win = window.open(
      `/create-engagement/${data.platform}/${data?.id}`,
      data.id,
      `height=${windowHeight},width=${windowWidth},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition}`,
    );
    win.onunload = handleCloseWindowMission;
  };

  // const handleClickButton = async (name) => {
  //   const left = window.screen.width / 2 - 800 / 2;
  //   const top = window.screen.height / 2 - 688 / 2;
  //   if (!data.boosted) {
  //     const newWindow = window.open(
  //       `/create-engagement/${data.platform}/${data?.id}`,
  //       data.id,
  //       `width=${800}, height=${688}, top=${top}, left=${left}`,
  //     );
  //     if (newWindow) {
  //       await fetchData();
  //       newWindow.onbeforeunload = async () => {
  //         console.log("111");
  //         await fetchData();
  //         alert("abc");
  //       };
  //     }
  //   } else {
  //     await confirm(data.id);
  //   }
  // };

  const handlePinCard = async () => {
    await cardProvider.setPinedSocialCards(data.id);
    fetchData();
    // setDatas((prev) => [
    //   ...prev.map((item) =>
    //     item.id === data.id
    //       ? {
    //           ...item,
    //           pinned: res.pinned,
    //         }
    //       : item,
    //   ),
    // ]);
  };

  const checkLogo = (type) => {
    switch (type) {
      case "twitter":
        return iconX;
      case "facebook":
        return iconFaceBook;
      case "pantip":
        return iconPantip;
      case "tiktok":
        return iconTikTok;
      case "internet":
        return iconInternet;
      default:
        return "";
    }
  };

  const checkLabelEngagement = (label) => {
    if (data.platform === "twitter") {
      if (label === "shares") return "reposts";
      if (label === "comments") return "replies";
      if (label === "likes") return "likes";
    } else {
      if (label === "shares") return "shares";
      if (label === "comments") return "comments";
      if (label === "likes") return "likes";
    }
    return label;
  };

  const checkLabelTrend = (negativePercent, positivePercent) => {
    if (positivePercent > negativePercent)
      return `Positive ${positivePercent}%`;
    if (negativePercent > positivePercent)
      return `Negative ${negativePercent}%`;
    return `Natural`;
  };

  return (
    <StyledCard id="card" warned={data?.warned}>
      {/* Top Part */}
      {contextHolder}

      <Flex className="card-top" justify="space-between">
        <Flex className="left" vertical gap="8px">
          <Flex align="start" gap="8px">
            <img
              src={checkLogo(data?.platform)}
              alt={`Logo-${data?.platform}`}
            />
            <StyleCardTitle>{data?.title}</StyleCardTitle>
            {page === "dashboard" && (
              <Tooltip title={data?.pinned ? "ยกเลิกการปักหมุด" : "ปักหมุด"}>
                <Button
                  type="text"
                  size="small"
                  onClick={() => handlePinCard(!data?.pinned)}
                  style={{
                    background: "transparent",
                  }}
                >
                  <img
                    src={data?.pinned ? IconPin : IconUnPin}
                    alt={`${data?.pinned ? "pin" : "Unpin"} icon`}
                  />
                </Button>
              </Tooltip>
            )}
          </Flex>
        </Flex>

        <Flex className="right" justify="right" align="start" gap="8px">
          {page === "dashboard" && (
            <>
              {data?.warned ? (
                <Flex align="center" gap="8px">
                  <img src={IconWarningYellow} alt="warning icon" />
                  <span>Following </span>
                </Flex>
              ) : (
                ""
              )}
              <StyleSwitch
                checked={data?.warned}
                warned={data?.warned}
                onChange={(e) => handleToggle(e)}
              />
            </>
          )}
          {page !== "dashboard" && <div>{ButtonComp}</div>}
        </Flex>
      </Flex>

      {/* Description */}
      <div>
        {page === "dashboard" && data?.description?.length > 0 && (
          <StyleCardDescription>{data?.description}</StyleCardDescription>
        )}
        {page !== "dashboard" && (
          <Flex gap="4px">
            <img src={IconClock} alt="clock icon" />
            <span
              style={{
                color: "#6D6E71",
                fontSize: "14px",
              }}
            >
              โพสเมื่อ {formatTime(data?.updated_date)}
            </span>
          </Flex>
        )}
        <Button
          type="link"
          size="small"
          style={{
            display: "flex",
            padding: 0,
            width: "100%",
          }}
        >
          <a
            href={data?.url}
            target="_blank"
            rel="noreferrer"
            style={{
              width: "100%",
            }}
          >
            <span
              className="card-refUrl"
              style={{
                display: "inline-block",
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "left",
              }}
            >
              {data?.url}
            </span>
          </a>
        </Button>
      </div>

      {/* Bottom part */}
      <Flex className="card-bottom" justify="space-between">
        <Flex className="left" vertical>
          {page === "dashboard" && (
            <div
              style={{
                position: "relative",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 700,
                }}
              >
                Impact :
              </span>
              <span
                style={{
                  fontSize: "10px",
                  fontWeight: 480,
                }}
              >
                {checkLabelTrend(data?.negative_trend, data?.positive_trend)}
              </span>
              {/* <Flex
                justify="space-between"
                style={{
                  fontSize: "10px",
                }}
              >
                <span>อันตราย</span>
                <span>ปลอดภัย</span>
              </Flex>
              <StackBarChart
                positiveValue={data?.positive_trend}
                negativeValue={data?.negative_trend}
              /> */}
              <StackBarImpact
                positiveValue={data?.positive_trend}
                negativeValue={data?.negative_trend}
              />
              <div
                id="stackbar-middle-splitter"
                style={{
                  // width: "50%",
                  height: "100%",
                  borderRight: "1px dashed white",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                }}
              />
            </div>
          )}
          <Flex className="reach-amount" gap="16px">
            {["shares", "likes", "comments"].map(
              (type) =>
                (data?.[type] || data?.[type] === 0) && (
                  <StyleEngagement
                    key={`reach-${type}`}
                    title={checkLabelEngagement(type)}
                    value={formatNumberUppercase(data?.[type])}
                  />
                ),
            )}
          </Flex>
        </Flex>

        {page === "dashboard" && (
          <Flex gap={4} align="center" justify="end" vertical>
            {Boolean(data?.boosted) && (
              <a href="/my-engagements" className="running">
                Missions running
              </a>
            )}
            <ButtonBooster onClick={() => handleClickButton()}>
              Boost Engages
            </ButtonBooster>
          </Flex>
        )}
      </Flex>
    </StyledCard>
  );
};

export default Cards;
