import React, { useState } from "react";
import styled from "styled-components";
import { Flex } from "antd";
import TwoColumn from "../../../../assets/icons/icon-button-layout-1.svg";
import FourColumn from "../../../../assets/icons/icon-button-layout-2.svg";
import SixColumn from "../../../../assets/icons/icon-button-layout-3.svg";

const ToggleContainer = styled(Flex)`
  border-radius: 10px;
  padding: 4px;
  background-color: ${(props) => props.theme.showColumnToggleBackground};
`;

const Option = styled(Flex)`
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  background-color: ${(props) =>
    props.active
      ? props.theme.colorMagenta
      : props.theme.showColumnToggleBackground};
  transition: all 0.3s ease;
`;

const SelectShowColumn = ({ setShowColumn }) => {
  const [activeOption, setActiveOption] = useState(2);

  const handleOptionClick = (option, setColumn) => {
    setActiveOption(option);
    setShowColumn(setColumn);
  };

  return (
    <Flex horizontal align="center" justify="center" gap={16}>
      <span>Show Column : </span>
      <ToggleContainer align="center">
        <Option
          active={activeOption === 1}
          onClick={() => handleOptionClick(1, 24)}
        >
          <img src={TwoColumn} alt="icon-button-layout-1" />
        </Option>
        <Option
          active={activeOption === 2}
          onClick={() => handleOptionClick(2, 12)}
        >
          <img src={FourColumn} alt="icon-button-layout-2" />
        </Option>
        <Option
          active={activeOption === 3}
          onClick={() => handleOptionClick(3, 8)}
        >
          <img src={SixColumn} alt="icon-button-layout-3" />
        </Option>
      </ToggleContainer>
    </Flex>
  );
};

export default SelectShowColumn;
