/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Flex,
  Form,
  Space,
  TimePicker,
} from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useParams } from "react-router-dom";
import TextAreaCommon from "../../common/components/TextArea/index";
import InputUnit from "../../common/components/InputUnit";
import { target, typeMission } from "./mockData";
import GroupRadioSocial from "../../common/components/GroupRadioSocial";
import WindowComponent from "../../common/components/WindowComponent";
import Audience from "./audience";
import CreateMissionProvider from "../createMission/provider/createMissionProvider";
import ModalConfirm from "../../common/components/ModalConfirm";

const StyleForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: calc(100vh - 73px);
  overflow-y: auto;
  padding: 16px 32px;

  .ant-form-item {
    margin: 0px;
  }

  .require {
    font-size: 16px !important;
    font-family: Sarabun !important;
    font-weight: 400;
    color: ${(props) => props.theme.errMsg};
    margin-right: 4px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    font-family: Sarabun !important;
    color: ${(props) => props.theme.colorTextButton};
  }

  .sub-title {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.colorTextButton};
  }

  label[title="Total"],
  label[title="Time"],
  label[title="Start Date"],
  label[title="Stop Date"] {
    font-weight: 400;
    &::before {
      display: none !important;
    }
  }

  .sub-content {
    padding-left: 24px;
  }

  .ant-input-group-wrapper {
    max-width: 100px !important;
  }

  .ant-picker-outlined {
    width: 270px;
  }
  .ant-picker {
    width: 270px;
  }

  .ant-form-item-label {
    padding: 0px !important;
    font-size: 16px !important;
    font-weight: 700;
  }

  .ant-form-item-label > label {
    width: 100% !important;
    height: 32px;

    &::after {
      visibility: visible;
    }
  }

  .ant-form-item-control {
    width: auto !important;
  }

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    max-width: 800px;
  }

  .text-label-input-unit {
    font-size: 16px;
    padding-left: 16px;
  }

  #total_help {
    .ant-form-item-explain-error {
      padding-left: 66px;
    }
  }

  .fix-row {
    .ant-row {
      flex-direction: row;
    }

    .ant-form-item-label {
      flex: none !important;
    }
  }

  .target-group {
    width: calc((800px - ${8 * (4 - 1)}px) / ${4});
  }
`;

const StyleButton = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: red;
  width: 100%;
  padding: 16px;
  border-top: 1px solid ${(props) => props?.theme?.actionBorder};
  background-color: ${(props) => props?.theme?.white};

  .ant-btn-cancel {
    padding: 6px 16px;
    height: 100%;
    width: 180px;
    font-size: 16px;
    font-weight: 700;
    /* color: ${(props) => props?.theme?.colorTextDesc}; */
  }

  .ant-btn-primary {
    font-size: 16px;
    font-weight: 700;
    /* color: ${(props) => props?.theme?.white}; */
    padding: 6px 16px;
    height: 100%;
    width: 180px;
  }
`;

const StyleDivider = styled(Divider)`
  margin: 8px 0px;
`;

const FeatureCreateEngagement = () => {
  const [form] = Form.useForm();

  const genWatch = Form.useWatch("gen", form);
  const genderWatch = Form.useWatch("gender", form);
  const startDateWatch = Form.useWatch("startDate", form);
  const stopDateWatch = Form.useWatch("stopDate", form);
  const startTimeWatch = Form.useWatch("startTime", form);
  const engagementWatch = Form.useWatch("engagement", form);
  const stopTimeWatch = Form.useWatch("stopTime", form);

  const missionProvider = CreateMissionProvider();
  const [isOpenModalAlert, setIsOpenModalAlert] = useState(false);

  // const { id = null, social = "" } = queryString.parse(window.location.search);
  const { id = null, social = "", mission = "" } = useParams();
  let initialEngagement = [];
  let initialReplies = 0;
  let initialLikes = 0;
  let initialShares = 0;
  let initialQuotes = 0;

  if (social === "facebook" || social === "twitter") {
    initialEngagement = ["replies", "likes", "shares", "quotes"];
    initialReplies = 1;
    initialLikes = 1;
    initialShares = 1;
    initialQuotes = 1;
  } else if (social === "instagram" || social === "tiktok") {
    initialEngagement = ["replies", "likes", "shares"];
    initialReplies = 1;
    initialLikes = 1;
    initialShares = 1;
  } else if (
    social === "pantip" ||
    social === "internet" ||
    social === "youtube"
  ) {
    initialEngagement = ["replies", "likes"];
    initialReplies = 1;
    initialLikes = 1;
  }

  //  if (mission === "reply") {
  //   initialEngagement = ["replies"];
  //   initialReplies = 1;
  //  if (mission === "share") {
  //   initialEngagement = ["shares"];
  //   initialShares = 1;
  //  if (mission === "quote") {
  //   initialEngagement = ["quotes"];
  //   initialQuotes = 1;
  //  {
  //   return [];
  // }

  const initialValue = {
    requirement: "",
    socialMedia: social || "",
    startDate: dayjs(new Date()),
    startTime: dayjs(),
    stopDate: "",
    stopTime: "",
    gen: ["Baby", "X", "Y", "Z", "Alpha"], // default B, X
    gender: ["male", "female"], // default male
    category: [],
    audience: false,
    replies: initialReplies,
    likes: initialLikes,
    shares: initialShares,
    quotes: initialQuotes,
    // engagement: ["replies", "likes", "shares", "quotes"],
    engagement: initialEngagement,
    config: ["organic", "web_cookie", "api"],
  };

  useEffect(() => {
    const type = {
      replies: 1,
      likes: 1,
      shares: 1,
      quotes: 1,
    };
    let missionType = typeMission[mission];
    if (missionType === "share_quote") {
      missionType = "quotes";
    }
    if (mission) {
      form.setFieldValue(typeMission[mission], 1);
      form.setFieldValue("engagement", [typeMission[mission]]);
      for (const prop in type) {
        if (prop !== typeMission[mission]) {
          type[prop] = 0;
          form.setFieldValue(prop, 0);
        }
      }
    }
    return () => {};
  }, [mission]);

  const closeWindow = () => {
    // window.onbeforeunload = null;
    window.onbeforeunload = function () {
      return null;
    };
    window.close();
  };

  const onFinish = async (value) => {
    try {
      dayjs.extend(utc);

      const startDate = dayjs(value?.startDate).format("YYYY-MM-DD");
      let startTime = dayjs(value?.startTime).format("HH:mm");
      const stopDate = dayjs(value?.stopDate).format("YYYY-MM-DD");
      const stopTime = dayjs(value?.stopTime).format("HH:mm");
      // check now is over startTime
      if (dayjs().isAfter(dayjs(startTimeWatch))) {
        startTime = dayjs().format("HH:mm");
        form.setFieldsValue({
          startTime: dayjs(),
        });
      }

      const combineStartDate = dayjs(`${startDate} ${startTime}`).format(
        "YYYY-MM-DD HH:mm:ss",
      );
      const combineEndDate = dayjs(`${stopDate} ${stopTime}`).format(
        "YYYY-MM-DD HH:mm:ss",
      );

      const targets = {
        replies: Number(value?.replies) || 0,
        likes: Number(value?.likes) || 0,
        shares: Number(value?.shares) || 0,
        quotes: Number(value?.quotes) || 0,
      };

      const payload = {
        type: "engage",
        news_id: id,
        remark: value?.requirement?.trim(),
        target: {
          replies: Number(value?.replies) || 0,
          likes: Number(value?.likes) || 0,
          shares: Number(value?.shares) || 0,
          quotes: Number(value?.quotes) || 0,
        },
        platforms: [value?.socialMedia],
        start_date: combineStartDate,
        end_date: combineEndDate,
        audiences_generation: value?.gen || [],
        audiences_gender: value?.gender || [],
        categories: value?.category || [],
      };

      for (const key in payload.target) {
        const updatedPayload = {
          ...payload,
        };
        updatedPayload.target = {
          [key]: payload.target[key],
        };
        if (payload.target[key] > 0) {
          const response = await missionProvider.createMission(updatedPayload);
        }
      }
      await closeWindow();
    } catch (err) {
      const { code, message } = err.data;

      if (
        (code === 400 &&
          message ===
            "end_date must be at least 30 minutes after start_date") ||
        message === "start_date must be less than end_date"
      ) {
        setIsOpenModalAlert(!isOpenModalAlert);
        form.setFields([
          {
            name: "stopTime",
            errors: ["Please set a minimum duration of 30 minutes"],
          },
        ]);
      }
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const key = Object.keys(changedValues)[0];

    // Condition: for checking checkbok must has checked at least one item.
    if (key === "gen" || key === "gender") {
      if (
        changedValues[key].length === 0 &&
        (genWatch.length === 1 || genderWatch.length === 1)
      ) {
        form.setFieldsValue({
          [key]: key === "gen" ? genWatch : genderWatch,
        });
      }
    } else if (key === "startDate") {
      // form.setFieldsValue({
      //   stopDate: "",
      //   stopTime: "",
      //   [key]: dayjs(changedValues[key]),
      // });
    } else if (key === "stopDate") {
      // form.setFieldsValue({
      //   [key]: dayjs(changedValues[key]).set("hour", 0).set("minute", 0),
      // });
    } else if (key === "startTime") {
      form.setFieldsValue({
        stopTime: "",
      });
    } else if (key === "engagement") {
      const diff = engagementWatch.filter(
        (item) => !changedValues[key].includes(item),
      );

      if (changedValues[key].length === 0 && engagementWatch.length === 1) {
        form.setFieldsValue({
          [key]: engagementWatch,
        });
      } else if (diff.length > 0) {
        const keyTarget = diff[0];
        form.setFieldsValue({
          [keyTarget]: 0,
          socialMedia: social,
        });
      } else {
        // checked will reset input target
        const checked = changedValues[key][changedValues[key].length - 1];
        form.setFieldsValue({
          [checked]: 1,
        });
      }
    } else {
      form.setFieldsValue(allValues);
    }
  };

  const checkboxComp = (value) => {
    return value.map((item) => (
      <Checkbox
        key={item?.label}
        value={item?.value}
        disabled={!initialEngagement.includes(item.value)}
        // checked={false}
      >
        {item?.label}
      </Checkbox>
    ));
  };

  const timeComp = ({
    name = "",
    message = "",
    placeholder = "00:00",
    required = true,
    format = " HH:mm",
  }) => {
    const isOverDate = dayjs(stopDateWatch).isAfter(dayjs(startDateWatch));

    const disabledTime = () => {
      const current = dayjs();
      const currentHour = current.hour();
      const currentMinute = current.minute();

      const getDisabledMinutes = (selectedHour) => {
        if (currentHour === selectedHour)
          return [...Array(currentMinute).keys()];
        return [];
      };

      return {
        disabledHours: () => [...Array(currentHour).keys()],
        disabledMinutes: (selectedHour) => getDisabledMinutes(selectedHour),
      };
    };

    return (
      <Form.Item
        className="fix-row"
        label="Time"
        name={name}
        rules={[
          {
            required,
            message,
          },
          {
            message: "date time is incorrect",
            validator: () => {
              try {
                if (
                  startDateWatch &&
                  stopDateWatch &&
                  startTimeWatch &&
                  stopTimeWatch
                ) {
                  const startDate = startDateWatch.format("YYYY-MM-DD");
                  const startTime = startTimeWatch.format("HH:mm");
                  const stopDate = stopDateWatch.format("YYYY-MM-DD");
                  const stopTime = stopTimeWatch.format("HH:mm");

                  const combineStartDate = `${startDate}T${startTime}Z`;
                  const combineEndDate = `${stopDate}T${stopTime}Z`;

                  if (dayjs(combineStartDate).isAfter(dayjs(combineEndDate))) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject();
                  }
                }
                return Promise.resolve();
              } catch (error) {
                console.log("error", error);
                return Promise.reject();
              }
            },
          },
        ]}
      >
        <TimePicker
          format={format}
          placeholder={placeholder}
          disabledTime={!isOverDate ? disabledTime : null}
        />
      </Form.Item>
    );
  };

  const dateComp = ({
    label = "",
    name = "",
    message = "Please select a Start Date",
  }) => {
    const isOverDate = dayjs(startDateWatch).isAfter(dayjs());

    const disabledDate = (current) => {
      if (startDateWatch && name === "stopDate" && isOverDate) {
        return current < startDateWatch;
      }
      return current < dayjs().startOf("day");
    };

    return (
      <Form.Item
        className="fix-row"
        label={label}
        name={name}
        rules={[
          {
            required: true,
            message,
          },
          {
            message: "date time is incorrect",
            validator: () => {
              try {
                if (
                  startDateWatch &&
                  stopDateWatch &&
                  startTimeWatch &&
                  stopTimeWatch
                ) {
                  const startDate = startDateWatch.format("YYYY-MM-DD");
                  const startTime = startTimeWatch.format("HH:mm");
                  const stopDate = stopDateWatch.format("YYYY-MM-DD");
                  const stopTime = stopTimeWatch.format("HH:mm");

                  const combineStartDate = `${startDate}T${startTime}Z`;
                  const combineEndDate = `${stopDate}T${stopTime}Z`;

                  if (dayjs(combineStartDate).isAfter(dayjs(combineEndDate))) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject();
                  }
                }
                return Promise.resolve();
              } catch (error) {
                console.log("error", error);
                return Promise.reject();
              }
            },
          },
        ]}
      >
        <DatePicker placeholder="Select Date" disabledDate={disabledDate} />
      </Form.Item>
    );
  };

  return (
    <WindowComponent>
      <StyleForm
        form={form}
        initialValues={initialValue}
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
        layout="vertical"
      >
        {/* ============= Requirement ============= */}
        <Form.Item
          colon={false}
          label="Requirement"
          name="requirement"
          rules={[
            {
              required: true,
              message: "Please enter a Requirement",
            },
          ]}
        >
          <TextAreaCommon
            placeholder="Please enter a Requirement"
            maxLength={300}
          />
        </Form.Item>

        {/* ============= Engagement ============= */}
        <Form.Item
          name="engagement"
          label="Engagement"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox.Group
            style={{
              paddingLeft: "16px",
            }}
          >
            {checkboxComp(target)}
          </Checkbox.Group>
        </Form.Item>

        {/* ============= Target ============= */}
        <Form.Item>
          <Flex>
            <span className="require">*</span>
            <span className="title">Target</span>
          </Flex>
          <Flex
            // wrap="wrap"
            gap={16}
            style={{
              paddingLeft: "16px",
              paddingTop: "16px",
            }}
          >
            {target.map((item, index) => {
              const disabled =
                engagementWatch && !engagementWatch.includes(item?.name);
              return (
                <Space.Compact className="target-group">
                  <Flex gap={8} align="baseline">
                    <span
                      style={{
                        height: "32px",
                      }}
                    >
                      {item?.label}:{" "}
                    </span>
                    <Form.Item
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      colon={false}
                      name={item?.name}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a Total Target",
                        },
                        {
                          validator: (_, value) =>
                            value === "0"
                              ? Promise.reject(
                                  new Error(`Please enter a value 
                      greater than 0.`),
                                )
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <InputUnit disabled={disabled} />
                    </Form.Item>
                  </Flex>
                </Space.Compact>
              );
            })}
          </Flex>
        </Form.Item>

        {/* ============= Social media ============= */}
        <Form.Item
          colon={false}
          name="socialMedia"
          label="Social media"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <GroupRadioSocial active={social} />
        </Form.Item>

        <StyleDivider />

        {/* ============= Audience ============= */}
        <Form.Item>
          <Audience form={form} />
        </Form.Item>

        <StyleDivider />

        {/* ============= Period ============= */}
        <Flex gap={4}>
          <span className="require">*</span>
          <h1 className="title">Mission Period</h1>
        </Flex>

        <Form.Item>
          <Flex gap={16} wrap="wrap">
            {dateComp({
              name: "startDate",
              label: "Start Date",
              placeholder: "Select Date",
              message: "Please select a Start Date",
            })}
            {timeComp({
              name: "startTime",
              message: "Please select a Start Time",
            })}
          </Flex>
        </Form.Item>

        <Form.Item>
          <Flex gap={16} wrap="wrap">
            {dateComp({
              name: "stopDate",
              label: "Stop Date",
              message: "Please select a Stop Date",
            })}

            {timeComp({
              name: "stopTime",
              message: "Please select a Stop Time",
            })}
          </Flex>
        </Form.Item>

        <StyleButton align="center" justify="center" gap={16}>
          <Button type="cancel" onClick={() => closeWindow()}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Confirm
          </Button>
        </StyleButton>
      </StyleForm>

      <ModalConfirm
        isModalOpen={isOpenModalAlert}
        type="warning"
        title="An error has occurred."
        subTitle="Please try again."
        callbackBtnRight={() => setIsOpenModalAlert(!isOpenModalAlert)}
        callbackBtnLeft={() => setIsOpenModalAlert(!isOpenModalAlert)}
      />
    </WindowComponent>
  );
};

export default FeatureCreateEngagement;
