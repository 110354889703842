import { Button } from "antd";
import styled from "styled-components";
import iconDefault from "../../../assets/icons/icon-engages.svg";
import iconStop from "../../../assets/icons/icon-engages-stop.svg";
import iconStopPost from "../../../assets/icons/icon-stop-post.svg";
import iconBoostPost from "../../../assets/icons/icon-boost-post.svg";

const StyledButton = styled(Button)`
  /* width: 110px; */
  height: 44px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid ${(props) => props.theme.colorBorderCardSocial};
  font-size: 16px;
  font-weight: 700;
  width: 155px;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);

  .ant-btn-icon {
    margin-inline-end: 0 !important;

    img {
      width: 26px;
      height: 26px;
    }
  }
`;

const StyledButtonBooster = styled(StyledButton)`
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  background: ${(props) =>
    props.boosted ? props.theme.colorButtonCancelBoost : props.theme.white};
  color: ${(props) =>
    props.boosted ? props.theme.white : props.theme.colorTextButton};

  &:not(:disabled):hover {
    border-color: ${(props) =>
      props.boosted ? props.theme.white : props.theme.colorButtonBoost};
    background: ${(props) =>
      props.boosted
        ? props.theme.colorButtonHoverButtonBoost
        : props.theme.white} !important;
    color: ${(props) =>
      props.boosted
        ? props.theme.white
        : props.theme.colorTextButton} !important;
  }
`;

const ButtonBooster = ({
  onClick,
  icon,
  disabled,
  boosted,
  children,
  isDefault = false,
  typeBoost = "engages",
}) => {
  const labelDefault = {
    post: boosted ? "Cancel" : "Re-Boost",
    engages: boosted ? "Cancel" : "Re-Boost",
  };
  const defaultData = isDefault
    ? {
        // eslint-disable-next-line no-nested-ternary
        img: boosted
          ? typeBoost === "engages"
            ? iconDefault
            : iconStopPost
          : typeBoost === "engages"
            ? iconStop
            : iconBoostPost,
        label: labelDefault[typeBoost],
      }
    : {
        img: icon,
        label: children,
      };
  const {
    // img,
    label,
  } = defaultData;
  return (
    <StyledButtonBooster
      type="primary"
      // icon={img && <img src={img} alt="boost icon" />}
      disabled={disabled}
      boosted={boosted}
      onClick={onClick}
    >
      {label}
    </StyledButtonBooster>
  );
};

export default ButtonBooster;
