import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  filter: {
    facebook: true,
    twitter: true,
    tiktok: true,
    pantip: true,
    internet: true,
    youtube: true,
    instagram: true,
  },
  sortby: "created_date",
  pinned: true,
  warned: true,
  isAllData: true,
};

export const socialCardSlice = createSlice({
  name: "socialCard",
  initialState,
  reducers: {
    setIntialSocialCard: () => initialState,
    setDataSocialCard: (state, action) => {
      state.data = action.value;
    },
    setFilterSocialCard: (state, action) => {
      state.filter[action.payload.key] = action.payload.value;
    },
    setKeySocialCard: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setQueryAll: (state, action) => {
      state.isAllData = action.payload.value;
      state.pinned = action.payload.value;
      state.warned = action.payload.value;
    },
  },
});

export const {
  setIntialSocialCard,
  setDataSocialCard,
  setFilterSocialCard,
  setKeySocialCard,
  setQueryAll,
} = socialCardSlice.actions;

export default socialCardSlice.reducer;
