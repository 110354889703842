import React, { useState, useEffect } from "react";
import { Flex, Tabs } from "antd";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { myPostStatus } from "../../../common/utils/keyDatas";
import ContentJobDetail from "../../../common/components/ContentJobDetail";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
} from "../../../common/utils/filterDatas";
import FilterData from "../../../common/components/FilterData";
import MainLayout from "../../../common/layouts/main";
import TablePostMission from "./TablePostMission";
import { ReactComponent as IconArrow } from "../../../assets/icons/icon-dropdown.svg";
import missionProvider from "../provider/missionProvider";
// import { queryStringFormat } from "../../../common/utils/format";
// import CardPostMission from "../../../common/components/MissionDetail/CardPostMission";
// import { setFiledFilterData } from "../../filterData/filterDataSlice";
// import dayjs from "dayjs";
// import { useDispatch, useSelector } from "react-redux";

export const usePostSubMissionList = () => {
  const { id: idMission } = useParams();
  const [detail, setDetail] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // const [limit, setLimit] = useState(50);
  const limit = 50;
  const navigate = useNavigate();
  const missionApi = missionProvider();
  const filterState = useSelector((state) => state.filterData);
  const { queryFilterRedux } = filterState;
  const parseQuery = queryString.parse(queryFilterRedux);
  const [tabActive, setTabActive] = useState("1");
  const getSubMissionDetail = async (page) => {
    const query = {
      ...parseQuery,
      type: ["post"],
      order_by: "asc",
      sort_by: "created_date",
      limit,
      page: page || 1,
    };
    // const path = queryString.stringify(query);
    const res = await missionApi.getSubMissionContent({
      id: idMission,
      query: queryString.stringify(query),
    });
    setDetail(res);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    (async () => {
      if (queryFilterRedux) {
        setCurrentPage(1);
        if (!("platform" in parseQuery) && "status" in parseQuery) {
          await getSubMissionDetail();
        }
      }
    })();
    return () => {};
  }, [queryFilterRedux]);

  const onChangeTabs = async (key) => {
    setTabActive(key);
    // await getSubMissionDetail();
  };

  // useEffect(() => {
  //   console.log("sss");
  //   return () => {};
  // }, [tabActive]);0/2

  return {
    navigate,
    detail,
    setDetail,
    getSubMissionDetail,
    limit,
    currentPage,
    setCurrentPage,
    onChangeTabs,
    tabActive,
  };
};

const PostMissionListContainer = styled.div`
  padding: 16px;
  /* background-color: ${(props) => props.theme.white}; */

  .ant-pagination {
    /* margin: 0 !important; */
  }

  :where(.css-dev-only-do-not-override-14yae4w).ant-tabs-top
    > .ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-14yae4w).ant-tabs-bottom
    > .ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-14yae4w).ant-tabs-top
    > div
    > .ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-14yae4w).ant-tabs-bottom
    > div
    > .ant-tabs-nav::before {
    border-bottom: 0;
  }
`;

const PostSubMission = (props) => {
  const {
    navigate,
    detail,
    setDetail,
    getSubMissionDetail,
    currentPage,
    limit,
    setCurrentPage,
    onChangeTabs,
    tabActive,
  } = usePostSubMissionList(props);
  const {
    news_title: newsTitle,
    // requirement,
    targets,
    // actual,
    mission_end_date: missionEndDate,
    mission_start_date: missionStartDate,
    mission_status: missionStatus,
    mission_canceled_date: missionCancelDate,
    mission_completed_date: missionCompleteDate,
    mission_created_date: missionCreateDate,
    pending,
    approve,
    complete,
    total,
    platform,
    // cancelDate,
  } = detail || {};
  const items = [
    {
      key: "1",
      label: "Copywriter Submissions",
      children: (
        <>
          {tabActive === "1" && (
            <FilterData
              title=""
              labelSocial="Social Media :"
              labelDetail={`Total ${total} Items`}
              labelSort="Sorting :"
              labelFilter="Show :"
              disabledButton={false}
              filter={{
                optionSortList: optionSortDefaultList,
                optionSortSelect: optionSortDefaultSelect,
                typeShow: "myPostsDetailWriter",
                typeCount: {
                  submitted: `(${pending?.toLocaleString()})`,
                  approved: `(${(approve + complete)?.toLocaleString()}/${targets?.toLocaleString()})`,
                },
              }}
            />
          )}

          <TablePostMission
            detail={detail}
            setDetail={setDetail}
            getSubMissionDetail={getSubMissionDetail}
            currentPage={currentPage}
            limit={limit}
            setCurrentPage={setCurrentPage}
            newsTitle={newsTitle}
            missionStatus={missionStatus}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "Influencer Submissions",
      children: (
        <>
          {tabActive === "2" && (
            <FilterData
              title=""
              labelSocial="Social Media :"
              labelDetail={`Total ${total} Items`}
              labelSort="Sorting :"
              labelFilter="Show :"
              disabledButton={false}
              filter={{
                optionSortList: optionSortDefaultList,
                optionSortSelect: optionSortDefaultSelect,
                typeShow: "myPostsDetailInfluencer",
                typeRole: "missionInfluencer",
                typeCount: {
                  running: `(${approve?.toLocaleString()})`,
                  done: `(${complete?.toLocaleString()}/${targets?.toLocaleString()})`,
                },
              }}
            />
          )}

          <TablePostMission
            detail={detail}
            setDetail={setDetail}
            getSubMissionDetail={getSubMissionDetail}
            currentPage={currentPage}
            limit={limit}
            setCurrentPage={setCurrentPage}
            newsTitle={newsTitle}
            roleUser="influencer"
            missionStatus={missionStatus}
          />
        </>
      ),
    },
  ];

  return (
    <MainLayout>
      <PostMissionListContainer>
        <Flex
          direction="row"
          gap={5}
          align="start"
          onClick={() => navigate("/my-posts")}
          style={{
            cursor: "pointer",
          }}
        >
          <IconArrow /> Back
        </Flex>

        <ContentJobDetail
          dataItem={{
            missionCompleteDate,
            missionCancelDate,
            missionStatus,
            content: newsTitle,
            startDate: missionStartDate,
            endDate: missionEndDate,
            // cancelDate:
            type: platform,
            createdDate: new Date(missionCreateDate),
            status: missionStatus,
            typeStatus: myPostStatus,
            copywriterCount: {
              actual: (complete + approve)?.toLocaleString(),
              target: targets?.toLocaleString(),
            },
            influencerCount: {
              actual: complete?.toLocaleString(),
              target: targets?.toLocaleString(),
            },
          }}
          // dataItemRequirement={requirement}
        />

        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChangeTabs}
          activeKey={tabActive}
        />
      </PostMissionListContainer>
    </MainLayout>
  );
};

export default PostSubMission;
