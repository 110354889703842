/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { myNewWindow } from "../../../../common/utils/helper";
import EngagementProvider from "./engagementProvider";
import { tapSubmissionWriterAndInfluencer } from "../../../../common/utils/filterDatas";
import { setFiledEngagement } from "../../owner/myEngagements/engagementSlice";

const useFetchData = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const engagementProvider = EngagementProvider();

  const dispatch = useDispatch();
  const { queryFilterRedux = "" } = useSelector((state) => state.filterData);
  const { submissionList = [], engagementPaginationQuery = "" } = useSelector(
    (state) => state.engagement,
  );
  const parseQuery = queryString.parse(queryFilterRedux);

  const [datalist, setDatalist] = useState({
    data: [],
    total: 0,
  });
  const [selectTabIndex, setSelectTabIndex] = useState(1);

  const queryByTab = (queryLabel, index) => {
    return `?status=${tapSubmissionWriterAndInfluencer[
      Math.max(0, Number(index) - 1)
    ].label.toLowerCase()}&${queryLabel}`
      .replace(/submitted/g, "pending")
      .replace("quote", "share_quote")
      .replace(/status=all/g, "")
      .replace(/approved/g, "approve&status=complete")
      .replace(/rejected/g, "reject")
      .replace(/done/g, "complete");
  };

  const fetchSubmission = async () => {
    try {
      const query = [
        queryFilterRedux
          .replace(/platform=/g, "platforms=")
          .replace(/target=/g, "type="),
        engagementPaginationQuery,
      ]
        .filter(Boolean)
        .join("&");
      const res = await engagementProvider.getSubMissionWriter(
        queryByTab(query, selectTabIndex),
      );

      dispatch(
        setFiledEngagement({
          key: "submissionList",
          value: {
            data: res?.data || [],
            total: res?.total || 0,
          },
        }),
      );
      // setDatalist({
      //   data: res?.data || [],
      //   total: res?.total || 0,
      // });
    } catch (error) {
      return error;
    }
  };

  let win = null;
  async function handleCloseWindowMission() {
    if (win.location !== "about:blank") {
      await fetchSubmission();
    }
  }
  const handleOpenWindow = async (contentId) => {
    win = myNewWindow(`/my-engagements/detail/${contentId}`, "");
    win.onunload = handleCloseWindowMission;
  };

  // const left = window.screen.width / 2 - 800 / 2;
  // const top = window.screen.height / 2 - 688 / 2;
  // const handleOpenWindow = async (contentId) => {
  //   const newWindow = window.open(
  //     `/my-engagements/detail/${contentId}`,
  //     "",
  //     `width=${800}, height=${688}, top=${top}, left=${left}`,
  //   );
  //   if (newWindow) {
  //     // get & set Data
  //     await fetchSubmission();
  //     newWindow.onbeforeunload = async () => {
  //       // await onUnload();
  //       await fetchSubmission();
  //     };
  //   }
  // };

  useEffect(() => {
    if (queryFilterRedux) {
      // if ("platform" in parseQuery && "type" in parseQuery) {
      fetchSubmission();
      // }
    }
  }, [queryFilterRedux, engagementPaginationQuery, selectTabIndex]);

  // useEffect(() => {
  //   dispatch(
  //     setFiledEngagement({
  //       key: "submissionList",
  //       value: datalist,
  //     }),
  //   );
  // }, [datalist]);

  return {
    data: submissionList?.data || [],
    total: submissionList?.total || 0,
    selectTabIndex,
    setSelectTabIndex,
    handleOpenWindow,
    currentPage,
    setCurrentPage,
    queryFilterRedux,
  };
};

export default useFetchData;
