/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
// eslint-disable-next-line prettier/prettier
import {
 Modal, Button, Flex, Radio, Form, Checkbox 
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";
import moment from "moment";
import { isRoleSuperAdmin } from "../../../common/utils/helper";
import { useAuth } from "../../../common/hooks/useProviderAuth";
import GroupRadioSocial from "../../../common/components/GroupRadioSocial";
import ContentJobDetail from "../../../common/components/ContentJobDetail";
import WindowComponent from "../../../common/components/WindowComponent";
import ContentDetail from "../../../common/components/ContentDetail";
import RequirementDetail from "../../../common/components/RequirementDetail";
import MissionDetail from "../../../common/components/MissionDetail";
import HeaderWindowDetail from "../../flowJob/HeaderWindowDetail";
import Icon from "../../../assets/icons/icon-copywriter2.svg";
import mockPost from "./mock";
import missionProvider from "../provider/missionProvider";
import { usePostSubMissionList } from "../PostMissionList";
import { IconWarning } from "../MyPostsList";

const StyleTextTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colorTextButton};
`;

const PostDetailWrap = styled.div`
  padding: 16px 32px;
  margin-bottom: ${(props) => (props.status === "waiting" ? "162px" : "50px")};
  padding-bottom: 110px;
`;

const H6 = styled.h6`
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`;

const ButtonCustom = styled(Button)`
  border: ${(props) =>
    props.typeBtn === "submit"
      ? "none"
      : `1px solid ${props.theme.colorMagenta}`};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: 180px;
  height: 38.8px;
  background-color: ${(props) =>
    props.typeBtn === "submit" ? props.theme.colorMagenta : props.theme.white};
  color: ${(props) =>
    props.typeBtn === "submit" ? props.theme.white : props.theme.colorMagenta};
`;

const FooterWrap = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${(props) => props.theme.white};
`;

const StyledTextArea = styled(TextArea)`
  height: 140px !important;
  font-family: "Sarabun" !important;

  &:hover {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
  &:focus {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
`;

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.colorcheckbox}!important;
    border-color: ${(props) => props.theme.colorcheckbox}!important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(props) => props.theme.colorcheckbox} !important;
  }
`;

const useWindowMissionDetail = () => {
  const { user } = useAuth();
  const [data, setData] = useState(null);
  const [statusContent, setStatusContent] = useState("reject");
  const [detail, setDetail] = useState(null);
  const missionApi = missionProvider();
  const { id: missionId, type: typeMission } = useParams();
  const [modal, contextHolder] = Modal.useModal();
  const [socialAccounts, setsocialAccounts] = useState([]);

  const closeWindow = async () => {
    window.close();
  };

  const modalError = () => {
    modal.info({
      title: "No job available.",
      icon: <IconWarning />,
      content: "",
      centered: true,
      okText: "OK",
      onOk: () => closeWindow(),
    });
  };

  const getMissionDetail = async () => {
    const res = await missionApi.getMissionById(missionId);

    /* for check user audiencen of account is match with this mission */
    const socialAccountsRes = await missionApi.getSocailAccount({
      platform: [res?.platforms[0]],
      missionId,
    });
    setsocialAccounts(socialAccountsRes);

    const isActive = !["cancel", "complete"].includes(res.status);
    const isNotExpire = moment().isBefore(moment(res.end_date));
    const isNotComplete = res.actual.copywriter < res.target;
    if (isActive && isNotExpire && isNotComplete) {
      setDetail(res);
    } else {
      modalError();
    }
  };

  useEffect(() => {
    getMissionDetail();
  }, []);

  const AcceptMission = async (val) => {
    try {
      const res = await missionApi.createSubmissionContent({
        type: typeMission,
        content_text: val?.contentText,
        mission_id: parseInt(missionId),
        is_pick: val?.isPick,
        platform: detail?.platforms[0],
      });
      if (res.id) {
        closeWindow();
      }
      return res;
    } catch (error) {
      const { message, code } = error;
      if (code === 422 && message === "Social not found") {
        Modal.error({
          title: "An error has occurred.",
          content: "Please try again.",
          icon: <IconWarning />,
          centered: true,
          okText: "OK",
          onOk: () => {
            window.location.reload();
          },
          cancelText: "Cancel",
        });
      } else {
        modalError();
      }
      // alert("error");
    }
  };

  const confirm = (val) => {
    modal.confirm({
      title: "Are you sure you want to do this?",
      icon: <IconWarning />,
      content: "Would you like to confirm?",
      centered: true,
      okText: "OK",
      onOk: () => AcceptMission(val),
      // onCancel: () => closeWindow(),
      cancelText: "Cancel",
    });
  };

  return {
    data,
    statusContent,
    setStatusContent,
    closeWindow,
    detail,
    confirm,
    contextHolder,
    typeMission,
    AcceptMission,
    socialAccounts,
  };
};

const WindowMissionDetail = () => {
  const [form] = useForm();
  const {
    data,
    statusContent,
    setStatusContent,
    updateApprove,
    closeWindow,
    detail,
    confirm,
    contextHolder,
    AcceptMission,
    typeMission,
    socialAccounts,
  } = useWindowMissionDetail();
  const {
    status,
    platforms,
    created_date: createdDate,
    type,
    writer_name: writerName,
    writer_poster_name: writerPosterName,
    news_title: newsTitle,
    remark,
    content_text: contentText,
    start_date: startDate,
    end_date: endDate,
    id,
    user_id: userId,
    url: newsUrl,
  } = detail || {
    status: "",
    platforms: ["facebook"],
    createdDate: "",
    type: "",
    writerName: "",
    writerPosterName: "",
    newsTitle: "",
    requirement: "",
    contentText: "",
    startDate: "",
    endDate: "",
    newsUrl: "",
  };

  const { user } = useAuth();

  const getUserRole = () => {
    if (user?.roles) {
      if (user?.roles.includes("influencer")) return "influencer";
      if (user?.roles.includes("writer") && !user?.roles.includes("influencer"))
        return "writer";
    }
    return "writer";
  };

  return (
    <WindowComponent>
      {Object.keys(user).length ? (
        <Form
          form={form}
          name="postDetail"
          onFinish={confirm}
          initialValues={{
            isPick: true,
          }}
          layout="vertical"
        >
          <PostDetailWrap status={status}>
            <HeaderWindowDetail
              imgProfile={Icon}
              name={writerName || user?.username}
              roleUser={getUserRole()}
              title="Write Content"
              tagContent={typeMission}
              // status={status}
              date={createdDate}
              showSubmissionsDate={false}
            />

            <br />
            <hr />
            {/* <br /> */}

            <Flex vertical>
              {/* <H6>Mission</H6> <br />
        <MissionDetail
          type="twitter"
          href="https://twitter.com/PPTVHD36/status/1763484738210201726"
          content="โพสถึง Ais ในทิศทางบวก รูปที่ใช้ในการโพสขนาด 1200 x 600 px โพสที่ Social media ตามที่กำหนด แล้วให้โปรโมการใช้งานอินเตอ์เน็ต เกี่ยวกับการไปปดูคอนเสริต์ ตัวอย่างการโพส เน็ตดีมาก ดูคอนเสริต์พี่ Ed sheeran ที่ราชมังคือดี จึ้งสุดๆ ส่งรูปวิดีโอไม่มีสะดุด ไม่ว่าจะส่งให้ใครก็เร็วแรง คมชัด วิดีโอคอลหาที่บ้านสัญญาณคือชัดเเจ๋ว ไม่มีกระตุก เริ่ดมากกก สัญญาณ ปังสุด ยกให้เป็นที่ 1"
          startDate="2024-05-20 11:30"
          endDate="2024-05-30 11:30"
          link="https://twitter.com/PPTVHD36/status/1763484738210201726"
          campaign=""
        />
        <RequirementDetail content="โพสถึง Ais ในทิศทางบวก รูปที่ใช้ในการโพสขนาด 1200 x 600 px โพสที่ Social media ตามที่กำหนด แล้วให้โปรโมการใช้งานอินเตอ์เน็ตเกี่ยวกับการไปปดูคอนเสริต์ ตัวอย่างการโพส เน็ตดีมาก ดูคอนเสริต์พี่ Ed sheeran ที่ราชมังคือดี จึ้งสุดๆ ส่งรูปวิดีโอไม่มีสะดุด ไม่ว่าจะส่งให้ใครก็เร็วแรง คมชัด วิดีโอคอลหาที่บ้านสัญญาณคือชัดเเจ๋ว ไม่มีกระตุก เริ่ดมากกก สัญญาณปังสุด ยกให้เป็นที่ 1 และการพูดถึง Ais ต้อง ไม่เสียดสีค่ายอื่นว่าเป็นอย่างไร และการโพส ต้องติด Hashtag #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส#เน็ตแรง #" /> */}
              <ContentJobDetail
                dataItem={{
                  content: newsTitle || remark,
                  startDate,
                  endDate,
                  type: platforms[0] || "",
                  href: newsUrl,
                }}
                dataItemRequirement={remark}
              />
              {!isRoleSuperAdmin() && (
                <>
                  <StyleTextTitle>Social Media</StyleTextTitle> <br />
                  <GroupRadioSocial
                    active={platforms[0] || "facebook"}
                    value={platforms[0] || "facebook"}
                  />
                </>
              )}
              <br />
            </Flex>

            {!isRoleSuperAdmin() && (
              <div>
                <Form.Item
                  colon={false}
                  labelCol={{
                    style: {
                      width: 200,
                    },
                  }}
                  label={<StyleTextTitle>Content</StyleTextTitle>}
                  name="contentText"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a Content",
                    },
                  ]}
                >
                  <StyledTextArea placeholder="Please enter a Content" />
                </Form.Item>
                {getUserRole() === "influencer" &&
                  socialAccounts?.length > 0 && (
                    <Form.Item
                      colon={false}
                      labelCol={{
                        style: {
                          width: 200,
                        },
                      }}
                      name="isPick"
                      valuePropName="checked"
                    >
                      <StyledCheckbox>
                        I am the first person to post
                      </StyledCheckbox>
                    </Form.Item>
                  )}
              </div>
            )}
          </PostDetailWrap>

          <FooterWrap>
            <hr />

            {!isRoleSuperAdmin() && (
              <Form.Item>
                <Flex
                  gap={15}
                  justify="center"
                  style={{
                    padding: "16px",
                  }}
                >
                  <ButtonCustom
                    type="cancel"
                    htmlType="button"
                    onClick={closeWindow}
                  >
                    Cancel
                  </ButtonCustom>
                  <ButtonCustom
                    typeBtn="submit"
                    type="primary"
                    htmlType="submit"
                  >
                    Confirm
                  </ButtonCustom>
                </Flex>
              </Form.Item>
            )}
            {contextHolder}
          </FooterWrap>
        </Form>
      ) : null}
    </WindowComponent>
  );
};

export default WindowMissionDetail;
