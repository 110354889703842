import { Modal } from "antd";
import styled from "styled-components";

const ModalComponent = ({
  title,
  okText,
  okColor,
  cancelText,
  // cancelColor,
  open,
  onOk,
  onCancel,
  footer,
  bodyAlign,
  width,
  children,
}) => {
  const StyledModal = styled(Modal)`
    .ant-modal-header {
      height: 60px;
      display: flex;
      align-items: center;
    }
    .ant-modal-close-x {
      font-size: 30px;
    }
    .ant-modal-close {
      top: 25px;
      inset-inline-end: 30px;
    }
    .ant-modal-body {
      display: ${bodyAlign && "flex"};
      justify-content: ${bodyAlign || ""};
    }
    .ant-modal-footer {
      padding: 16px;
      text-align: center;
      .ant-btn {
        width: 180px;
      }
      :nth-child(2) {
        background: ${okColor || ""};
        text-align: center;
      }
    }
  `;
  return (
    <StyledModal
      title={title}
      okText={okText}
      cancelText={cancelText}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      bodyAlign={bodyAlign}
      // footer={[
      //   <Button key="1">1</Button>,
      //   <Button key="2">2</Button>,
      //   <Button key="3" type="primary">
      //     3
      //   </Button>,
      // ]}
      width={width}
    >
      {children}
    </StyledModal>
  );
};

export default ModalComponent;
