/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { Flex, Table, Modal } from "antd";
import "dayjs/locale/en";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useEffect } from "react";
import { myNewWindow } from "../../../../common/utils/helper";
import { myPostStatus } from "../../../../common/utils/keyDatas";
import missionProvider from "../../provider/missionProvider";
import ButtonBooster from "../../../../common/components/ButtonBooster";
import ButtonIcon from "../../../../common/components/ButtonIcon";
import EmptyDefault from "../../../../common/components/EmptyDefault";
import FilterData from "../../../../common/components/FilterData";
import MainLayout from "../../../../common/layouts/main";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
  socialDefault,
} from "../../../../common/utils/filterDatas";
import { PaginationQuery } from "../../../../common/utils/queryStr";
import CardEngagement, { ComponentTarget } from "./CardEngagement";
import CardStatus from "./CardStatus";
import { setFiledEngagement } from "./engagementSlice";
import useFetchData from "./useFetchData";
import MyPostList, { IconWarning, StyledButtonStatus } from "../../MyPostsList";

const StyledTableEngagement = styled(Flex)`
  overflow-y: scroll;
  padding: 1rem;
  min-height: calc(100vh - 100px);
  background: ${(props) => props.theme.bgTablePage};
`;

const StyledTableContainer = styled(Table)`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.bgTablePage};

  .ant-table-thead .ant-table-cell {
    padding: 4px 16px;
  }
`;

const TableEngagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data,
    total,
    fetchEngagement,
    limit,
    currentPage,
    setCurrentPage,
    queryFilterRedux,
    engagementPaginationQuery,
  } = useFetchData();
  const missionApi = missionProvider();
  const [modal, contextHolder] = Modal.useModal();

  const cancelMission = async (id) => {
    const res = await missionApi.updateStatusMission(id, "cancel");
    if (res.message === "success") {
      await fetchEngagement();
    }
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure you want to do this?",
      content: "Would you like to confirm?",
      icon: <IconWarning />,
      centered: true,
      okText: "OK",
      onOk: () => cancelMission(id),
      cancelText: "Cancel",
    });
  };

  // const createMissionEngage = async (item) => {
  //   const left = window.screen.width / 2 - 800 / 2;
  //   const top = window.screen.height / 2 - 688 / 2;
  //   const newWindow = window.open(
  //     `/create-engagement/${item?.apiData?.platform}/${item?.apiData?.news_id}`,
  //     "_blank",
  //     `width=${800}, height=${688}, top=${top}, left=${left}`,
  //   );
  //   if (newWindow) {
  //     await fetchEngagement();
  //     newWindow.onbeforeunload = async () => {
  //       await fetchEngagement();
  //     };
  //   }
  // };

  let win = null;
  async function handleCloseWindowMission() {
    if (win.location !== "about:blank") {
      await fetchEngagement();
    }
  }
  const createMissionEngage = async (item) => {
    win = myNewWindow(
      `/create-engagement/${item?.apiData?.platform}/${item?.apiData?.news_id}/${item?.mission}`,
      item?.apiData?.news_id,
    );
    win.onunload = handleCloseWindowMission;
  };

  const onClickButtonEngages = async (item) => {
    console.log("item", item);
    return item.status === "active"
      ? confirm(item.apiData.id)
      : createMissionEngage(item);
  };

  const onClickBoxEngages = (item) => {
    const { id } = item?.apiData;
    return !true ? null : navigate(`/my-engagements/${id}`);
  };

  /* Mock */
  const dataSource = [
    ...[...data].map((item, index) => {
      const typeCheck = {
        reply: "Reply",
        share: "Share",
        quote: "Quote",
        like: "Like",
        engage: "Engage",
      };

      const {
        url = "#",
        actual,
        target,
        news_title,
        remark,
        platforms,
        type,
        status,
        created_date,
        stop_date,
        start_date,
        end_date,
        views = "-",
        mission = "",
      } = item;

      const CheckTarget = (vActual, vTarget) => {
        if (vActual || vTarget) {
          return `${Math.max(0, vActual)}/${Math.max(0, vTarget)}`;
        }
        return "-";
      };

      return {
        apiData: {
          ...item,
          platform: platforms[0],
        },
        cancelDate: item.canceled_date,
        completeDate: item.completed_date,
        actual,
        title: news_title,
        target,
        // textPreview: remark,
        textTitle: news_title,
        href: url,
        targetLink: "_blank",
        groupSocial: platforms,
        status,
        type: typeCheck[type],
        mission,
        // type: ["like"],
        socialButtonTypeList: [
          mission
            .toLowerCase()
            .replace("replies", "reply")
            .replace("likes", "like")
            .replace("shares", "share")
            .replace("quotes", "quote")
            .replace("share_quote", "share_quote"),
        ],
        created: {
          startDate: created_date,
          // endDate: item.end_date, // Note: Api ??
        },
        workOn: {
          startDate: start_date,
          endDate: end_date,
        },
        // statusDateTime: "01/31/24",
        replies: CheckTarget(actual.replies, target.replies),
        likes: CheckTarget(actual.likes, target.likes),
        shares: CheckTarget(actual.shares, target.shares),
        quotes: CheckTarget(actual.quotes, target.quotes),
        views,
      };
    }),
  ];

  const columns = [
    {
      align: "left",
      // width: "300px",
      title: "List",
      dataIndex: "list",
      key: "list",
      render: (_, items) => <CardEngagement data={items} />,
    },
    // {
    //   align: "center",
    //   width: "80px",
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (_, items) => {
    //     // return null;
    //     return <CardStatus type="cardEngagement" status={items.status} />;
    //   },
    // },
    {
      title: "Copywriter Status",
      // align: "center",
      dataIndex: "status",
      render: (text, items) =>
        items.mission === "reply" ? (
          <Flex justify="center" align="center">
            <StyledButtonStatus
              color={
                myPostStatus[
                  items?.actual?.copywriter === items?.target
                    ? "complete"
                    : items.status
                ]?.color
              }
            >
              <span>
                {
                  myPostStatus[
                    items?.actual?.copywriter === items?.target
                      ? "complete"
                      : items.status
                  ]?.label
                }
              </span>{" "}
              <br />
              <span>
                ({items?.actual?.copywriter?.toLocaleString() || 0}/
                {items?.target?.toLocaleString() || 0})
              </span>
            </StyledButtonStatus>
          </Flex>
        ) : (
          <Flex justify="center" align="center">
            -
          </Flex>
        ),
      width: "29px",
    },
    {
      title: "Influencer Status",
      dataIndex: "targetPost",
      render: (text, items) => (
        <Flex justify="center" align="center">
          <StyledButtonStatus color={myPostStatus[items.status]?.color}>
            <span>{myPostStatus[items.status]?.label}</span> <br />
            <span>
              ({items?.actual?.influencer?.toLocaleString() || 0}/
              {items?.target?.toLocaleString() || 0})
            </span>
          </StyledButtonStatus>
        </Flex>
      ),
      width: "29px",
    },
    // {
    //   align: "center",
    //   title: "Target",
    //   // dataIndex: "target",
    //   // key: "target",
    //   rowScope: "row",

    //   children: [
    //     {
    //       align: "center",
    //       width: "90px",
    //       title: "Replies",
    //       dataIndex: "replies",
    //       key: "replies",
    //       render: (items) => {
    //         // return null;
    //         return (
    //           <ComponentTarget width="unset" status={items || "Replies"} />
    //         );
    //       },
    //     },
    //     {
    //       align: "center",
    //       width: "90px",
    //       title: "Likes",
    //       dataIndex: "likes",
    //       key: "likes",
    //       render: (items) => {
    //         // return null;
    //         return <ComponentTarget width="unset" status={items || "Likes"} />;
    //       },
    //     },
    //     {
    //       align: "center",
    //       width: "90px",
    //       title: "Shares",
    //       dataIndex: "shares",
    //       key: "shares",
    //       render: (items) => {
    //         // return null;
    //         return <ComponentTarget width="unset" status={items || "Shares"} />;
    //       },
    //     },
    //     {
    //       align: "center",
    //       width: "90px",
    //       title: "Quotes",
    //       dataIndex: "quotes",
    //       key: "quotes",
    //       render: (items) => {
    //         // return null;
    //         return <ComponentTarget width="unset" status={items || "Quotes"} />;
    //       },
    //     },
    //     {
    //       align: "center",
    //       width: "90px",
    //       title: "Views",
    //       dataIndex: "views",
    //       key: "views",
    //       render: (items) => {
    //         // return null;
    //         return <ComponentTarget width="unset" status={items || "-"} />;
    //       },
    //     },
    //   ],
    // },
    {
      align: "center",
      width: "70px",
      title: " ",
      dataIndex: "status",
      key: "status",
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (_, items) => {
        // return null;
        return (
          <Flex justify="center">
            <ButtonBooster
              isDefault="true"
              boosted={items?.status?.toLowerCase() === "active"}
              onClick={() => onClickButtonEngages(items)}
            />
          </Flex>
        );
      },
    },
  ];

  /* Mock */

  const onChangePagination = (page) => {
    setCurrentPage(page);
    dispatch(
      setFiledEngagement({
        key: "engagementPaginationQuery",
        value: PaginationQuery(page, limit),
      }),
    );
  };

  useEffect(() => {
    onChangePagination(1);
    return () => {};
  }, [queryFilterRedux, engagementPaginationQuery]);

  const onChangeTable = (pagination, filters, sorter, extra) => {
    // console.log(`----  onChangeTable  extra:`, extra);
    // console.log(`----  onChangeTable  sorter:`, sorter);
    // console.log(`----  onChangeTable  filters:`, filters);
    // console.log(`----  onChangeTable  pagination:`, pagination);
    // // 1. filter data
    // let dataCopy = [...dataSource];
    // for (const [k, v] of Object.entries(filters)) {
    //   if (v) {
    //     // get onFilter callback of that column
    //     const filterCb = columns.find((e) => e.dataIndex === k).onFilter;
    //     dataCopy = dataCopy.filter((e) => filterCb(v, e));
    //   }
    // }
    // // 2. rebuild columns using only the filtered data
    // const columns = columns.map((e) => ({
    //   ...e,
    // }));
    // for (const colConfig of columns) {
    //   if (colConfig.filters) {
    //     const colFilters = [...colConfig.filters];
    //     const newColFilters = [];
    //     // iterate over filtered data to retain only values that
    //     // match the onFilter condition of the current colConfig
    //     for (const record of dataCopy) {
    //       const filterRecord = colFilters.find((e) =>
    //         colConfig.onFilter(e.value, record),
    //       );
    //       // add this filterRecord to newColFilter if not exists already
    //       if (
    //         filterRecord &&
    //         newColFilters.findIndex((e) => e.value === filterRecord.value) ===
    //           -1
    //       ) {
    //         newColFilters.push(filterRecord);
    //       }
    //     }
    //     colConfig.filters = newColFilters;
    //   }
    // }
    // // return modified columns
    // // return columns;
    // // console.log(`----  onChange  columns:`, columns);
  };

  return (
    <MainLayout>
      {contextHolder}
      <StyledTableEngagement vertical gap="16px">
        {/* Filter */}
        <FilterData
          title="My Engagements"
          labelSocial="Social Media : "
          labelDetail={`Total ${Math.max(0, total)} Items`}
          labelSort="Sorting :"
          labelFilter="Show :"
          disabledButton={false}
          filter={{
            social: socialDefault,
            optionSortList: optionSortDefaultList,
            optionSortSelect: optionSortDefaultSelect,
            typeShow: "engagementsOwner",
          }}
        />
        {/* Filter */}

        <StyledTableContainer
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                onClickBoxEngages(record);
              },
              // onDoubleClick: (event) => {
              //   console.log(`----  onRow  onDoubleClick:`, event);
              // },
              // onContextMenu: (event) => {
              //   console.log(`----  onRow  onContextMenu:`, event);
              // },
              // onMouseEnter: (event) => {
              //   console.log(`----  onRow  onMouseEnter:`, event);
              // },
              // onMouseLeave: (event) => {
              //   console.log(`----  onRow  onMouseLeave:`, event);
              // },
            };
          }}
          locale={{
            emptyText: <EmptyDefault />,
            filterConfirm: <ButtonIcon label="Confirm" size={null} />,
            filterReset: (
              <ButtonIcon
                label="Reset"
                type="default"
                size={null}
                primary={false}
              />
            ),
          }}
          style={{
            minHeight: "calc(100vh - 240px)",
            height: "max-content",
          }}
          onChange={onChangeTable}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            position: ["bottomRight"],
            defaultCurrent: 1,
            current: currentPage,
            pageSize: limit,
            total,
            showLessItems: false,
            showSizeChanger: false,
            showQuickJumper: false,
            hideOnSinglePage: false,
            // showPrevNextJumpers: false,
            onChange: (page, pageSize) => onChangePagination(page, pageSize),
          }}
          // bordered
          // scroll={{
          //   x: "max-content",
          //   y: "calc(100vh - 354px)",
          // }}
          // showHeader={false}
        />
      </StyledTableEngagement>
    </MainLayout>
  );
};

export default TableEngagement;
