import React from "react";
import { Tabs as Tab } from "antd";
import styled from "styled-components";

const StyleTabs = styled(Tab)`
  .ant-tabs-tab {
    padding: 12px 30px;
    font-family: Sarabun !important;
  }

  .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.colorTextButton};
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-weight: 700;
      color: ${(props) => props.theme.colorMagenta} !important;
    }
  }

  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colorMagenta};
  }
`;

const Tabs = (props) => {
  const {
    tabActive = "1",
    tabList = [
      {
        key: "1",
        label: "Tab 1",
        children: "Content of Tab Pane 1",
      },
      {
        key: "2",
        label: "Tab 2",
        children: "Content of Tab Pane 2",
      },
      {
        key: "3",
        label: "Tab 3",
        children: "Content of Tab Pane 3",
      },
    ],
    handleClickTabs = () => null,
  } = props;

  const onChange = (key) => {
    handleClickTabs(key);
  };

  return (
    <StyleTabs
      defaultActiveKey="1"
      activeKey={String(tabActive)}
      items={tabList}
      onChange={onChange}
    />
  );
};

export default Tabs;
