import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const sentimentProvider = () => {
  const { axios } = useAxios();

  const getSentiment = async (id) => {
    try {
      const url = `${host}/sentiment/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  const updateSentiment = async (id, body) => {
    try {
      const url = `${host}/sentiment/${id}`;
      const res = await axios.patch(url, body);
      return res.status;
    } catch (error) {
      return error;
    }
  };

  return {
    getSentiment,
    updateSentiment,
  };
};

export default sentimentProvider;
