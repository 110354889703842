import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const authProvider = () => {
  const { axios } = useAxios();

  const userLogin = async (body) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host}/auth/login`;
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const userRegister = async (body) => {
    try {
      const url = `${host}/users/register`;
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  const userLogout = async (id, body) => {
    try {
      const url = `${host}/users/logout`;
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  return {
    userLogin,
    userRegister,
    userLogout,
  };
};

export default authProvider;
