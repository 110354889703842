import styled from "styled-components";
// eslint-disable-next-line object-curly-newline
import { Button, Flex, Input, Form, Tooltip, Radio, Modal } from "antd";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import copy from "copy-text-to-clipboard";
import { useParams, useBeforeUnload } from "react-router-dom";
import dayjs from "dayjs";
import { isRoleSuperAdmin } from "../../../common/utils/helper";
import RadioSubMission from "../../flowJob/RadioSubMission";
import ProfileCard from "../../../common/components/ProfileCard";
import SocialButton from "../../../common/components/SocialButton";
// import IconSocial from "../../../common/components/IconSocial";
import ContentJobDetail from "../../../common/components/ContentJobDetail";
import IconSocial from "../../../common/components/IconSocial";
import submitConttentProvider from "../provider/submissionContentProvider";
import { useAuth } from "../../../common/hooks/useProviderAuth";
import { IconWarning } from "../../missions/MyPostsList";

const StyleFormSubmitContent = styled(Flex)`
  height: 100vh;
  padding-bottom: 100px;
  overflow-y: scroll;
`;

const StyleFooter = styled.div`
  border-top: solid 1px ${(props) => props?.theme?.actionBorder};
  padding: 20px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: white;

  .ant-form-item {
    margin-bottom: 0px;
  }
`;

const StyleButtonFooter = styled(Button)`
  width: 180px;
  height: 100%;
  padding: 6px 16px;
  font-size: 16px;
  font-weight: 700;
`;

const StyleContainer = styled.div`
  padding: 20px;
`;

const StyleTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const StyleButton = styled(Button)`
  width: fit-content;
  width: 100px;
  /* border-color: ${(props) => props?.theme?.colorButtonDelete}; */
  /* color: ${(props) => props?.theme?.colorButtonDelete}; */
`;

const StyleContentPreview = styled.div`
  padding: 20px;
  background-color: ${(props) => props?.theme?.backgroundFilter};
  border-radius: 10px;
  color: ${(props) => props?.theme?.colorTextButton};
  font-size: 14px;
  overflow-y: auto;
  height: 142px;
`;

const postType = {
  post: "Post",
  reply: "Reply",
};

const StyleRoleUser = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: ${(props) => props.theme.colorTertiary};
`;

const FormSubmitContent = () => {
  const { user } = useAuth();
  const { contentId } = useParams();
  const [formSubmitContent] = Form.useForm();
  const SubmitConttentProvider = submitConttentProvider();
  const [showToolTipCopy, setShowToolTipCopy] = useState(false);
  const [contentJobDetail, setContentJobDetail] = useState({});
  const [socailAccounts, setSocailAccounts] = useState([]);
  const [modal, contextHolder] = Modal.useModal();
  let isPickACard = false;

  const logItem = (value) => {
    const valueItem = `${value} @ ${new Date().toLocaleTimeString()}`;
    const storage = localStorage.getItem("logPick");
    if (storage) {
      const logPick = JSON.parse(storage);
      logPick.array = [...logPick.array, valueItem];
      localStorage.setItem("logPick", JSON.stringify(logPick));
    } else {
      localStorage.setItem(
        "logPick",
        JSON.stringify({
          array: [valueItem],
        }),
      );
    }
  };

  const closeWindow = async () => {
    logItem("function closeWindow");
    // eslint-disable-next-line no-use-before-define
    await unloadWindow();
    window.close();
  };

  const modalConfirmDetails = {
    sessionError: {
      title: "Your session is about to expire!",
      subTitle: "",
    },
    noJobAvailable: {
      title: "No job available.",
      subTitle: "",
    },
    errorOccurred: {
      title: "An error has occurred.",
      subTitle: "Please try again.",
    },
  };

  const modalError = (param) => {
    const { title, subTitle, onOk = () => closeWindow() } = param;
    modal.info({
      title,
      subTitle,
      icon: <IconWarning />,
      content: "",
      centered: true,
      okText: "OK",
      onOk,
    });
  };

  const fetchGetPostContent = async () => {
    try {
      const response = await SubmitConttentProvider.getPostContent({
        id: contentId,
      });
      setContentJobDetail(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchCreatPostContent = async (param) => {
    const payload = {
      id: contentId,
      body: {
        post_url: param?.url,
        social_account_id: param?.name,
      },
    };
    try {
      await SubmitConttentProvider.createSubmitPost(payload);
    } catch (error) {
      const { code, message } = error?.data || {};
      if (code === 403) {
        if (message === "Social account does not exist") {
          modalError(modalConfirmDetails.errorOccurred);
        } else if (message === "Content not found") {
          modalError(modalConfirmDetails.noJobAvailable);
        } else if (message === "Incorrect mission") {
          modalError(modalConfirmDetails.noJobAvailable);
        }
      } else if (code === 422) {
        if (message === "Mission expired") {
          modalError(modalConfirmDetails.expired);
        }
      } else {
        modalError(modalConfirmDetails.errorOccurred);
      }
      throw error;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const fetchlockSubmissionContent = async () => {
    try {
      await SubmitConttentProvider.lockSubmissionContent({
        id: contentId,
      });
      logItem("Fetch Pick!");
    } catch (error) {
      modalError(modalConfirmDetails.noJobAvailable);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const fetchUnlockSubmissionContent = async () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        logItem("before Fetch UnPick!");
        await SubmitConttentProvider.unlockSubmissionContent({
          id: contentId,
        });
        logItem("after Fetch UnPick!");
        resolve(true);
      } catch (error) {
        console.log(error);
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(false);
      }
    });
  };

  /* param(Object) : {platform: "facebook"} */
  const fetchGetSocailAccount = async ({ platform, missionId }) => {
    if (platform && missionId) {
      try {
        const res = await SubmitConttentProvider.getSocailAccount({
          platform,
          missionId,
        });
        if (res) {
          const socialAccountApproved = res?.filter(
            (item) => item?.approved_date !== null,
          );
          setSocailAccounts(socialAccountApproved);
          if (socialAccountApproved && socialAccountApproved?.length > 0) {
            formSubmitContent.setFieldsValue({
              name: socialAccountApproved[0]?.id,
            });
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const unloadWindow = async () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        logItem("before Fetch UnPick!");
        await SubmitConttentProvider.unlockSubmissionContent({
          id: contentId,
        });
        logItem("after Fetch UnPick!");
        resolve(true);
      } catch (error) {
        console.log(error);
        // eslint-disable-next-line prefer-promise-reject-errors
        reject(false);
      }
    });
  };

  useBeforeUnload(
    React.useCallback(async () => {
      const result = await fetchUnlockSubmissionContent();
      logItem("result", result);
    }, []),
  );

  useEffect(() => {
    formSubmitContent.resetFields();
    fetchGetPostContent();

    if (!isPickACard) {
      isPickACard = true;
      setTimeout(() => {
        logItem("Pick!");
        fetchlockSubmissionContent();
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (contentJobDetail) {
      fetchGetSocailAccount({
        platform: [contentJobDetail?.platform],
        missionId: contentJobDetail?.mission_id,
      });
    }
  }, [contentJobDetail]);

  const copyToClipboard = (text) => {
    copy(text);
    setShowToolTipCopy(true);
    setTimeout(() => {
      setShowToolTipCopy(false);
    }, 1000);
  };

  const handleCancel = async () => {
    await unloadWindow();
    logItem("after unLoadwindow");
    window.close();
  };

  const handleConfirm = async () => {
    try {
      const formValue = formSubmitContent.getFieldsValue();
      await fetchCreatPostContent(formValue);
      window.close();
    } catch (error) {
      console.log("error");
    }
  };

  const onFinish = () => {
    modal.confirm({
      title: "Are you sure you want to do this?",
      content: "Would you like to confirm?",
      icon: <IconWarning />,
      centered: true,
      okText: "Confirm",
      onOk: () => handleConfirm(),
      cancelText: "Cancel",
      onCancel: () => handleCancel(),
    });
  };

  const getUserRole = () => {
    if (user?.roles) {
      if (user?.roles.includes("influencer")) return "influencer";
      if (user?.roles.includes("writer") && !user?.roles.includes("influencer"))
        return "writer";
    }
    return "writer";
  };

  // eslint-disable-next-line no-unused-vars
  const getShortName = (fullName) => {
    return fullName ? `${fullName.substring(0, 3)}...` : "";
  };

  return (
    <StyleFormSubmitContent vertical>
      {Object.keys(user).length ? (
        <Form
          form={formSubmitContent}
          name="form-submit-content"
          onFinish={onFinish}
        >
          <StyleContainer>
            <Flex vertical gap={10}>
              {/* section title */}
              <Flex gap={10}>
                <StyleTitle>Post Content</StyleTitle>
                <SocialButton type={contentJobDetail?.type} />
              </Flex>

              {/* section card profile */}
              <ProfileCard
                img="https://d3h1lg3ksw6i6b.cloudfront.net/media/image/2019/04/19/a67f753a5ce74ac296d7fb792289dd8c_refreshingsummertreat.jpg"
                label={user?.username}
                roleUser={getUserRole()}
              />

              {/* section mission */}
              <Flex vertical gap={20}>
                <ContentJobDetail
                  dataItem={{
                    type: contentJobDetail?.platform,
                    content:
                      contentJobDetail?.type === "post"
                        ? contentJobDetail?.requirement
                        : contentJobDetail?.news_title,
                    startDate: contentJobDetail?.start_date,
                    endDate: contentJobDetail?.end_date,
                  }}
                  dataItemRequirement={contentJobDetail?.requirement}
                />

                <RadioSubMission
                  value={postType[contentJobDetail?.type]}
                  onChange={() => {
                    // console.log("onchange radio submission");
                  }}
                  socialList={["Post", "Reply"]}
                />

                <Flex gap={20} justify="space-between">
                  <div>
                    <StyleTitle>Content</StyleTitle>{" "}
                    <StyleRoleUser>
                      Create By: {getShortName(contentJobDetail?.writer_name)}
                      {/* {`${contentJobDetail?.writer_name} (${contentJobDetail?.role_name})`} */}
                    </StyleRoleUser>
                  </div>
                  {!isRoleSuperAdmin() && (
                    <Tooltip title="COPIED!" open={showToolTipCopy} defaultOpen>
                      <StyleButton
                        type="warning"
                        onClick={() =>
                          copyToClipboard(contentJobDetail?.content_text)
                        }
                      >
                        Copy Text
                      </StyleButton>
                    </Tooltip>
                  )}
                </Flex>

                <StyleContentPreview>
                  {contentJobDetail?.content_text}
                </StyleContentPreview>

                {socailAccounts && socailAccounts.length > 0 && (
                  <Form.Item name="name" label="Name">
                    <Radio.Group>
                      {socailAccounts &&
                        socailAccounts.map((item) => (
                          <Radio key={item?.name} value={item?.id}>
                            {item?.name}
                          </Radio>
                        ))}
                    </Radio.Group>
                  </Form.Item>
                )}

                {!isRoleSuperAdmin() && (
                  <Form.Item
                    name="url"
                    style={{
                      width: "100%",
                      marginBottom: "0px",
                    }}
                    rules={[
                      {
                        required: true,
                        message: "URL is required!",
                      },
                      {
                        validator: (_, value) =>
                          value.trim()
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Please enter a Job URL"),
                              ),
                      },
                    ]}
                  >
                    <Flex vertical gap={20}>
                      <StyleTitle>Job URL</StyleTitle>
                      <Flex gap={20} align="center">
                        <IconSocial type={contentJobDetail?.platform} />
                        <Input placeholder="Link URL" />
                      </Flex>
                    </Flex>
                  </Form.Item>
                )}
              </Flex>
              <Flex justify="center" vertical={false}>
                <span
                  style={{
                    color: "red",
                  }}
                >
                  Please submit your job by
                  {dayjs().add(1, "hour").format("HH:mm")}
                </span>
              </Flex>
            </Flex>
          </StyleContainer>
          {!isRoleSuperAdmin() && (
            <StyleFooter>
              <Form.Item>
                <Flex align="center" justify="center" gap={10}>
                  <StyleButtonFooter
                    type="cancel"
                    htmlType="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </StyleButtonFooter>
                  <StyleButtonFooter type="primary" htmlType="submit">
                    Confirm
                  </StyleButtonFooter>
                </Flex>
              </Form.Item>
            </StyleFooter>
          )}
        </Form>
      ) : null}

      {contextHolder}
    </StyleFormSubmitContent>
  );
};

export default FormSubmitContent;
