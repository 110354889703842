/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table, Button, Flex, Modal } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { myNewWindow } from "../../common/utils/helper";
import EmptyDefault from "../../common/components/EmptyDefault";
import { myPostStatus } from "../../common/utils/keyDatas";
import GroupSocial from "../../common/components/GroupSocial";
import ButtonBooster from "../../common/components/ButtonBooster";
import FilterData from "../../common/components/FilterData";
import CardPost from "../../common/components/CardPost";
import CardEngagement from "../missions/owner/myEngagements/CardEngagement";

import {
  optionSortDefaultList,
  optionSortDefaultSelect,
  socialDefault,
} from "../../common/utils/filterDatas";
import missionOwnerProvider from "./provider/missionOwnerProvider";

const left = window.screen.width / 2 - 800 / 2;
const top = window.screen.height / 2 - 688 / 2;

export const StyledButtonStatus = styled.span`
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  text-transform: capitalize;
  color: ${(props) => props.theme[props.color]};
`;

const TablePostList = styled(Table)`
  margin-top: 15px;

  .ant-table-cell-row-hover {
    cursor: pointer;
    background: transparent !important;
  }
  .ant-table-filter-column {
    .ant-table-column-title {
      color: ${(props) => props.theme.white} !important;
    }
    .ant-table-filter-trigger {
      svg {
        path {
          color: ${(props) => props.theme.white} !important;
        }
      }
    }
  }
  .ant-table-thead {
    ::before {
      display: none !important;
    }
    th {
      background-color: ${(props) => props.theme.colortitletable} !important;
      color: ${(props) => props.theme.white} !important;
    }
    td {
      background-color: ${(props) => props.theme.colortitletable} !important;
      color: ${(props) => props.theme.white} !important;
    }
  }
  .ant-table-column-title {
    /* color: #2c3e50; */
  }
`;

const PostListContainer = styled.div`
  padding: 16px;
`;

export const IconWarning = styled(ExclamationCircleOutlined)`
  path {
    color: ${(props) => props.theme.colorTextButton};
  }
`;

const GroupSocialCustom = styled(GroupSocial)`
  padding: 32px;
`;

const useMissionList = () => {
  const navigate = useNavigate();
  const [listMission, setListMission] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 50;
  const missionApi = missionOwnerProvider();
  const { data: missionItem } = listMission;
  const filterState = useSelector((state) => state.filterData);
  const { queryFilterRedux } = filterState;
  const parseQuery = queryString.parse(queryFilterRedux);
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();

  const getMissionList = async (page) => {
    try {
      const query = {
        ...parseQuery,
        page: page || 1,
        limit,
      };
      const path = queryString
        .stringify(query)
        .replace(/target=/g, "type=")
        .replace(/platform=/g, "platforms=");
      const res = await missionApi.getSubMissionContent(path);
      setListMission(res);
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatusMission = async (id, status) => {
    try {
      const res = await missionApi.updateStatusMission(id, status);
      if (res?.message === "success") {
        await getMissionList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure you want to do this?",
      content: "Would you like to confirm?",
      icon: <IconWarning />,
      centered: true,
      okText: "OK",
      onOk: () => updateStatusMission(id, "cancel"),
      cancelText: "Cancel",
    });
  };
  // function ปิด window
  let win = null;
  async function handleCloseWindowMission() {
    if (win.location !== "about:blank") {
      await getMissionList();
    }
  }

  const handleOpenWindowCreateMission = (data) => {
    win = myNewWindow(
      data === undefined ? "/create-mission" : `/create-mission/${data?.id}`,
      data?.id || "",
    );
    win.onunload = handleCloseWindowMission;
  };

  const onChangePagination = async (page, pageSize) => {
    setCurrentPage(page);
    await getMissionList(page);
  };

  useEffect(() => {
    setCurrentPage(1);
    if (queryFilterRedux) {
      if ("platform" in parseQuery && "target" in parseQuery) {
        getMissionList();
      }
    }
    return () => {};
  }, [queryFilterRedux]);

  const columns = [
    {
      title: "Task",
      dataIndex: "title",
      width: "80%",
      render: (text, data) => (
        <Flex direction="row" gap="16px">
          {data.type === "post" ? (
            <>
              {" "}
              <GroupSocialCustom list={[data.platform]} />
              <CardPost
                content={data.requirement}
                startDate={data.start_date}
                endDate={data.end_date}
                createDate={data.created_date}
                updateDate={data.status === "cancel" && data.updated_date}
                missionType={data.type}
                cancelDate={data.canceled_date}
                completeDate={data.completed_date}
              />
            </>
          ) : (
            <CardEngagement
              data={{
                ...data,
                created: {
                  startDate: data.created_date,
                },
                workOn: {
                  startDate: data.start_date,
                  endDate: data.end_date,
                },
                socialButtonTypeList: [data.type],
                groupSocial: [data.platform],
                href: data.news_url,
                title: data.news_title,
              }}
            />
          )}
        </Flex>
      ),
    },
    {
      title: "Copywriter Status",
      dataIndex: "status",
      align: "center",
      render: (text, data) => (
        <StyledButtonStatus color={myPostStatus[data.status]?.color}>
          <span>{myPostStatus[data.status]?.label}</span> <br />
          <span>
            ({data.actual || 0}/{data.target || 0})
          </span>
        </StyledButtonStatus>
      ),
      width: "29px",
    },
    // {
    //   title: "Influencer Status",
    //   dataIndex: "targetPost",
    //   align: "center",
    //   render: (text, data) => (
    //     <StyledButtonStatus color={myPostStatus[data.status]?.color}>
    //       <span>{myPostStatus[data.status]?.label}</span> <br />
    //       <span>
    //         ({data.actual.influencer || 0}/{data.target || 0})
    //       </span>
    //     </StyledButtonStatus>
    //   ),
    //   width: "29px",
    // },
    // {
    //   title: "",
    //   dataIndex: "pick",
    //   render: (text, data) => {
    //     const isBoosted = data?.status === "active";
    //     return (
    //       <ButtonBooster
    //         boosted={isBoosted}
    //         typeBoost="post"
    //         onClick={
    //           isBoosted
    //             ? (e) => {
    //                 confirm(data.id);
    //                 e.stopPropagation();
    //               }
    //             : (e) => {
    //                 handleOpenWindowCreateMission(data);
    //                 e.stopPropagation();
    //               }
    //         }
    //         isDefault
    //       />
    //     );
    //   },
    //   align: "center",
    //   width: "10%",
    // },
  ];

  return {
    columns,
    missionItem,
    listMission,
    onChangePagination,
    handleOpenWindowCreateMission,
    handleCloseWindowMission,
    navigate,
    contextHolder,
    currentPage,
    limit,
  };
};

const MissionContentOwner = () => {
  const {
    columns,
    onChange,
    listMission,
    missionItem,
    onChangePagination,
    handleOpenWindowCreateMission,
    navigate,
    contextHolder,
    currentPage,
    limit,
  } = useMissionList();
  const { data, total, actual } = listMission;

  return (
    <PostListContainer>
      <FilterData
        title="Missions"
        labelSocial="Social Media :"
        labelDetail={`Total ${total || 0} ${total > 1 ? "Items" : "Item"}`}
        labelSort="Sorting :"
        labelFilter="Show :"
        // labelButton="New"
        // onClickButton={() => handleOpenWindowCreateMission()}
        disabledButton={false}
        filter={{
          social: socialDefault,
          optionSortList: optionSortDefaultList,
          optionSortSelect: optionSortDefaultSelect,
          typeShow: "missionCopywriter",
        }}
      />
      <TablePostList
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/submission-owner/${record.id}/${record.type}`);
            },
          };
        }}
        columns={columns}
        dataSource={missionItem}
        onChange={onChange}
        locale={{
          emptyText: <EmptyDefault />,
        }}
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: 1,
          pageSize: limit,
          current: currentPage,
          total,
          showLessItems: false,
          showSizeChanger: false,
          showQuickJumper: false,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => onChangePagination(page, pageSize),
        }}
      />
      {contextHolder}
    </PostListContainer>
  );
};

export default MissionContentOwner;
