import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Flex, Select } from "antd";
import { useNavigate } from "react-router-dom";

import SelectFilter from "../../../common/components/SelectFilterComponent";
import {
  StyledPagination,
  StyledButtonIcon,
  TextSelect,
  StyledButtonAdd,
} from "../../../common/components/ThemeStyledComponent";
import { formatNumberUppercase } from "../../../common/utils/format";
import ModalConfirmComponent from "../../../common/components/ModalComponent";
import ListTrendProvider from "../provider/listTrendProvider";
import { keysIcons } from "../../../common/utils/keyDatas";

const titleData = [
  {
    title: "Campaign",
  },
  {
    title: "Title",
  },
  {
    title: "url",
  },
  {
    title: "Platform",
  },
  {
    title: "Share",
  },
  {
    title: "Like",
  },
  {
    title: "Comment",
  },
  {
    title: "views",
  },
  {
    title: "Effect",
  },
  {
    title: "Status",
  },
];

const optionsStatus = [
  {
    value: 0,
    label: "รออนุมัติ",
  },
  {
    value: 1,
    label: "อนุมัติ",
  },
  // {
  //   value: 2,
  //   label: "รอตรวจสอบ",
  // },
];

const optionsFilterStatus = [
  {
    value: 0,
    label: "รออนุมัติ",
  },
  {
    value: 1,
    label: "อนุมัติ",
  },
  {
    value: 2,
    label: "สถานะทั้งหมด",
  },
];

const StyledDiv = styled.div`
  @media (max-width: 1000px) or (orientation: portrait) {
    width: calc(100vw - 64px);
    overflow-x: auto;
    border-radius: 10px;
  }
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

const StyledTableCell = styled.td`
  background-color: ${(props) => props.theme.white};
  padding: 8px;

  a {
    width: 250px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const TextTable = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const StyledTableHeader = styled.th`
  padding: 8px;
  background-color: ${(props) => props.theme.colortitletable};
  color: ${(props) => props.theme.white};
`;

const StyledEvenRow = styled.tr`
  background-color: #f2f2f2;
`;

const StyledHoverRow = styled.tr`
  &:hover {
    background-color: #ddd;
  }
`;

const StyledStatus = styled(Select)`
  width: 110px !important;
  .ant-select-selector {
    border: 0px solid #d9d9d9 !important;
  }

  .ant-select-selection-item {
    font-size: 14px;
    color: ${(props) => props.color};
  }

  .ant-select-arrow {
    color: #0000008a;
  }
`;

const ListTable = () => {
  const navigate = useNavigate();
  const listTrendProvider = ListTrendProvider();
  const { filter, sortby } = useSelector((state) => state.filterList);
  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentId, setCurretId] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState(2);
  const pageSize = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchData = async () => {
    try {
      const filterPlatform = Object.keys(filter).filter((key) => filter[key]);

      const platformString = filterPlatform
        .map((platform) => `platform=${platform}`)
        .join("&");

      let params = `?&${platformString}&sort=${sortby}`;
      if (filterStatus === 2) {
        params += "&approved=1&approved=0";
      } else if (filterStatus === 1) {
        params += "&approved=1";
      } else {
        params += "&approved=0";
      }

      const res = await listTrendProvider.getListTrend(params);
      if (res) {
        const data = res.filter((e) => !!e);
        setNewsData(data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
    setCurrentPage(1);
  }, [filter, sortby, filterStatus]);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = newsData.slice(startIndex, endIndex);

  const onEditTrend = (news) => {
    navigate("/edit/trend", {
      state: {
        trendId: news?.id,
        title: news?.title,
        description: news?.description,
        url: news?.url,
        likes: news?.likes,
        shares: news?.shares,
        comments: news?.comments,
        views: news?.views,
        sentiment: {
          positive_trend: news?.positive_trend,
          negative_trend: news?.negative_trend,
        },
        campaign_id: news?.campaign_id,
        arrow: news?.arrow,
        keyword: news?.keyword,
        platform: news?.platform,
        type: "edit",
      },
    });
  };

  const onAddTrend = () => {
    navigate("/add/trend", {
      state: {
        type: "add",
      },
    });
  };

  const showModal = (id) => {
    setCurretId(id);
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    await listTrendProvider.deleteListTrend(currentId);
    fetchData();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeFilteStatus = (value) => {
    setFilterStatus(value);
  };

  const handleChangeStatus = async (id, value) => {
    const body = {
      approved: value,
    };
    await listTrendProvider.editListTrend(id, body);
    fetchData();
  };

  const getStatusColor = (status) => {
    if (status === 1) {
      return "#0EA67F";
    }
    if (status === 2) {
      return "#7D5DE8";
    }
    return "#FF2643";
  };

  return (
    <Flex
      gap="small"
      wrap="wrap"
      justify="space-between"
      style={{
        padding: "8px 16px",
      }}
      vertical
    >
      <Flex justify="space-between">
        <Flex justify="flex-start" align="center">
          <TextSelect>สถานะ</TextSelect>
          <SelectFilter
            options={optionsFilterStatus}
            value={filterStatus}
            onChange={handleChangeFilteStatus}
          />
        </Flex>
        <Flex justify="flex-end">
          <StyledButtonAdd
            type="primary"
            color="#0EA67F"
            onClick={() => onAddTrend()}
          >
            <img
              src={keysIcons.add}
              alt="add"
              style={{
                height: "20px",
                width: "20px",
              }}
            />
            เพิ่มข่าว
          </StyledButtonAdd>
        </Flex>
      </Flex>
      <StyledDiv>
        <StyledTable>
          <thead>
            <tr>
              {titleData.map((e) => (
                <StyledTableHeader key={e.title}>{e.title}</StyledTableHeader>
              ))}
              <StyledTableHeader />
            </tr>
          </thead>
          <tbody>
            {currentData.map((news, index) => (
              <React.Fragment key={news.id}>
                <StyledHoverRow as={index % 2 === 0 ? StyledEvenRow : "tr"}>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <TextTable>{news.campaign_name}</TextTable>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      width: "70%",
                    }}
                  >
                    <TextTable>{news.title}</TextTable>
                  </StyledTableCell>
                  <StyledTableCell>
                    <a
                      href={news.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {news.url}
                    </a>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={keysIcons[news.platform]}
                      alt={news.platform}
                      style={{
                        height: "30px",
                        width: "30px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {formatNumberUppercase(news.shares)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {formatNumberUppercase(news.likes)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {formatNumberUppercase(news.comments)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {formatNumberUppercase(news.views)}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={keysIcons[news.arrow]}
                      alt={news.platform}
                      style={{
                        height: "30px",
                        width: "30px",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <StyledStatus
                      color={getStatusColor(news.approved)}
                      value={news.approved}
                      options={optionsStatus}
                      onChange={(val) => handleChangeStatus(news?.id, val)}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Flex justify="space-around">
                      <StyledButtonIcon
                        type="text"
                        size="small"
                        hoverColor="orange"
                        onClick={() => showModal(news?.id)}
                      >
                        <img
                          src={keysIcons.delete}
                          alt={news.platform}
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </StyledButtonIcon>
                      <StyledButtonIcon
                        type="text"
                        size="small"
                        hoverColor="orange"
                        onClick={() => onEditTrend(news)}
                      >
                        <img
                          src={keysIcons.edit}
                          alt={news.platform}
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </StyledButtonIcon>
                    </Flex>
                  </StyledTableCell>
                </StyledHoverRow>
              </React.Fragment>
            ))}
          </tbody>
        </StyledTable>
      </StyledDiv>
      <ModalConfirmComponent
        okText="Yes"
        okColor="red"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete this trend?</p>
      </ModalConfirmComponent>
      {Boolean(newsData.length > 10) && (
        <StyledPagination
          current={currentPage}
          total={newsData.length}
          pageSize={pageSize}
          onChange={handlePageChange}
          showSizeChanger={false}
          style={{
            marginTop: "20px",
            textAlign: "center",
          }}
        />
      )}
    </Flex>
  );
};

export default ListTable;
