/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { createContext, useContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useProviderAuth";

const host = process.env.REACT_APP_API_ENDPOINT;

const AxiosContext = createContext();

const useProviderAxios = () => {
  const navigate = useNavigate();
  const { signout, signin, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  let isRefreshToken = false;

  const axiosInstance = axios.create();

  const getRefreshToken = async (param) => {
    /* param: {refreshToken: "adsooo000808dsfdf"} */
    // eslint-disable-next-line no-useless-catch, no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${host}/auth/refresh-token`;
        const res = await axios.post(url, param);
        resolve(res.data);
      } catch (error) {
        // signout();
        reject(error);
      }
    });
  };

  axiosInstance.interceptors.request.use(
    (config) => {
      /* if there is login on another force the old one refresh */
      if (
        Cookies.get("username") &&
        user?.username &&
        user?.username !== Cookies.get("username")
      )
        window.location.reload();

      setIsLoading(true);
      const token = Cookies.get("token") || null;
      if (token != null) config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    (error) => {
      // console.error("Request Interceptor Error:", error);
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);

      return response;
    },
    async (error) => {
      const err = error.response;

      /* Unauthorized 401 */
      const refreshToken = Cookies.get("refresh");
      if (refreshToken) {
        if (err?.status === 401 && !isRefreshToken) {
          isRefreshToken = true;
          try {
            if (refreshToken) {
              const response = await getRefreshToken({
                refreshToken,
              });
              const { access, refresh } = response || {};
              signin(
                {
                  token: access.token,
                  refresh: refresh.token,
                },
                () => {},
              );
              isRefreshToken = false;

              setTimeout(() => {
                window.location.reload();
              }, 1500);
              return Promise.reject(err);
              // return error;
            }
            signout();
            return Promise.reject(err);
          } catch (errors) {
            signout(() => navigate("/login"));
            return Promise.reject(errors);
          }
        }
      }

      if (err?.status === 422) {
        setIsLoading(false);
        return Promise.reject(err.data);
      }

      if (err?.status === 500) {
        setIsLoading(false);
        const errorMessage = new Error("Server Error");
        return Promise.reject(errorMessage);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 500);

      return Promise.reject(err);
    },
  );

  return {
    axiosInstance,
    isLoading,
  };
};

const useAxios = () => {
  const { axiosInstance, isLoading } = useContext(AxiosContext);
  return {
    axios: axiosInstance,
    isLoading,
  };
};
const AxiosProvider = ({ children }) => {
  const instanceRef = useProviderAxios();

  return (
    <AxiosContext.Provider value={instanceRef}>
      {children}
    </AxiosContext.Provider>
  );
};

export { AxiosProvider, useAxios };
