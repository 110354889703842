import React from "react";
import styled from "styled-components";
import { Flex } from "antd";
import SelectFilter from "../../../common/components/SelectFilterComponent";
import {
  TextSelect,
  StyledButtonAdd,
} from "../../../common/components/ThemeStyledComponent";
import IconClock from "../../../assets/icons/icon-clock.svg";

const platformOptions = [
  {
    key: "facebook",
    value: "facebook",
    label: "Facebook",
  },
  {
    key: "twitter",
    value: "twitter",
    label: "Twitter",
  },
  {
    key: "tiktok",
    value: "tiktok",
    label: "Tiktok",
  },
  {
    key: "pantip",
    value: "pantip",
    label: "Pantip",
  },
  {
    key: "internet",
    value: "internet",
    label: "Internet",
  },
];

const FilterTrendLatest = ({ onOpenModal }) => {
  const TextTime = styled.span`
    color: #6d6e71;
    text-align: right;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  `;

  return (
    <Flex justify="space-between">
      <Flex justify="flex-start" align="center">
        <TextSelect>สถานะ</TextSelect>
        <SelectFilter options={platformOptions} defaultValue="internet" />
      </Flex>
      <Flex justify="flex-start" align="center" gap="small">
        <img src={IconClock} alt="icon-clock" />
        <TextTime>อัปเดทล่าสุดเมื่อ 20 มี.ค. 2567 12.00 น.</TextTime>
        <StyledButtonAdd
          type="primary"
          color="#0EA67F"
          onClick={() => onOpenModal()}
        >
          เพิ่มเทรนด์
        </StyledButtonAdd>
      </Flex>
    </Flex>
  );
};

export default FilterTrendLatest;
