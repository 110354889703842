import { Flex } from "antd";
import styled from "styled-components";
import IconNoItem from "../../../assets/icons/icon-no-item.svg";

const EmptyDefault = ({
  icon = IconNoItem,
  label = "No Items",
  minHeight = "50vh",
}) => {
  const StyledEmptyDefault = styled(Flex)`
    overflow: auto;
    border: none;
    color: ${(props) => props.theme.colorTextButton};
    min-height: ${(props) => (props.minHeight ? props.minHeight : "50vh")};
    min-width: 100%;

    img {
      width: 60px;
      height: 60px;
      filter: ${(props) => props.theme.colorToggleDisableFilter};
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
    }
  `;

  return (
    <StyledEmptyDefault
      minHeight={minHeight}
      vertical
      align="center"
      justify="center"
      gap="8px"
    >
      <img src={icon} alt="iconEmptyDefault" />
      <p>{label}</p>
    </StyledEmptyDefault>
  );
};

export default EmptyDefault;
