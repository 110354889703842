/* eslint-disable no-unused-vars */
import { Flex } from "antd";
import { ReactComponent as IconAddGray } from "../../../assets/icons/icon-add-gray.svg";
import { ReactComponent as IconRemoveGray } from "../../../assets/icons/icon-remove-gray.svg";
import { StyledButtonCardNewsArchive } from "../styleNewsArchive";

const ButtonCardNewsArchive = ({
  allDatas,
  data,
  type = "ADD",
  setAllDatas,
  setAddDatas,
}) => {
  const handleAddNews = () => {
    if (type === "ADD") {
      setAddDatas((prev) => [data, ...prev]);
      setAllDatas((prev) =>
        prev.filter((e) => {
          return e.id !== data.id;
        }),
      );
    }
  };

  const handleDeleteNews = () => {
    if (type === "DEL") {
      setAddDatas((prev) =>
        prev.filter((e) => {
          return e.id !== data.id;
        }),
      );
      setAllDatas((prev) => {
        const updatedData = [data, ...prev];
        updatedData.sort((a, b) => {
          const ADate = new Date(a.created_date);
          const BDate = new Date(b.created_date);

          return ADate - BDate;
        });
        return updatedData;
      });
    }
  };

  const handleClick = () => {
    if (type === "ADD") handleAddNews();
    if (type === "DEL") handleDeleteNews();
  };

  return (
    <StyledButtonCardNewsArchive type={type} onClick={() => handleClick()}>
      <Flex align="center" gap="8px">
        {type === "ADD" ? <IconAddGray /> : <IconRemoveGray />}
        <p>{type === "ADD" ? "เพิ่มข่าว" : "ลบข่าว"}</p>
      </Flex>
    </StyledButtonCardNewsArchive>
  );
};

export default ButtonCardNewsArchive;
