/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Flex, Table, Tabs } from "antd";
import "dayjs/locale/en";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { myPostStatus } from "../../../../../common/utils/keyDatas";
import EmptyDefault from "../../../../../common/components/EmptyDefault";
import FilterData from "../../../../../common/components/FilterData";
// import Tabs from "../../../../../common/components/Tabs";
import GoBackButton from "../../../../../common/components/goBackButton";
import MainLayout from "../../../../../common/layouts/main";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
} from "../../../../../common/utils/filterDatas";
import { PaginationQuery } from "../../../../../common/utils/queryStr";
import CardDetailEngagement from "../CardDetailEngagement";
import CardEngagementShareAndLikeAndQuote from "../CardEngagementShareAndLikeAndQuote";
import CardStatus from "../CardStatus";
import { setFiledEngagement } from "../engagementSlice";
import useFetchData from "./useFetchData";
import CardEngagementOwnerDetail from "./CardEngagementOwnerDetail";
import TableDetailEngage from "./TableDetailEngage";

const StyledTableDetailByIdEngagement = styled(Flex)`
  /* overflow-y: scroll;
  height: calc(100vh - 80px);
  background: ${(props) => props.theme.bgTablePage}; */
  padding: 1rem;
  :where(.css-dev-only-do-not-override-14yae4w).ant-tabs-top
    > .ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-14yae4w).ant-tabs-bottom
    > .ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-14yae4w).ant-tabs-top
    > div
    > .ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-14yae4w).ant-tabs-bottom
    > div
    > .ant-tabs-nav::before {
    border-bottom: 0;
  }
`;

const StyledTableContainer = styled(Table)`
  /* margin-top: 8px; */
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.bgTablePage};
`;

const TableDetailByIdEngagement = () => {
  const dispatch = useDispatch();
  const {
    dataCardEngagement,
    dataDetailEngagement,
    data,
    total,
    targets,
    actual,
    selectTabIndex = "1",
    setSelectTabIndex,
    handleOpenWindow,
    currentPage,
    setCurrentPage,
    limit,
    queryFilterRedux,
    tabActive,
    setTabActive,
    onChangeTabs,
    detail,
    setDetail,
    getSubMissionDetail,
  } = useFetchData();
  const { mission = "" } = Object(dataCardEngagement);

  // const {
  //   approve = 0,
  //   pending = 0,
  //   complete = 0,
  //   targets = 0,
  // } = dataDetailEngagement;

  const statusCheck = {
    active: "Running",
    cancel: "Cancelled",
    complete: "Complete",
  };

  const dataMission = (key) => {
    if (mission === "reply") {
      return {
        missionStatus: detail?.mission_status || "",
        approve: detail?.approve || 0,
        targets: detail?.targets || 0,
        pending: detail?.pending || 0,
        complete: detail?.complete || 0,
      };
    }
    return {
      missionStatus: dataDetailEngagement?.mission_status || "",
      approve: dataDetailEngagement?.approve || 0,
      targets: dataDetailEngagement?.targets || 0,
      pending: dataDetailEngagement?.pending || 0,
      complete: dataDetailEngagement?.complete || 0,
    };
  };

  const CardMission = () => {
    const {
      groupSocial = "",
      href = "",
      content = "",
      startDate = "",
      endDate = "",
      createDate = "",
      link = "",
      campaign = "",
      status = "",
      missionStatus = "",
    } = {
      groupSocial: dataCardEngagement?.platforms || "",
      content: dataCardEngagement?.news_title || "",
      createDate: dataCardEngagement?.created_date || "",
      startDate: dataCardEngagement?.start_date || "",
      endDate: dataCardEngagement?.end_date || "",
      href: dataCardEngagement?.url || "",
      link: dataCardEngagement?.url || "",
      status: statusCheck[dataCardEngagement?.status] || "",
      campaign: "",
      ...dataMission(),
    };

    const {
      complete,
      approve,
      targets: missionTargets,
      mission_canceled_date,
      mission_completed_date,
    } = mission === "reply" ? Object(detail) : Object(dataDetailEngagement);

    return (
      <CardDetailEngagement
        // groupSocial={groupSocial}
        type={groupSocial}
        href={href}
        content={content}
        startDate={startDate}
        endDate={endDate}
        createDate={createDate}
        link={link}
        campaign={campaign}
        status={missionStatus}
        typeStatus={myPostStatus}
        copywriterCount={{
          actual: complete + approve,
          target: mission === "reply" ? missionTargets : 0,
        }}
        influencerCount={{
          actual: complete,
          target: missionTargets,
        }}
        cancelDate={mission_canceled_date}
        completeDate={mission_completed_date}
      />
    );
  };

  const dataSourceTableEngagement = [
    ...[...data].map((item, index) => {
      return {
        apiData: {
          ...item,
        },
        dateTime: item.created_date, // "01/31/24",
        groupSocial: Array(item?.platform), // "facebook",
        socialButtonType: item.type, // "Like",
        profileName: item.poster_name, // "profile Name",
        profileRole: item.writer_poster_name ? "influencer" : "copywriter",
        status: item.status, // "Done",
      };
    }),
  ];

  /* Mock */
  const dataSourceTable = [
    [
      ...[...data].map((item, index) => {
        return {
          apiData: {
            ...dataDetailEngagement,
            ...item,
          },
          submissionDate: item.created_date, // item.created_date,
          groupSocial: Array(item?.platform), // "facebook",
          textTitle: detail.news_title, // "textTitle",
          textPreview: item.content_text, // "textPreview",
          socialButtonType: item.type, // "Reply",
          status: item.status, // "Done",
          profileName: item.writer_name, // "profile Name",
          profileRole: item.writer_poster_name ? "influencer" : "copywriter",
        };
      }),
    ],
    [
      ...[...data].map((item, index) => {
        return {
          apiData: {
            ...item,
          },
          dateTime: item.created_date, // "01/31/24",
          groupSocial: Array(item?.platform), // "facebook",
          socialButtonType: item.type, // "Like",
          profileName: item.poster_name, // "profile Name",
          profileRole: item.writer_poster_name ? "influencer" : "copywriter",
          status: item.status, // "Done",
        };
      }),
    ],
    [
      ...[...data].map((item, index) => {
        return {
          apiData: {
            ...item,
          },
          dateTime: item.created_date, // "01/31/24",
          groupSocial: Array(item?.platform), // "facebook",
          socialButtonType: item.type, // "Like",
          profileName: item.poster_name, // "profile Name",
          profileRole: item.writer_poster_name ? "influencer" : "copywriter",
          status: item.status, // "Done",
        };
      }),
    ],
    [
      ...[...data].map((item, index) => {
        return {
          apiData: {
            ...item,
          },
          dateTime: item.created_date, // "01/31/24",
          groupSocial: Array(item?.platform), // "facebook",
          socialButtonType: item.type, // "Like",
          profileName: item.poster_name, // "profile Name",
          profileRole: item.writer_poster_name ? "influencer" : "copywriter",
          status: item.status, // "Done",
        };
      }),
    ],
  ];

  const columnsTable = [
    [
      // Table Index1 : Reply
      {
        align: "left",
        width: "450px",
        title: "List",
        dataIndex: "list",
        key: "list",
        render: (_, items) => {
          // return null;
          return (
            <CardEngagementOwnerDetail
              data={{
                ...items,
                writerPosterName: items.apiData.writer_poster_name,
                handleOpenWindow: (id) => handleOpenWindow(id),
              }}
            />
          );
        },
      },
      {
        align: "center",
        width: "130px",
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, items) => {
          // return null;
          return (
            <CardStatus
              type="cardEngagementReplycardEngagementReplyOwner"
              status={items.status}
              handleOpenWindow={handleOpenWindow}
            />
          );
        },
      },
    ],
    [
      // Table Index2 : Like
      {
        align: "left",
        width: "450px",
        title: "List",
        dataIndex: "list",
        key: "list",
        render: (_, items) => {
          // return null;
          return (
            <CardEngagementShareAndLikeAndQuote
              data={{
                ...items,
              }}
            />
          );
        },
      },
      {
        align: "center",
        width: "130px",
        title: "Status",
        dataIndex: "status",
        key: "status",
        filterMultiple: true,
        render: (_, items) => {
          // return null;
          return (
            <CardStatus type="cardEngagementReplyOwner" status={items.status} />
          );
        },
      },
    ],
    [
      // Table Index3 : Share
      {
        align: "left",
        width: "450px",
        title: "List",
        dataIndex: "list",
        key: "list",
        render: (_, items) => {
          // return null;
          return (
            <CardEngagementShareAndLikeAndQuote
              data={{
                ...items,
              }}
            />
          );
        },
      },
      {
        align: "center",
        width: "130px",
        title: "Status",
        dataIndex: "status",
        key: "status",
        filterMultiple: true,
        render: (_, items) => {
          // return null;
          return (
            <CardStatus type="cardEngagementReplyOwner" status={items.status} />
          );
        },
      },
    ],
    [
      // Table Index4 : Quote
      {
        align: "left",
        width: "450px",
        title: "List",
        dataIndex: "list",
        key: "list",
        render: (_, items) => {
          // return null;
          return (
            <CardEngagementShareAndLikeAndQuote
              data={{
                ...items,
              }}
            />
          );
        },
      },
      {
        align: "center",
        width: "130px",
        title: "Status",
        dataIndex: "status",
        key: "status",
        filterMultiple: true,
        render: (_, items) => {
          // return null;
          return (
            <CardStatus type="cardEngagementReplyOwner" status={items.status} />
          );
        },
      },
    ],
  ];

  /* Mock */

  const onChangePagination = (page) => {
    setCurrentPage(page);
    dispatch(
      setFiledEngagement({
        key: "engagementPaginationQuery",
        value: PaginationQuery(page, limit),
      }),
    );
  };

  const onChangeTab = (e) => {
    setSelectTabIndex(String(e));
  };

  const tabsRole = [
    {
      key: "1",
      label: "Copywriter Submissions",
      children: (
        <>
          {tabActive === "1" && (
            <FilterData
              title=""
              labelSocial="Social Media :"
              labelDetail={`Total ${total} Items`}
              labelSort="Sorting :"
              labelFilter="Show :"
              disabledButton={false}
              filter={{
                optionSortList: optionSortDefaultList,
                optionSortSelect: optionSortDefaultSelect,
                typeShow: "myPostsDetailWriter",
                typeCount: {
                  submitted: `(${detail?.pending?.toLocaleString()})`,
                  approved: `(${(detail?.approve + detail?.complete)?.toLocaleString()}/${detail?.targets?.toLocaleString()})`,
                },
              }}
            />
          )}

          <TableDetailEngage
            detail={detail}
            setDetail={setDetail}
            getSubMissionDetail={getSubMissionDetail}
            currentPage={currentPage}
            limit={limit}
            setCurrentPage={setCurrentPage}
            newsTitle={detail?.news_title}
            missionStatus={detail?.mission_status}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "Influencer Submissions",
      children: (
        <>
          {tabActive === "2" && (
            <FilterData
              title=""
              labelSocial="Social Media :"
              labelDetail={`Total ${total} Items`}
              labelSort="Sorting :"
              labelFilter="Show :"
              disabledButton={false}
              filter={{
                optionSortList: optionSortDefaultList,
                optionSortSelect: optionSortDefaultSelect,
                typeShow: "myPostsDetailInfluencer",
                typeRole: "missionInfluencer",
                typeCount: {
                  running: `(${detail?.approve?.toLocaleString()})`,
                  done: `(${detail?.complete?.toLocaleString()}/${detail?.targets?.toLocaleString()})`,
                },
              }}
            />
          )}

          <TableDetailEngage
            detail={detail}
            setDetail={setDetail}
            getSubMissionDetail={getSubMissionDetail}
            currentPage={currentPage}
            limit={limit}
            setCurrentPage={setCurrentPage}
            newsTitle={detail?.news_title}
            missionStatus={detail?.mission_status}
            roleUser="influencer"
          />
        </>
      ),
    },
  ];

  const columnEngagement = [
    {
      align: "left",
      width: "450px",
      title: "List",
      dataIndex: "list",
      key: "list",
      render: (_, items) => {
        // return null;
        return (
          <CardEngagementShareAndLikeAndQuote
            data={{
              dateTime: items.created_date,
              socialType: items.platform,
              socialButtonType: items.type,
              profileName: items.user_name,
              profileRole: items.role_name.includes("influencer")
                ? "influencer"
                : "copywriter",
              status: items.status,
              id: items.id,
            }}
          />
        );
      },
    },
    {
      align: "center",
      width: "130px",
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterMultiple: true,
      render: (_, items) => {
        // return null;
        return (
          <CardStatus type="cardEngagementReplyOwner" status={items.status} />
        );
      },
    },
  ];

  const tableContent = () => {
    if (dataCardEngagement?.mission === "reply") {
      return (
        <Tabs
          defaultActiveKey="1"
          items={tabsRole}
          onChange={onChangeTabs}
          activeKey={tabActive}
        />
      );
    }
    if (
      dataCardEngagement?.mission === "like" ||
      dataCardEngagement?.mission === "share" ||
      dataCardEngagement?.mission === "quote" ||
      dataCardEngagement?.mission === "share_quote"
    ) {
      return (
        <>
          <FilterData
            title=""
            labelSocial="Social Media :"
            labelDetail={`Total ${total} Items`}
            labelSort="Sorting :"
            labelFilter="Show :"
            disabledButton={false}
            filter={{
              optionSortList: optionSortDefaultList,
              optionSortSelect: optionSortDefaultSelect,
              typeShow: "myEngageDetail",
              typeRole: "missionInfluencer",
              typeCount: {
                running: `(${dataDetailEngagement?.approve?.toLocaleString()})`,
                done: `(${dataDetailEngagement?.complete?.toLocaleString()}/${dataDetailEngagement?.targets?.toLocaleString()})`,
              },
            }}
          />

          <br />

          <StyledTableContainer
            locale={{
              emptyText: <EmptyDefault />,
            }}
            style={{
              height: "max-content",
            }}
            columns={columnEngagement}
            dataSource={dataDetailEngagement?.data}
            pagination={{
              position: ["bottomRight"],
              defaultCurrent: 1,
              current: currentPage,
              pageSize: limit,
              total,
              showLessItems: false,
              showSizeChanger: false,
              showQuickJumper: false,
              hideOnSinglePage: false,
              onChange: (page, pageSize) => onChangePagination(page, pageSize),
            }}
            scroll={{
              x: "max-content",
            }}
          />
        </>
      );
    }
    return null;
  };

  const typeCheck = {
    reply: "Reply",
    share: "Share",
    quote: "Quote",
    share_quote: "Quote",
    like: "Like",
    engage: "Engage",
  };

  return (
    <MainLayout>
      <StyledTableDetailByIdEngagement vertical>
        {/* Header */}
        <Flex vertical gap="8px">
          <GoBackButton />
          <Flex vertical gap="8px">
            <h1
              style={{
                fontSize: "18px",
                minHeight: "18px",
                minWidth: "fit-content",
              }}
            >
              Engagements{" "}
              {dataCardEngagement?.mission &&
                `(${typeCheck[dataCardEngagement?.mission] || ""})`}
            </h1>

            <CardMission />
          </Flex>
        </Flex>

        {/* Filter */}
        {/* <FilterData
          labelSocial=""
          labelDetail={`${`${Math.max(0, actual)}/${Math.max(
            0,
            total,
          )}`} Items`}
          labelSort="Sorting :"
          labelFilter="Show :"
          filter={{
            optionSortList: optionSortDefaultList,
            optionSortSelect: optionSortDefaultSelect,
            typeShow:
              String(selectTabIndex) === "1"
                ? "engagementsDetailOwner"
                : "like_share_quoteEngagementsDetailOwner",
          }}
        /> */}

        {/* Filter */}

        {/* Header */}
        {/* <Tabs
          tabActive={selectTabIndex}
          tabList={[...tapOwnerEngagement].map((item) => {
            return {
              ...item,
              children: (
                <StyledTableContainer
                  locale={{
                    emptyText: <EmptyDefault />,
                  }}
                  style={{
                    height: "max-content",
                  }}
                  columns={columnsTable[selectTabIndex - 1]}
                  dataSource={dataSourceTable[selectTabIndex - 1]}
                  pagination={{
                    position: ["bottomRight"],
                    defaultCurrent: 1,
                    current: currentPage,
                    pageSize: limit,
                    total,
                    showLessItems: false,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    hideOnSinglePage: false,
                    // showPrevNextJumpers: false,
                    onChange: (page, pageSize) =>
                      onChangePagination(page, pageSize),
                  }}
                  // bordered
                  scroll={{
                    x: "max-content",
                    // y: "35.5vh",
                  }}
                  // showHeader={false}
                />
              ),
            };
          })}
          handleClickTabs={onChangeTab}
        /> */}

        {tableContent()}
      </StyledTableDetailByIdEngagement>
    </MainLayout>
  );
};

export default TableDetailByIdEngagement;
