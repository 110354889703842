/* eslint-disable import/no-extraneous-dependencies */
import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
const host = process.env.REACT_APP_API_ENDPOINT;

const useProviderAuth = () => {
  const navigate = useNavigate();
  const accessToken = Cookies.get("token");
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState({});

  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = Cookies.get("token") || null;
      if (token != null) config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  const getUserInfo = async () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${host}/users/info`;
        const res = await axiosInstance.get(url);
        if (res?.data) setUser(res?.data);
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (Cookies.get("token")) getUserInfo();
  }, []);

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken]);

  const signin = (tokens, callback = null) => {
    const { token, userInfo, refresh } = tokens || {};
    Cookies.set("token", token);
    Cookies.set("refresh", refresh);
    Cookies.set("username", userInfo?.username);
    setUser(userInfo);
    callback();
  };

  const signout = () => {
    Cookies.remove("token");
    Cookies.remove("refresh");
    Cookies.remove("username");
    navigate("/login");
  };

  return {
    // userData,
    accessToken,
    signin,
    signout,
    user,
  };
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const ProvideAuth = ({ children }) => {
  const auth = useProviderAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
