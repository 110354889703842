/* eslint-disable no-useless-catch */
import { useAxios } from "../../../common/hooks/useProviderAxios";

// eslint-disable-next-line no-unused-vars
const host = process.env.REACT_APP_API_ENDPOINT;

const socialMedaiReviewProvider = () => {
  const { axios } = useAxios();

  const getListSocialMediaReview = async ({ filter }) => {
    try {
      const url = `${host}/social-accounts/status/request?${filter}&sort_by=requested_date`;
      const response = await axios.get(url);
      return response?.data;
    } catch (error) {
      return error;
    }
  };

  const getSocialMediaRequestDetail = async ({ requestId }) => {
    try {
      const url = `${host}/social-accounts/${requestId}`;
      const response = await axios.get(url);
      return response?.data;
    } catch (error) {
      return error;
    }
  };

  const updateStatusSocialAccount = async (param) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { requestId, action, body } = param || {};
      const url = `${host}/social-accounts/${requestId}/${action}`;
      await axios.patch(url, body);
      return true;
    } catch (error) {
      throw error;
    }
  };

  const updateSocialAccount = async (param) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { requestId, body } = param || {};
      const url = `${host}/social-accounts/${requestId}`;
      await axios.patch(url, body);
      return true;
    } catch (error) {
      return error;
    }
  };

  return {
    getListSocialMediaReview,
    getSocialMediaRequestDetail,
    updateStatusSocialAccount,
    updateSocialAccount,
  };
};

export default socialMedaiReviewProvider;
