import React, { useEffect } from "react";
import styled from "styled-components";
// import { useSelector } from "react-redux";
import { Spin } from "antd";
import { useAxios } from "../../hooks/useProviderAxios";

const StyleLoading = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: default;

  .background-loading {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: #1677ff;
    box-shadow: 0px 0px 30px 0px rgba(181, 174, 174, 1);
    -webkit-box-shadow: 0px 0px 30px 0px rgba(181, 174, 174, 1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(181, 174, 174, 1);
  }
`;
const Loading = () => {
  const { isLoading } = useAxios();
  //   const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: AnimationLoading,
  //   };

  useEffect(() => {
    if (document.getElementById("content")) {
      if (isLoading) {
        document.getElementById("content").style.overflowY = "hidden";
      } else {
        document.getElementById("content").style.overflowY = "auto";
      }
    }
  }, [isLoading]);

  if (!isLoading) {
    return null;
  }

  return (
    <StyleLoading>
      <div className="background-loading">
        <Spin tip="Loading" size="large" />
        <span>Loading...</span>
      </div>
    </StyleLoading>
  );
};

export default Loading;
