import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenInsertModal: false,
};

export const insertTrend = createSlice({
  name: "insertTrend",
  initialState,
  reducers: {
    setIsOpenInsertModal: (state, action) => {
      state.isOpenInsertModal = action.payload.value;
    },
  },
});

export const { setIsOpenInsertModal } = insertTrend.actions;

export default insertTrend.reducer;
