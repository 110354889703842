import React, { useEffect } from "react";
import { Chart } from "@antv/g2";
import { styled } from "styled-components";
import { Empty } from "antd";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const Barchart = (props) => {
  const { data, idChart } = props;

  useEffect(() => {
    if (data.length > 0) {
      const chart = new Chart({
        container: `bar-precent-chart-${idChart}`,
        height: 228,
        // width: 568,
        paddingLeft: 100,
        autoFit: true,
      });

      chart.coordinate({
        transform: [
          {
            type: "transpose",
          },
        ],
      });

      chart
        .interval()
        .data(data)
        .transform({
          type: "stackY",
        })
        .transform({
          type: "sortX",
          by: "y",
          reverse: true,
        })
        .transform({
          type: "normalizeY",
        })
        // .axis("x", false)
        .axis("y", false)
        .encode("x", (d) => `${d.trendName}`)
        .axis({
          x: {
            labelFormatter: (datum) => datum,
            zIndex: 1,
            labelFontSize: 14,
            labelOpacity: 1,
            labelFill: "#444450",
            labelTextAlign: "start",
            labelFontFamily: "Sarabun",
            labelTransform: "translate(-105, 0)",
          },
          y: false,
        })
        .encode("y", "trendCount")
        .encode("color", "platform")
        .scale("color", {
          relations: [
            ["twitter", "#1BBBFF"],
            ["facebook", "#3D7DE5"],
            ["tiktok", "#49474C"],
            ["internet", "#FF2643"],
            ["pantip", "#7F36DB"],
          ],
        })
        .style("maxWidth", 25)
        .legend(false)
        .tooltip({
          title: (d) => d.platform,
        })
        .tooltip({
          channel: "y0",
          valueFormatter: ".0%",
        })
        .legend({
          color: {
            position: "bottom",
            itemMarker: "circle",
            layout: {
              justifyContent: "center",
            },
          },
        });

      chart.render();
    }
  }, [data]);

  return (
    <div>
      <Title>Tracking</Title>
      {data.length > 0 ? (
        <div id={`bar-precent-chart-${idChart}`} />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default Barchart;
