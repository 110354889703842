/* eslint-disable no-nested-ternary */
/* eslint-disable no-const-assign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
import { Button, Checkbox, Flex } from "antd";
import styled from "styled-components";

import useFilterData from "../../hooks/useFilterData";
import IconSocial from "../IconSocial";
import SelectComponent from "../SelectComponent";
import { FirstUpperLabel } from "../../utils/firstLabel";

// {/* <FilterData
//   title="title"
//   labelSocial="Social Media :"
//   labelDetail={`${Math.max(0, 10)} Items`}
//   labelSort="Sorting :"
//   labelFilter="Show :"
//   labelButton="New"
//   onClickButton={() => console.log("onClickButton")}
//   disabledButton={false}
//   filter={{
//     otherSort: {
//       // All not send
//       pending: {
//         type: "status",
//       },
//     },
//     social: socialDefault,
//     optionSortList: optionSortDefaultList,
//     optionSortSelect: optionSortDefaultSelect,
//     typeShow: "status",
//   }}
// />; */}

const StyleButton = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 120px;
  background: ${(props) =>
    props.type === "primary"
      ? props.theme.colorButtonBoost
      : props.type === "confirm"
        ? props.theme.colorButtonBoost
        : props.theme.colorButtonBoost};
  font-size: 14px;
  font-weight: 700;
  color: ${(props) =>
    props.type === "primary" || props.type === "confirm"
      ? props.theme.white
      : props.theme.textInput};

  &:hover {
    background: ${(props) =>
      props.type === "primary"
        ? props.theme.hoverPrimaryBtn
        : props.type === "confirm"
          ? props.theme.hoverConfirmBtn
          : props.theme.hoverPrimaryBtn};
  }
  &:disabled {
    pointer-events: none;
    cursor: none;
  }
`;

const StyleFilterData = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
  /* min-height: 90px; */
  h1 {
    font-size: 24px;
    font-weight: 700;
    color: ${(props) => props.theme.colorTextButton};
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.colorTextButton};
  }

  h3 {
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.colorTextButton};
  }

  .ant-checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const VerticalLine = styled.span`
  border-left: ${(props) => `2px solid ${props.theme.actionBorder}`};
`;

const FilterData = ({
  title = "",
  labelSocial = "",
  labelDetail = "",
  labelFilter = "",
  labelSort = "",
  labelButton = "",
  disabledButton = false,
  onClickButton = () => null,
  callBackQueryFilter = () => null,
  filter = {},
  children = null,
  typeBtn = "",
}) => {
  const {
    social,
    optionSortList,
    optionSortSelect,
    typeShow,
    otherSort,
    typeCount,
    typeRole = "",
  } = filter;
  const {
    socialHook,
    typeHook,
    optionSortListHook,
    optionSortSelectHook,
    onSetIsCheckHook,
    onChangeSortHook,
    callBackQueryFilterHook,
  } = useFilterData({
    social,
    optionSortList,
    optionSortSelect,
    typeShow,
    // callBackQueryFilter,
    otherSort,
    typeRole,
  });
  const typeHookArr = Object.keys(typeHook);

  const onChange = (e, caseType, status) => {
    const { name, checked } = e.target;

    if (caseType === "type") {
      let targetCount = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in typeHook) {
        if (typeHook.hasOwnProperty(key)) {
          if (typeHook[key].type === status && typeHook[key].isCheck) {
            targetCount++;
          }
        }
      }
      if (targetCount === 1 && !checked) return;
    }
    onSetIsCheckHook(name, checked, caseType);
  };

  const onChangeSort = (value) => {
    onChangeSortHook(value);
  };

  return (
    <StyleFilterData vertical>
      {/* Header */}
      <Flex align="center" justify="space-between" gap="10vw">
        {title?.length > 0 ? (
          <h1
            style={{
              fontSize: "18px",
              minHeight: "32px",
              minWidth: "fit-content",
            }}
          >
            {title}
          </h1>
        ) : (
          <Flex> </Flex>
        )}

        {/* Fixed Social */}
        {Object.keys(socialHook)?.length > 0 && (
          <Flex
            align="center"
            justify="center"
            gap="8px"
            style={{ minHeight: "32px" }}
          >
            <h3>{labelSocial ?? labelSocial}</h3>
            {Object.keys(socialHook).map((item, index) => {
              return (
                <Checkbox
                  disabled={socialHook[item]?.isDisable}
                  checked={socialHook[item]?.isCheck}
                  name={item}
                  onChange={(e) => onChange(e, "social")}
                >
                  <IconSocial type={item} />
                </Checkbox>
              );
            })}
          </Flex>
        )}
        {/* Fixed Social */}
      </Flex>
      {/* Header */}

      {children}

      <Flex align="center" justify="space-between" gap="10vw">
        {labelDetail?.length > 0 ? (
          <h2
            style={{
              minHeight: "32px",
              minWidth: "fit-content",
              marginTop: "16px",
            }}
          >
            {labelDetail}
          </h2>
        ) : (
          <Flex> </Flex>
        )}

        <Flex align="center" justify="center" gap="16px">
          {/* Fixed Filter */}
          {Object.keys(typeHook)?.length > 0 && (
            <Flex
              align="center"
              justify="center"
              gap="8px"
              style={{ minHeight: "32px", marginTop: "16px" }}
            >
              <p style={{ minWidth: "fit-content" }}>
                {labelFilter ?? labelFilter}
              </p>
              {Object.keys(typeHook).map((item, index) => {
                const label = item === "share_quote" ? "quote" : item;
                return (
                  <div>
                    {/* {typeHook[typeHookArr[index - 1]]?.type !==
                    typeHook[item].type
                      ? "true"
                      : "false"} */}
                    {typeHook[typeHookArr[index - 1]]?.type !==
                      typeHook[item].type && index > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px",
                        }}
                      >
                        <VerticalLine />
                        <Checkbox
                          disabled={typeHook[item]?.isDisable}
                          checked={typeHook[item]?.isCheck}
                          name={item}
                          onChange={(e) =>
                            onChange(e, "type", typeHook[item].type)
                          }
                        >
                          <p>
                            {FirstUpperLabel(label)}{" "}
                            {typeCount && typeCount[item]}
                          </p>
                        </Checkbox>
                      </div>
                    ) : (
                      <Checkbox
                        disabled={typeHook[item]?.isDisable}
                        checked={typeHook[item]?.isCheck}
                        name={item}
                        onChange={(e) =>
                          onChange(e, "type", typeHook[item].type)
                        }
                      >
                        <p>
                          {FirstUpperLabel(label)}{" "}
                          {typeCount && typeCount[item]}
                        </p>
                      </Checkbox>
                    )}
                  </div>
                );
              })}
            </Flex>
          )}
          {/* Fixed Filter */}

          {/* Fixed Sort */}
          {optionSortListHook?.length > 0 && (
            <Flex
              align="center"
              justify="center"
              gap="8px"
              style={{ minHeight: "32px", marginTop: "16px" }}
            >
              <p style={{ minWidth: "fit-content" }}>{labelSort}</p>
              <SelectComponent
                value={optionSortSelectHook}
                onChange={onChangeSort}
                options={optionSortListHook}
                style={{
                  width: "140px",
                }}
              />
            </Flex>
          )}
          {/* Fixed Sort */}

          {/* Button */}
          {labelButton && (
            <Flex
              align="center"
              justify="center"
              gap="8px"
              style={{ minHeight: "32px", marginTop: "16px" }}
            >
              <StyleButton
                type={typeBtn}
                onClick={onClickButton}
                disabled={disabledButton}
              >
                {labelButton}
              </StyleButton>
            </Flex>
          )}
          {/* Button */}
        </Flex>
      </Flex>
    </StyleFilterData>
  );
};

export default FilterData;
