import React from "react";
import styled from "styled-components";
import MainLayout from "../common/layouts/main";
import FeatureJobEngagement from "../features/jobEngagement";

const StyleJobEngagement = styled.div`
  padding: 16px;
`;

const JobEngagement = () => {
  return (
    <MainLayout>
      <StyleJobEngagement>
        <FeatureJobEngagement />
      </StyleJobEngagement>
    </MainLayout>
  );
};

export default JobEngagement;
