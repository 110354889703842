import React from "react";
import { Card, Flex } from "antd";
import styled from "styled-components";
import CircleProgressComponent from "../CicleProgressComponent";
import IconPin from "../../../../../assets/icons/icon-pin.svg";
// import IconUnPin from "../../../../assets/icons/icon-unpin.svg";

const StyleCard = styled(Card)`
  background-color: ${(props) => props.theme.backgroundFilter};
`;

const StyleCardDescription = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  color: ${(props) => props.theme.colorTextDesc};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

const CardSentiment = ({
  platform,
  pinned,
  positiveValue,
  negativeValue,
  title,
}) => (
  <StyleCard>
    <Flex horizontal gap={16} justify="space-between">
      <Flex horizontal gap={16} align="center">
        <CircleProgressComponent
          platform={platform}
          positiveValue={positiveValue}
          negativeValue={negativeValue}
        />
        <StyleCardDescription>{title}</StyleCardDescription>
      </Flex>
      <Flex justify="flex-end" align="start">
        {pinned && (
          <img
            style={{
              alignSelf: "flex-start",
            }}
            src={IconPin}
            alt="pin-icon"
          />
        )}
      </Flex>
    </Flex>
  </StyleCard>
);
export default CardSentiment;
