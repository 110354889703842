import styled from "styled-components";
import { Flex } from "antd";
import { cardStatus } from "../../../../../common/utils/filterDatas";

/* 

<CardStatus
  type=""
  status=""
/>

*/

const StyledCardStatus = styled(Flex)`
  color: ${(props) => props.theme[props.color]};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  width: 100%;
`;

const CardStatus = ({ type = "Running", status = "colorRunning" }) => {
  const { label = "", color = "" } = cardStatus[type][status] || {
    label: "",
    color: "",
  };
  return (
    <StyledCardStatus justify="center" color={color}>
      {label}
    </StyledCardStatus>
  );
};

export default CardStatus;
