/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import styled, { css } from "styled-components";
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { Button, Flex, Form, Radio, Modal } from "antd";
import { useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { IconWarning } from "../../missions/MyPostsList";
import Audience from "./audience";
import GroupRadioSocial from "../../../common/components/GroupRadioSocial";
import ModalConfirm from "../../../common/components/ModalConfirm";
import { useAuth } from "../../../common/hooks/useProviderAuth";
import socialMedaiReviewProvider from "../provider/socialMedaiReviewProvider";
import IconRankInfluEndUser from "../../../assets/icons/icon-rank-influencer-endUser.svg";
import IconRankInfluNano from "../../../assets/icons/icon-rank-influencer-nano.svg";
import IconRankInfluMicro from "../../../assets/icons/icon-rank-influencer-micro.svg";
import IconRankInfluMidTier from "../../../assets/icons/icon-rank-influencer-midTier.svg";
import IconRankInfluMacro from "../../../assets/icons/icon-rank-influencer-macro.svg";
import IconRankInfluMega from "../../../assets/icons/icon-rank-influencer-mega.svg";

const StyledFormSubmitSocialMedia = styled(Flex)`
  height: calc(100dvh - 50px);
  overflow-y: scroll;
  padding-bottom: 60px;
`;
const BoldLabel = styled.span`
  font-weight: bold;
`;
const StyleContainer = styled.div`
  padding: 20px;
`;

const StyledMainTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: solid whitesmoke 1px;
`;

const StyleFooter = styled.div`
  border-top: solid 1px ${(props) => props?.theme?.actionBorder};
  padding: 20px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: white;

  .ant-form-item {
    margin-bottom: 0px;
  }
`;

const StyleTitleLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;

  .title {
    font-size: 16px;
  }
  .description {
    font-size: 14px;
    color: #bebebe;
  }
`;

const StyleFormItem1 = styled(Form.Item)`
  .ant-form-item-control-input-content {
    justify-content: center;
    display: flex;
  }
`;

const StyleButtonFooter = styled(Button)`
  width: 180px;
  height: 100%;
  padding: 6px 16px;
  font-size: 16px;
  font-weight: 700;
`;

const StyledTextArea = styled(TextArea)`
  &:hover {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
  &:focus {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
`;
// Styled components for Tier of Influencer
const TierContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  height: fit-content;
  overflow-x: auto;
`;

const TierItem = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  padding: 5px;
  border-radius: 4px;
  background: ${(props) =>
    props.active
      ? "linear-gradient(112.27deg, #D196FF 2.36%, #7D5DE8 82.93%)"
      : "#f0f0f0"};
  color: ${(props) => (props.active ? "white" : "rgba(42, 44, 45, 0.25)")};
  cursor: not-allowed;
  ${(props) =>
    props.active &&
    css`
      &:hover {
        background-color: #7261c4;
      }
    `}
  span {
    font-weight: ${(props) => (props.active ? "bold" : "normal")};
  }

  .tier-label {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
  }

  .tier-range {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => (props.active ? "white" : "rgba(42, 44, 45, 0.25)")};
  }

  .icon {
    width: 34px;
    height: 34px;
    margin-right: 8px;
  }

  .tier-range-small {
    font-size: 10px;
  }
`;

const tiers = [
  {
    label: "End User",
    range: "(Below 1K)",
    value: "endUser",
    min: 0,
    max: 1000,
    icon: IconRankInfluEndUser,
  },
  {
    label: "Nano",
    range: "(1K-10K)",
    value: "nanoUser",
    min: 1001,
    max: 10000,
    icon: IconRankInfluNano,
  },
  {
    label: "Micro",
    range: "(10K-50K)",
    value: "microUser",
    min: 10001,
    max: 50000,
    icon: IconRankInfluMicro,
  },
  {
    label: "Mid-Tier",
    range: "(50K-100K)",
    value: "midTierUser",
    min: 50001,
    max: 100000,
    icon: IconRankInfluMidTier,
  },
  {
    label: "Macro",
    range: "(100K-1M)",
    value: "macroUser",
    min: 100001,
    max: 1000000,
    icon: IconRankInfluMacro,
  },
  {
    label: "Mega",
    range: "(1M+)",
    value: "topStarUser",
    min: 1000001,
    max: Infinity,
    icon: IconRankInfluMega,
  },
];

const TierOfInfluencer = ({ followerCount }) => {
  return (
    <TierContainer>
      {tiers.map((tier) => (
        <TierItem
          key={tier.value}
          active={followerCount >= tier.min && followerCount <= tier.max}
        >
          {followerCount >= tier.min && followerCount <= tier.max && (
            <img src={tier.icon} alt={`${tier.label} icon`} className="icon" />
          )}
          <div>
            <span className="tier-label">{tier.label}</span>
            <span
              className={`tier-range ${
                ["endUser", "midTierUser"].includes(tier.value) &&
                followerCount >= tier.min &&
                followerCount <= tier.max
                  ? "tier-range-small"
                  : ""
              }`}
            >
              {tier.range}
            </span>
          </div>
        </TierItem>
      ))}
    </TierContainer>
  );
};

const FormSubmitSocialMedia = () => {
  const SocialMedaiReviewProvider = socialMedaiReviewProvider();
  // eslint-disable-next-line no-unused-vars
  const { user } = useAuth();
  const { requestId, method } = useParams();
  const [formSubmitSocialMedia] = Form.useForm();
  const socialMedia = Form.useWatch("socialMedia", formSubmitSocialMedia);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
  const [modalAlertType, setModalAlertType] = useState("confirm");
  const [isOpenModalAlert, setIsOpenModalAlert] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const [socialMediaDetail, setSocialMediaDetail] = useState(null);
  const [radioValue, setRadioValue] = useState("web_cookie");
  const [followerCount, setFollowerCount] = useState("follower");
  const [socialMediaDetailSnap, setsocialMediaDetailSnap] = useState(null);

  const fetchGetSocialMediaRequestDetail = async (id) => {
    try {
      const response =
        await SocialMedaiReviewProvider.getSocialMediaRequestDetail({
          requestId: id,
        });
      setsocialMediaDetailSnap(response);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const fetchUpdateSocialMediaAccount = async (formValue) => {
    try {
      const body = {
        platform: formValue?.socialMedia,
        social_name: formValue?.name,
        category_id: formValue?.category,
        audience_gender: formValue?.gender,
        audience_generation: formValue?.gen,
        follower: parseFloat(formValue?.follower.replace(/,/g, "")),
      };

      if (socialMediaDetailSnap.profile_url !== formValue?.profileUrl)
        body.profile_url = formValue?.profileUrl;
      if (socialMediaDetailSnap.profile_id !== formValue?.userId)
        body.profile_id = formValue?.userId;

      await SocialMedaiReviewProvider.updateSocialAccount({
        requestId,
        body,
      });
      window.close();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUpdateStatusSocialMediaAccount = async (formValue) => {
    try {
      const body = {
        platform: formValue?.socialMedia,
        social_name: formValue?.name,
        profile_id: formValue?.userId,
        profile_url: formValue?.profileUrl,
        category_id: formValue?.category,
        audience_gender: formValue?.gender,
        audience_generation: formValue?.gen,
        follower: parseFloat(formValue?.follower.replace(/,/g, "")),
      };

      await SocialMedaiReviewProvider.updateStatusSocialAccount({
        requestId,
        action: formValue?.action,
        body,
      });
      window.close();
    } catch (error) {
      const { code, message } = error;
      if (code === 422 && message === "Invalid social") {
        Modal.error({
          title: "An error has occurred.",
          content: "Please try again.",
          icon: <IconWarning />,
          centered: true,
          okText: "OK",
          onOk: () => window.close(),
          cancelText: "Cancel",
        });
      }
      if (error.code === 422 && error.message === "Profile id already") {
        formSubmitSocialMedia.setFields([
          {
            name: "userId",
            errors: ["Your data is already exists"],
          },
        ]);
      } else if (
        error.code === 422 &&
        error.message === "Profile url already"
      ) {
        formSubmitSocialMedia.setFields([
          {
            name: "profileUrl",
            errors: ["Your data is already exists"],
          },
        ]);
      } else {
        Modal.error({
          title: "An error has occurred.",
          content: "Please try again.",
          icon: <IconWarning />,
          centered: true,
          okText: "OK",
          onOk: () => window.close(),
          cancelText: "Cancel",
        });
      }
    }
  };

  const checkUpdateDataFromUser = async () => {
    const socialMediaDetailNew =
      await fetchGetSocialMediaRequestDetail(requestId);
    if (
      JSON.stringify(socialMediaDetail) === JSON.stringify(socialMediaDetailNew)
    )
      return true;
    return false;
  };

  const initialData = async () => {
    const requestDetail = await fetchGetSocialMediaRequestDetail(requestId);
    if (requestDetail) {
      setSocialMediaDetail(requestDetail);
      formSubmitSocialMedia.setFieldsValue({
        name: requestDetail?.name,
        socialMedia: requestDetail?.platform,
        action: "reject",
        userId: requestDetail?.profile_id,
        profileUrl: requestDetail?.profile_url,
        follower: requestDetail?.follower.toLocaleString(),
      });
      if (method === "add")
        formSubmitSocialMedia.setFieldsValue({
          gen: ["Baby", "X", "Y", "Z", "Alpha"],
          gender: ["male", "female"],
        });
      if (method === "edit") {
        formSubmitSocialMedia.setFieldsValue({
          /* convert API format to Conponent Audience format */
          gen: requestDetail?.audience_generation
            ? requestDetail?.audience_generation.map((item) => {
                return `${item[0].toUpperCase()}${item.substring(1, item.length)}`;
              })
            : [],
          gender: requestDetail?.audience_gender,
          category: requestDetail?.categories.map((item) => item?.category_id),
          follower: requestDetail?.follower.toLocaleString(),
        });
        console.log(formSubmitSocialMedia.getFieldsValue());
      }
      setFollowerCount(requestDetail?.follower);
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  const handleConfirm = async () => {
    const formValue = formSubmitSocialMedia.getFieldsValue();
    setIsModalConfirmOpen(false);

    if (method === "add") {
      const isUserUpdateDataFrom = await checkUpdateDataFromUser();
      if (isUserUpdateDataFrom === true) {
        await fetchUpdateStatusSocialMediaAccount(formValue);
      } else {
        Modal.warning({
          title: "An error has occurred",
          content: "Please try again.",
          icon: <IconWarning />,
          okText: "OK",
          centered: true,
          onOk: () => {
            initialData();
            window.close();
          },
        });
      }
    }

    if (method === "edit") {
      await fetchUpdateSocialMediaAccount(formValue);
    }
  };

  const handleCancel = () => {
    setIsModalConfirmOpen(false);
    window.close();
  };

  const modalConfirmDetails = {
    confirm: {
      title: "Are you sure you want to do this?",
      subTitle: "Would you like to confirm?",
      callbackBtnRight: () => handleConfirm(),
      callbackBtnLeft: () => setIsModalConfirmOpen(false),
    },
    sessionError: {
      title: "Your session is about to expire!",
      subTitle: "",
      callbackBtnRight: () => setIsModalConfirmOpen(!isModalConfirmOpen),
      callbackBtnLeft: () => setIsModalConfirmOpen(!isModalConfirmOpen),
    },
  };

  const onFinish = () => {
    setModalAlertType("confirm");
    setIsModalConfirmOpen(true);
  };

  const validateWhitespace = (_, value) => {
    if (value && String(value).trim() === "") {
      return Promise.reject(new Error("Please enter letters."));
    }
    return Promise.resolve();
  };

  return (
    <StyledFormSubmitSocialMedia vertical>
      <Form
        layout="vertical"
        form={formSubmitSocialMedia}
        name="form-submit-social-media"
        onFinish={onFinish}
      >
        <StyleContainer>
          <Flex vertical gap={10}>
            {/* Titile */}
            <h2>Social media Details</h2>

            {/* Social media */}
            <Form.Item
              colon={false}
              name="socialMedia"
              label={<BoldLabel>Social media</BoldLabel>}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <GroupRadioSocial active={socialMedia} />
            </Form.Item>

            {/* Audience */}
            <Form.Item>
              <Audience
                form={formSubmitSocialMedia}
                initDefaultValue={method === "add"}
              />
            </Form.Item>

            {/* Profile URL */}
            <Form.Item
              name="profileUrl"
              label={
                <StyleTitleLabel>
                  <span
                    className="title"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Profile URL
                  </span>
                  <span className="description" />
                </StyleTitleLabel>
              }
              rules={[
                {
                  required: true,
                  message: "Please input your user profile URL!",
                },
                {
                  validator: validateWhitespace,
                },
              ]}
            >
              <StyledTextArea placeholder="Please fill in" />
            </Form.Item>

            {/* User ID */}
            <Form.Item
              name="userId"
              label={
                <StyleTitleLabel>
                  <span
                    className="title"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Profile ID
                  </span>
                  <span className="description">(Limit 50 Characters)</span>
                </StyleTitleLabel>
              }
              rules={[
                {
                  required: true,
                  message: "Please input your user Id!",
                },
                {
                  validator: validateWhitespace,
                },
              ]}
            >
              <StyledTextArea placeholder="Please fill in" maxLength="50" />
            </Form.Item>

            {/* Name */}
            <Form.Item
              name="name"
              label={
                <StyleTitleLabel>
                  <span
                    className="title"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </span>
                  <span className="description">(Limit 50 Characters)</span>
                </StyleTitleLabel>
              }
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
                {
                  validator: validateWhitespace,
                },
              ]}
            >
              <StyledTextArea placeholder="Please fill in" maxLength="50" />
            </Form.Item>

            <Form.Item
              colon={false}
              labelCol={{
                style: {
                  width: 200,
                },
              }}
              name="tierInfluencer"
              label={
                <div>
                  <span
                    className="title"
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    Tier of Influencer
                  </span>
                </div>
              }
            >
              <Form.Item
                name="follower"
                label={
                  <StyleTitleLabel>
                    <span
                      className="title"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Follower
                    </span>
                    <span className="description">(Limit 10 Characters)</span>
                  </StyleTitleLabel>
                }
                rules={[
                  {
                    validator: validateWhitespace,
                  },
                ]}
              >
                <StyledTextArea
                  id="followerInput"
                  placeholder="Please fill in"
                  maxLength="10"
                  onChange={(e) => setFollowerCount(e.target.value)}
                  onKeyPress={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (!regex.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    const text = e.clipboardData.getData("text/plain");
                    if (text.length <= 10 && /^[0-9]+$/.test(text)) {
                      setFollowerCount(text);
                      setTimeout(() => {
                        e.target.value = text;
                      }, 0);
                    }
                  }}
                />
              </Form.Item>
              <TierOfInfluencer followerCount={followerCount} />
            </Form.Item>

            {/* Footer */}
            <StyleFooter>
              {user?.roles && user?.roles?.includes("admin") && (
                <div
                  style={{
                    marginBottom: "16px",
                  }}
                >
                  {method === "add" && (
                    <StyleFormItem1 name="action">
                      <Radio.Group>
                        <Radio value="reject"> Reject </Radio>
                        <Radio value="approve"> Approve </Radio>
                      </Radio.Group>
                    </StyleFormItem1>
                  )}
                </div>
              )}
              <Form.Item>
                <Flex align="center" justify="center" gap={10}>
                  <StyleButtonFooter
                    type="cancel"
                    htmlType="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </StyleButtonFooter>
                  <StyleButtonFooter type="primary" htmlType="submit">
                    Confirm
                  </StyleButtonFooter>
                </Flex>
              </Form.Item>
            </StyleFooter>
          </Flex>
        </StyleContainer>
      </Form>
      <ModalConfirm
        type="warning"
        title={modalConfirmDetails[modalAlertType]?.title}
        subTitle={modalConfirmDetails[modalAlertType]?.subTitle}
        callbackBtnRight={modalConfirmDetails[modalAlertType]?.callbackBtnRight}
        callbackBtnLeft={modalConfirmDetails[modalAlertType]?.callbackBtnLeft}
        textBtnLeft="Cancel"
        textBtnRight="OK"
        isModalOpen={isModalConfirmOpen}
      />
    </StyledFormSubmitSocialMedia>
  );
};

export default FormSubmitSocialMedia;
