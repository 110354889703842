/* eslint-disable no-unused-vars */
import { Button, Flex, Table, Modal } from "antd";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useAuth } from "../../../common/hooks/useProviderAuth";
import { isRoleSuperAdmin, myNewWindow } from "../../../common/utils/helper";
import { StyledButtonStatus, IconWarning } from "../../missions/MyPostsList";
import CardJobTable from "../../../common/components/CardJobTable";
import SubmissionContentProvider from "../provider/submissionContentProvider";
import IconSocial from "../../../common/components/IconSocial";
import EmptyDefault from "../../../common/components/EmptyDefault";

const StyleTableSubmissionContent = styled.div`
  padding: 10px;

  tbody.ant-table-tbody {
    background-color: ${(props) => props.theme.white};
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset; //Change the existing color to 'unset'
  }
`;

const StyleButtonPick = styled(Button)`
  font-size: 16px;
  font-weight: 700;
  width: 70px;
  height: 35px;
`;

const columns = [
  {
    title: "List",
    dataIndex: "list",
    width: "70px",
  },
  {
    title: "  ",
    dataIndex: "detail",
    // width: "800px",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "120px",
  },
  {
    title: "  ",
    dataIndex: "pick",
    width: "150px",
  },
];

const statusLabel = {
  approve: "Available",
  complete: "Done",
  cancel: "Cancelled",
  reject: "Rejected",
};

const TableSubmissionContent = ({ status = "all", callBack = () => {} }) => {
  const { user } = useAuth();
  const [submissionContents, setSubmissionContents] = useState([]);
  const submissionContentProvider = SubmissionContentProvider();
  const { queryFilterRedux } = useSelector((state) => state.filterData) || {};
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 50;
  const contentIdSelected = useRef(null);
  const newWindow = useRef(null);
  const [modal, contextHolder] = Modal.useModal();
  const [disableSubmissionId, setDisableSubmissionId] = useState(null);

  const closeWindow = () => {
    // eslint-disable-next-line no-use-before-define
    fetchSubmissionContent();
  };

  const modalConfirmDetails = {
    sessionError: {
      title: "Your session is about to expire!",
      subTitle: "",
    },
    noJobAvailable: {
      title: "No job available.",
      subTitle: "",
      // eslint-disable-next-line prettier/prettier, no-use-before-define
      onOk: () => fetchSubmissionContent()
    },
    errorOccurred: {
      title: "An error has occurred.",
      subTitle: "Please try again.",
    },
  };

  const modalError = (param) => {
    const { title, subTitle, onOk = () => closeWindow() } = param;
    modal.info({
      title,
      subTitle,
      icon: <IconWarning />,
      content: "",
      centered: true,
      okText: "OK",
      onOk,
    });
  };

  useEffect(() => {
    setCurrentPage(1);
    return () => {};
  }, [status]);

  /* status: ready, done */
  const fetchSubmissionContent = async () => {
    try {
      const param = {
        limit,
        page: currentPage,
        filter: queryFilterRedux,
      };

      switch (status) {
        case "approve":
          param.status = "active";
          break;
        case "complete":
          param.status = "complete";
          break;
        default:
          break;
      }

      const response = await submissionContentProvider.getMissionJobs(param);

      setSubmissionContents(response?.data);
      setTotal(response?.total);
      callBack({
        total: response?.total,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLockSubmissionContent = async (contentId) => {
    try {
      const res = await submissionContentProvider.lockSubmissionContent({
        id: contentId,
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const fetchUnlockSubmissionContent = async (contentId) => {
    try {
      await submissionContentProvider.unlockSubmissionContent({
        id: contentId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGetMissionContentDetail = async (missionId) => {
    try {
      const response = await submissionContentProvider.getMissionContentDetail({
        missionId,
      });
      return response;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  /* param(Object) : {platform: "facebook"} */
  // eslint-disable-next-line consistent-return
  const fetchGetSocailAccount = async ({ platform, missionId }) => {
    if (platform && missionId) {
      try {
        const res = await submissionContentProvider.getSocailAccount({
          platform,
          missionId,
        });
        if (res) {
          return res?.filter((item) => item?.approved_date !== null);
        }
        return [];
      } catch (err) {
        return [];
      }
    }
  };

  useEffect(() => {
    if (currentPage && queryFilterRedux && status) {
      fetchSubmissionContent();
    }
  }, [currentPage, queryFilterRedux, status]);

  // function ปิด window
  const handleCloseWindow = async () => {
    if (
      newWindow.current?.location &&
      newWindow.current?.location !== "about:blank"
    ) {
      /* ยกเลิกการจอง slot ของ influencer */
      if (contentIdSelected.current) {
        await fetchUnlockSubmissionContent(contentIdSelected.current);
        contentIdSelected.current = null;
        await fetchSubmissionContent();
        setDisableSubmissionId(null);
      }
    }
  };

  // function เปิด window
  // eslint-disable-next-line no-unused-vars
  const handleOpenWindow = async (contentId, pickExpireDate, item) => {
    const isPickExpire = pickExpireDate
      ? dayjs().isAfter(dayjs(pickExpireDate))
      : false;

    if (newWindow.current) newWindow.current.close();
    const windowRef = myNewWindow(`/submit-content/${contentId}`, "");
    windowRef.onunload = handleCloseWindow;
    setTimeout(() => {
      setDisableSubmissionId(contentId);
      if (!pickExpireDate || isPickExpire)
        contentIdSelected.current = contentId;
      else contentIdSelected.current = null;
    }, 2000);

    newWindow.current = windowRef;
  };

  const checkCanPickCardJob = async (pickExpireDate, contentId) => {
    try {
      // console.log(dayjs(pickExpireDate).format("DD/MM/YYYY - HH:mm"));
      // console.log(dayjs().format("DD/MM/YYYY - HH:mm"));
      /* Check user can Pick ในกรณีที่เปิดหน้าจอทิ้งแล้ว แล้วคนอื่น กด Pick ไปก่อนทำให้ status เปลี่ยน */
      const isPickExpire = pickExpireDate
        ? dayjs().isAfter(dayjs(pickExpireDate))
        : true;

      /* จอง slot ไว้กัน influencer คนอื่นรับงาน */
      if (isPickExpire) {
        await fetchLockSubmissionContent(contentId);
      } else {
        return false;
      }

      // await fetchLockSubmissionContent(contentId);

      return true;
    } catch (error) {
      return false;
    }
  };

  const checkJobCardIsAvailable = async (item) => {
    const missionDetail = await fetchGetMissionContentDetail(item?.mission_id);
    const socialAccounts = await fetchGetSocailAccount({
      platform: item?.platforms ? [item.platforms[0]] : "",
      missionId: item?.mission_id,
    });
    if (user?.roles.includes("superadmin")) {
      handleOpenWindow(item?.id, item?.pick_expire_date, item);
      return;
    }

    let isSocialAccountExist = socialAccounts && socialAccounts.length > 0;

    // const isCardCanPick = await checkCanPickCardJob(
    //   item?.pick_expire_date,
    //   item?.id,
    // );

    const isStatusCorrect = !["cancel", "complete"].includes(
      missionDetail.status,
    );
    const isTargetCorrect =
      missionDetail.target > missionDetail?.actual?.influencer;
    const isDateNotExpired = dayjs().isBefore(dayjs(missionDetail?.end_date));

    // console.log({
    //   isSocialAccountExist,
    //   isStatusCorrect,
    //   isTargetCorrect,
    //   isDateNotExpired,
    //   isCardCanPick,
    // });

    if (user?.roles.includes("superadmin")) {
      isSocialAccountExist = true;
    }

    if (
      isSocialAccountExist &&
      isStatusCorrect &&
      isTargetCorrect &&
      isDateNotExpired
      // isCardCanPick
    ) {
      handleOpenWindow(item?.id, item?.pick_expire_date, item);
    } else {
      modalError(modalConfirmDetails.noJobAvailable);
    }
  };

  const editorStatusContent = {
    // ["pending", "complete", "reject", "error", "cancel", "approve"]
    available: {
      label: "Available",
      color: "colorApproved",
    },
    pending: {
      label: "Pending",
      color: "colorWaiting",
    },
    waiting: {
      label: "Pending",
      color: "colorWaiting",
    },
    approve: {
      label: "Approved",
      color: "colorApproved",
    },
    complete: {
      label: "Done",
      color: "colorApproved",
    },
    done: {
      label: "Done",
      color: "colorDone",
    },
    reject: {
      label: "Rejected",
      color: "colorRejected",
    },
    verified: {
      label: "Verified",
      color: "colorVerified",
    },
    error: {
      label: "Error",
      color: "colorRejected",
    },
    cancel: {
      label: "Cancelled",
      color: "colorRejected",
    },
    running: {
      label: "Available",
      color: "colorApproved",
    },
    active: {
      label: "Available",
      color: "colorApproved",
    },
  };

  const provideData = () => {
    return submissionContents
      ? submissionContents.map((item, index) => {
          const dataItem = {
            missionType: item?.target,
            href: item?.news_url,
            startDate: item?.start_date,
            endDate: item?.end_date,
            content: item?.news_url ? item?.news_title : item?.requirement,
            status: item?.status,
            message: item?.content_text,
          };

          const itemStatus =
            item?.status === "approve" ? "active" : item?.status;

          const isPickNotExpired = item?.pick_expire_date
            ? dayjs().isBefore(dayjs(item?.pick_expire_date))
            : false;

          const isCardJobDateExpired = dayjs(item?.end_date).isBefore(dayjs());
          const isCardPicked = !!(
            disableSubmissionId && disableSubmissionId === item?.id
          );

          const isStatusCorrect = [
            "complete",
            "reject",
            "cancel",
            "pending",
          ].includes(item.status);

          // if (index === 0)
          //   console.log({
          //     title: item?.requirement,
          //     isStatusCorrect,
          //     isCardJobDateExpired,
          //     isPickNotExpired,
          //     status: item.status,
          //   });
          // console.log(dayjs(item?.pick_expire_date).format("DD/MM/YY HH:mm"));

          return {
            key: index,
            list: (
              <Flex justify="center">
                <IconSocial
                  type={item?.platforms ? item?.platforms[0] : item?.platform}
                />
              </Flex>
            ),
            detail: <CardJobTable dataItem={dataItem} />,
            status: (
              <StyledButtonStatus
                color={editorStatusContent[itemStatus]?.color}
              >
                {editorStatusContent[itemStatus]?.label}
              </StyledButtonStatus>
            ),
            pick: (
              <StyleButtonPick
                type="primary"
                disabled={
                  isStatusCorrect ||
                  isCardJobDateExpired ||
                  isCardPicked ||
                  isPickNotExpired
                }
                onClick={() => checkJobCardIsAvailable(item)}
              >
                Pick
              </StyleButtonPick>
            ),
          };
        })
      : [];
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <StyleTableSubmissionContent>
      <Table
        // style={{
        //   minHeight: "calc(100vh - 220px)",
        //   height: "max-content",
        // }}
        // scroll={{
        //   x: "max-content",
        //   y: "52.5vh",
        // }}
        // scroll={{
        //   y: "calc(100vh - 360px)",
        //   x: "max-content",
        // }}
        style={{
          // minHeight: "calc(100vh - 280px)",
          height: "max-content",
        }}
        columns={columns}
        dataSource={provideData()}
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: currentPage,
          pageSize: 50,
          current: currentPage,
          total,
          showLessItems: false,
          showSizeChanger: false,
          showQuickJumper: false,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => onChangePagination(page, pageSize),
        }}
        locale={{
          emptyText: <EmptyDefault />,
        }}
      />
      {contextHolder}
    </StyleTableSubmissionContent>
  );
};

export default TableSubmissionContent;
