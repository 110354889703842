/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Chart, ChartEvent } from "@antv/g2";
import { styled } from "styled-components";
import { Empty } from "antd";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const WordCloudDiv = styled.div`
  position: relative;

  .word-chart {
    font-family: Sarabun !important;
  }
`;

const WordCloud = (props) => {
  const { data, idChart } = props;
  const [isHover, setIsHover] = useState({
    data: {},
    status: false,
    position: {
      x: 0,
      y: 0,
    },
  });

  useEffect(() => {
    if (data.length > 0) {
      const chart = new Chart({
        container: `word-chart-${idChart}`,
        height: 228,
        // width: 228,
        autoFit: true,
      });

      chart
        .wordCloud()
        .data(data)
        .encode("text", "name")
        // .style("cursor", "pointer")
        .style("shadowColor", "red")
        .style("fill", (d) => d.wordColor)
        .style("fontSize", (d) => d.weighFont)
        .style("fontFamily", "Noto Sans Thai")
        .layout({
          spiral: "rectangular",
          padding: 0,
          rotate: 0,
          random: 0.4,
        })
        .transform({
          type: "stackEnter",
          groupBy: ["x", "y"],
          orderBy: "color",
          duration: 1500,
        })
        .tooltip(false)
        .legend(false);
      // chart.on("element:click", (e) => {
      //   console.log(e.data.data);
      //   window.open(`https://www.sdfghjk.com/`, "_blank");
      // });

      chart.on(`element:${ChartEvent.POINTER_OVER}`, (ev) => {
        // ev.target.style.fontSize = ev.data.data.weighFont * 1.05;
        ev.target.style.filter = `drop-shadow(1px 1px 1px ${ev.data.data.wordColor})`;
        setIsHover((prev) => ({
          ...prev,
          status: true,
          content: {
            data: ev.data.data.text,
            treandCount: ev.data.data.treandCount,
            color: ev.data.data.wordColor,
          },
          position: {
            x: ev.canvas.x,
            y: ev.canvas.y - 30,
          },
        }));
      });

      chart.on(`element:${ChartEvent.POINTER_OUT}`, (ev) => {
        // ev.target.style.fontSize = ev.data.data.weighFont;
        ev.target.style.filter = "unset";
        setIsHover({
          content: {
            data: "",
            treandCount: 0,
            color: "",
          },
          status: false,
          position: {
            x: 0,
            y: 0,
          },
        });
      });

      chart.render();
    }
  }, [data]);

  return (
    <WordCloudDiv>
      {isHover.status && (
        <div
          style={{
            position: "absolute",
            zIndex: 99,
            fontFamily: "Noto Sans Thai",
            top: isHover.position.y,
            left: isHover.position.x,
            background: "white",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "0 0 5px 0px rgba(0,0,0,.2)",
            color: isHover.content.color,
          }}
        >
          {isHover.content.data} - {isHover.content.treandCount}
        </div>
      )}
      <Title>Top Keywords</Title>
      {data.length > 0 ? (
        <div id={`word-chart-${idChart}`} className="word-chart" />
      ) : (
        <Empty />
      )}
    </WordCloudDiv>
  );
};

export default WordCloud;
