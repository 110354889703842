import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const missionProvider = () => {
  const { axios } = useAxios();

  const getSubMissionContent = async (params) => {
    try {
      const url = `${host}/sub-mission-contents/mission?${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  return {
    getSubMissionContent,
  };
};

export default missionProvider;
