import styled from "styled-components";

const StyleFooter = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background: ${(props) => props.theme.backgroundFilter};

  span {
    color: ${(props) => props.theme.colorTextDesc};
    font-family: "Noto Sans Thai";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;
  }
`;

const Footer = () => {
  return (
    <StyleFooter>
      <span>CopyRight©Sentiment Analysis</span>
    </StyleFooter>
  );
};

export default Footer;
