/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import queryString from "query-string";

const PaginationQuery = (page = 1, limit = 50) => {
  return (
    queryString.stringify({
      page: [page],
      limit: [limit],
    }) || ""
  );
};

export { PaginationQuery };
