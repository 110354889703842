/* eslint-disable no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import styled from "styled-components";
import { Button, Flex } from "antd";
import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import ExcelViewer from "../features/newsArchive/ExcelViewer";
import ModalConfirmComponent from "../common/components/ModalComponent";
import SelectSocialNewsArchive from "../features/newsArchive/SelectSocialNewsArchive";
import ButtonCardNewsArchive from "../features/newsArchive/ButtonCardNewsArchive";
import { StyledButtonSubmitNewsArchive } from "../features/newsArchive/styleNewsArchive";
import Cards from "../features/dashboard/socialCard/Cards";
import FilterSocial from "../features/dashboard/filterPlatform";
import MainLayout from "../common/layouts/main";
import IconNoItem from "../assets/icons/icon-no-item.svg";
import newsArchiveProvider from "../features/newsArchive/provider/newsArchiveProvider";
import { convertDataToSpreadsheetFormat } from "../features/newsArchive/ExcelViewer/convertData";

const StyledPageNewsArchive = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
  background: ${(props) => props.theme.backgroundDashBoard};
  height: calc(100dvh - 80px);

  .spliter {
    display: flex;
    justify-content: space-between;
    padding-top: 11px;
    padding-bottom: 16px;
    height: calc(100dvh - 157px);

    > div {
      width: 49%;
      background: white;
      border-radius: 10px;
      padding: 16px;
      overflow: auto;
    }
  }
`;

const NewsArchive = () => {
  const NewsArchiveProvider = newsArchiveProvider();
  const [allDatas, setAllDatas] = useState([]);
  const [addDatas, setAddDatas] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);
  const [dataSpreadsheet, setDataSpreadsheet] = useState([]);

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, {
        type: "binary",
      });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });
      const titleExcel = [
        "Campaign",
        "Title",
        "Description",
        "Url",
        "Share",
        "Like",
        "Comment",
        "Views",
        "Platform",
        "Date",
      ];
      const newJsonData = jsonData.slice(1);
      newJsonData.unshift(titleExcel);

      setDataExcel(newJsonData);
    };
    setIsOpenModal(true);
    reader.readAsBinaryString(file);
  };

  const apapterData = (rawData) => {
    // ADDLOGIC ADAPTER DATA

    return rawData;
  };

  const getData = async () => {
    const res = await NewsArchiveProvider.getNewsArchive();

    setAllDatas(apapterData(res));
  };

  const handleSubmitNews = () => {
    if (addDatas.length > 0) {
      console.log("SUBMIT with", addDatas);
    }
  };

  const handleCloseModalUploadFile = () => {
    // setDataExcel([]);
    fileInputRef.current.value = "";
    setIsOpenModal(false);
  };

  const handleSubmitModalUploadFile = () => {
    // setDataExcel([]);
    console.log("dataExcel", dataExcel);
    fileInputRef.current.value = "";
    setIsOpenModal(false);
  };

  useEffect(() => {
    getData();
    fileInputRef.current.value = "";
    setIsOpenModal(false);
  }, []);

  useEffect(() => {
    setDataSpreadsheet(convertDataToSpreadsheetFormat(dataExcel));
  }, [dataExcel]);

  return (
    <MainLayout>
      <StyledPageNewsArchive id="news-archive">
        <FilterSocial
          title="คลังข่าว"
          isSelect
          Select={<SelectSocialNewsArchive />}
        />
        <div className="spliter">
          {/* LEFT */}
          <Flex className="left" vertical gap="10px">
            <Flex justify="space-between">
              <h3>All News</h3>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  document.getElementById("fileInput").click();
                }}
              >
                <p>#อัพโหลดเอกสาร</p>
              </Button>
              <input
                ref={fileInputRef}
                id="fileInput"
                type="file"
                style={{
                  display: "none",
                }}
                accept=".xlsx, .xls"
                onChange={handleFileChange}
              />
            </Flex>
            <Flex vertical gap="10px">
              {allDatas.map((data) => (
                <Cards
                  data={data}
                  page="newsArcive"
                  ButtonComp={
                    <ButtonCardNewsArchive
                      data={data}
                      type="ADD"
                      setAllDatas={setAllDatas}
                      setAddDatas={setAddDatas}
                      allDatas={allDatas}
                    />
                  }
                />
              ))}
            </Flex>
          </Flex>

          {/* RIGHT */}
          <Flex className="right" vertical gap="10px">
            <Flex>
              <h3>ข้อมูลนำเข้า</h3>
            </Flex>
            {addDatas.length > 0 ? (
              <Flex vertical gap="10px">
                {addDatas.map((data) => (
                  <Cards
                    data={data}
                    page="newsArcive"
                    ButtonComp={
                      <ButtonCardNewsArchive
                        data={data}
                        type="DEL"
                        setAllDatas={setAllDatas}
                        setAddDatas={setAddDatas}
                        allDatas={allDatas}
                      />
                    }
                  />
                ))}
              </Flex>
            ) : (
              <Flex
                justify="center"
                align="center"
                style={{
                  height: "100%",
                  borderRadius: "10px",
                  border: "1px solid #DBDBDB",
                  background: "white",
                }}
                vertical
              >
                <img src={IconNoItem} alt="รูปไม่มีรายการ" />
                <span>ไม่มีรายการ</span>
              </Flex>
            )}
          </Flex>
        </div>
        <Flex justify="end">
          <StyledButtonSubmitNewsArchive
            onClick={() => handleSubmitNews()}
            disabled={addDatas.length === 0}
          >
            ยืนยัน
          </StyledButtonSubmitNewsArchive>
        </Flex>
        <ModalConfirmComponent
          title="ตรวจสอบเอกสาร"
          okText="อัพโหลด"
          cancelText="ยกเลิก"
          open={isOpenModal}
          onOk={() => handleSubmitModalUploadFile()}
          onCancel={() => handleCloseModalUploadFile()}
          bodyAlign="center"
          width={1080}
        >
          <ExcelViewer data={dataSpreadsheet} setData={setDataSpreadsheet} />
        </ModalConfirmComponent>
      </StyledPageNewsArchive>
    </MainLayout>
  );
};

export default NewsArchive;
