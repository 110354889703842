import styled from "styled-components";
import { useState } from "react";
import { Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CheckboxComponent from "../../../common/components/CheckboxComponent";
import { setFilterSocialCard } from "../socialCard/socialCardSlice";

import IconFaceBook from "../../../assets/icons/icon-facebook.svg";
import IconTwitter from "../../../assets/icons/icon-x.svg";
import IconTikTok from "../../../assets/icons/icon-tiktok.svg";
import IconPantip from "../../../assets/icons/icon-pantip.svg";
import IconInternet from "../../../assets/icons/icon-internet.svg";
import IconYoutube from "../../../assets/icons/icon-youtube.svg";
import IconInstagram from "../../../assets/icons/icon-instagram.svg";

const StyleFilterSocial = styled(Flex)`
  /* justify-content: space-between;
  align-items: center; */
  padding: 8px 16px;
  height: 32px;
  background: ${(props) => props.theme.backgroundFilter};

  .filter-header {
    font-size: 24px;
    font-weight: 700;
    color: ${(props) => props.theme.colorTextButton};
  }

  .filter-checkbox-group img {
    width: 24px;
    height: 24px;
  }
`;

const FilterSocial = ({
  title,
  isSelect = false,
  Select = () => null,
  children,
}) => {
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.socialCard);
  const { facebook, twitter, tiktok, pantip, internet, youtube, instagram } =
    filter;

  const stateCheckPlatform = [
    {
      key: "facebook",
      id: "facebook",
      icon: IconFaceBook,
      label: "",
      checked: facebook,
    },
    {
      key: "twitter",
      id: "twitter",
      icon: IconTwitter,
      label: "",
      checked: twitter,
    },
    {
      key: "tiktok",
      id: "tiktok",
      icon: IconTikTok,
      label: "",
      checked: tiktok,
    },
    {
      key: "pantip",
      id: "pantip",
      icon: IconPantip,
      label: "",
      checked: pantip,
    },
    {
      key: "internet",
      id: "internet",
      icon: IconInternet,
      label: "",
      checked: internet,
    },
    {
      key: "youtube",
      id: "youtube",
      icon: IconYoutube,
      label: "",
      checked: youtube,
    },
    {
      key: "instagram",
      id: "instagram",
      icon: IconInstagram,
      label: "",
      checked: instagram,
    },
  ];
  const [stateCheck, SetStateCheck] = useState(stateCheckPlatform);

  // const onChageCheckbox = (e, index) => {
  //   // set State Checkbox
  //   const { checked } = e.target;
  //   SetStateCheck((prev) => {
  //     const temp = [...prev];
  //     temp[index] = {
  //       ...temp[index],
  //       checked,
  //     };
  //     return temp;
  //   });

  //   // set reducer
  //   const payload = {
  //     key: stateCheck[index].id,
  //     value: checked,
  //   };
  //   dispatch(setFilterSocialCard(payload));
  // };

  const onChageCheckbox = (e, index) => {
    const { checked } = e.target;

    const checkedCount = stateCheck.filter((item) => item.checked).length;

    if (!checked && checkedCount === 1) {
      return;
    }

    SetStateCheck((prev) => {
      const temp = [...prev];
      temp[index] = {
        ...temp[index],
        checked,
      };
      return temp;
    });

    const payload = {
      key: stateCheck[index].id,
      value: checked,
    };
    dispatch(setFilterSocialCard(payload));
  };

  return (
    <StyleFilterSocial align="center" justify="space-between">
      <span className="filter-header">{title}</span>
      <Flex className="filter-checkbox-group" align="center" gap="8px">
        <span>Social Media :</span>
        <CheckboxComponent
          listCheck={stateCheck}
          onChange={onChageCheckbox}
          gap="20px"
        />
        {isSelect && <div>{Select}</div>}
        {children}
      </Flex>
    </StyleFilterSocial>
  );
};

export default FilterSocial;
