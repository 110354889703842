import React from "react";
import MainLayout from "../common/layouts/main";
import SocialMediaReview from "../features/socialMediaReview";

const SocialMediaReviewPage = () => {
  return (
    <MainLayout>
      <SocialMediaReview />
    </MainLayout>
  );
};

export default SocialMediaReviewPage;
