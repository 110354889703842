import React from "react";
import styled from "styled-components";
import { Button, Flex } from "antd";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: 700;
`;

const TitleText = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colorTextDesc};
`;

const DateText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colorTextDesc};
`;

const TitleCampaign = ({ title, date }) => {
  const navigate = useNavigate();

  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <TitleText>{title}</TitleText>
        <DateText>Campaign Start : {date}</DateText>
      </Flex>
      <Flex>
        <StyledButton type="link" danger onClick={() => navigate("/")}>
          {"Look up >"}
        </StyledButton>
      </Flex>
    </Flex>
  );
};

export default TitleCampaign;
