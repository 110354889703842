/* eslint-disable import/prefer-default-export */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
const generateMockData = (trendListMany) => {
  const platforms = [
    "twitter",
    "facebook",
    "internet",
    "tiktok",
    // "youtube",
    "pantip",
  ];
  const trendsPerHour = trendListMany;
  const hours = 24;
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(12, 0, 0, 0);

  const data = [];

  for (let i = 0; i < hours; i++) {
    const currentDate = new Date(yesterday.getTime() + i * 60 * 60 * 1000);
    const currentPlatform = getRandomPlatform(platforms);

    const hourlyData = {
      createdAt: currentDate.toISOString(),
      platform: currentPlatform,
      trendList: generateTrendList(trendsPerHour, currentPlatform),
    };

    data.push(hourlyData);
  }

  return data;
};

const getRandomPlatform = (platforms) => {
  return platforms[Math.floor(Math.random() * platforms.length)];
};

const generateTrendList = (count, platform) => {
  const trends = [];

  for (let j = 0; j < count; j++) {
    const trendName = `#${getRandomString(10)}`;
    const views = getRandomNumber(5000, 15000);

    const trend = {
      trendName,
      views,
      platform,
    };

    trends.push(trend);
  }

  return trends;
};

const getRandomString = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const mockData = generateMockData(18);
