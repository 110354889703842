import React from "react";
import styled from "styled-components";
import { Flex } from "antd";
import { ThemeStyledSelect } from "../../../common/components/ThemeStyledComponent";

const Text = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colorTextDesc};
`;

const optionSortDefaultList = [
  {
    label: "Oldest",
    value: "asc",
    type: "order_by",
  },
  {
    label: "Latest",
    value: "desc",
    type: "order_by",
  },
];

const SortSocialMedia = ({ counts = 0, children, onChange, value }) => {
  return (
    <Flex justify="space-between" align="center">
      <Flex vertical>
        <Text>Total {counts} Items</Text>
      </Flex>
      <Flex align="center" gap={8}>
        <Text>Sorting :</Text>
        <ThemeStyledSelect
          style={{
            width: "160px",
          }}
          value={value}
          onChange={onChange}
          options={optionSortDefaultList}
        />
        {children}
      </Flex>
    </Flex>
  );
};

export default SortSocialMedia;
