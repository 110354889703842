/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
import { Flex } from "antd";
import styled from "styled-components";
import { FirstLabel } from "../../utils/firstLabel";

/*

<ProfileCard
  img={profileImg}
  label={profileName}
  roleUser={profileRole}
/>

*/

export const Role = (role = "") => {
  if (role.includes("influencer")) {
    return "Copywriter & Influencer";
  }
  if (role.includes("writer") || role.includes("copywriter")) {
    return "Copywriter";
  }
};

const ProfileCard = ({ label = "", roleUser = "" }) => {
  const StyledProfileCard = styled(Flex)`
    min-width: 130px;
    p {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      color: ${(props) => props.theme.colorTertiary};
    }
  `;

  const StyledProfileName = styled(Flex)`
    aspect-ratio: 1/1;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: ${(props) => props.theme.white};
    font-size: 6.896px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.723px; /* 170% */
    background-color: ${(props) => props.theme.profileColor(props?.index)};
  `;

  const StyledProfileCardTop = styled(Flex)`
    text-transform: capitalize;
    img {
      width: 18px;
      height: 18px;
      object-fit: cover;
      border-radius: 50%;
      background-color: ${(props) => props.theme.white};
    }

    p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      color: ${(props) => props.theme.colorTextButton};
      &.role-display {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        color: ${(props) => props.theme.colorTertiary};
      }
    }
  `;
  const customColorByText =
    label &&
    String(FirstLabel(label).charCodeAt(0))
      .split("")
      .reduce(function (prev, curr) {
        return (Number(prev) || 0) + (Number(curr) || 0);
      });

  return (
    <StyledProfileCard justify="center" align="flex-start" vertical>
      <StyledProfileCardTop justify="center" align="start" gap="8px">
        {/* {!!img && <img src={img} alt="imageProfile" />} */}
        <StyledProfileName
          justify="center"
          align="center"
          index={Math.min(15, customColorByText)}
        >
          {label && FirstLabel(label)}
        </StyledProfileName>
        {!!label && <p>{label}</p>}
      </StyledProfileCardTop>
      <div>
        {!!roleUser && label && (
          <p className="role-display">{Role(roleUser)}</p>
        )}
      </div>
      {/* {!!roleUser && <p>{Role(roleUser)}</p>} */}
    </StyledProfileCard>
  );
};

export default ProfileCard;
