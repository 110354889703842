/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import IconFaceBook from "../../assets/icons/icon-facebook.svg";
import IconTwitter from "../../assets/icons/icon-x.svg";
import IconTikTok from "../../assets/icons/icon-tiktok.svg";
import IconPantip from "../../assets/icons/icon-pantip.svg";
import IconInternet from "../../assets/icons/icon-internet.svg";
import IconAround from "../../assets/icons/icon-around.svg";
import IconIn from "../../assets/icons/icon-in.svg";
import IconOut from "../../assets/icons/icon-out.svg";
import IconCreate from "../../assets/icons/create-icon.svg";
import IconDelete from "../../assets/icons/delete-list-icon.svg";
import IconAdd from "../../assets/icons/add-list-icon.svg";
import IconInstagram from "../../assets/icons/icon-instagram.svg";
import IconLike from "../../assets/icons/icon-like.svg";
import IconShare from "../../assets/icons/icon-share.svg";
import IconReply from "../../assets/icons/icon-reply.svg";
import IconQuote from "../../assets/icons/icon-quote.svg";
import IconPost from "../../assets/icons/icon-post.svg";
import IconYoutube from "../../assets/icons/icon-youtube.svg";

const keysIcons = {
  facebook: IconFaceBook,
  twitter: IconTwitter,
  tiktok: IconTikTok,
  pantip: IconPantip,
  internet: IconInternet,
  around: IconAround,
  instagram: IconInstagram,
  in: IconIn,
  out: IconOut,
  edit: IconCreate,
  delete: IconDelete,
  add: IconAdd,
  youtube: IconYoutube,
};

const stateSelectCardFilter = [
  {
    value: "created_date",
    label: "มาใหม่",
  },
  {
    value: "positive_trend",
    label: "Most Positive",
  },
  {
    value: "negative_trend",
    label: "Most Negative",
  },
  // {
  //   value: "pinned",
  //   label: "ปักหมุดขึ้นก่อน",
  // },
  // {
  //   value: "warning",
  //   label: "แจ้งเตือนขึ้นก่อน",
  // },
];

const backgroundTypeMission = {
  like: "bgLike",
  share: "bgShare",
  shares: "bgShare",
  quote: "bgQuote",
  share_quote: "bgQuote",
  reply: "bgReply",
  engage: "bgReply",
  post: "bgPost",
};

const iconSocial = {
  like: IconLike,
  share: IconShare,
  shares: IconShare,
  quote: IconQuote,
  share_quote: IconQuote,
  reply: IconReply,
  engage: IconReply,
  post: IconPost,
};

const bgSocial = {
  facebook: "bgFacebook",
  instagram: "bgInstagram",
  twitter: "bgTwitter",
  tiktok: "bgTiktok",
  pantip: "bgPantip",
  internet: "bgInternet",
};

const myPostStatus = {
  active: {
    label: "Running",
    color: "colorRunning",
  },
  complete: {
    label: "Completed",
    color: "colorDone",
  },
  cancel: {
    label: "Cancelled",
    color: "colorRejected",
  },
  request: {
    label: "Request",
    color: "colorVerified",
  },
  approve: {
    label: "Verified",
    color: "colorVerified",
  },
  webBot: {
    label: "WEB",
    color: "colorVerified",
  },
  apiBot: {
    label: "API",
    color: "colorVerified",
  },
};

const typeBot = {
  web: {
    label: "WEB",
    color: "colorVerified",
  },
  api: {
    label: "API",
    color: "colorVerified",
  },
  basic: {
    label: "BASIC",
    color: "colorVerified",
  },
};

const myPostInfluencerStatus = {
  approve: {
    label: "Running",
    color: "colorRunning",
  },
  pending: {
    label: "Running",
    color: "colorRunning",
  },
  complete: {
    label: "Done",
    color: "colorDone",
  },
  cancel: {
    label: "running",
    color: "textInput",
  },
};

const myPostInfluencerStatusCancel = {
  approve: {
    label: "Running",
    color: "textInput",
  },
  pending: {
    label: "Running",
    color: "textInput",
  },
  complete: {
    label: "Done",
    color: "colorDone",
  },
  cancel: {
    label: "Running",
    color: "textInput",
  },
};

const myPostListMissionStatus = {
  pending: {
    label: "Submitted",
    color: "colorWaiting",
  },
  approve: {
    label: "Approved",
    color: "colorDone",
  },
  complete: {
    label: "Approved",
    color: "colorApproved",
  },
  cancel: {
    label: "Cancelled",
    color: "colorRejected",
  },
  reject: {
    label: "Rejected",
    color: "colorRejected",
  },
};

const myPostListMissionStatusCancel = {
  pending: {
    label: "Submitted",
    color: "textInput",
  },
  approve: {
    label: "Approved",
    color: "colorApproved",
  },
  complete: {
    label: "Approved",
    color: "colorApproved",
  },
  cancel: {
    label: "Cancelled",
    color: "colorRejected",
  },
  reject: {
    label: "Rejected",
    color: "colorRejected",
  },
  verified: {
    label: "Verified",
    color: "colorVerified",
  },
};

const statusContent = {
  // ["pending", "complete", "reject", "error", "cancel", "approve", "verified"]
  pending: {
    label: "Waiting",
    color: "colorWaiting",
  },
  waiting: {
    label: "Waiting",
    color: "colorWaiting",
  },
  approve: {
    label: "Approved",
    color: "colorApproved",
  },
  complete: {
    label: "Completed",
    color: "colorApproved",
  },
  done: {
    label: "Done",
    color: "colorDone",
  },
  reject: {
    label: "Rejected",
    color: "colorRejected",
  },
  error: {
    label: "Error",
    color: "colorRejected",
  },
  cancel: {
    label: "Cancelled",
    color: "colorRejected",
  },
  running: {
    label: "Running",
    color: "colorRunning",
  },
  active: {
    label: "Running",
    color: "colorRunning",
  },
  verified: {
    label: "Verified",
    color: "colorVerified",
  },
};

const editorStatusContent = {
  // ["pending", "complete", "reject", "error", "cancel", "approve"]
  available: {
    label: "Available",
    color: "colorApproved",
  },
  pending: {
    label: "Pending",
    color: "colorWaiting",
  },
  // waiting: {
  //   label: "Pending",
  //   color: "colorWaiting",
  // },
  // approve: {
  //   label: "Approved",
  //   color: "colorApproved",
  // },
  // complete: {
  //   label: "Complete",
  //   color: "colorApproved",
  // },
  done: {
    label: "Done",
    color: "colorDone",
  },
  reject: {
    label: "Rejected",
    color: "colorRejected",
  },
  verified: {
    label: "Verified",
    color: "colorVerified",
  },
  // error: {
  //   label: "Error",
  //   color: "colorRejected",
  // },
  // cancel: {
  //   label: "Cancelled",
  //   color: "colorRejected",
  // },
  // running: {
  //   label: "Available",
  //   color: "colorApproved",
  // },
  active: {
    label: "Available",
    color: "colorApproved",
  },
};

const subMissionCardStatusDetailContent = {
  pending: { label: "Pending", color: "colorWaiting" },
  approve: { label: "Pending", color: "colorWaiting" },
  complete: { label: "Done", color: "colorDone" },
  reject: { label: "Rejected", color: "colorRejected" },
  error: { label: "Rejected", color: "colorCanceled" },
  cancel: { label: "Rejected", color: "colorCanceled" },
  verified: { label: "Verified", color: "colorVerified" },
};

const colorIcon = {
  info: "#2c3e50",
};

export {
  keysIcons,
  stateSelectCardFilter,
  backgroundTypeMission,
  iconSocial,
  bgSocial,
  statusContent,
  editorStatusContent,
  myPostListMissionStatus,
  myPostStatus,
  subMissionCardStatusDetailContent,
  myPostInfluencerStatus,
  myPostInfluencerStatusCancel,
  myPostListMissionStatusCancel,
  colorIcon,
  typeBot,
};
