/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Flex, Image, Typography } from "antd";
import GroupSocial from "../../../common/components/GroupSocial";
import { StyleMission } from "../../../common/components/CardPost";
import SocialButton from "../../../common/components/SocialButton";
import { keysIcons, statusContent } from "../../../common/utils/keyDatas";
import { StyledButtonStatus } from "../MyPostsList";
import IconClock from "../../../assets/icons/icon-clock.svg";

const { Text } = Typography;
const StyleCardMissionDetail = styled(Flex)`
  border-radius: 10px;
  align-items: center;
  /* border: 1px solid ${(props) => props?.theme?.dividerLine}; */
  /* background: ${(props) => props?.theme?.white}; */
  /* padding: 16px; */
`;

const StyleContent = styled.div`
  width: 100%;
`;

const StyleTextTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colorTextButton};
`;

const StyleTextContent = styled.a`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextButton} !important;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-decoration: none;
  line-height: 22px;
  cursor: default;
  padding: 3px 2px;
  line-height: 29px;

  ${(props) =>
    props?.href &&
    `
  text-decoration: underline !important;
  cursor: pointer;
  `}
`;

const StyleDate = styled(Flex)`
  font-size: 12px !important;

  p {
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  span {
    color: ${(props) => props?.theme?.colorTextButton};
  }
`;

const StyleDesc = styled.span`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextDesc};
`;

const StyleTextRequirment = styled.span`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextButton};
  display: -webkit-box;
  overflow-y: auto;
  height: 86px;
  padding: 3px 2px;
  line-height: 29px;
`;

const ContentMissionDetailCard = (props) => {
  const {
    dataItem = {
      type: "reply",
      pageType: "",
      href: "",
      content: "",
      startDate: "",
      endDate: "",
      createdDate: "",
      status: "active",
      platforms: [],
    },
    dataItemRequirement = "",
  } = props;

  const {
    type,
    pageType,
    href,
    content,
    startDate,
    endDate,
    createdDate,
    status,
    platforms,
  } = dataItem;

  return (
    <Flex
      vertical
      gap={12}
      justify="center"
      // align="center"
      // style={{
      //   // marginTop: "16px",
      // }}
    >
      <StyleCardMissionDetail gap="8px">
        {/* {type && (
          <Image
            src={keysIcons[type]}
            height={24}
            preview={false}
            style={{
              width: "24px",
            }}
          />
        )} */}
        <GroupSocial list={[platforms[0]]} />

        <StyleContent>
          <StyleTextContent href={href} target="_blank">
            {content}
          </StyleTextContent>
          <Flex
            direction="row"
            gap={10}
            style={{
              marginTop: "16px",
            }}
            justify={status ? "space-between" : "end"}
          >
            <Flex direction="row" gap={10}>
              {createdDate && pageType !== "mission" && (
                <StyleDate align="center" gap="4px">
                  <p>Created : </p>
                  <span>{moment(createdDate).format("YYYY-MM-DD HH:mm")}</span>
                </StyleDate>
              )}
              {startDate && endDate && pageType !== "mission" && (
                <StyleDate align="center" gap="4px">
                  <img src={IconClock} alt="icon-clock" />
                  <p>Work on : </p>
                  <span>
                    {moment(startDate).format("YYYY-MM-DD HH:mm")} to{" "}
                    {moment(endDate).format("YYYY-MM-DD HH:mm")}
                  </span>
                </StyleDate>
              )}
            </Flex>
          </Flex>
          {status && (
            <StyleMission align="center" justify="space-between" horizontal>
              <Flex gap={8}>
                Missions : <SocialButton type={type} />
              </Flex>
              {startDate && endDate && (
                <Flex>
                  Date : {moment(startDate).format("YYYY-MM-DD HH:mm")} -{" "}
                  {moment(endDate).format("YYYY-MM-DD HH:mm")}
                </Flex>
              )}
            </StyleMission>
          )}
          {/* <Flex
            justify="flex-end"
            style={{
              paddingTop: "16px",
            }}
          >
            status
          </Flex> */}
        </StyleContent>
      </StyleCardMissionDetail>

      {dataItemRequirement && (
        <Flex vertical gap={8}>
          <StyleDesc>Requirement : </StyleDesc>
          <StyleTextRequirment>{dataItemRequirement}</StyleTextRequirment>
        </Flex>
      )}
    </Flex>
  );
};

export default ContentMissionDetailCard;
