import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const verifiedProvider = () => {
  const { axios } = useAxios();

  const getVerified = async ({ filter, page, limit }) => {
    console.log(filter);
    try {
      const url = `${host}/social-accounts/status/approve?${filter}&page=${page}&limit=${limit}`;
      const response = await axios.get(url);
      return response?.data;
    } catch (error) {
      return error;
    }
  };

  const getVerifiedDetail = async (id) => {
    try {
      const url = `${host}/social-accounts/status/approve/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get social-accounts Error");
      return [];
    }
  };

  const updateStatusVerified = async () => {
    try {
      const url = `${host}/social-accounts/status/approve`;
      const res = await axios.patch(url, {
        status: "approve",
      });
      return res.data;
    } catch (error) {
      console.log("update Error:", error);
      return [];
    }
  };

  const deleteVerified = async (id) => {
    try {
      const url = `${host}/social-accounts/${id}`;
      await axios.delete(url);
      return true;
    } catch (error) {
      console.log("Delete error:", error);
      return error.response;
    }
  };

  return {
    getVerified,
    getVerifiedDetail,
    updateStatusVerified,
    deleteVerified,
  };
};

export default verifiedProvider;
