import React, { useEffect } from "react";
import { Chart } from "@antv/g2";
import { styled } from "styled-components";
import { Empty } from "antd";
import { formatNumberUppercase } from "../../../../common/utils/format";

const Title = styled.span`
  color: #444450;

  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const BarTotalChart = (props) => {
  const { data } = props;

  const calculate20Percent = (event) => {
    const maxCount = Math.max(...event.map((item) => item.trendCount));
    const twentyPercent = maxCount * 0.2;
    return twentyPercent;
  };

  useEffect(() => {
    if (data.length > 0) {
      const chart = new Chart({
        container: "bar-total-chart",
        paddingTop: 10,
        height: 300,
        autoFit: true,
      });

      chart
        .interval()
        .data(data)
        .transform({
          type: "sortX",
          by: "y",
          reverse: true,
          slice: 6,
        })
        .transform({
          type: "dodgeX",
        })
        .encode("x", "platform")
        .encode("y", "trendCount")
        .encode("color", "trend")
        .scale("color", {
          relations: [
            ["natural", "#D9D9D9"],
            ["negative", "#FF2643"],
            ["positive", "#0EA67F"],
          ],
        })

        .axis("y", {
          title: false,
          labelFormatter: "~s",
          line: true,
        })
        .axis("x", {
          title: false,
          line: true,
        })
        .label({
          text: "trendCount",
          render: (text, datum) => {
            return text > calculate20Percent(data)
              ? `
          <div style="left:-50%;top:15px;position:relative;font-size:10px;font-weight: bold;transform: rotate(-0.25turn);color:${
            datum.trend === "natural" ? "black" : "white"
          };">
            <span>${formatNumberUppercase(datum.trendCount)}</span>
          </div>
        `
              : `<div style="left:-50%;top:20px;position:relative;font-size:10px;font-weight: bold;transform: rotate(90deg);">`;
          },
        })
        .legend({
          color: {
            position: "bottom",
            itemMarker: "circle",
            layout: {
              justifyContent: "center",
            },
          },
        })
        .scale("y", {
          nice: true,
        });

      chart
        .interaction("tooltip", {
          shared: true,
        })
        .interaction("elementHighlightByColor", {
          background: true,
        });

      chart.render();
    }
  }, [data]);

  return (
    <div>
      <Title>Social Data</Title>
      {data.length > 0 ? <div id="bar-total-chart" /> : <Empty />}
    </div>
  );
};

export default BarTotalChart;
