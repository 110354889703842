/* eslint-disable no-unused-vars */
import { Button, Flex } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SelectComponent from "../../../../common/components/SelectComponent";
import CheckboxComponent from "../../../../common/components/CheckboxComponent";
import { setQueryAll, setKeySocialCard } from "../socialCardSlice";

import IconPined from "../../../../assets/icons/icon-pin.svg";
import IconWarning from "../../../../assets/icons/icon-warning.svg";

const ButtonSelect = styled(Button)`
  background: ${(props) =>
    props.active && props.theme.secondaryBg200} !important;
  color: ${(props) => props.active && props.theme.colorcheckbox} !important;
  border-color: ${(props) =>
    props.active && props.theme.showColumnToggleBackground} !important;

  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

const CardFilter = () => {
  const dispatch = useDispatch();
  const { sortby, isAllData, pinned, warned } = useSelector(
    (state) => state.socialCard,
  );

  const stateCheck = [
    // {
    //   key: "ข้อมูลทั้งหมด",
    //   type: "isAllData",
    //   label: "ข้อมูลทั้งหมด",
    //   checked: isAllData,
    // },
    {
      key: "ปักหมุด",
      type: "pinned",
      label: "Pinned",
      checked: pinned,
      icon: IconPined,
    },
    {
      key: "เฝ้าระวัง",
      type: "warned",
      label: "Following",
      checked: warned,
      icon: IconWarning,
    },
  ];

  const stateSelectCardFilter = [
    // {
    //   value: "created_date",
    //   label: "มาใหม่",
    // },
    {
      value: "created_date",
      label: "Lastest",
    },
    {
      value: "positive_trend",
      label: "Oldest",
    },
    // {
    //   value: "negative_trend",
    //   label: "Lastest",
    // },
  ];

  const [stateSelect, setStateSelect] = useState(sortby);

  const onChageCheckbox = (e, index) => {
    const { checked } = e.target;

    // ALL
    // if (index === 0) {
    //   const payload = {
    //     value: checked,
    //   };
    //   dispatch(setQueryAll(payload));
    // }
    // CUSTOM
    // else {
    if (!checked) {
      if (
        stateCheck.filter((item, idx) => item.checked === true).length === 1
      ) {
        return;
      }
    }
    const payload = {
      key: stateCheck[index].type,
      value: checked,
    };
    dispatch(setKeySocialCard(payload));
    // }
  };

  const onChageSelect = (e) => {
    if (!e) return;
    setStateSelect(e);
    const payload = {
      key: "sortby",
      value: e,
    };
    dispatch(setKeySocialCard(payload));
  };

  useEffect(() => {
    if (pinned && warned) {
      dispatch(
        setQueryAll({
          value: true,
        }),
      );
    } else {
      const payload = {
        key: "isAllData",
        value: false,
      };
      dispatch(setKeySocialCard(payload));
    }
  }, [pinned, warned]);

  const handleSelectAll = () => {
    dispatch(
      setQueryAll({
        value: true,
      }),
    );
  };

  const handleClearAll = () => {
    dispatch(
      setKeySocialCard({
        key: "pinned",
        value: false,
      }),
    );
    dispatch(
      setKeySocialCard({
        key: "warned",
        value: false,
      }),
    );
  };

  return (
    <Flex id="card-filter" gap={8} vertical>
      <Flex justify="space-between" align="center">
        <span
          style={{
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Sentiment
        </span>
        <Flex align="center" gap="10px">
          <span>Sorting</span>
          <SelectComponent
            value={stateSelect}
            onChange={onChageSelect}
            options={stateSelectCardFilter}
            style={{
              width: "140px",
            }}
          />
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center">
        <CheckboxComponent
          listCheck={stateCheck}
          onChange={onChageCheckbox}
          gap="17px"
        />
        <Flex gap={4}>
          <ButtonSelect active={isAllData} onClick={() => handleSelectAll()}>
            Select All
          </ButtonSelect>
          <ButtonSelect
            active={!pinned && !warned}
            onClick={() => handleClearAll()}
          >
            Clear All
          </ButtonSelect>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardFilter;
