import React from "react";
import styled from "styled-components";

import FilterList from "../features/listTrend/filterList";
import ListTable from "../features/listTrend/listTable";
import MainLayout from "../common/layouts/main";

const ListTrend = () => {
  const ListTrendBackground = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 16px;
    height: calc(100dvh - 80px);
    background: ${(props) => props.theme.backgroundDashBoard};
    overflow: auto;
  `;

  return (
    <MainLayout>
      <ListTrendBackground id="list-trend">
        <FilterList />
        <ListTable />
      </ListTrendBackground>
    </MainLayout>
  );
};

export default ListTrend;
