/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";
import { Card } from "antd";
import WordCloud from "./WordCloud";
// import WordCloud from "../../../common/components/TestGrap/WordCloudPlot";
import LineChart from "./LineChart";
import BarPrecentChart from "./BarPrecentChart";
import ChartProvider from "../provider/chartProvider";
import BarTotalChart from "./BarTotalChart";
import TrackingChart from "./TrackingChart";

const StyledChart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Charts = () => {
  const chartProvider = ChartProvider();
  const { isCollapse } = useSelector((state) => state.sidebar);
  const { filter } = useSelector((state) => state.socialCard);
  const [lineChart, setLineChart] = useState([]);
  const [barChart, setBarChart] = useState([]);
  const [wordClud, setWordClud] = useState([]);
  const [barTotalChart, setBarTotalChart] = useState([]);

  const fetchLineChartData = async (params) => {
    const lineChartData = await chartProvider.getLineChart(params);
    setLineChart(lineChartData);
  };

  const fetchBarChartData = async (params) => {
    const barChartData = await chartProvider.getBarpercentChart(params);

    // const transformedData = barChartData?.reduce((accumulator, item) => {
    //   const mappedData = item.data.map((platformData) => ({
    //     trendName: item.trendName,
    //     platform: platformData.platform,
    //     trendCount: platformData.trendCount,
    //   }));

    //   return [...accumulator, ...mappedData];
    // }, []);
    // setBarChart(transformedData);
    setBarChart(barChartData);
  };

  const fetchWordCloudData = async (params) => {
    const wordCloudData = await chartProvider.getWordCloud(params);
    setWordClud(wordCloudData || []);
  };

  const fetchBarTotalChartData = async (params) => {
    const barTotalChartData = await chartProvider.getBarTotalChart(params);
    setBarTotalChart(barTotalChartData || []);
  };

  useEffect(() => {
    const filterPlatform = Object.keys(filter).filter((key) => filter[key]);

    const platformString = filterPlatform
      .map((platform) => `platform=${platform}`)
      .join("&");

    const params = `?&${platformString}`;

    fetchLineChartData(params);
    fetchBarChartData(params);
    fetchWordCloudData(params);
    fetchBarTotalChartData(params);
  }, [filter, isCollapse]);

  return (
    <StyledChart id="charts">
      <Card>
        <LineChart data={lineChart} />
      </Card>
      <Card>
        <TrackingChart data={barChart} />
        {/* <BarPrecentChart data={barChart} /> */}
      </Card>
      <Card>
        <WordCloud data={wordClud} />
      </Card>
      <Card>
        <BarTotalChart data={barTotalChart} />
      </Card>
    </StyledChart>
  );
};

export default Charts;
