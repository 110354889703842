import JobContent from "../features/jobContent";
import MainLayout from "../common/layouts/main";

const JobContentPage = () => {
  return (
    <MainLayout>
      <JobContent />
    </MainLayout>
  );
};

export default JobContentPage;
