import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lineChart: [
    {
      platform: "Facebook",
      treandColor: "#3D7DE5",
      treand: "positive",
      createAt: "2013-05-13T00:00:00.000Z",
      treandCount: 21,
    },
  ],
  barChart: [
    {
      treandNam: "หมูเถื่อน",
      platform: "Facebook",
      treandCount: 10,
    },
  ],
  wordClud: [
    {
      treandCount: 9,
      name: "AntV",
      wordColor: "#0EA67F",
      weighFont: 36,
    },
  ],
};

export const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    wordCloudAction: (state, action) => {
      state.wordClud = action.payload;
    },
    barChartAction: (state, action) => {
      state.barChart = action.payload;
    },
    lineChartAction: (state, action) => {
      state.lineChart = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { wordCloudAction, barChartAction, lineChartAction } =
  chartSlice.actions;

export default chartSlice.reducer;
