/* eslint-disable no-unused-vars */
import { Flex } from "antd";
import Layout, { Content } from "antd/es/layout/layout";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setIsCollapse } from "../../components/Sidebar/siderSlice";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";

const StyledSidebar = styled.div`
  .ant-menu-item-selected,
  .ant-menu-item {
    width: 100%;
    border-radius: 5px;
    margin: 0 !important;
    height: 50px;
    padding-left: 24px !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    // border-radius: 0;
  }

  .ant-menu-item-selected .ant-menu-title-content {
    color: ${(props) => props.theme.white};
  }

  .ant-menu-item {
    display: flex;
    justify-content: center;
  }

  .ant-menu-root {
    li {
      margin-bottom: 10px;
    }
    div {
      display: flex;
      align-items: center;
    }
    > li:last-child {
      display: ${(props) => (props.isCollapse ? "none" : "flex")};
      position: absolute;
      bottom: 0;
      width: 100%;
      border-bottom: none;
    }

    .ant-menu-submenu {
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      border-radius: 0;
      :hover {
        /* background-color: ${(props) =>
          props.theme.colorMagenta} !important; */
      }

      &.ant-menu-submenu-open {
        color: ${(props) => props.theme.colorMagenta};
        .ant-menu-submenu-title {
          margin-left: 0px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          width: 100%;
          border-radius: 0;
          /* border-radius: 5px !important; */
          /* color: ${(props) => props.theme.white}; */
          height: 55px !important;
          /* background-color: ${(props) =>
            props.theme.colorMagenta} !important; */
          /* svg {
            path {
              fill: ${(props) => props.theme.white};
            }
          } */
        }
        .ant-menu-submenu-selected {
          color: ${(props) => props.theme.colorMagenta};
        }
      }
    }
    .ant-menu-submenu-title {
      /* height: 50px; */
      /* background-color: transparent !important; */
    }

    .ant-menu-title-content {
      background-color: transparent !important;
    }
    .ant-menu-sub {
      background-color: transparent !important;

      & li:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }

    &.ant-menu-inline {
      border-inline-end: 0px solid rgba(5, 5, 5, 0.06);
    }
  }

  .ant-menu-title-content {
    height: 100% !important;

    span {
      display: flex;
      gap: 10px;
    }
  }

  .ant-menu-light .ant-menu-item-selected {
    color: ${(props) => props.theme.white} !important;
    background-color: ${(props) => props.theme.colorMagenta};
    /* font-family: "Noto Sans Thai"; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 5px;
    letter-spacing: 0.15px;
    /* border-radius: 5px; */
    svg {
      path {
        fill: ${(props) => props.theme.white};
      }
    }
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
  .ant-menu-light
    > .ant-menu
    .ant-menu-submenu-selected
    > .ant-menu-submenu-title {
    /* color: ${(props) => props.theme.white}; */
    color: ${(props) => props.theme.colorMagenta} !important;
    background-color: ${(props) => props.theme.white};
    svg {
      path {
        fill: ${(props) => props.theme.colorMagenta};
      }
    }
  }

  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: ${(props) => props.theme.showColumnToggleBackground};
    color: ${(props) => props.theme.secondaryDark600};
    /* color: ${(props) => props.theme.white} !important; */

    width: 100%;
    /* border-radius: 5px; */
    margin: 10px;
  }

  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: transparent;
  }

  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }

  #sidebar .ant-tooltip {
    display: none;
  }

  :where(.css-dev-only-do-not-override-14yae4w).ant-menu-inline
    > .ant-menu-item,
  :where(.css-dev-only-do-not-override-14yae4w).ant-menu-vertical
    > .ant-menu-item,
  :where(.css-dev-only-do-not-override-14yae4w).ant-menu-inline
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  :where(.css-dev-only-do-not-override-14yae4w).ant-menu-vertical
    > .ant-menu-submenu
    > .ant-menu-submenu-title {
    height: 50px;
  }

  :where(.css-dev-only-do-not-override-14yae4w).ant-menu .ant-menu-item,
  :where(.css-dev-only-do-not-override-14yae4w).ant-menu
    .ant-menu-submenu-title {
    transition: auto;
  }
`;

const ContentCustom = styled(Content)`
  height: calc(100vh - 81px);
  overflow: scroll;
`;

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { isCollapse } = useSelector((state) => state.sidebar);

  const toggleSidebar = () => {
    // alert("open");
    dispatch(setIsCollapse());
  };

  return (
    <Flex vertical>
      {/* WITH SIDE BAR */}
      <Navbar toggleSidebar={() => toggleSidebar()} />
      <StyledSidebar isCollapse={isCollapse}>
        <Layout>
          <Sidebar id="sidebar" isCollapse={isCollapse} />
          <ContentCustom>{children}</ContentCustom>
        </Layout>
      </StyledSidebar>

      {/* MO SIDE BAR */}
      {/* <Navbar /> */}
      {/* {children} */}
      <Footer />
    </Flex>
  );
};

export default MainLayout;
