import styled from "styled-components";
import FormSubmitContent from "../features/jobContent/formSubmitContent";

const StyleSubmitContent = styled.div`
  width: 100%;
`;

const SubmitContent = () => {
  return (
    <StyleSubmitContent>
      <FormSubmitContent />
    </StyleSubmitContent>
  );
};

export default SubmitContent;
