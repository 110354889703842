/* eslint-disable import/no-named-as-default */
import { combineSlices } from "@reduxjs/toolkit";
import modalErrorSlice from "../common/components/ModalError/modalErrorSlice";
import siebarSlice from "../common/components/Sidebar/siderSlice";
import filterListSlice from "../features/listTrend/filterListSlice";
import chartsSlice from "../features/dashboard/chartSocial/chartSlice";
import SocialCardSlice from "../features/dashboard/socialCard/socialCardSlice";
import insertTrendSlice from "../features/InsertTrend/insertTrendSlice";
import filterDataSlice from "../features/filterData/filterDataSlice";
import engagementSlice from "../features/missions/owner/myEngagements/engagementSlice";
import socialMediaSlice from "../features/socialMedia/socialMediaSlice";
import influencerSubmissionSlice from "../features/missions/copywriter&influencer/submission/influencerSubmissionSlice";

const rootReducer = combineSlices({
  chart: chartsSlice,
  socialCard: SocialCardSlice,
  filterList: filterListSlice,
  sidebar: siebarSlice,
  modalError: modalErrorSlice,
  insertTrend: insertTrendSlice,
  filterData: filterDataSlice,
  engagement: engagementSlice,
  socialMedia: socialMediaSlice,
  influencerSubmission: influencerSubmissionSlice,
});

export default rootReducer;
