import React from "react";
import styled from "styled-components";
import { Flex, Card, Col, Row } from "antd";
import { calculatedTime } from "../../../common/utils/format";
import { keysIcons } from "../../../common/utils/keyDatas";

const StyledRow = styled(Row)`
  .ant-card-head {
    border-radius: 10px 10px 0px 0px;
    background: #afaeae;
    .title-card {
      display: flex;
      padding: 8px 0px;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
    .text-title {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
  }
`;

const CardsTrend = ({ data }) => {
  return (
    <StyledRow gutter={16}>
      {data?.map((e) => (
        <Col span={6} key={e.createdAt}>
          <Card
            title={
              <div className="title-card">
                <img src={keysIcons[e.platform]} alt={`icon-${e.platform}`} />
                <span className="text-title">
                  {calculatedTime(e.createdAt)}
                </span>
              </div>
            }
            bordered={false}
          >
            <Flex
              vertical
              style={{
                gap: "8px",
              }}
            >
              {e.trendList.map((list) => (
                <span className="text-body">{list.trendName}</span>
              ))}
            </Flex>
          </Card>
        </Col>
      ))}
    </StyledRow>
  );
};
export default CardsTrend;
