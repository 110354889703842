/* eslint-disable no-useless-catch */
import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const missionProvider = () => {
  const { axios } = useAxios();

  const getSubMissionContent = async (params) => {
    try {
      const url = `${host}/sub-mission-contents/mission/${params.id}/owner?${params.query}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  // const updateApproveContent = async (id) => {
  //   try {
  //     const url = `${host}/sub-mission-contents/mission/${id}`;
  //     const res = await axios.patch(url);
  //     return res.data;
  //   } catch (error) {
  //     console.log("update Error");
  //     return [];
  //   }
  // };

  const createSubmissionContent = async (body) => {
    try {
      const url = `${host}/sub-mission-contents`;
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      console.log("update Error");
      throw error;
      // return [];
    }
  };

  const getMission = async (params) => {
    try {
      const url = `${host}/missions/all?${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  const getSubMissionDetail = async (id) => {
    try {
      const url = `${host}/sub-mission-contents/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  const getSubMissionPostsDetail = async (id) => {
    try {
      const url = `${host}/sub-mission-posts/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  const updateStatusSubmit = async (params) => {
    try {
      const url = `${host}/sub-mission-contents/${params.id}/${params.status}`;
      const res = await axios.patch(url);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const updateStatusMission = async (id, status) => {
    try {
      const url = `${host}/missions/${id}`;
      const res = await axios.patch(url, {
        status,
      });
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  const getMissionById = async (id) => {
    try {
      const url = `${host}/missions/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  const getCategories = async () => {
    try {
      const url = `${host}/categories`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  const getMissionAvailable = async (params) => {
    try {
      const url = `${host}/missions/available?${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("get mission Error");
      return [];
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getSocailAccount = async ({ platform, missionId }) => {
    try {
      const url = `${host}/social-accounts/mission/${missionId}`;
      const res = await axios.get(url, {
        params: {
          platform,
          order_by: "desc",
          limit: 20,
          page: 1,
        },
      });
      return res?.data ? res?.data : null;
    } catch (error) {
      return error;
    }
  };

  return {
    getSubMissionContent,
    createSubmissionContent,
    // updateApproveContent,
    getMission,
    getSubMissionDetail,
    updateStatusSubmit,
    updateStatusMission,
    getMissionById,
    getCategories,
    getMissionAvailable,
    getSubMissionPostsDetail,
    getSocailAccount,
  };
};

export default missionProvider;
