/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import EngagementProvider from "./engagementProvider";
import { setFiledEngagement } from "./engagementSlice";

const useFetchData = () => {
  const engagementProvider = EngagementProvider();

  const dispatch = useDispatch();
  const limit = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const { queryFilterRedux = "" } = useSelector((state) => state.filterData);
  const parseQuery = queryString.parse(queryFilterRedux);
  const { engagementList = [], engagementPaginationQuery = "" } = useSelector(
    (state) => state.engagement,
  );

  // const [datalist, setDatalist] = useState({
  //   data: [],
  //   total: 0,
  // });

  const fetchEngagement = async () => {
    try {
      const query = [queryFilterRedux, engagementPaginationQuery]
        .filter(Boolean)
        .join("&");
      const res = await engagementProvider.getEngagement(query);
      dispatch(
        setFiledEngagement({
          key: "engagementList",
          value: {
            data: res?.data || [],
            total: res?.total || 0,
          },
        }),
      );
      // setDatalist({
      //   data: res?.data || [],
      //   total: res?.total || 0,
      // });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (queryFilterRedux) {
      if ("platform" in parseQuery && "status" in parseQuery) {
        fetchEngagement();
      }
    }
    return () => {};
  }, [queryFilterRedux, engagementPaginationQuery, currentPage]);

  // useEffect(() => {
  //   dispatch(
  //     setFiledEngagement({
  //       key: "engagementList",
  //       value: datalist,
  //     }),
  //   );
  // }, [datalist]);

  return {
    fetchEngagement,
    data: engagementList?.data || [],
    total: engagementList?.total || 0,
    limit,
    currentPage,
    setCurrentPage,
    queryFilterRedux,
  };
};

export default useFetchData;
