import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
// eslint-disable-next-line prettier/prettier
import {Button, Flex, Table, Modal} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
// eslint-disable-next-line no-unused-vars
import { useAuth } from "../../common/hooks/useProviderAuth";
import { myNewWindow, isRoleSuperAdmin } from "../../common/utils/helper";
import { StyledButtonStatus, IconWarning } from "../missions/MyPostsList";
import SocialButton from "../../common/components/SocialButton";
import Tabs from "../../common/components/Tabs";
import IconSocial from "../../common/components/IconSocial";
import FilterData from "../../common/components/FilterData";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
  socialDefault,
} from "../../common/utils/filterDatas";
import JobEngagementProvider from "./provider/jobEngagementProvider";
import EmptyDefault from "../../common/components/EmptyDefault";

const StyleJobEngagement = styled.div`
  .ant-anchor-link {
    text-decoration: underline;
    width: 600px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  a.ant-anchor-link-title.ant-anchor-link-title-active {
    color: #161616 !important;
  }
`;

const StyleTable = styled(Table)`
  .ant-table-row {
    background-color: white;
  }
`;
const StyleTextContent = styled.span`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextButton};
  display: inline-block;
  width: 600px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
`;

const StyleBtn = styled(Button)`
  font-size: 16px;
  font-weight: 700;
  width: 70px;
  height: 35px;
`;

const StyleAnchor = styled.a`
  /* padding: 0 1px !important; */
  color: ${(props) => props.theme.colorTextButton} !important;
  text-decoration: underline !important;
  white-space: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 3px 2px;
  line-height: 29px;
`;

// const StyleAnchor = styled(Anchor)`
//   a.ant-anchor-link-title {
//     display: inline-block;
//     white-space: nowrap;
//     overflow: hidden !important;
//     text-overflow: ellipsis;
//     color: ${(props) => props?.theme?.colorTextInfo} !importtant;
//     text-decoration: underline !importtant;
//   }
// `;

const editorStatusContent = {
  // ["pending", "complete", "reject", "error", "cancel", "approve"]
  available: {
    label: "Available",
    color: "colorApproved",
  },
  pending: {
    label: "Pending",
    color: "colorWaiting",
  },
  waiting: {
    label: "Pending",
    color: "colorWaiting",
  },
  approve: {
    label: "Approved",
    color: "colorApproved",
  },
  complete: {
    label: "Done",
    color: "colorApproved",
  },
  done: {
    label: "Done",
    color: "colorDone",
  },
  reject: {
    label: "Rejected",
    color: "colorRejected",
  },
  verified: {
    label: "Verified",
    color: "colorVerified",
  },
  error: {
    label: "Error",
    color: "colorRejected",
  },
  cancel: {
    label: "Cancelled",
    color: "colorRejected",
  },
  running: {
    label: "Available",
    color: "colorApproved",
  },
  active: {
    label: "Available",
    color: "colorApproved",
  },
};

const FeatureJobEngagement = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState("1");
  const engagementProvider = JobEngagementProvider();
  const { queryFilterRedux } = useSelector((state) => state.filterData) || {};

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 50;
  const [content, setContent] = useState([]);
  const newWindow = useRef(null);
  const [modal, contextHolder] = Modal.useModal();

  const closeWindow = () => {
    // eslint-disable-next-line no-use-before-define
    initData();
  };

  const modalConfirmDetails = {
    sessionError: {
      title: "Your session is about to expire!",
      subTitle: "",
    },
    noJobAvailable: {
      title: "No job available.",
      subTitle: "",
    },
    errorOccurred: {
      title: "An error has occurred.",
      subTitle: "Please try again.",
    },
  };

  const modalError = (param) => {
    const { title, subTitle, onOk = () => closeWindow() } = param;
    modal.info({
      title,
      subTitle,
      icon: <IconWarning />,
      content: "",
      centered: true,
      okText: "OK",
      onOk,
    });
  };

  const cardJob = (record) => {
    let type = record?.target;
    type = type === "share_quote" ? "quote" : type;

    return (
      <Flex vertical gap={16}>
        {record?.new_title ? (
          <StyleAnchor href={record?.new_url} target="_blank" rel="noreferrer">
            {record?.new_title}
          </StyleAnchor>
        ) : (
          // <StyleAnchor
          //   items={[
          //     {
          //       target: "_blank",
          //       href: record?.new_url,
          //       title: record?.new_title,
          //     },
          //   ]}
          // />
          <StyleTextContent>{record?.remark}</StyleTextContent>
        )}

        <Flex gap={8} justify="space-between" align="center">
          <Flex gap={4} align="center">
            <span>Mission:</span>
            <SocialButton type={type} />
          </Flex>

          <Flex gap={4}>
            <span>Date: </span>
            <span>
              {moment(record?.start_date).format("YYYY-MM-DD HH:mm")} -{" "}
              {moment(record?.end_date).format("YYYY-MM-DD HH:mm")}
            </span>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const getListEngagementDone = async (params = null) => {
    try {
      const res = await engagementProvider.getListJobDoneEngagement(params);
      if (res) {
        setTotal(res?.total);
        setContent(res?.data);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getListEngagementReady = async (params = null) => {
    try {
      const res = await engagementProvider.getListJobReadyEngagement(params);
      if (res) {
        setTotal(res?.total);
        setContent(res?.data);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getListEngagementAll = async (params = null) => {
    try {
      const res = await engagementProvider.getJobEngagements(params);
      if (res) {
        setTotal(res?.total);
        setContent(res?.data);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getMissionContentDetail = async (missionId) => {
    try {
      const response = await engagementProvider.getMissionContentDetail({
        missionId,
      });
      return response;
    } catch (error) {
      console.log("error", error);
      return null;
    }
  };

  // eslint-disable-next-line consistent-return
  const getSocailAccount = async ({ platform, missionId }) => {
    if (platform && missionId) {
      try {
        const res = await engagementProvider.getSocailAccount({
          platform,
          missionId,
        });
        if (res) {
          return res?.filter((item) => item?.approved_date !== null);
        }
        return [];
      } catch (err) {
        return [];
      }
    }
  };

  // function ปิด window
  function handleCloseWindow() {
    if (
      newWindow?.current?.location &&
      newWindow?.current?.location !== "about:blank"
    ) {
      // eslint-disable-next-line no-use-before-define
      initData();
    }
  }

  // function เปิด window
  const handleOpenWindow = (record) => {
    if (newWindow?.current) newWindow?.current?.close();
    const missionType = record?.target === "shares" ? "share" : record?.target;

    const windowRef = myNewWindow(
      `submit-engagement?missionId=${record?.id}&missionType=${missionType}`,
      "_blank",
    );
    windowRef.onunload = handleCloseWindow;
    newWindow.current = windowRef;
  };

  const checkJobCardIsAvailable = async (record) => {
    const response = await getMissionContentDetail(record?.id);
    const socialAccounts = await getSocailAccount({
      platform: record?.platforms ? [record.platforms[0]] : "",
      missionId: record?.id,
    });

    if (isRoleSuperAdmin) {
      handleOpenWindow(record);
      return;
    }

    let isSocialAccountExist = socialAccounts && socialAccounts.length > 0;
    const isStatusCorrect = !["cancel", "complete"].includes(response.status);
    const isTargetCorrect = response.target > response?.actual?.influencer;
    const isDateNotExpired = dayjs().isBefore(dayjs(response?.end_date));

    if (user?.roles.includes("superadmin")) {
      isSocialAccountExist = true;
    }

    if (
      isSocialAccountExist &&
      isStatusCorrect &&
      isTargetCorrect &&
      isDateNotExpired
    ) {
      handleOpenWindow(record);
    } else {
      modalError(modalConfirmDetails.noJobAvailable);
    }
  };

  const columns = [
    {
      align: "left",
      width: "50px",
      title: "List",
      dataIndex: "platform",
      key: "platform",
      render: (_, record) => (
        <IconSocial
          type={record?.platform ? record?.platform : record?.platforms[0]}
        />
      ),
    },
    {
      align: "left",
      width: "450px",
      title: "",
      dataIndex: "status",
      key: "status",
      // eslint-disable-next-line no-unused-vars
      render: (_, record) => cardJob(record),
    },
    {
      align: "center",
      width: "50px",
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        let status = "";
        if (record?.status === "approve" || record?.status === "available") {
          status = "Available";
        } else if (record?.status === "done") {
          status = "Done";
        }
        return (
          <StyledButtonStatus
            color={editorStatusContent[record?.status]?.color}
          >
            {status}
          </StyledButtonStatus>
        );
        // return <TextStatus status={status}>{status}</TextStatus>;
      },
    },
    {
      align: "center",
      width: "50px",
      title: "",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <StyleBtn
          type="primary"
          disabled={
            record?.status === "done" ||
            dayjs(record?.end_date).isBefore(dayjs())
          }
          onClick={() => checkJobCardIsAvailable(record)}
        >
          Pick
        </StyleBtn>
      ),
    },
  ];

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  const customTable = () => {
    return (
      <StyleTable
        style={{
          // minHeight: "calc(100vh - 280px)",
          height: "max-content",
        }}
        locale={{
          emptyText: <EmptyDefault />,
        }}
        columns={columns}
        dataSource={content}
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: currentPage,
          pageSize: limit,
          total,
          showLessItems: false,
          showSizeChanger: false,
          showQuickJumper: false,
          hideOnSinglePage: false,
          onChange: (page) => onChangePagination(page),
        }}
      />
    );
  };

  const tabList = [
    {
      key: "1",
      label: "All",
      children: customTable(),
    },
    {
      key: "2",
      label: "Running",
      children: customTable(),
    },
    {
      key: "3",
      label: "Done",
      children: customTable(),
    },
  ];

  const initData = () => {
    const status = tabList[activeTab - 1]?.label;
    const newFilterQuery = queryFilterRedux;

    switch (status) {
      case "All":
        getListEngagementAll({
          page: currentPage,
          limit,
          filter: newFilterQuery,
        });
        break;
      case "Running":
        // if (queryFilterRedux) {
        //   newFilterQuery = queryFilterRedux.replace(
        //     /target=quote/g,
        //     "target=share_quote",
        //   );
        // }
        getListEngagementAll({
          page: currentPage,
          limit,
          filter: newFilterQuery,
          status: "active",
        });
        break;
      case "Done":
        // if (queryFilterRedux) {
        //   newFilterQuery = queryFilterRedux.replaceAll("platform", "platforms");
        //   newFilterQuery = newFilterQuery.replaceAll("target", "type");
        // }
        getListEngagementAll({
          page: currentPage,
          limit,
          filter: newFilterQuery,
          status: "complete",
        });
        break;
      default:
        break;
    }
  };

  const handleClickTabs = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (currentPage && queryFilterRedux) {
      initData();
    }
  }, [activeTab, queryFilterRedux, currentPage]);

  return (
    <StyleJobEngagement>
      <FilterData
        title="Jobs"
        labelSocial="Social Media : "
        labelDetail={`Total ${total || 0} Items`}
        labelSort="Sorting :"
        labelFilter="Show :"
        callBackQueryFilter={() => {}}
        // callBackQueryFilter={(e) => setFilterQuery(e)}
        filter={{
          social: socialDefault,
          optionSortList: optionSortDefaultList,
          optionSortSelect: optionSortDefaultSelect,
          typeShow: "like_share_quoteCopywriterAndInfluencer",
        }}
      />
      <Tabs
        tabList={tabList}
        tabActive={activeTab}
        handleClickTabs={(e) => handleClickTabs(e)}
      />
      {contextHolder}
    </StyleJobEngagement>
  );
};

export default FeatureJobEngagement;
