/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { typeAll } from "../utils/filterDatas";
import {
  setFiledFilterData,
  setInitialFilterData,
} from "../../features/filterData/filterDataSlice";

const useFilterData = ({
  social = null,
  typeShow = null,
  optionSortList = [],
  optionSortSelect = "",
  // callBackQueryFilter = () => null,
  otherSort = null,
  typeRole = "",
}) => {
  const dispatch = useDispatch();
  const [socialHook, setSocialHook] = useState({
    ...social,
  });
  const [typeHook, setTypeHook] = useState({
    ...typeAll[typeShow],
  });
  const [optionSortSelectHook, setOptionSortHook] = useState(optionSortSelect);
  const [optionSortListHook, setOptionSortListHook] = useState([
    ...optionSortList,
  ]);

  useEffect(() => {
    setSocialHook({
      ...social,
    });
  }, [social]);
  useEffect(() => {
    setTypeHook({
      ...typeAll[typeShow],
    });
  }, [typeShow]);
  useEffect(() => {
    setOptionSortListHook([...optionSortList]);
  }, [optionSortList]);
  useEffect(() => {
    setOptionSortHook(optionSortSelect);
  }, [optionSortSelect]);

  const FilterDataObject = (data, filterBy = "isCheck") => {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => ((res[key] = obj[key]), res), {});

    const filter = Object.filter(data, (item) => {
      return item[filterBy];
    });
    return filter;
  };

  const GroupDataObject = (data, filterBy = "type") => {
    const groupType = Object.keys(data).reduce((acc, d) => {
      acc[data[d][filterBy]] = Object.keys(data).filter((g) => {
        return data[g][filterBy] === data[d][filterBy];
      });
      return acc;
    }, {});
    return groupType;
  };

  const StructureSortObject = (data, filterBy = "value") => {
    const groupType = data?.reduce((acc, item) => {
      return {
        ...acc,
        [item[filterBy]]: {
          ...(acc[item[filterBy]] || {}),
          ...item,
        },
      };
    }, {});
    return groupType;
  };

  useEffect(() => {
    const structureSortObject = StructureSortObject(optionSortListHook);
    const filterSocialHook = FilterDataObject(socialHook);
    const filterTypeHook = FilterDataObject(typeHook);

    const sumFilterAll = {
      ...filterSocialHook,
      ...filterTypeHook,
      ...otherSort,
      [optionSortSelectHook]: structureSortObject[optionSortSelectHook],
    };
    const groupType = GroupDataObject(sumFilterAll);
    const queryFilter = queryString.stringify(groupType) || "";
    const queryReplace = queryFilter
      .replace("running", "active")
      .replace("completed", "complete")
      .replace("cancelled", "cancel")
      .replace("waiting", "pending") // Status=> engagementsDetailOwner
      .replace("approved", "approve") // Status=> engagementsDetailOwner
      .replace("done", "complete") // Status=> engagementsDetailOwner
      // .replace("cancelled", "cancel") // Status=> engagementsDetailOwner
      .replace("rejected", "reject") // Status=> engagementsDetailOwner
      .replace("submitted", "pending"); // Status=> engagementsDetailOwner

    // callBackQueryFilter(queryReplace);

    const queryMissionInfluencer = queryFilter
      .replace("running", "approve")
      .replace("done", "complete");

    const queryReplaceBy =
      typeRole === "missionInfluencer" ? queryMissionInfluencer : queryReplace;

    dispatch(
      setFiledFilterData({
        key: "queryFilterRedux",
        value: queryReplaceBy,
      }),
    );
  }, [socialHook, typeHook, optionSortSelectHook, otherSort, typeShow]);

  const onSetIsCheckHook = (name, checked, caseType) => {
    if (caseType?.includes("social")) {
      setSocialHook((pre) => {
        const res = {
          ...pre,
          [name]: {
            ...pre[name],
            isCheck: checked,
          },
        };
        const isDisableCheck = Object.keys(res).filter((key) => {
          return res[key].isCheck;
        });

        return isDisableCheck.length <= 0 && !checked
          ? {
              ...pre,
            }
          : {
              ...res,
            };
      });
    } else if (caseType?.includes("type")) {
      setTypeHook((pre) => {
        const res = {
          ...pre,
          [name]: {
            ...pre[name],
            isCheck: checked,
          },
        };
        const isDisableCheck = Object.keys(res).filter((key) => {
          return res[key].isCheck;
        });

        return isDisableCheck.length <= 0 && !checked
          ? {
              ...pre,
            }
          : {
              ...res,
            };
      });
    }
  };

  const onSetIsDisableHook = (name, disable, caseType) => {
    if (caseType?.includes("social")) {
      setSocialHook((pre) => {
        return {
          ...pre,
          [name]: {
            ...pre[name],
            isDisable: disable,
          },
        };
      });
    } else if (caseType?.includes("typeShow")) {
      setTypeHook((pre) => {
        return {
          ...pre,
          [name]: {
            ...pre[name],
            isDisable: disable,
          },
        };
      });
    }
  };

  const onChangeSortHook = (value) => {
    setOptionSortHook(value);
  };

  return {
    socialHook,
    typeHook,
    optionSortListHook,
    optionSortSelectHook,
    onSetIsDisableHook,
    onSetIsCheckHook,
    onChangeSortHook,
  };
};
export default useFilterData;
