import styled from "styled-components";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
// import { setKeySidebar } from "../common/components/sider/siderSlice";
// import { TimePicker } from "antd";
import FilterSocial from "../features/dashboard/filterPlatform";
import Charts from "../features/dashboard/chartSocial";
import SocialCard from "../features/dashboard/socialCard";
import MainLayout from "../common/layouts/main";

const DashBoard = () => {
  // const dispatch = useDispatch();
  // const { isCollapse } = useSelector((state) => state.sidebar);

  const StyleDashBoard = styled.div`
    /* width: calc(100% - ${(props) =>
      props.isCollapse ? "80px" : "200px"}); */
    display: flex;
    flex-direction: column;
    padding: 4px 16px;
    background: ${(props) => props.theme.backgroundDashBoard};
    height: calc(100vh - 100px);
    overflow: hidden;
  `;

  const StyleDashBoardSpliter = styled.div`
    display: flex;
    gap: 24px;
    margin-top: 8px;
    margin-bottom: 4px;
    height: calc(100dvh - 132px);

    > div {
      width: 49%;
      height: inherit;
      overflow: auto;
    }
  `;

  // useEffect(() => {
  //   const tempStr = window.location.pathname;
  //   const path = tempStr.slice(1, tempStr.length);

  //   if (path === "dashboard") {
  //     dispatch(
  //       setKeySidebar({
  //         value: "1",
  //       }),
  //     );
  //   }
  // }, []);

  return (
    <MainLayout>
      <StyleDashBoard id="dashboard">
        {/* <StyleDashBoard id="dashboard" isCollapse={isCollapse}> */}
        <FilterSocial title="Dashboard" />
        {/* <TimePicker /> */}
        <StyleDashBoardSpliter>
          <Charts />
          <SocialCard />
        </StyleDashBoardSpliter>
      </StyleDashBoard>
    </MainLayout>
  );
};

export default DashBoard;
