import { Checkbox, Flex } from "antd";
import styled from "styled-components";

const CheckboxComponent = ({ listCheck, onChange, gap = 0 }) => {
  const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${(props) => props.theme.colorcheckbox}!important;
      border-color: ${(props) => props.theme.colorcheckbox}!important;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${(props) => props.theme.colorcheckbox} !important;
    }
  `;

  return (
    <Flex align="center" gap={gap}>
      {listCheck.map((option, index) => (
        <StyledCheckbox
          key={`option-${option.id}`}
          name={option.label}
          checked={option.checked}
          onChange={(e) => onChange(e, index)}
          style={{
            alignItems: "center",
          }}
        >
          <Flex align="center">
            {option.icon && (
              <img src={option.icon} alt={`icon-${option.label}`} />
            )}
            {option.label && <span>{option.label}</span>}
          </Flex>
        </StyledCheckbox>
      ))}
    </Flex>
  );
};

export default CheckboxComponent;
