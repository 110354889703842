import React from "react";
import MainLayout from "../common/layouts/main";
import MissionContentOwner from "../features/missionOwner";

const MissionOwner = () => {
  return (
    <MainLayout>
      <MissionContentOwner />
    </MainLayout>
  );
};

export default MissionOwner;
