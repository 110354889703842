/* eslint-disable no-multi-str */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Flex, Radio, Form, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import ModalConfirm from "../../../common/components/ModalConfirm";
import ContentSubmissionDetail from "../ContentSubmissionDetail";
import WindowComponent from "../../../common/components/WindowComponent";
import ContentDetail from "../../../common/components/ContentDetail";
import HeaderWindowDetail from "../../flowJob/HeaderWindowDetail";
import Icon from "../../../assets/icons/icon-copywriter2.svg";
import SubmissionOwnerProvider from "../provider/submissionOwnerProvider";

const PostDetailWrap = styled.div`
  padding: 16px 32px;
  /* margin-bottom: ${(props) =>
    props.status === "waiting" ? "162px" : "50px"}; */
`;

const H6 = styled.h6`
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`;

const ButtonCustom = styled(Button)`
  border: ${(props) =>
    props.typeBtn === "submit"
      ? "none"
      : `1px solid ${props.theme.colorMagenta}`};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: 180px;
  height: 38.8px;
  background-color: ${(props) =>
    props.typeBtn === "submit" ? props.theme.colorMagenta : props.theme.white};
  color: ${(props) =>
    props.typeBtn === "submit" ? props.theme.white : props.theme.colorMagenta};
`;

const FooterWrap = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${(props) => props.theme.white};
`;

export const IconWarning = styled(ExclamationCircleOutlined)`
  path {
    color: ${(props) => props.theme.colorTextButton};
  }
`;

const useWindowContentDetail = () => {
  const [data, setData] = useState(null);
  const [statusContent, setStatusContent] = useState("reject");
  const [detail, setDetail] = useState(null);
  const submissionOwnerProvider = SubmissionOwnerProvider();
  const { id: missionId } = useParams();
  const [isOpenModalAlert, setIsOpenModalAlert] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const getMissionDetail = async () => {
    const res = await submissionOwnerProvider.getSubmissionOwnerById(missionId);
    setDetail(res);
  };

  useEffect(() => {
    (async () => {
      await getMissionDetail();
    })();
    return () => {};
  }, []);

  const updateApprove = async (id) => {
    try {
      const res = await submissionOwnerProvider.updateSubmissionStatusSubmit({
        id,
        status: statusContent,
      });
      window.close();
      return res;
    } catch (error) {
      const { code, message } = error;
      // alert("error");
      setIsOpenModalAlert(!isOpenModalAlert);
    }
  };

  const closeWindow = async () => {
    window.close();
  };

  const updateApproveStatus = (id) => {
    modal.confirm({
      // title: `There are ${detail.pending} jobs waiting to be reviewed.`,
      title: `Are you sure you want to do this?`,
      content: "Would you like to confirm?",
      icon: <IconWarning />,
      centered: true,
      // content: `Press the Ok button to approve this job and rejected ${
      //   detail.pending - 1
      // } jobs . Press the Cancel button to review other jobs.`,
      okText: "OK",
      onOk: () => updateApprove(id),
      cancelText: "Cancel",
    });
  };

  const confirm = (id) => {
    modal.confirm({
      title: `There are ${detail.pending} jobs waiting to be reviewed.`,
      // title: `Are you sure you want to do this?`,
      icon: <IconWarning />,
      centered: true,
      content: `Press the Ok button to approve this job and rejected ${
        detail.pending - 1
      } jobs . Press the Cancel button to review other jobs.`,
      okText: "OK",
      onOk: () => updateApprove(id),
      cancelText: "Cancel",
    });
  };

  return {
    data,
    statusContent,
    setStatusContent,
    updateApprove,
    closeWindow,
    detail,
    isOpenModalAlert,
    setIsOpenModalAlert,
    contextHolder,
    confirm,
    updateApproveStatus,
  };
};

const WindowContentDetail = () => {
  const [form] = useForm();
  const {
    data,
    statusContent,
    setStatusContent,
    updateApprove,
    closeWindow,
    detail,
    isOpenModalAlert,
    setIsOpenModalAlert,
    contextHolder,
    confirm,
    updateApproveStatus,
  } = useWindowContentDetail();
  const {
    status,
    created_date: createdDate,
    type,
    writer_name: writerName,
    writer_poster_name: writerPosterName,
    requirement,
    content_text: contentText,
    start_date: startDate,
    end_date: endDate,
    id,
    role_name: roleName,
    platform,
    news_url: newsUrl,
    news_title: newsTitle,
    canceled_date: canceledDate,
  } = detail || {};
  return (
    <div>
      <WindowComponent
        minusHeight={status === "waiting" || status === "pending" ? 200 : 0}
      >
        <PostDetailWrap status={status}>
          <HeaderWindowDetail
            imgProfile={Icon}
            name={writerName}
            roleUser={roleName}
            title="Post Content"
            tagContent={type}
            // status={status}
            date={createdDate}
          />

          <br />
          <hr />
          <br />

          <Flex vertical>
            <ContentSubmissionDetail
              dataItem={{
                // type,
                href: newsUrl,
                content: requirement,
                startDate,
                endDate,
                platform,
                newsTitle,
                // type: platform,
              }}
              dataItemRequirement={requirement}
            />
            <ContentDetail message={contentText} imageList={[]} />

            <br />
          </Flex>
        </PostDetailWrap>
        {status === "waiting" ||
          (status === "pending" && (
            <FooterWrap>
              <Form
                form={form}
                name="postDetail"
                onFinish={() =>
                  // detail.approve + detail.complete + 1 >= detail.targets &&
                  // detail.pending - 1 > detail.targets
                  detail.approve + 1 === detail.targets &&
                  detail.pending - 1 > 0 &&
                  statusContent !== "reject"
                    ? confirm(id)
                    : updateApproveStatus(id)
                }
              >
                <Form.Item
                  label=""
                  name="statusContent"
                  initialValue={statusContent}
                  rules={[
                    {
                      required: true,
                      message: "Please select a status!",
                    },
                  ]}
                >
                  {!canceledDate && !moment().isAfter(endDate) && (
                    <Flex
                      justify="center"
                      style={{
                        padding: "16px",
                      }}
                    >
                      <Radio.Group
                        onChange={(e) => setStatusContent(e.target.value)}
                        defaultValue="reject"
                      >
                        <Radio value="reject">Reject</Radio>
                        <Radio value="approve">Approve</Radio>
                      </Radio.Group>
                    </Flex>
                  )}
                </Form.Item>

                <hr />
                <Form.Item>
                  <Flex
                    gap={15}
                    justify="center"
                    style={{
                      padding: "16px",
                    }}
                  >
                    <ButtonCustom
                      type="cancel"
                      htmlType="button"
                      onClick={closeWindow}
                    >
                      Cancel
                    </ButtonCustom>
                    <ButtonCustom
                      typeBtn="submit"
                      type="primary"
                      htmlType="submit"
                    >
                      Confirm
                    </ButtonCustom>
                  </Flex>
                </Form.Item>
              </Form>
            </FooterWrap>
          ))}
        {contextHolder}

        <ModalConfirm
          isModalOpen={isOpenModalAlert}
          type="warning"
          title="An error has occurred."
          subTitle="Please try again."
          callbackBtnRight={() => setIsOpenModalAlert(!isOpenModalAlert)}
          callbackBtnLeft={() => setIsOpenModalAlert(!isOpenModalAlert)}
        />
      </WindowComponent>
    </div>
  );
};

export default WindowContentDetail;
