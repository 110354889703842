/* eslint-disable no-unused-vars */
import { Flex } from "antd";
import { useEffect, useRef, useState } from "react";

const StackBarImpact = ({ positiveValue, negativeValue }) => {
  const stackSizeRef = useRef();
  const [barSize, setBarSize] = useState(300);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const calPercent = (value) => {
    if (windowSize[0] <= 1250) {
      return (value / 100) * barSize - 0.5;
    }
    return (value / 100) * 300 - 0.5;
  };

  useEffect(() => {
    // Read and Set Screen width for detech screensize
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    // SetWidth barchart depense on div size
    const divNegative = document.getElementById("negative-bar");
    const divPositive = document.getElementById("negative-bar");

    if (windowSize[0] <= 1250) {
      setBarSize(stackSizeRef.current.offsetWidth);
      const positiveWidth = calPercent(positiveValue);
      const negativeWidth = calPercent(negativeValue);
      divNegative.style.width = negativeWidth;
      divPositive.style.width = positiveWidth;
    }
  }, [windowSize]);

  const negativeColor = (percent) => {
    if (percent === 50) return "#D9D9D9";
    if (percent <= 40 && percent > 20) return "#FEB11A";
    if (percent <= 60 && percent > 40) return "#FE881A";
    if (percent <= 80 && percent > 60) return "#FE5F1A";
    if (percent <= 100 && percent > 80) return "#FF2643";
    return "#FEDA1A";
  };

  const positiveColor = (percent) => {
    if (percent === 50) return "#D9D9D9";
    if (percent <= 40 && percent > 20) return "#F1DE34";
    if (percent <= 60 && percent > 40) return "#CADB12";
    if (percent <= 80 && percent > 60) return "#92BE17";
    if (percent <= 100 && percent > 80) return "#0EA67F";
    return "#FEDA1A";
  };

  return (
    <Flex align="center" id="stack-bar-chart" ref={stackSizeRef}>
      {negativeValue > positiveValue ? (
        <>
          <div
            id="negative-bar"
            style={{
              width:
                calPercent(negativeValue) > 0 ? calPercent(negativeValue) : "0",
              height: "25px",
              cursor: "default",
              backgroundColor: negativeColor(negativeValue),
              // borderRadius: negativeValue === 100 ? "5px" : "5px 0 0 5px",
              borderRadius: "5px 0 0 5px",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                color: "white",
                paddingTop: "2px",
                paddingLeft: "8px",
                fontSize: "12px",
                fontWeight: 600,
                width: "max-content",
              }}
            >
              {negativeValue > 10 ? `${negativeValue}  %` : ""}
            </span>
          </div>
          <div
            id="white-line"
            style={{
              width: "1px",
              height: "33px",
              backgroundColor: "black",
            }}
          />
          <div
            id="positive-bar"
            style={{
              width:
                calPercent(positiveValue) > 0 ? calPercent(positiveValue) : "0",
              height: "25px",
              cursor: "default",
              backgroundColor: "#DBDBDB",
              // borderRadius: positiveValue === 100 ? "5px" : "0 5px 5px 0",
              borderRadius: "0 5px 5px 0",
            }}
          />
        </>
      ) : null}
      {positiveValue > negativeValue ? (
        <>
          <div
            id="negative-bar"
            style={{
              width:
                calPercent(positiveValue) > 0 ? calPercent(positiveValue) : "0",
              height: "25px",
              cursor: "default",
              backgroundColor: positiveColor(positiveValue),
              // borderRadius: positiveValue === 100 ? "5px" : "5px 0 0 5px",
              borderRadius: "5px 0 0 5px",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "flex-start",
                color: "white",
                paddingTop: "2px",
                paddingLeft: "8px",
                fontSize: "12px",
                fontWeight: 600,
                width: "max-content",
              }}
            >
              {positiveValue > 10 ? `${positiveValue}  %` : ""}
            </span>
          </div>
          <div
            id="white-line"
            style={{
              width: "1px",
              height: "33px",
              backgroundColor: "black",
            }}
          />
          <div
            id="positive-bar"
            style={{
              width:
                calPercent(negativeValue) > 0 ? calPercent(negativeValue) : "0",
              height: "25px",
              cursor: "default",
              backgroundColor: "#DBDBDB",
              borderRadius: negativeValue === 100 ? "5px" : "0 5px 5px 0",
            }}
          />
        </>
      ) : null}
      {positiveValue === negativeValue ? (
        <>
          <div
            id="negative-bar"
            style={{
              width:
                calPercent(positiveValue) > 0 ? calPercent(positiveValue) : "0",
              height: "25px",
              cursor: "default",
              backgroundColor: positiveColor(negativeValue),
              // borderRadius: positiveValue === 100 ? "5px" : "5px 0 0 5px",
              borderRadius: "5px 0 0 5px",
            }}
          />
          <div
            id="white-line"
            style={{
              width: "1px",
              height: "33px",
              backgroundColor: "black",
            }}
          />
          <div
            id="positive-bar"
            style={{
              width:
                calPercent(negativeValue) > 0 ? calPercent(negativeValue) : "0",
              height: "25px",
              cursor: "default",
              backgroundColor: positiveColor(positiveValue),
              borderRadius: negativeValue === 100 ? "5px" : "0 5px 5px 0",
            }}
          />
        </>
      ) : null}
    </Flex>
  );
};

export default StackBarImpact;
