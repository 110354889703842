import { useAxios } from "../../../common/hooks/useProviderAxios";
import { mockData } from "./mockDataFunction";

const host = process.env.REACT_APP_API_ENDPOINT;

const updateTrendProvider = () => {
  const { axios } = useAxios();

  const getUpdateListTrend = async (params) => {
    try {
      if (mockData)
        return mockData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );
      const url = `${host}/getNews${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  return {
    getUpdateListTrend,
  };
};

export default updateTrendProvider;
