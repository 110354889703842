/* eslint-disable no-useless-catch */
import { useAxios } from "../../../common/hooks/useProviderAxios";

// eslint-disable-next-line no-unused-vars
const host = process.env.REACT_APP_API_ENDPOINT;

const createMissionProvider = () => {
  const { axios } = useAxios();

  const createMission = async (payload) => {
    try {
      const url = `${host}/missions`;
      const res = await axios.post(url, payload);
      return res.status;
    } catch (error) {
      throw error;
    }
  };

  return {
    createMission,
  };
};
export default createMissionProvider;
