/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import styled from "styled-components";
import { CaretDownOutlined } from "@ant-design/icons";
import { Select } from "antd";

const SelectFilter = ({ value, onChange, options, defaultValue }) => {
  const StyledSelect = styled(Select)`
    width: 130px !important;

    .ant-select-selector {
      background-color: transparent !important;
      border-bottom: 1px solid #000000 !important;
      border: none;
      border-radius: 0px;
    }

    .ant-select-selection-item {
      color: #000000;
    }

    .ant-select-dropdown {
      border-radius: 0;
    }
  `;

  // const StyledMenu = styled.div`
  //   border-radius: 80px;
  // `;

  return (
    <StyledSelect
      // open
      suffixIcon={
        <CaretDownOutlined
          style={{
            color: "#000000",
          }}
        />
      }
      bordered={false}
      value={value}
      onChange={onChange}
      options={options}
      defaultValue={defaultValue}
      // dropdownRender={(menu) => <StyledMenu>{menu}</StyledMenu>}
    />
  );
};

export default SelectFilter;
