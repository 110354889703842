/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable object-curly-newline */
import { Button, Flex, Form, Radio } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  setIsOpenModalError,
  setTitleModalError,
} from "../common/components/ModalError/modalErrorSlice";
import EditSentiment from "../features/weightSentiment";
import {
  StyleInputForm,
  ThemeStyledTextArea,
  ThemeStyledInputNumber,
  ThemeStyledSelect,
} from "../common/components/ThemeStyledComponent";
import ModalConfirmComponent from "../common/components/ModalComponent";
import { ThemeStyledInput } from "../features/keyword/styled";
import MainLayout from "../common/layouts/main";
import keywordProvider from "../features/keyword/provider/keywordProvider";
import ListTrendProvider from "../features/listTrend/provider/listTrendProvider";
import {
  options,
  arrowOptions,
  sentimentOptions,
  mapSentimentValue,
  sentimentTypeColor,
} from "../features/weightSentiment/optionsData";

const ButtonConfirm = styled(Button)`
  background-color: #7d5de8;
  width: 180px;
  /* &:hover {
    background:  !important;
  } */
`;

const SentimentRedio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  width: 98px;
  height: 48px;
  border-radius: 10px;
  padding: 0px 16px;
  font-weight: 700;
`;

const InputForm = () => {
  const dispatch = useDispatch();
  const listTrendProvider = ListTrendProvider();
  const keywordProviders = keywordProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const [formTrend] = Form.useForm();
  const { state = { type: "add" } } = location;
  const [isInsert, setIsInsert] = useState(true);
  const [mainKeyword, setMainKeyword] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSentimentModalOpen, setIsSentimentModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    navigate("/list-trend");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (val) => {
    try {
      const body = {
        title: val.title,
        description: val.description ? val.description : "",
        url: val.url,
        platform: val.platform,
        likes: val.likes,
        shares: val.shares,
        views: val.views,
        comments: val.comments,
        campaign_id: val.campaign_id,
        keyword: val.keyword,
        arrow: val.arrow,
      };
      const mappedSentimentData = mapSentimentValue(val?.sentiment);

      if (state?.type === "add") {
        body.positive_trend = mappedSentimentData.positive_trend;
        body.negative_trend = mappedSentimentData.negative_trend;
      }

      if (state?.type === "add") {
        await listTrendProvider.addListTrend(body);
      } else {
        await listTrendProvider.editListTrend(state?.trendId, body);
      }
      navigate("/list-trend");
    } catch (error) {
      dispatch(setTitleModalError({ value: "เกิดข้อผิดพลาด" }));
      dispatch(setIsOpenModalError({ value: true }));
    }
  };

  const setEditValue = () => {
    for (const [key, val] of Object.entries(state)) {
      formTrend.setFieldValue(key, val);
      if (key === "sentiment") {
        formTrend.setFieldValue("sentiment", mapSentimentValue(val));
      }
    }
  };

  const getData = async () => {
    const res = await keywordProviders.getListKeyword();
    if (res) {
      const tempArr = res.reduce((acc, cur) => {
        return [...acc, { value: cur.id, label: cur.name }];
      }, []);

      setMainKeyword(tempArr);
    }
  };

  const SetDefaultValue = () => {
    formTrend.setFieldValue("sentiment", "natural");
    // formTrend.setFieldValue("negative_trend", 50);
    // formTrend.setFieldValue("positive_trend", 50);
    formTrend.setFieldValue("comments", 0);
    formTrend.setFieldValue("likes", 0);
    formTrend.setFieldValue("shares", 0);
    formTrend.setFieldValue("views", 0);
    formTrend.setFieldValue("campaign_id", 1);
    formTrend.setFieldValue("arrow", "in");
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (state?.type === "edit") {
      setEditValue();
      setIsInsert(false);
    } else {
      SetDefaultValue();
    }
    if (state === null) {
      navigate("/list-trend");
    }
  }, [isInsert]);

  const validateWhitespace = (_, value) => {
    if (value && value.trim() === "") {
      return Promise.reject(new Error("Please enter letters."));
    }
    return Promise.resolve();
  };

  return (
    <MainLayout>
      <StyleInputForm>
        <div id="input-form">
          <h2 className="input-form-header">
            {isInsert ? "Input Form" : "Edit Form"}
          </h2>
          <Form
            form={formTrend}
            labelCol={{
              span: 3,
            }}
            layout="horizontal"
            style={{
              width: "100%",
              maxWidth: "1000px",
              margin: "auto",
              padding: "16px",
            }}
            onFinish={handleSubmit}
            autoComplete="off"
            // onValuesChange={onFormChange}
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter a title",
                },
                { validator: validateWhitespace },
              ]}
            >
              <ThemeStyledInput placeholder="Please enter a title" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <ThemeStyledTextArea
                rows={5}
                placeholder="Please enter a description"
              />
            </Form.Item>
            <Form.Item
              label="Url"
              name="url"
              rules={[
                { required: true, message: "Please enter a url" },
                {
                  type: "url",
                  warningOnly: true,
                },
              ]}
            >
              <ThemeStyledInput placeholder="Please enter a url" />
            </Form.Item>
            <Form.Item
              label="Platform"
              name="platform"
              rules={[{ required: true, message: "Please select a platform" }]}
            >
              <ThemeStyledSelect
                placeholder="Select a platform"
                options={options}
              />
            </Form.Item>
            <Form.Item label="Engagement">
              <Flex justify="space-between" gap=" 16px">
                <Form.Item
                  label="Likes"
                  name="likes"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter likes" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Shares"
                  name="shares"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter shares" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Comments"
                  name="comments"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter comments" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
                <Form.Item
                  label="Views"
                  name="views"
                  style={{
                    display: "inline-block",
                    marginBottom: 0,
                    width: "calc(100%/4)",
                  }}
                  rules={[{ required: true, message: "Please enter views" }]}
                >
                  <ThemeStyledInputNumber
                    type="number"
                    min={0}
                    placeholder="0"
                  />
                </Form.Item>
              </Flex>
            </Form.Item>
            {state?.type === "add" ? (
              <Form.Item
                label="Sentiment"
                name="sentiment"
                className="sentiment"
                rules={[
                  { required: true, message: "Please select a sentiment" },
                ]}
              >
                <Radio.Group>
                  {sentimentOptions.map((e) => (
                    <Radio value={e.value}>
                      <SentimentRedio
                        color={e.id === "natural" ? "black" : "white"}
                        backgroundColor={sentimentTypeColor(e.id)}
                      >
                        {e.label}
                      </SentimentRedio>
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            ) : (
              <Form.Item label="Sentiment" name="sentiment">
                <Button onClick={() => setIsSentimentModalOpen(true)}>
                  Weight Sentiment
                </Button>
              </Form.Item>
            )}

            <Form.Item
              label="Arrow"
              name="arrow"
              rules={[{ required: true, message: "Please select a arrow" }]}
            >
              <Radio.Group>
                {arrowOptions.map((option) => (
                  <Radio value={option.value}>
                    <Flex align="center">
                      {option.icon && (
                        <img src={option.icon} alt={`icon-${option.label}`} />
                      )}
                      {option.label && <span>{option.label}</span>}
                    </Flex>
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Campaign"
              name="campaign_id"
              rules={[
                { required: true, message: "Please select a campaign_id" },
              ]}
            >
              <Radio.Group>
                {mainKeyword.map((e) => (
                  <Radio value={e.value}>{e.label}</Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Keyword"
              name="keyword"
              rules={[
                { required: true, message: "Please enter a keyword" },
                { validator: validateWhitespace },
              ]}
            >
              <ThemeStyledInput placeholder="Please enter a keyword" />
            </Form.Item>
            <Form.Item>
              <Flex justify="center" gap="16px">
                <Button
                  onClick={() => {
                    showModal();
                  }}
                  style={{ width: "180px" }}
                >
                  Cancel
                </Button>
                <ButtonConfirm type="primary" htmlType="submit">
                  Send
                </ButtonConfirm>
              </Flex>
            </Form.Item>
          </Form>
        </div>
      </StyleInputForm>
      <ModalConfirmComponent
        okText="Confirm"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelColor="red"
      >
        <p>Are you sure cancel?</p>
      </ModalConfirmComponent>
      <EditSentiment
        sentimentId={state?.trendId}
        setModalOpen={setIsSentimentModalOpen}
        modalOpen={isSentimentModalOpen}
        options={sentimentOptions}
      />
    </MainLayout>
  );
};

export default InputForm;
