/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import styled from "styled-components";
// eslint-disable-next-line no-unused-vars
import { Button, Flex } from "antd";
import dayjs from "dayjs";
import { statusContent } from "../../../common/utils/keyDatas";
import SocialButton from "../../../common/components/SocialButton";
import ProfileCard from "../../../common/components/ProfileCard";
import IconProfile from "../../../assets/icons/icon-profile.svg";

export const StyledButton = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-transform: capitalize;
  color: ${(props) => props.theme[props.color]};
  cursor: default;
  &.ant-btn-text:hover {
    pointer-events: none;
  }
  &.ant-btn-text:active {
    pointer-events: none;
  }
`;

export const TitleText = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props.theme.colorTextDesc};
`;

export const DateText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colorTextDesc};
`;

export const UsernameSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colorTextButton};

  .img-profile {
    width: 18px;
    height: 18px;
  }
`;

const TextStatus = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: rgba(68, 68, 80, 1);
`;

export const RoleSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colorTextButton};
`;

export const ButtonStatus = (status = "", typeStatus = statusContent) => {
  return (
    <StyledButton color={typeStatus[status].color} type="text">
      {typeStatus[status].label}
    </StyledButton>
  );
};

const HeaderWindowDetail = ({
  title = "Engagements Content",
  imgProfile = IconProfile,
  status = "",
  date = "2024-05-24 19:15",
  // name = "",
  roleUser = "writer",
  tagContent = "like",
  showSubmissionsDate = true,
  name = "",
  typeStatus = statusContent,
}) => {
  return (
    <Flex vertical gap={8}>
      <Flex justify="space-between" align="center" wrap="wrap">
        <Flex align="center" gap={10} wrap="wrap">
          <TitleText>{title}</TitleText>: <SocialButton type={tagContent} />
        </Flex>
        {status && (
          <Flex gap={4}>
            <TextStatus>Status :</TextStatus>
            {ButtonStatus(status, typeStatus)}
          </Flex>
        )}
      </Flex>
      <Flex justify="space-between" align="end" wrap="wrap" gap={15}>
        <ProfileCard roleUser={roleUser} label={name} img={imgProfile} />
        {showSubmissionsDate && name && (
          <DateText>
            Submissions Date : {dayjs(date).format("YYYY-MM-DD HH:mm")}
          </DateText>
        )}
      </Flex>
    </Flex>
  );
};

export default HeaderWindowDetail;
