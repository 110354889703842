/* eslint-disable class-methods-use-this */
import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const cardProvider = () => {
  const { axios } = useAxios();

  const getSocialCards = async (params) => {
    try {
      const url = `${host}/news/getNews${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get Social Card", error);
      return [];
    }
  };

  const setPinedSocialCards = async (id) => {
    try {
      const url = `${host}/news/pin/${id}`;
      const res = await axios.post(url);
      return res.data;
    } catch (error) {
      console.log("Error on set Pinned", error);
      return [];
    }
  };

  const setWarningSocialCards = async (id) => {
    try {
      const url = `${host}/news/warning/${id}`;
      const res = await axios.post(url);
      return res.data;
    } catch (error) {
      console.log("Error on set Warning", error);
      return [];
    }
  };

  const setBombSocialCards = async (id) => {
    try {
      const url = `${host}/bomb/${id}`;
      const res = await axios.post(url);
      return res.data;
    } catch (error) {
      console.log("Error on set Bomb", error);
      return [];
    }
  };

  const setBoostSocialCards = async (id) => {
    try {
      const url = `${host}/boost/${id}`;
      const res = await axios.post(url);
      return res.data;
    } catch (error) {
      console.log("Error on set Boost", error);
      return [];
    }
  };

  const stopBoostSocialCards = async (id) => {
    try {
      const url = `${host}/news/stop-boost/${id}`;
      const res = await axios.post(url);
      return res.data;
    } catch (error) {
      console.log("Error on set Boost", error);
      return [];
    }
  };

  return {
    getSocialCards,
    setWarningSocialCards,
    setPinedSocialCards,
    setBombSocialCards,
    setBoostSocialCards,
    stopBoostSocialCards,
  };
};

export default cardProvider;
