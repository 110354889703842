/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Flex, Image, Typography } from "antd";
// import CardStatusMission from "../../missions/CardShowStatus";
// import { StyledButtonStatus } from "../../missions/MyPostsList";
import { keysIcons, statusContent } from "../../../common/utils/keyDatas";
import IconClock from "../../../assets/icons/icon-clock.svg";
import IconCreated from "../../../assets/icons/icon-created.svg";
import GroupSocial from "../../../common/components/GroupSocial";

// const { Text } = Typography;

const StyledContentContainer = styled.div`
  font-family: "Sarabun";
`;

const StyleCardMissionDetail = styled(Flex)`
  border-radius: 10px;
  border: 1px solid ${(props) => props?.theme?.dividerLine};
  background: ${(props) => props?.theme?.white};
  padding: 16px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 1024px) {
    /* flex-direction: column; */
  }
`;

const StyleContent = styled(Flex)`
  width: 100%;
  min-height: 80px;
  flex-wrap: wrap;
`;

const StyleTextTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colorTextButton};
`;

const StyleTextContent = styled.a`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextButton} !important;
  display: -webkit-box;
  overflow: hidden;
  /* line-clamp: 2;
  -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-decoration: none;
  line-height: 22px;
  cursor: default;
  padding: 3px 2px;
  line-height: 29px;

  ${(props) =>
    props?.href &&
    `
  text-decoration: underline !important;
  cursor: pointer;
  `}
`;

const StyleDate = styled(Flex)`
  font-size: 12px !important;

  p {
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  span {
    color: ${(props) => props?.theme?.colorTextButton};
  }
`;

const StyleDesc = styled.span`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextDesc};
`;

const StyleTextRequirment = styled.span`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextButton};
  display: -webkit-box;
  overflow-y: auto;
  height: 86px;
  padding: 3px 2px;
  line-height: 29px;
`;

const ContentJobDetail = (props) => {
  const {
    dataItem = {
      type: "",
      href: "",
      content: "",
      startDate: "",
      endDate: "",
      createdDate: "",
      status: "",
      platform: "",
      typeStatus: statusContent,
      copywriterCount: {},
      influencerCount: {},
      newsTitle: "",
    },
    dataItemRequirement = "",
  } = props;

  const {
    type,
    href,
    content,
    startDate,
    endDate,
    createdDate,
    status,
    platform,
    typeStatus,
    copywriterCount,
    influencerCount,
    newsTitle,
  } = dataItem;

  return (
    <StyledContentContainer>
      <Flex
        vertical
        gap={12}
        style={{
          marginTop: "16px",
        }}
      >
        <StyleTextTitle>Mission</StyleTextTitle>

        {content && (
          <StyleCardMissionDetail gap="8px">
            {/* <GroupSocial
              list={[platform]}
              paddingLeft="8px"
              paddingRight="8px"
            /> */}
            {platform && (
              <Image
                src={keysIcons[platform]}
                height={24}
                preview={false}
                style={{
                  width: "24px",
                }}
              />
            )}

            <StyleContent justify="space-between" vertical>
              <StyleTextContent href={href}>
                {type === "reply" ? newsTitle : content}
              </StyleTextContent>
              <Flex
                direction="row"
                wrap="wrap"
                gap={10}
                style={{
                  marginTop: "16px",
                }}
                justify="end"
              >
                <Flex
                  direction="row"
                  gap={10}
                  align="end"
                  // justify="end"
                  wrap="wrap"
                >
                  {startDate && endDate && (
                    <StyleDate align="center" gap="4px">
                      <img src={IconClock} alt="icon-clock" />
                      <p>Work on : </p>
                      <span>
                        {moment(startDate).format("YYYY-MM-DD HH:mm")} to{" "}
                        {moment(endDate).format("YYYY-MM-DD HH:mm")}
                      </span>
                    </StyleDate>
                  )}
                </Flex>
              </Flex>
            </StyleContent>
          </StyleCardMissionDetail>
        )}
        {dataItemRequirement && (
          <Flex vertical gap={8}>
            <StyleDesc>Requirement : </StyleDesc>
            <StyleTextRequirment>{dataItemRequirement}</StyleTextRequirment>
          </Flex>
        )}
      </Flex>
    </StyledContentContainer>
  );
};

export default ContentJobDetail;
