import { useState, useEffect } from "react";

const useAuthenticate = (accessToken) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // const user = localStorage.getItem("user");
    if (accessToken) {
      setIsAuthenticated(true);
    }
  }, []);

  return isAuthenticated;
};

const useRoles = () => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const user = JSON.stringify({
      roles: ["user", "writer", "influencer", "superadmin"],
    });
    if (user) {
      const { roles: userRole } = JSON.parse(user);
      setRoles(userRole);
    }
  }, []);

  return roles;
};

const getAllowedRoles = (route) => {
  const allowedRoles = {
    "/login": ["admin", "user", "influencer", "writer"],
    "/": ["admin", "user", "influencer", "writer"],
    "/dashboard": ["user"],
    "/list-trend": ["admin", "user"],
    "/add/trend": ["admin", "user"],
    "/edit/trend": ["admin", "user"],
    "/manage-campaigns": ["admin", "user"],
    "/mainpage": ["admin", "user"],
    "/update-trend": ["admin", "user"],
    "/news-archive": ["admin"],
    "/campaign-dashboard": ["admin", "user"],
    "/my-engagements": ["user"],
    "/my-engagements/detail/:id": ["user", "influencer"],
    "/my-engagements/detail/:role/:id": ["user", "influencer"],
    "/my-engagements/:type/detail/:id": ["user", "influencer"],
    "/my-engagements/:id": ["user"],
    "/my-posts": ["user"],
    "/my-posts/:id": ["user"],
    "/post-detail/:id": ["user"],
    "/post-detail/influencer/:id": ["user"],
    "/create-mission": ["admin", "user"],
    "/create-mission/:id": ["admin", "user"],
    "/create-engagement/:social/:id": ["admin", "user"],
    "/create-engagement/:social/:id/:mission": ["admin", "user"],
    "/create-social": ["writer", "influencer"],
    "/create-social/:socialId": ["writer", "influencer"],
    "/submit-engagement": ["influencer"],
    "/job-engagement": ["influencer"],
    "/job-content": ["influencer"],
    "/submit-content/:contentId": ["influencer"],
    "/mission-editor": ["writer", "influencer"],
    "/mission-detail/:id/:type": ["writer", "influencer"],
    "/submission": ["writer", "influencer"],
    "/submissions-editor": ["writer", "influencer"],
    "/social-media": ["admin", "writer", "influencer"],
    "/social-media-review": ["admin"],
    "/social-media-review/detail/:requestId/:method": ["superadmin", "admin"],
    "/social-media-verified": ["admin"],
    "/social-media-bot": ["superadmin"],
    "/submission-owner": ["user"],
    "/submission-detail/:id": ["user"],
  };
  return allowedRoles[route] ? allowedRoles[route] : [];
};

const useCanAccess = (route, roles) => {
  try {
    const allowedRoles = getAllowedRoles(route);
    // console.log({
    //   route,
    //   roles,
    //   allowedRoles,
    //   allow: roles.some((role) => allowedRoles.includes(role)),
    // });
    return roles.some((role) => allowedRoles.includes(role));
  } catch (error) {
    return null;
  }
};

export { useAuthenticate, useRoles, useCanAccess };
