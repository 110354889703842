/* eslint-disable no-unused-vars */
import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const socialMediaProvider = () => {
  const { axios } = useAxios();

  const getListSocialAll = async (param) => {
    try {
      const url = `${host}/social-accounts`;
      const res = await axios.get(url, {
        params: {
          platform: [
            "facebook",
            "twitter",
            "instagram",
            "tiktok",
            "pantip",
            "internet",
            "youtube",
          ],
          order_by: param.order_by,
          limit: param.limit,
          page: param.page,
        },
      });
      return res.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  const getSocialById = async (id) => {
    try {
      const url = `${host}/social-accounts/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  // eslint-disable-next-line consistent-return
  const createSocial = async (body) => {
    try {
      const url = `${host}/social-accounts`;
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      if (error.code === "ER_BAD_FIELD_ERROR") {
        throw error;
      }
      throw error;
      // return error;
    }
  };

  const editSocial = async (id, body) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host}/social-accounts/${id}`;
      const res = await axios.patch(url, body);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  const deleteSocial = async (id) => {
    try {
      const url = `${host}/social-accounts/${id}`;
      const res = await axios.delete(url);

      return res.data;
    } catch (error) {
      return error;
    }
  };

  return {
    getListSocialAll,
    getSocialById,
    createSocial,
    editSocial,
    deleteSocial,
  };
};

export default socialMediaProvider;
