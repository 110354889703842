import React from "react";
import styled from "styled-components";
import { Flex } from "antd";

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
`;

const ProgressIndicator = styled.div`
  height: 100%;
  width: ${(props) => props.progress}%;
  background-color: ${(prors) => prors.backgroundColor};
  border-radius: 10px;
  transition: width 0.3s ease-in-out;
`;

const ProgressBar = ({ progress, type }) => {
  return (
    <Flex
      horizontal
      style={{
        alignItems: "center",
      }}
      gap={16}
    >
      <ProgressBarContainer>
        <ProgressIndicator
          progress={progress}
          backgroundColor={type === "negative" ? "#FF2643" : "#0EA67F"}
        />
      </ProgressBarContainer>{" "}
      <span>{progress}%</span>
    </Flex>
  );
};

export default ProgressBar;
