import React from "react";
// import {
//   optionSortDefaultList,
//   optionSortDefaultSelect,
//   socialDefault,
// } from "../common/utils/filterDatas";
// import FilterData from "../common/components/FilterData";
import SuperAdminJobVerifiedList from "../features/socialMediaBot";
import MainLayout from "../common/layouts/main";

const SocialMediaBot = () => {
  return (
    <MainLayout>
      {/* <FilterData
        title="Jobs"
        labelDetail={`Total ${1} ${true ? "Items" : "Item"}`}
        labelSort="Sorting :"
        onClickButton={() => alert("clicked")}
        disabledButton={false}
        filter={{
          social: socialDefault,
          optionSortList: optionSortDefaultList,
          optionSortSelect: optionSortDefaultSelect,
        }}
      /> */}
      <SuperAdminJobVerifiedList />
    </MainLayout>
  );
};

export default SocialMediaBot;
