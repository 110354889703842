import { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ThemeStyledSelect } from "../ThemeStyledComponent";

const SelectComponent = ({ value, onChange, options, style = {} }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownVisibleChange = (open) => {
    setDropdownOpen(open);
  };
  return (
    <div>
      <ThemeStyledSelect
        value={value}
        onChange={onChange}
        options={options}
        style={style}
        showSearch
        onDropdownVisibleChange={handleDropdownVisibleChange}
        suffixIcon={dropdownOpen ? <UpOutlined /> : <DownOutlined />}
      />
    </div>
  );
};

export default SelectComponent;
