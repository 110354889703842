/* eslint-disable prettier/prettier */
const gen = [
  {
    label: "B",
    value: "Baby",
  },
  {
    label: "X",
    value: "X",
  },
  {
    label: "Y",
    value: "Y",
  },
  {
    label: "Z",
    value: "Z",
  },
  {
    label: "Alpha",
    value: "Alpha",
  },
];

const gender = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

const category = [
  {
    label: "Beauty",
    value: 1,
  },
  {
    label: "Fashion",
    value: 2,
  },
  {
    label: "Photographer",
    value: 3,
  },
  {
    label: "Blogger Vlogger",
    value: 4,
  },
  {
    label: "Food beverage",
    value: 5,
  },
  {
    label: "Sports fitness",
    value: 6,
  },
  {
    label: "Entertainment",
    value: 7,
  },
  {
    label: "Gamer",
    value: 8,
  },
  {
    label: "Technology",
    value: 9,
  },
  {
    label: "Environment",
    value: 10,
  },
  {
    label: "Parenting",
    value: 11,
  },
  {
    label: "Travel",
    value: 12,
  },
];

const target = [
  {
    label: "Reply",
    name: "replies",
    value: "replies",
  },
  {
    label: "Like",
    name: "likes",
    value: "likes",
  },
  {
    label: "Share",
    name: "shares",
    value: "shares",
  },
  {
    label: "Quote",
    name: "quotes",
    value: "quotes",
  },
];

const targetCheckBox = [
  {
    label: "Reply",
    name: "replies",
    value: "Reply",
  },
  {
    label: "Like",
    name: "likes",
    value: "Like",
  },
  {
    label: "Share",
    name: "shares",
    value: "Share",
  },
  {
    label: "Quote",
    name: "quotes",
    value: "Quote",
  },
];

const typeInfluencer = [
  {
    label: "Basic",
    value: "organic",
  },
  {
    label: "WEB Cookie",
    value: "web_cookie",
  },
  {
    label: "API",
    value: "api",
  },
];

const typeMission = {
  like: "likes",
  reply: "replies",
  share: "shares",
  share_quote: "quotes",
};

export {gen,
  gender,
  category,
  target,
  targetCheckBox,
  typeInfluencer,
  typeMission,};
