import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenModalError: false,
  title: "",
  desc: "",
};

export const modalError = createSlice({
  name: "modalError",
  initialState,
  reducers: {
    setIsOpenModalError: (state, action) => {
      state.isOpenModalError = action.payload.value;
    },
    setTitleModalError: (state, action) => {
      state.title = action.payload.value;
    },
    setDescModalError: (state, action) => {
      state.desc = action.payload.value;
    },
  },
});

export const { setIsOpenModalError, setTitleModalError, setDescModalError } =
  modalError.actions;

export default modalError.reducer;
