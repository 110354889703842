import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import Cookies from "js-cookie";
import { ThemeStyledInput } from "../features/keyword/styled";
import MainLayout from "../common/layouts/main";
import { useAuth } from "../common/hooks/useProviderAuth";
import AuthProvider from "../features/login/provider/authProvider";

// const username = "admin";
// const password = "adminpass";

const StyledLoginPage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  background: ${(props) => props.theme.backgroundDashBoard};
  height: calc(100dvh - ${(props) => props.theme.navbarHeight} - 8px);

  #login-form {
    width: 40%;
    padding: 16px;
    background: ${(props) => props.theme.white};
    border-radius: 10px;
  }

  @media (max-width: 430px) {
    #login-form {
      width: 90%;
    }
  }
`;

export const ThemeStyledInputPassword = styled(Input.Password)`
  &:hover,
  &:focus-within {
    border: 1px solid ${(props) => props.theme.colorcheckbox} !important;
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1) !important;
  }
`;

function Login() {
  const { signin, user, accessToken } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const authProvider = AuthProvider();

  useEffect(() => {
    const isLogin = accessToken;
    // console.log("--roles--", user.role);
    if (isLogin) navigate(user.role === "admin" ? "/list-trend" : "/dashboard");
  }, []);

  const onFinish = async (values) => {
    try {
      if (values.username.trim() && values.password.trim()) {
        const body = {
          username: values.username.toLowerCase().trim().replace(/ /g, ""),
          password: values.password.trim().replace(/ /g, ""),
        };
        const response = await authProvider.userLogin(body);
        signin(
          {
            token: response.access.token,
            refresh: response.refresh.token,
            userInfo: response.user,
          },
          () =>
            navigate(
              response.user.username === "admin" ? "/list-trend" : "/dashboard",
            ),
        );
      } else {
        form.setFields([
          {
            name: "username",
            errors: [""],
          },
          {
            name: "password",
            errors: ["username or password are incorrect"],
          },
        ]);
      }
    } catch (error) {
      console.log("Error Login :>> ", error?.data?.code);
      if (error?.data?.code === 401) {
        form.setFields([
          {
            name: "username",
            errors: [""],
          },
          {
            name: "password",
            errors: ["username or password are incorrect"],
          },
        ]);
      }
    }
  };

  // const onFinishFailed = () => {
  //   form.setFields([
  //     {
  //       name: "username",
  //       errors: ["please fill username"],
  //     },
  //     {
  //       name: "password",
  //       errors: ["please fill password"],
  //     },
  //   ]);
  // };

  return (
    <MainLayout>
      <StyledLoginPage>
        <div id="login-form">
          <h2>Login</h2>

          <Form
            name="basic"
            form={form}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            // initialValues={{
            //   remember: true,
            // }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <ThemeStyledInput />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <ThemeStyledInputPassword />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </StyledLoginPage>
    </MainLayout>
  );
}

export default Login;
