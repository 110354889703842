/* eslint-disable no-unused-vars */
import { Flex } from "antd";
import { useState } from "react";
import { ThemeStyledInput } from "../styled";

const EditKeyword = ({ editValue, setEditValue }) => {
  const onChangeEdit = (val) => {
    setEditValue({
      id: editValue.id,
      account_id: editValue.account_id,
      name: val.target.value,
      status: editValue.status,
    });
  };
  return (
    <Flex
      style={{
        height: "200px",
      }}
      justify="center"
      align="center"
      vertical
    >
      <div
        style={{
          alignSelf: "flex-start",
        }}
      >
        <span
          style={{
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Campaign Name
        </span>
        <span
          style={{
            fontSize: "12px",
          }}
        >
          {" "}
          (Limit 500 Characters)
        </span>
      </div>
      <ThemeStyledInput
        placeholder="Please fill in"
        value={editValue.name}
        onChange={onChangeEdit}
      />
    </Flex>
  );
};

export default EditKeyword;
