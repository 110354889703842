import { useAuth } from "../hooks/useProviderAuth";

const isRoleExist = (a, b) => {
  return a.filter((item) =>
    b?.some((role) => item.role.includes(role) && !b.includes(item.notShowFor)),
  );
};

const isRoleSuperAdmin = () => {
  const { user } = useAuth();
  if (user?.roles?.includes("superadmin")) {
    return true;
  }
  return false;
};

const myNewWindow = (url, id) => {
  const windowWidth = 800;
  const windowHeight = 688;
  const leftPosition = window.screen.width / 2 - (windowWidth / 2 + 10);
  const topPosition = window.screen.height / 2 - (windowHeight / 2 + 50);
  return window.open(
    url,
    id,
    `height=${windowHeight},width=${windowWidth},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition}`,
  );
};

// eslint-disable-next-line import/prefer-default-export
export { isRoleExist, isRoleSuperAdmin, myNewWindow };
