// eslint-disable-next-line object-curly-newline
import { Flex, Form, Input, Radio, Button, Divider, Modal } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { isRoleSuperAdmin } from "../../common/utils/helper";
import WindowComponent from "../../common/components/WindowComponent";
import HeaderWindowDetail from "../flowJob/HeaderWindowDetail";
import IconSocial from "../../common/components/IconSocial";
import GroupRadioSocial from "../../common/components/GroupRadioSocial";
import ContentJobDetail from "../../common/components/ContentJobDetail";
import SubmitEngagementProvider from "./provider/submitEngagementProvider";
import { useAuth } from "../../common/hooks/useProviderAuth";
import { IconWarning } from "../missions/MyPostsList";

const StyleWindow = styled.div`
  padding: 16px 32px;
  width: 100%;
  // height: calc(800px - 180px);
  // overflow-y: auto;
  padding-bottom: 100px;

  .job {
    .ant-form-item-explain-error {
      padding-left: 40px;
    }
  }

  .ant-form-item-label > label {
    font-size: 16px !important;
    font-weight: 700 !important;
    font-family: Sarabun !important;
  }

  .ant-radio-group {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const StyleButton = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: red;
  width: 100%;
  padding: 16px;
  border-top: 1px solid ${(props) => props?.theme?.actionBorder};
  background-color: ${(props) => props?.theme?.white};

  .ant-btn-cancel {
    padding: 6px 16px;
    height: 100%;
    width: 180px;
    font-size: 16px;
    font-weight: 700;
    /* color: ${(props) => props?.theme?.colorTextDesc}; */
  }

  .ant-btn-primary {
    font-size: 16px;
    font-weight: 700;
    /* color: ${(props) => props?.theme?.white}; */
    padding: 6px 16px;
    height: 100%;
    width: 180px;
  }
`;

const FeatureSubmitEngagement = () => {
  const [form] = Form.useForm();
  const submissionWatch = Form.useWatch("submission", form);
  const [missionDetail, setMissionDetail] = useState({});
  const [socailAccounts, setSocailAccounts] = useState([]);
  const { user } = useAuth();
  const [modal, contextHolder] = Modal.useModal();
  if (!user) {
    return null;
  }

  const engagementProvider = SubmitEngagementProvider();

  const submissionList = [
    {
      label: "Like",
      value: "like",
    },
    {
      label: "Share",
      value: "share",
    },
    {
      label: "Quote",
      value: "share_quote",
    },
  ];

  const { missionId = 8, missionType = "post" } = queryString.parse(
    window.location.search,
  );

  const initialForm = {
    social: "",
    name: "Moopink",
    submission: "",
    jobURL: "",
  };

  const closeWindow = () => {
    window.close();
  };

  const modalErrorDetails = {
    expired: {
      title: "Your session is about to expire!",
      subTitle: "",
    },
    noJobAvailable: {
      title: "No job available.",
      subTitle: "",
    },
    errorOccurred: {
      title: "An error has occurred.",
      subTitle: "Please try again.",
    },
  };

  const modalError = (param) => {
    const { title, subTitle, onOk = () => closeWindow() } = param;
    modal.info({
      title,
      subTitle,
      icon: <IconWarning />,
      content: "",
      centered: true,
      okText: "OK",
      onOk,
    });
  };

  const getMissionDetail = async () => {
    try {
      const res = await engagementProvider.getMissionContentDetail(missionId);
      if (res) {
        setMissionDetail(res);
        form.setFieldsValue({
          submission: missionType,
          social: res?.platforms[0],
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  /* param(Object) : {platform: "facebook"} */
  const getSocailAccount = async (param) => {
    try {
      const res = await engagementProvider.getSocailAccount(param);
      if (res) {
        const socialAccountApproved = res?.filter(
          (_) => _?.approved_date !== null,
        );
        setSocailAccounts(socialAccountApproved);
        form.setFieldsValue({
          name: socialAccountApproved[0]?.id,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (missionDetail && missionDetail?.platforms) {
      getSocailAccount({
        platform: missionDetail?.platforms ? [missionDetail?.platforms[0]] : "",
        missionId,
      });
    }
  }, [missionDetail]);

  useEffect(() => {
    getMissionDetail();
  }, [missionId]);

  const handleSubmit = async () => {
    try {
      const values = form.getFieldsValue();
      const mission = missionType === "quote" ? "share_quote" : missionType;
      const payload = {
        post_url: values?.jobURL,
        type: mission,
        social_account_id: values?.name,
      };

      await engagementProvider.submitEngagement(missionDetail?.id, payload);
      window.close();
    } catch (err) {
      const { code, message } = err?.data || {};
      if (code === 403) {
        if (message === "Social account does not exist") {
          modalError(modalErrorDetails.errorOccurred);
        } else if (message === "Content not found") {
          modalError(modalErrorDetails.noJobAvailable);
        } else if (message === "Incorrect mission") {
          modalError(modalErrorDetails.noJobAvailable);
        }
      } else if (code === 422) {
        if (message === "Mission expired") {
          modalError(modalErrorDetails.expired);
        }
      } else {
        modalError(modalErrorDetails.errorOccurred);
      }
      throw err;
    }
  };

  const onFinish = () => {
    modal.confirm({
      title: "Are you sure you want to do this?",
      content: "Would you like to confirm?",
      icon: <IconWarning />,
      centered: true,
      okText: "Confirm",
      onOk: () => handleSubmit(),
      cancelText: "Cancel",
      onCancel: () => {},
    });
  };

  const getUserRole = () => {
    if (user?.roles) {
      if (user?.roles.includes("influencer")) return "influencer";
      if (user?.roles.includes("writer") && !user?.roles.includes("influencer"))
        return "writer";
    }
    return "writer";
  };

  return (
    <WindowComponent>
      {Object.keys(user).length ? (
        <StyleWindow>
          <HeaderWindowDetail
            roleUser={getUserRole()}
            name={user?.username}
            tagContent={missionType}
            showSubmissionsDate={false}
          />

          <Divider />
          <ContentJobDetail
            dataItem={{
              type: missionDetail?.platforms ? missionDetail?.platforms[0] : "",
              href: missionDetail?.url,
              content: missionDetail?.news_title,
              startDate: missionDetail?.start_date,
              endDate: missionDetail?.end_date,
            }}
            dataItemRequirement={missionDetail?.remark}
          />

          <Form
            layout="vertical"
            initialValues={initialForm}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item name="social" label="Social media">
              <GroupRadioSocial
                active={
                  missionDetail?.platforms ? missionDetail?.platforms[0] : ""
                }
              />
            </Form.Item>

            <Form.Item name="submission" label="Type">
              <Radio.Group>
                {submissionList.map((item) => (
                  <Radio
                    key={item?.label}
                    value={item?.value}
                    disabled={item?.value !== submissionWatch}
                  >
                    {item?.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {socailAccounts && socailAccounts.length > 0 && (
              <Form.Item name="name" label="Name">
                <Radio.Group>
                  {socailAccounts &&
                    socailAccounts.map((item) => (
                      <Radio key={item?.name} value={item?.id}>
                        {item?.name}
                      </Radio>
                    ))}
                </Radio.Group>
              </Form.Item>
            )}

            {!isRoleSuperAdmin() && (
              <Form.Item
                className="job"
                name="jobURL"
                label="Job URL"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Job URL",
                  },
                  {
                    validator: (_, value) =>
                      value.trim()
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please enter a Job URL")),
                  },
                ]}
              >
                <Flex gap={16}>
                  <IconSocial
                    type={
                      missionDetail?.platforms
                        ? missionDetail?.platforms[0]
                        : ""
                    }
                  />
                  <Input.TextArea
                    placeholder="Link URL"
                    autoSize={{
                      minRows: 2,
                      maxRows: 6,
                    }}
                  />
                </Flex>
              </Form.Item>
            )}

            {!isRoleSuperAdmin() && (
              <StyleButton align="center" justify="center" gap={16}>
                <Button type="cancel" onClick={() => window.close()}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Confirm
                </Button>
              </StyleButton>
            )}
          </Form>

          {contextHolder}
        </StyleWindow>
      ) : null}
    </WindowComponent>
  );
};

export default FeatureSubmitEngagement;
