import { Button, Space } from "antd";
import styled from "styled-components";

const ButtonIcon = ({
  size = 50,
  icon = null,
  label = null,
  primary = true,
  type = "primary",
  onClick = () => null,
}) => {
  const StyledButtonIcon = styled(Button)`
    height: "fit-content";
    width: "fit-content";
    display: "flex";
    flex-direction: "column";
    justify-content: "center";
    align-items: "center";
    gap: "4px";

    .ant-btn-icon {
      margin: 0 !important;
      margin-inline-end: 0 !important;
    }
  `;

  return (
    <Space wrap>
      <StyledButtonIcon
        onClick={onClick}
        type={type}
        icon={icon}
        size={size}
        primary={primary}
      >
        {label}
      </StyledButtonIcon>
    </Space>
  );
};

export default ButtonIcon;
