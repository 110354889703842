/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Flex, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import SubMenu from "antd/es/menu/SubMenu";
import { IconProvider } from "@ant-design/icons";
import { isRoleExist } from "../../utils/helper";
import { setDefaultFilterData } from "../../../features/filterData/filterDataSlice";
import { useAuth } from "../../hooks/useProviderAuth";
import { setKeySidebar, setSubMenuKeySidebar } from "./siderSlice";
// import IconDashBoard from "../../../assets/icons/icon-dashboard-black.svg";
import { ReactComponent as IconCopyWriter } from "../../../assets/icons/icon-copy-writer.svg";
import { ReactComponent as IconNewsArchive } from "../../../assets/icons/icon-news-archive-black.svg";
import { ReactComponent as IconLatestNews } from "../../../assets/icons/icon-latest-news-black.svg";
import { ReactComponent as IconLatestTrend } from "../../../assets/icons/icon-latest-trend-black.svg";
import { ReactComponent as IconDashBoardWhite } from "../../../assets/icons/icon-dashboard-white.svg";
import { ReactComponent as IconNewsArchiveWhite } from "../../../assets/icons/icon-news-archive-white.svg";
import { ReactComponent as IconLatestNewsWhite } from "../../../assets/icons/icon-latest-news-white.svg";
import { ReactComponent as IconLatestTrendWhite } from "../../../assets/icons/icon-latest-trend-white.svg";
import { ReactComponent as IconDashboard } from "../../../assets/icons/icon-dashboard.svg";
import { ReactComponent as IconCampaigns } from "../../../assets/icons/icon-campaigns.svg";
import { ReactComponent as IconSubmissions } from "../../../assets/icons/icon-submissions.svg";
import { ReactComponent as IconLogout } from "../../../assets/icons/icon-logout.svg";
import { ReactComponent as IconInfluencer } from "../../../assets/icons/icon-influencer.svg";
import { ReactComponent as IconSocial } from "../../../assets/icons/icon-social.svg";
import { ReactComponent as IconMission } from "../../../assets/icons/icon-mission.svg";
import IconCopyWriter1 from "../../../assets/icons/icon-copywriter1.svg";
import IconCopyWriter2 from "../../../assets/icons/icon-copywriter2.svg";
import IconAdmin from "../../../assets/icons/icon-admin.svg";
import IconSuperAdmin from "../../../assets/icons/icon-superadmin.svg";

const MenuCustom = styled(Menu)`
  .hQndFI
    .ant-menu-root
    .ant-menu-submenu.ant-menu-submenu-open
    .ant-menu-submenu-title {
    border-bottom: 1px solid red;
  }
  .ant-menu-title-content {
    display: flex;
    align-items: center;
  }
  ul {
    li {
      .ant-menu-title-content {
        display: flex;
        align-items: center;
        padding: 28px;
        color: ${(props) => props.theme.colorTextButton};
        /* color: red; */
      }
    }
  }
  .active-menu {
    path {
      fill: white;
    }
    g {
      path {
        fill: white;
      }
    }
  }
`;

const SiderCustom = styled(Sider)`
  display: ${(props) => !props.isAccess && "none !important"};
  background-color: ${(props) => props.theme.white} !important;
`;

const MenuItem = styled(Menu.Item)`
  background-color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.selected
      ? props.theme.colorMagenta
      : props.disabled
        ? props.theme.disableSideBar
        : "transparent"};

  color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.selected
      ? props.theme.white
      : props.disabled
        ? props.theme.disableSideBar
        : "transparent"};

  &.ant-menu-item-disabled {
    background-color: ${(props) => props.theme.backgroundFilter} !important;
    cursor: default;
    color: ${(props) => props.theme.colortitletable} !important;

    &:hover {
      background-color: ${(props) => props.theme.backgroundFilter} !important;
    }
  }
`;

const SidebarEleCustom = styled.li`
  padding: 5px;
  border-bottom: 1px solid ${(props) => props.theme.actionBorder};
`;

const BackgroundRole = styled.div`
  position: relative;
  padding: 20px;
  height: 70px;
  background: rgb(36, 0, 0);
  background: linear-gradient(
    266deg,
    rgba(10, 0, 0, 0) 0%,
    rgba(215, 205, 248, 1) 50%
  );

  .icon-copy-1 {
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: 10;
    /* margin-top: auto; */
  }
  .icon-copy-2 {
    position: absolute;
    bottom: 0;
    right: 40px;
    z-index: 9;
  }
  .icon-admin {
    position: absolute;
    bottom: 0px;
    right: 10px;
    z-index: 0;
    width: 89px;
  }
  .icon-super-admin {
    position: absolute;
    bottom: 0px;
    right: 10px;
    z-index: 0;
    width: 115px;
  }
  p {
    color: ${(props) => props.theme.colorTertiary};
    -webkit-text-stroke-width: 1.5;
    -webkit-text-stroke-color: ${(props) => props.backgroundFilter};
    font-family: Sarabun;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin-top: auto;
  }
`;

const SubMenuCustom = styled(SubMenu)`
  .ant-menu-submenu-arrow {
    &::after {
      transform: ${(props) =>
        props.subMenuKey !== props.openSubMenus &&
        `rotate(-45deg) translateX(2.5px) !important`};
    }
    &::before {
      transform: ${(props) =>
        props.subMenuKey !== props.openSubMenus &&
        `rotate(45deg) translateX(-2.5px) !important`};
    }
  }

  &.ant-menu-submenu-inline
    > .ant-menu-submenu-title
    > .ant-menu-submenu-arrow {
    transform: translateY(0px) !important;
  }
`;

const Sidebar = ({ isCollapse }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signout, accessToken, user } = useAuth();
  const isAccess = accessToken !== undefined;
  const { key = "2", subMenuKey: subMenuSelected } = useSelector(
    (state) => state.sidebar,
  );
  const [openSubMenus, setOpenSubMenus] = useState(null);

  const handleSubMenuOpen = (subKey) => {
    setOpenSubMenus(subKey);
  };

  const handleSubMenuClose = (subKey) => {
    setOpenSubMenus(null);
  };
  const rolesUser = user?.roles; // writer,influencer,admin,user,superadmin
  // const rolesUser = ["user"]; // writer,influencer,admin,user,superadmin
  const roleSideBar = {
    // editor: (
    //   <Flex align="center">
    //     <p>Owner</p>
    //     <img src={IconCopyWriter1} alt="" className="icon-copy-1" />
    //     <img src={IconCopyWriter2} alt="" className="icon-copy-2" />
    //   </Flex>
    // ),
    influencer: (
      <Flex align="center">
        <p>
          Copywriter & <br />
          Influencer
        </p>
        <img src={IconCopyWriter1} alt="" className="icon-copy-1" />
        <img src={IconCopyWriter2} alt="" className="icon-copy-2" />
      </Flex>
    ),
    writer: (
      <Flex align="center">
        <p>Copywriter</p>
        {/* <img src={IconCopyWriter1} alt="" className="icon-copy-1" /> */}
        <img src={IconCopyWriter2} alt="" className="icon-copy-2" />
      </Flex>
    ),
    admin: (
      <Flex align="center">
        <p>Administrator</p>
        <img src={IconAdmin} alt="" className="icon-admin" />
      </Flex>
    ),
    superadmin: (
      <Flex align="center">
        <p>Super Admin</p>
        <img src={IconSuperAdmin} alt="" className="icon-super-admin" />
      </Flex>
    ),
  };
  const menuList = [
    {
      key: "copywriter",
      icon: (
        <IconCopyWriter
          className={key === "copy-writer" ? "active-menu" : ""}
        />
      ),
      label: "Copywriter",
      page: "/copywriter",
      role: ["influencer", "writer"],
      children: [
        {
          key: "mission-editor",
          label: "Mission",
          page: "/mission-editor",
          role: ["influencer", "writer"],
        },
        {
          key: "submissions-editor",
          label: "Submission",
          page: "/submissions-editor",
          role: ["influencer", "writer"],
        },
      ],
    },
    {
      key: "influencer",
      icon: <IconInfluencer />,
      label: "Influencer",
      page: "/post",
      role: ["influencer"],
      children: [
        {
          key: "job-content",
          label: "Post / Reply",
          page: "/job-content",
          role: ["influencer"],
        },
        {
          key: "job-engagement",
          label: "Like / Share / Quote",
          page: "/job-engagement",
          role: ["influencer"],
        },
      ],
    },
    {
      key: "social-media",
      icon: <IconSocial />,
      label: "Social Media",
      page: "/social-media",
      role: ["admin"],
      children: [
        {
          key: "social-media-review",
          label: "In Review",
          page: "/social-media-review",
          role: ["admin"],
        },
        {
          key: "social-media-verified",
          label: "Verified",
          page: "/social-media-verified",
          role: ["admin"],
        },
      ],
    },
    {
      key: "social-media-superadmin",
      icon: <IconSocial />,
      label: "My Social Media",
      page: "/social-media",
      role: ["superadmin"],
      children: [
        {
          key: "social-media-bot",
          label: "BOT",
          page: "/social-media-bot",
          role: ["superadmin"],
        },
      ],
    },
    {
      key: "dashboard",
      icon: <IconDashboard />,
      label: "Dashboard",
      page: "/dashboard",
      role: ["user"],
    },
    {
      key: "manage-campaigns",
      icon: <IconCampaigns />,
      label: "Campaigns",
      page: "/manage-campaigns",
      role: [],
    },
    {
      key: "missions",
      icon: <IconMission />,
      label: "Missions",
      page: "/aa",
      role: ["user"],
      children: [
        {
          key: "my-posts",
          label: "My Posts",
          page: "/my-posts",
          role: ["user"],
        },
        {
          key: "my-engagements",
          label: "My Engagements",
          page: "/my-engagements",
          role: ["user"],
        },
      ],
    },
    {
      key: "submission-owner",
      icon: <IconSubmissions />,
      label: "Submissions",
      page: "/submission-owner",
      role: ["user"],
    },
    {
      key: "social-media",
      icon: <IconSocial />,
      label: "My Social Media",
      page: "/social-media",
      role: ["writer", "influencer"],
      notShowFor: "superadmin",
    },
    {
      key: "logout",
      icon: <IconLogout />,
      label: "Logout",
      page: "",
      role: ["user", "writer", "influencer", "admin", "superadmin"],
    },
  ];

  const handleLogout = () => {
    signout(() => navigate("/login"));
  };

  const handleSelectMenu = (newKey, path) => {
    if (path) {
      dispatch(
        setKeySidebar({
          value: newKey,
        }),
      );
      navigate(path);
    }
    if (newKey === "logout") {
      handleLogout();
    }
  };

  const handleClick = (e) => {
    if (e.key === "logout") {
      handleLogout();
    }
    dispatch(setDefaultFilterData());

    const allKeys = [];

    menuList.forEach((item) => {
      allKeys.push(item);
      if (item.children) {
        item.children.forEach((child) => {
          allKeys.push(child);
        });
      }
    });

    if (e.key) {
      dispatch(
        setKeySidebar({
          value: e.key,
        }),
      );
      const keyData = allKeys.find((item) => item.key === e.key);
      return navigate(keyData?.page);
    }
    return navigate("/");
  };

  // eslint-disable-next-line consistent-return
  const setSubMenu = (a, b, c, d) => {
    if (a.includes(undefined)) {
      return [];
    }
    dispatch(
      setSubMenuKeySidebar({
        value: a,
      }),
    );
  };

  const getActiveKey = (path) => {
    const paths = path.split("/");
    return [`${paths[1]}`]; // เลือกเฉพาะหลังจาก / ของ path ที่ใช้เป็น key ที่ active
  };

  useEffect(() => {
    const url = window.location.pathname;
    let defaultKey;
    let subMenuKey;
    menuList.forEach((item) => {
      if (item.page === url) {
        defaultKey = item.key;
      } else if (item.children) {
        item.children.forEach((child) => {
          if (child.page === url) {
            defaultKey = child.key;
            subMenuKey = item.key;
          }
        });
      }
    });

    dispatch(
      setKeySidebar({
        value: defaultKey,
      }),
    );
    if (subMenuKey !== undefined) {
      dispatch(
        setSubMenuKeySidebar({
          value: [subMenuKey],
        }),
      );
    }

    return () => {};
  }, []);

  return (
    <SiderCustom
      trigger={null}
      collapsible
      collapsed={isCollapse}
      collapsedWidth={0}
      isAccess={isAccess}
      width="240px"
    >
      {rolesUser?.length > 0 && rolesUser[0] !== "user" && (
        <BackgroundRole>
          {
            roleSideBar[
              // eslint-disable-next-line no-nested-ternary
              rolesUser?.length === 1
                ? rolesUser[0]
                : rolesUser?.includes("superadmin")
                  ? "superadmin"
                  : rolesUser?.includes("admin")
                    ? "admin"
                    : rolesUser?.includes("influencer")
                      ? "influencer"
                      : "writer"
            ]
          }
        </BackgroundRole>
      )}
      {/* <MenuCustom
        theme="light"
        mode="inline"
        selectedKeys={key}
        // defaultOpenKeys="Influencer"
        items={menuList.filter((item) => item.role.includes(rolesUser))}
      /> */}
      <div
        style={{
          padding: "5px",
        }}
      >
        <MenuCustom
          defaultSelectedKeys={getActiveKey(window.location.pathname)}
          defaultOpenKeys={subMenuSelected}
          openKeys={subMenuSelected || []}
          mode="inline"
          // theme="colorTextButton"
          key={key}
          // items={menuList.filter((item) =>
          //   rolesUser.some((role) => item.role.includes(role)),
          // )}
          items={isRoleExist(menuList, rolesUser)}
          onClick={(e) => handleClick(e)}
          onOpenChange={(a, b, c, d) => setSubMenu(a, b, c, d)}
          // inlineCollapsed={collapsed}
        />
      </div>
    </SiderCustom>
  );
};

export default Sidebar;
