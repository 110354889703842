/* eslint-disable no-unused-vars */
import React, { useState } from "react";

// eslint-disable-next-line object-curly-newline
import { Input, Button, message, Form, Radio, Flex } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import ModalConfirmComponent from "../../common/components/ModalComponent";
import { createTrends } from "../../controllers/trends";
import { keysIcons } from "../../common/utils/keyDatas";
import { setIsOpenInsertModal } from "./insertTrendSlice";

const { TextArea } = Input;

const StyledButton = styled(Button)`
  width: 180px;
  background-color: ${(props) => props.color};
`;

const LabelTrend = styled.span`
  color: #2a2c2d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

const options = [
  {
    value: "facebook",
    label: "Facebook",
  },
  {
    value: "twitter",
    label: "Twitter(x)",
  },
  {
    value: "tiktok",
    label: "Tiktok",
  },
  {
    value: "pantip",
    label: "Pantip",
  },
  {
    value: "internet",
    label: "Internet",
  },
];

const InsertTrendInput = () => {
  const dispatch = useDispatch();
  const { isOpenInsertModal } = useSelector((state) => state.insertTrend);
  const [open, setOpen] = useState(false);
  const [formTopTrend] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "บันทึกข้อมูลสำเร็จ",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "เกิดข้อผิดพลาดกับการบันทึกข้อมูล",
    });
  };

  //   const loading = () => {
  //     messageApi.open({
  //       type: "loading",
  //       content: "กำลังทำการบันทึกข้อมูล",
  //       duration: 0,
  //     });
  //   };

  // const onInsert = (e) => {
  //   setTrends(e.target.value);
  // };

  const sendData = async (data) => {
    const { trends } = data;
    const res = await createTrends(trends);
    if (res) {
      success();
      dispatch(
        setIsOpenInsertModal({
          value: false,
        }),
      );
    } else {
      error();
    }
  };

  return (
    <div>
      {/* <Button
        type="primary"
        onClick={() =>
          dispatch(
            setIsOpenInsertModal({
              value: true,
            }),
          )
        }
      >
        เพิ่มเทรนด์
      </Button> */}
      <ModalConfirmComponent
        title="เพิ่มเทรนด์"
        open={isOpenInsertModal}
        onCancel={() =>
          dispatch(
            setIsOpenInsertModal({
              value: false,
            }),
          )
        }
        footer={null}
        width={1080}
      >
        <Form form={formTopTrend} onFinish={sendData}>
          <Form.Item
            name="platform"
            rules={[
              {
                required: true,
                message: "Please select a arrow",
              },
            ]}
          >
            <Flex
              vertical
              gap={8}
              style={{
                paddingTop: "16px",
              }}
            >
              <LabelTrend>เลือกแพลทฟอร์มที่จะเพิ่มเทรนด์</LabelTrend>
              <Radio.Group>
                {options.map((option) => (
                  <Radio value={option.value}>
                    {option.value && (
                      <img
                        src={keysIcons[option.value]}
                        alt={`icon-${option.label}`}
                        style={{
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    )}
                  </Radio>
                ))}
              </Radio.Group>
            </Flex>
          </Form.Item>
          <Form.Item name="trends">
            <TextArea
              rows={25}
              placeholder="กรอกข้อมูล Trends For You ลงในนี้"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              paddingTop: "10px",
            }}
          >
            <StyledButton
              size="large"
              onClick={() =>
                dispatch(
                  setIsOpenInsertModal({
                    value: false,
                  }),
                )
              }
            >
              ยกเลิก
            </StyledButton>
            <StyledButton
              color="#0EA67F"
              type="primary"
              htmlType="submit"
              size="large"
            >
              ส่ง
            </StyledButton>
          </div>

          {contextHolder}
        </Form>
      </ModalConfirmComponent>
    </div>
  );
};

export default InsertTrendInput;
