import { Checkbox, Form } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../../common/hooks/useProviderAuth";
import { gen, gender, typeInfluencer } from "./constant";
import missionProvider from "../../missions/provider/missionProvider";

const StyleListCategory = styled(Checkbox.Group)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 700px;

  .ant-checkbox-wrapper {
    width: calc((700px - ${8 * (4 - 1)}px) / ${4});
  }
`;

const BoldLabel = styled.span`
  font-weight: bold;
`;

const SubTitle1 = styled.span`
  color: ${(props) => props.theme.colorTextButton};
  font-size: 16px !important;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

const TextRed = styled.span`
  color: ${(props) => props.theme.infoinfoRed};
  font-size: 16px;
`;

const Audience = (props) => {
  const { form, initDefaultValue = true } = props;
  const catagory = Form.useWatch("category", form) || [];
  const checkboxComp = (value, key) => {
    return value.map((item) => (
      <Checkbox
        key={item?.label}
        value={item?.value}
        checked={key === "category" && catagory.includes(item.value)}
        defaultChecked={key === "category" && catagory.includes(item.value)}
      >
        {item?.label}
      </Checkbox>
    ));
  };

  const [categories, setCategories] = useState([]);
  const categoriesApi = missionProvider();
  const { user } = useAuth();
  const getCategories = async () => {
    const res = await categoriesApi.getCategories();

    setCategories(
      res.map((item) => {
        return {
          ...item,
          label: item.name_en,
          value: item.id,
        };
      }),
    );
  };

  useEffect(() => {
    getCategories();
    return () => {};
  }, []);

  useEffect(() => {
    if (initDefaultValue === true) {
      form.setFieldsValue({
        category: categories.map((item) => item.id),
      });
    }
    return () => {};
  }, [categories]);

  return (
    <>
      <SubTitle1>Audience</SubTitle1>
      {
        <>
          {/* ============= Gen ============= */}
          <Form.Item
            colon={false}
            className="sub-content"
            name="gen"
            label={<BoldLabel>Gen</BoldLabel>}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Checkbox.Group>{checkboxComp(gen)}</Checkbox.Group>
          </Form.Item>

          {/* ============= Gender ============= */}
          <Form.Item
            colon={false}
            className="sub-content"
            name="gender"
            label={<BoldLabel>Gender</BoldLabel>}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Checkbox.Group>{checkboxComp(gender)}</Checkbox.Group>
          </Form.Item>

          {/* ============= Category ============= */}
          <Form.Item
            className="sub-content"
            name="category"
            label={<BoldLabel>Influencer Category</BoldLabel>}
            colon={false}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <StyleListCategory>
              {checkboxComp(categories, "category")}
            </StyleListCategory>
          </Form.Item>

          {/* ============= Type of Influencer ============= */}
          {(user?.allowBot && (
            <Form.Item
              colon={false}
              className="sub-content"
              name="posterTypes"
              label={
                <span>
                  <span>Type of Influencer</span>{" "}
                  <TextRed>(Administrator only)</TextRed>
                </span>
              }
              labelFontSize={50}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Checkbox.Group>{checkboxComp(typeInfluencer)}</Checkbox.Group>
            </Form.Item>
          )) ||
            null}
        </>
      }
    </>
  );
};

export default Audience;
