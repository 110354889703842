import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const listTrendProvider = () => {
  const { axios } = useAxios();

  const getListTrend = async (params) => {
    try {
      const url = `${host}/getNews${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  const addListTrend = async (body) => {
    try {
      const url = `${host}/addNews`;
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      if (error.code === "ER_BAD_FIELD_ERROR") {
        throw error;
      }
      return error;
    }
  };

  const editListTrend = async (id, body) => {
    try {
      const url = `${host}/${id}`;
      const res = await axios.patch(url, body);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  const deleteListTrend = async (id) => {
    try {
      const url = `${host}/${id}`;
      const res = await axios.delete(url);

      return res.data;
    } catch (error) {
      return error;
    }
  };

  return {
    getListTrend,
    addListTrend,
    editListTrend,
    deleteListTrend,
  };
};

export default listTrendProvider;
