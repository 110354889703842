/* eslint-disable no-unused-vars */
import { Checkbox, Form, Radio, Flex } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import missionProvider from "../../missions/provider/missionProvider";
import { gen, gender, category } from "./mockData";

const StyledCheckboxAudience = styled(Checkbox)`
  color: ${(props) => props?.theme?.colorTextButton};
  font-size: 16px;
  font-weight: 700;
  width: fit-content;
`;
const StyledSpanAudience = styled.span`
  color: ${(props) => props?.theme?.colorTextButton};
  font-size: 16px;
  font-weight: 700;
  width: fit-content;
`;

const StyleListCategory = styled(Checkbox.Group)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 700px;

  .ant-checkbox-wrapper {
    width: calc((700px - ${8 * (4 - 1)}px) / ${4});
  }
`;

const SubTitle1 = styled.span`
  color: ${(props) => props.theme.colorTextButton};
  /* CMS/body2 */
  /* font-family: Sarabun; */
  font-style: normal;
  font-size: 16px !important;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.15px;
  font-weight: bold;
`;

const Audience = (props) => {
  const { form } = props;
  const audience = Form.useWatch("audience", form);

  const checkboxComp = (value) => {
    return value.map((item) => (
      <Checkbox key={item?.label} value={item?.value}>
        {item?.label}
      </Checkbox>
    ));
  };
  const [categories, setCategories] = useState([]);
  const categoriesApi = missionProvider();
  const getCategories = async () => {
    const res = await categoriesApi.getCategories();
    setCategories(
      res.map((item) => ({
        ...item,
        label: item.name_en,
        value: item.id,
      })),
    );
  };

  useEffect(() => {
    getCategories();
    return () => {};
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/create-social/add") {
      const catId = categories.map((cat) => cat.id);
      form.setFieldValue("category", catId);
    }

    return () => {};
  }, [categories]);

  const redioComp = (value) => {
    return value.map((item) => (
      <Radio key={item?.label} value={item?.value}>
        {item?.label}
      </Radio>
    ));
  };

  return (
    <div>
      <SubTitle1>Audience</SubTitle1>

      <Flex
        gap={4}
        vertical
        style={{
          paddingLeft: "16px",
        }}
      >
        {/* <Form.Item name="audience" valuePropName="checked">
        <StyledCheckboxAudience>Audience</StyledCheckboxAudience>
      </Form.Item> */}
        {/* <StyledSpanAudience>Audience</StyledSpanAudience> */}
        {/* {audience && (
        <> */}
        {/* ============= Gen ============= */}
        {/* <SubTitle1>Audiance</SubTitle1> */}
        <Form.Item
          colon={false}
          className="sub-content"
          name="gen"
          label="Gen"
          style={{
            marginBottom: 0,
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox.Group>{checkboxComp(gen)}</Checkbox.Group>
        </Form.Item>

        {/* ============= Gender ============= */}
        <Form.Item
          colon={false}
          className="sub-content"
          name="gender"
          label="Gender"
          style={{
            marginBottom: 0,
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Checkbox.Group>{checkboxComp(gender)}</Checkbox.Group>
        </Form.Item>
        {/* ============= Category ============= */}
        <Form.Item
          className="sub-content"
          name="category"
          label="Influencer Category"
          style={{
            marginBottom: 0,
          }}
          colon={false}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <StyleListCategory>{checkboxComp(categories)}</StyleListCategory>
        </Form.Item>
      </Flex>
    </div>
  );
};

export default Audience;
