import { Flex, Input } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

/* 

<TextAreaCommon
  errMsg="errMsg"
  value=""
  placeholder="placeholder "
  onChange={(e) => {
  console.log(e.target.value);
  }}
/>

*/

const TextAreaCommon = ({
  rows = 6,
  maxLength = 9999,
  type = "text",
  placeholder = "",
  title = "",
  label = "",
  isRequire = false,
  value = "",
  onChange = null,
}) => {
  const StyledTextArea = styled(Flex)`
    textarea {
      font-family: Sarabun;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      border: 1px solid ${(props) => props.theme.dividerLine};
      resize: none;
    }

    h1 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: ${(props) => props.theme.colorTextButton};
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: ${(props) => props.theme.colorBorderCardSocialRed};
    }
    h2 {
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      color: ${(props) => props.theme.colorTextButton};
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: ${(props) => props.theme.errMsg};
    }
  `;

  return (
    <StyledTextArea vertical gap="4px">
      <Flex justify="flex-start" align="flex-end" gap="5px">
        <h1>{title}</h1>
        {isRequire && <span>*</span>}
        <h2>{label}</h2>
      </Flex>
      <TextArea
        defaultValue={value}
        onBlurCapture={onChange}
        type={type}
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    </StyledTextArea>
  );
};

export default TextAreaCommon;
