/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { StyledButtonStatus } from "../MyPostsList";

const StyledCardContainer = styled.div`
  width: 164px;
  height: 80px;
  border: 1px solid var(--Action-border-stroke, #d9d9d9);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
`;

const StyledTextLabel = styled.span`
  white-space: pre;
  color: ${(props) => props.theme.colorTertiary};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
`;

const StyledTextValue = styled.span`
  color: ${(props) => props.theme.colorTertiary};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
`;

const CardStatusMission = ({ label = "", value = "", status = "" }) => {
  return (
    <StyledCardContainer>
      <StyledTextLabel>{label}</StyledTextLabel>
      <StyledTextValue>{value}</StyledTextValue>
      <StyledButtonStatus>{status}</StyledButtonStatus>
    </StyledCardContainer>
  );
};

export default CardStatusMission;
