/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
// eslint-disable-next-line object-curly-newline
import { Button, Flex, Radio, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";
import moment from "moment";
import IconSocial from "../../../common/components/IconSocial";
import {
  myPostInfluencerStatus,
  myPostInfluencerStatusCancel,
  myPostListMissionStatus,
} from "../../../common/utils/keyDatas";
import ContentJobDetail from "../../../common/components/ContentJobDetail";
import WindowComponent from "../../../common/components/WindowComponent";
import ContentDetail from "../../../common/components/ContentDetail";
import RequirementDetail from "../../../common/components/RequirementDetail";
import MissionDetail from "../../../common/components/MissionDetail";
import HeaderWindowDetail from "../../flowJob/HeaderWindowDetail";
import Icon from "../../../assets/icons/icon-copywriter2.svg";
import mockPost from "./mock";
import missionProvider from "../provider/missionProvider";
import { usePostSubMissionList } from "../PostMissionList";

const PostDetailWrap = styled.div`
  padding: 16px 32px;
  margin-bottom: ${(props) => (props.status === "waiting" ? "162px" : "50px")};
`;

const H6 = styled.h6`
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`;

const Body2 = styled.span`
  font-family: "Sarabun";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;
`;

const ButtonCustom = styled(Button)`
  border: ${(props) =>
    props.typeBtn === "submit"
      ? "none"
      : `1px solid ${props.theme.colorMagenta}`};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: 180px;
  height: 38.8px;
  background-color: ${(props) =>
    props.typeBtn === "submit" ? props.theme.colorMagenta : props.theme.white};
  color: ${(props) =>
    props.typeBtn === "submit" ? props.theme.white : props.theme.colorMagenta};
`;

const FooterWrap = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${(props) => props.theme.white};
`;

const StyleTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
`;

const useWindowPostDetailInfluencer = () => {
  const [data, setData] = useState(null);
  const [statusContent, setStatusContent] = useState("reject");
  const [detail, setDetail] = useState(null);
  const missionApi = missionProvider();
  const { id: missionId } = useParams();

  const getMissionDetail = async () => {
    const res = await missionApi.getSubMissionDetail(missionId);
    setDetail(res);
  };

  useEffect(() => {
    (async () => {
      await getMissionDetail();
    })();
    return () => {};
  }, []);

  // useEffect(() => {
  //   setData(mockPost[0]);
  //   return () => {};
  // }, []);
  const closeWindow = async () => {
    window.onbeforeunload = function () {
      return null;
    };
    window.close();
  };

  const updateApprove = async (id) => {
    try {
      const res = await missionApi.updateStatusSubmit({
        id,
        status: statusContent,
      });
      closeWindow();
      // return res;
    } catch (error) {
      alert("error");
    }
  };

  const checkStatusCompleteMission = (
    mission,
    cancelDate,
    endDate,
    contentStatus,
  ) => {
    if (
      mission === "cancel" ||
      mission === "complete" ||
      cancelDate ||
      moment().isAfter(endDate)
    ) {
      return true;
    }
    return false;
  };

  return {
    data,
    statusContent,
    setStatusContent,
    updateApprove,
    closeWindow,
    detail,
    checkStatusCompleteMission,
  };
};

const WindowPostDetailInfluencer = () => {
  const [form] = useForm();
  const {
    data,
    statusContent,
    setStatusContent,
    updateApprove,
    closeWindow,
    detail,
    checkStatusCompleteMission,
  } = useWindowPostDetailInfluencer();
  const {
    status,
    created_date: createdDate,
    type,
    writer_name: writerName,
    user_name: username,
    writer_poster_name: writerPosterName,
    // poster_name: posterName,
    requirement,
    content_text: contentText,
    start_date: startDate,
    end_date: endDate,
    id,
    poster_username: posterUsername,
    platform,
    mission_status: missionStatus,
    canceled_date: cancelDate,
    social_name: socialAccountName,
  } = detail || {};
  return (
    <WindowComponent minusHeight={100}>
      <PostDetailWrap status={status}>
        <HeaderWindowDetail
          imgProfile={Icon}
          name={posterUsername}
          // roleUser={writerPosterName ? "influencer" : "copywriter"}
          roleUser="influencer"
          title="Post Content"
          tagContent={type}
          status={status}
          date={createdDate}
          typeStatus={
            checkStatusCompleteMission(
              missionStatus,
              cancelDate,
              endDate,
              // status,
            )
              ? myPostInfluencerStatusCancel
              : myPostInfluencerStatus
          }
        />

        <br />
        <hr />
        <br />

        <Flex vertical>
          {/* <H6>Mission</H6> <br />
          <MissionDetail
            type="twitter"
            href="https://twitter.com/PPTVHD36/status/1763484738210201726"
            content="โพสถึง Ais ในทิศทางบวก รูปที่ใช้ในการโพสขนาด 1200 x 600 px โพสที่ Social media ตามที่กำหนด แล้วให้โปรโมการใช้งานอินเตอ์เน็ต เกี่ยวกับการไปปดูคอนเสริต์ ตัวอย่างการโพส เน็ตดีมาก ดูคอนเสริต์พี่ Ed sheeran ที่ราชมังคือดี จึ้งสุดๆ ส่งรูปวิดีโอไม่มีสะดุด ไม่ว่าจะส่งให้ใครก็เร็วแรง คมชัด วิดีโอคอลหาที่บ้านสัญญาณคือชัดเเจ๋ว ไม่มีกระตุก เริ่ดมากกก สัญญาณ ปังสุด ยกให้เป็นที่ 1"
            startDate="2024-05-20 11:30"
            endDate="2024-05-30 11:30"
            link="https://twitter.com/PPTVHD36/status/1763484738210201726"
            campaign=""
          />
          <RequirementDetail content="โพสถึง Ais ในทิศทางบวก รูปที่ใช้ในการโพสขนาด 1200 x 600 px โพสที่ Social media ตามที่กำหนด แล้วให้โปรโมการใช้งานอินเตอ์เน็ตเกี่ยวกับการไปปดูคอนเสริต์ ตัวอย่างการโพส เน็ตดีมาก ดูคอนเสริต์พี่ Ed sheeran ที่ราชมังคือดี จึ้งสุดๆ ส่งรูปวิดีโอไม่มีสะดุด ไม่ว่าจะส่งให้ใครก็เร็วแรง คมชัด วิดีโอคอลหาที่บ้านสัญญาณคือชัดเเจ๋ว ไม่มีกระตุก เริ่ดมากกก สัญญาณปังสุด ยกให้เป็นที่ 1 และการพูดถึง Ais ต้อง ไม่เสียดสีค่ายอื่นว่าเป็นอย่างไร และการโพส ต้องติด Hashtag #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส#เน็ตแรง #" /> */}
          <ContentJobDetail
            dataItem={{
              content: requirement,
              startDate,
              endDate,
              //   status,
              // platform,
              type: platform,
            }}
            dataItemRequirement={requirement}
          />
          <ContentDetail
            message={contentText}
            imageList={[]}
            createdBy={writerName}
          />
        </Flex>

        {socialAccountName && (
          <Flex
            vertical
            gap={8}
            style={{
              paddingTop: "16px",
            }}
          >
            <H6>Social Account</H6>
            <Body2>{socialAccountName}</Body2>
          </Flex>
        )}

        {detail?.post_url && (
          <Flex
            vertical
            gap={20}
            style={{
              paddingTop: "16px",
            }}
          >
            <StyleTitle>Job URL</StyleTitle>
            <Flex gap={20} align="center">
              <IconSocial type={platform} />
              <a href={detail.post_url}>{detail.post_url}</a>
              {/* <Input
                prefix={<a href={detail.post_url}>{detail.post_url}</a>}
                style={{
                  cursor: "default",
                }}
              /> */}
            </Flex>
          </Flex>
        )}
      </PostDetailWrap>
      {status === "waiting" ||
        (status === "pending" && (
          <FooterWrap>
            <Form
              form={form}
              name="postDetail"
              onFinish={() => updateApprove(id)}
            >
              <Form.Item
                label=""
                name="statusContent"
                initialValue={statusContent}
                rules={[
                  {
                    required: true,
                    message: "Please select a status!",
                  },
                ]}
              >
                <Flex
                  justify="center"
                  style={{
                    padding: "16px",
                  }}
                >
                  <Radio.Group
                    onChange={(e) => setStatusContent(e.target.value)}
                    defaultValue="reject"
                  >
                    <Radio value="reject">Reject</Radio>
                    <Radio value="approve">Approve</Radio>
                  </Radio.Group>
                </Flex>
              </Form.Item>

              <hr />
              <Form.Item>
                <Flex
                  gap={15}
                  justify="center"
                  style={{
                    padding: "16px",
                  }}
                >
                  <ButtonCustom
                    type="default"
                    htmlType="button"
                    onClick={closeWindow}
                  >
                    Cancel
                  </ButtonCustom>
                  <ButtonCustom
                    typeBtn="submit"
                    type="primary"
                    htmlType="submit"
                  >
                    Confirm
                  </ButtonCustom>
                </Flex>
              </Form.Item>
            </Form>
          </FooterWrap>
        ))}
    </WindowComponent>
  );
};

export default WindowPostDetailInfluencer;
