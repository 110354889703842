import React from "react";
import MyPostList from "../features/missions/MyPostsList";
import MainLayout from "../common/layouts/main";

const MyPost = () => {
  return (
    <MainLayout>
      <MyPostList />
    </MainLayout>
  );
};

export default MyPost;
