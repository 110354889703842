import axios from "axios";

const createTrends = async (data) => {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "http://206.189.130.167:9001/xtrends",
    headers: {},
    data: {
      trending: data,
    },
  };

  try {
    const res = await axios.request(config);
    return res;
  } catch (error) {
    return false;
  }
};

const createTrends2 = "ssee";

export { createTrends, createTrends2 };
