import React from "react";
import { Flex, Input, Typography } from "antd";
import styled from "styled-components";

const { Text } = Typography;

const InputNumber = styled(Input)`
  .ant-input-outlined {
    border-right: 0;
  }

  .ant-input-group-addon {
    background-color: transparent !important;
    color: ${(props) => props.theme.textInput} !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  ${(props) =>
    props.disabled &&
    `
    .ant-input-group-addon {
      background-color: ${props?.theme.blackAlpha(0.04)} !important;
    }
  `}
`;

const InputUnit = ({
  value = "",
  onChange = () => {},
  unit = "Post",
  limit = 200,
  errMsg = "",
  label = "",
  disabled = false,
}) => {
  const handleInputChange = (e) => {
    if (e.target.value.length > limit) return;
    if (e.target.value.length === 0) {
      onChange("");
    } else {
      // desimal 2 position
      // const isNumeric = /^\d+(\.\d{0,2})?$/.test(e.target.value);
      const isNumeric = /^\d+$/.test(e.target.value);
      if (isNumeric) {
        onChange(e.target.value);
      }
    }
  };

  return (
    <>
      <Flex gap={8} align="center">
        {label && <span className="text-label-input-unit">{label} :</span>}
        <InputNumber
          addonAfter={unit}
          onChange={handleInputChange}
          value={value}
          status={errMsg ? "error" : false}
          disabled={disabled}
        />
      </Flex>

      <Text type="danger">{errMsg}</Text>
    </>
  );
};

export default InputUnit;
