import React, { useEffect, useState } from "react";
import { Flex } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import MediaExposureTab from "./MediaExposureTab";
import TrackingTab from "./TrackingTab";
import TopKeywordsTab from "./TopKeywordsTab";
import SentimentTab from "./SentimentTab";
import SelectShowColumn from "./ShowColumn";
import CampaignProvider from "./provider/campaignProvider";
import { StyledTabs } from "../../../common/components/ThemeStyledComponent";

const StyleDashBoard = styled(Flex)`
  padding: 4px 16px;
  background: ${(props) => props.theme.backgroundDashBoard};
`;

const TabCampaignDashboard = () => {
  const campaignProvider = CampaignProvider();
  const [dataCampaign, setDataCampaign] = useState([]);
  const [showColumn, setShowColumn] = useState(12);
  const { filter, sortby } = useSelector((state) => state.socialCard);
  const fetchData = async () => {
    try {
      const filterPlatform = Object.keys(filter).filter((key) => filter[key]);

      const platformString = filterPlatform
        .map((platform) => `platform=${platform}`)
        .join("&");

      const params = `?&${platformString}&sort=${sortby}&approved=1`;

      const res = await campaignProvider.getAllcampaign(params);
      if (res) {
        const currentData = res.slice(0, 10);
        setDataCampaign(currentData);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, sortby]);

  return (
    <StyledTabs
      centered
      onChange={() => setShowColumn(12)}
      defaultActiveKey="1"
      items={[
        {
          label: "Media Exposure",
          key: "1",
          children: (
            <StyleDashBoard vertical gap={4}>
              <Flex justify="end">
                {" "}
                <SelectShowColumn setShowColumn={setShowColumn} />
              </Flex>
              <MediaExposureTab data={dataCampaign} showColumn={showColumn} />
            </StyleDashBoard>
          ),
        },
        {
          label: "Tracking",
          key: "2",
          children: (
            <StyleDashBoard vertical>
              <Flex justify="end">
                {" "}
                <SelectShowColumn setShowColumn={setShowColumn} />
              </Flex>
              <TrackingTab data={dataCampaign} showColumn={showColumn} />
            </StyleDashBoard>
          ),
        },
        {
          label: "Top Keywords",
          key: "3",
          children: (
            <StyleDashBoard vertical>
              <Flex justify="end">
                {" "}
                <SelectShowColumn setShowColumn={setShowColumn} />
              </Flex>
              <TopKeywordsTab data={dataCampaign} showColumn={showColumn} />
            </StyleDashBoard>
          ),
        },
        {
          label: "Sentiment",
          key: "4",
          children: (
            <StyleDashBoard vertical>
              <Flex justify="end">
                {" "}
                <SelectShowColumn setShowColumn={setShowColumn} />
              </Flex>
              <SentimentTab data={dataCampaign} showColumn={showColumn} />
            </StyleDashBoard>
          ),
        },
      ]}
    />
  );
};
export default TabCampaignDashboard;
