/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Flex, Select } from "antd";
import styled from "styled-components";

const StyledSelect = styled(Select)`
  width: 160px;
  height: 44px;
`;

const StyledTableMainKeyword = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;

  .table-head-list-keyword,
  .table-body-list-keyword {
    display: flex;
    > tr {
      width: 100%;
      display: grid;
      grid-template-columns: 10% 1fr 20%;
    }
  }

  .table-head-list-keyword {
    background-color: ${(props) => props.theme.colortitletable};
    border-radius: 10px 10px 0 0;
    padding: 8px;
    color: white;
  }

  .table-body-list-keyword {
    flex-direction: column;
    background-color: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.backgroundDashBoard};
    border-radius: 0 0 10px 10px;

    > tr {
      padding: 8px 0;

      /* &:hover {
        background-color: ${(props) => props.theme.backgroundDashBoard};
      } */

      > td {
        display: flex;
        align-items: center;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  background: transparent !important;
  color: ${(props) => props.theme.colorcheckbox} !important;

  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

const ListKeyword = ({ data, setEditInput, openModalConfirmDelete }) => {
  return (
    <div
      id="list-keyword-container"
      style={{
        width: "100%",
      }}
    >
      <Flex vertical gap="16px">
        <Flex justify="space-between" align="center">
          <span>{data?.length} item</span>
          <Flex gap={16} align="center">
            <span>Sorting :</span>
            <StyledSelect />
          </Flex>
        </Flex>
        <StyledTableMainKeyword className="table-list-keyword">
          <thead className="table-head-list-keyword">
            <tr>
              <th>No</th>
              <th
                style={{
                  textAlign: "start",
                }}
              >
                Campaign Name
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="table-body-list-keyword">
            {data.length === 0 ? (
              <Flex
                justify="center"
                align="center"
                style={{
                  height: "150px",
                }}
                gap="8px"
              >
                <InfoCircleOutlined />
                <span>ไม่พบข้อมูล</span>
              </Flex>
            ) : (
              data.map((item, index) => (
                <tr key={item.id}>
                  <td
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      paddingLeft: "16px",
                    }}
                  >
                    {item.name}
                  </td>
                  <td
                    style={{
                      gap: "8px",
                      justifyContent: "center",
                    }}
                  >
                    <StyledButton
                      type="text"
                      size="small"
                      hoverColor="orange"
                      onClick={() => setEditInput(item)}
                    >
                      <EditOutlined />
                    </StyledButton>
                    <StyledButton
                      type="text"
                      size="small"
                      hoverColor="red"
                      onClick={() => openModalConfirmDelete(item.id)}
                    >
                      <DeleteOutlined />
                    </StyledButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </StyledTableMainKeyword>
      </Flex>
    </div>
  );
};

export default ListKeyword;
