// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from "query-string";
import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const SubmissionContentProvider = () => {
  const { axios } = useAxios();

  /* status: ready, done */
  const getSubmissionContent = async (params) => {
    try {
      const { status, limit, page, filter } = params || {};

      const qsParam = {
        limit,
        page,
      };

      /* status : approve,complete,reject,error,cancel */
      if (status !== "all") {
        if (status === "reject") {
          qsParam.status = ["reject", "cancel"];
        } else {
          qsParam.status = status;
        }
      }
      const queryStringParam = queryString.stringify(qsParam);

      const url = `${host}/sub-mission-contents/influencer?${queryStringParam}&${filter}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

  /* status: all */
  const getMissionJobs = async (params) => {
    try {
      const { limit, page, filter, status } = params || {};
      const qsParam = {
        limit,
        page,
      };

      if (status) qsParam.status = status;

      const filterNew = filter
        .replaceAll("platforms", "platform")
        .replaceAll("type", "target");
      const queryStringParam = queryString.stringify(qsParam);
      const url = `${host}/missions/jobs/content?${queryStringParam}&${filterNew}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getPostContent = async ({ id }) => {
    try {
      const url = `${host}/sub-mission-contents/${id}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      return error;
    }
  };

  const createSubmitPost = async ({ body, id }) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host}/sub-mission-posts/content/${id}/poster`;
      const res = await axios.post(url, body);
      return res.status;
    } catch (error) {
      throw error;
    }
  };

  const lockSubmissionContent = async ({ id }) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const url = `${host}/sub-mission-contents/${id}/pick`;
      const res = await axios.post(url);
      return res.status;
    } catch (error) {
      throw error;
    }
  };

  const unlockSubmissionContent = ({ id }) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${host}/sub-mission-contents/${id}/un-pick`;
        const res = await axios.post(url);
        resolve(res.status);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getMissionContentDetail = async ({ missionId = null }) => {
    // eslint-disable-next-line no-useless-catch, no-async-promise-executor
    try {
      const url = `${host}/missions/${missionId}`;
      const res = await axios.get(url);
      return res?.data;
    } catch (error) {
      throw error;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getSocailAccount = async ({ platform, missionId }) => {
    try {
      const url = `${host}/social-accounts/mission/${missionId}`;
      const res = await axios.get(url, {
        params: {
          platform,
          order_by: "desc",
          limit: 20,
          page: 1,
        },
      });
      return res?.data ? res?.data : null;
    } catch (error) {
      return error;
    }
  };

  return {
    getSubmissionContent,
    getPostContent,
    createSubmitPost,
    lockSubmissionContent,
    unlockSubmissionContent,
    getMissionJobs,
    getMissionContentDetail,
    getSocailAccount,
  };
};

export default SubmissionContentProvider;
