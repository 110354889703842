import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  success: false,
};

export const socialMediaSlice = createSlice({
  name: "socialMedia",
  initialState,
  reducers: {
    setIntialTrendList: () => initialState,
    setSocialSuccess: (state, action) => {
      state.data = action.payload.value;
    },
  },
});

export const { setSocialSuccess } = socialMediaSlice.actions;

export default socialMediaSlice.reducer;
