/* eslint-disable no-undef */
import { useAxios } from "../../../common/hooks/useProviderAxios";
// import { mockBarTotalChart } from "./mockChart";

const host = process.env.REACT_APP_API_ENDPOINT;

const chartProvider = () => {
  const { axios } = useAxios();

  const getLineChart = async (params) => {
    try {
      const url = `${host}/charts/line${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get line chart", error);
      return [];
    }
  };

  const getBarpercentChart = async (params) => {
    try {
      const url = `${host}/charts/bar${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get bar chart", error);
      return [];
    }
  };

  const getWordCloud = async (params) => {
    try {
      const url = `${host}/charts/wordcloud${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get word cloud", error);
      return [];
    }
  };

  const getBarTotalChart = async (params) => {
    try {
      // if (mockBarTotalChart) return mockBarTotalChart;
      const url = `${host}/charts/bar-total${params}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error) {
      console.log("Error on get word cloud", error);
      return [];
    }
  };

  return {
    getLineChart,
    getBarpercentChart,
    getWordCloud,
    getBarTotalChart,
  };
};

export default chartProvider;
