import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Flex, Image, Typography } from "antd";
import CardStatusMission from "../../../features/missions/CardShowStatus";
import { StyledButtonStatus } from "../../../features/missions/MyPostsList";
import { keysIcons, statusContent } from "../../utils/keyDatas";
import IconClock from "../../../assets/icons/icon-clock.svg";
import IconCreated from "../../../assets/icons/icon-created.svg";
import GroupSocial from "../GroupSocial";

const { Text } = Typography;

const StyledContentContainer = styled.div`
  font-family: "Sarabun";
`;

const StyleCardMissionDetail = styled(Flex)`
  border-radius: 10px;
  border: 1px solid ${(props) => props?.theme?.dividerLine};
  background: ${(props) => props?.theme?.white};
  padding: 16px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 1024px) {
    /* flex-direction: column; */
  }
`;

const StyleContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 80px;
  flex-wrap: wrap;
`;

const StyleTextTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${(props) => props?.theme?.colorTextButton};
`;

const StyleTextContent = styled.a`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextButton} !important;
  display: -webkit-box;
  overflow: hidden;
  /* line-clamp: 2;
  -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-decoration: none;
  line-height: 22px;
  cursor: default;
  padding: 3px 2px;
  line-height: 23px;

  ${(props) =>
    props?.href &&
    `
  text-decoration: underline !important;
  cursor: pointer;
  `}
`;

const StyleDate = styled(Flex)`
  font-size: 12px !important;
  /* width: 100%; */
  p {
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  span {
    color: ${(props) => props?.theme?.colorTextButton};
    &.error {
      color: ${(props) => props?.theme?.errMsg};
    }
    &.success {
      color: ${(props) => props?.theme?.colorComplete};
    }
  }
`;

const StyleDesc = styled.span`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextDesc};
`;

const StyleTextRequirment = styled.span`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextButton};
  display: -webkit-box;
  overflow-y: auto;
  height: 86px;
`;

const ContentJobDetail = (props) => {
  const {
    dataItem = {
      type: "",
      href: "",
      content: "",
      startDate: "",
      endDate: "",
      createdDate: "",
      status: "",
      platform: "",
      typeStatus: statusContent,
      copywriterCount: {},
      influencerCount: {},
      label: "",
      showLabel: "",
    },
    dataItemRequirement = "",
  } = props;

  const {
    type,
    href,
    content,
    startDate,
    endDate,
    createdDate,
    status,
    platform,
    typeStatus,
    copywriterCount,
    influencerCount,
    missionStatus,
    label = "Mission",
    // missionCancelDate,
    // missionCompleteDate,
    showLabel = true,
  } = dataItem;

  return (
    <StyledContentContainer>
      <Flex
        vertical
        gap={12}
        style={{
          marginTop: "16px",
        }}
      >
        <StyleTextTitle>{showLabel && label}</StyleTextTitle>
        {content && (
          <StyleCardMissionDetail gap="8px">
            <GroupSocial
              list={[platform]}
              paddingLeft="8px"
              paddingRight="8px"
            />

            {type && (
              <Image
                src={keysIcons[type]}
                height={24}
                preview={false}
                style={{
                  width: "24px",
                }}
              />
            )}

            <StyleContent>
              <StyleTextContent href={href} target="_blank">
                {content}
              </StyleTextContent>
              <Flex
                direction="row"
                wrap="wrap"
                gap={10}
                style={{
                  marginTop: "16px",
                  width: "100%",
                }}
                justify="end"
              >
                <Flex
                  direction="row"
                  gap={10}
                  align="end"
                  justify={createdDate ? "start" : "end"}
                  wrap="wrap"
                  style={{
                    width: "100%",
                  }}
                >
                  {createdDate && (
                    <StyleDate align="center" gap="4px">
                      <p>
                        <img src={IconCreated} alt="icon-created" /> Created :
                      </p>
                      <span>
                        {moment(createdDate).format("YYYY-MM-DD HH:mm")}
                      </span>
                    </StyleDate>
                  )}
                  {startDate && endDate && (
                    <StyleDate
                      align="center"
                      gap="4px"
                      justify="end"
                      // style={{
                      //   width: "auto",
                      // }}
                    >
                      <img src={IconClock} alt="icon-clock" />
                      <p>Work on : </p>
                      <span>
                        {moment(startDate).format("YYYY-MM-DD HH:mm")} to{" "}
                        {moment(endDate).format("YYYY-MM-DD HH:mm")}
                      </span>
                    </StyleDate>
                  )}
                  {/* {missionCompleteDate && (
                    <StyleDate
                      align="center"
                      gap="4px"
                      justify="end"
                      // style={{
                      //   width: "auto",
                      // }}
                    >
                      <span className="success">
                        {`(Completed : ${moment(missionCompleteDate).format("YYYY-MM-DD HH:mm")})`}
                      </span>
                    </StyleDate>
                  )}
                  {missionCancelDate && (
                    <StyleDate
                      align="center"
                      gap="4px"
                      justify="end"
                      // style={{
                      //   width: "auto",
                      // }}
                    >
                      <span className="error">
                        {`(Cancelled : ${moment(missionCancelDate).format("YYYY-MM-DD HH:mm")})`}
                      </span>
                    </StyleDate>
                  )}
                  {!missionCompleteDate &&
                    !missionCancelDate &&
                    moment().isAfter(endDate) && (
                      <StyleDate
                        align="center"
                        gap="4px"
                        justify="end"
                        // style={{
                        //   width: "auto",
                        // }}
                      >
                        <span className="error">
                          {`(Expired : ${moment(endDate).format("YYYY-MM-DD HH:mm")})`}
                        </span>
                      </StyleDate>
                    )} */}
                </Flex>
                {false && (
                  <Text>
                    Status :{" "}
                    <StyledButtonStatus color={typeStatus[status]?.color}>
                      {typeStatus[status]?.label}
                    </StyledButtonStatus>
                  </Text>
                )}
              </Flex>
              {/* <Flex
            justify="flex-end"
            style={{
              paddingTop: "16px",
            }}
          >
            status
          </Flex> */}
            </StyleContent>

            <div
              style={{
                display: "flex",
                gap: "8px",
              }}
            >
              {copywriterCount?.target && (
                <CardStatusMission
                  label="Copywriter Submissions"
                  value={`${copywriterCount?.actual}/${copywriterCount?.target}`}
                  status={
                    <StyledButtonStatus
                      color={
                        copywriterCount?.actual === copywriterCount?.target &&
                        missionStatus !== "cancel"
                          ? typeStatus?.complete?.color
                          : typeStatus[status].color
                      }
                    >
                      {copywriterCount?.actual === copywriterCount?.target &&
                      missionStatus !== "cancel"
                        ? typeStatus?.complete?.label
                        : typeStatus[status].label}
                    </StyledButtonStatus>
                  }
                />
              )}
              {influencerCount?.target && (
                <CardStatusMission
                  label="Influencer Submissions"
                  value={`${influencerCount?.actual}/${influencerCount?.target}`}
                  status={
                    <StyledButtonStatus
                      color={
                        influencerCount?.actual === influencerCount?.target
                          ? typeStatus?.complete?.color
                          : typeStatus[status]?.color
                      }
                    >
                      {influencerCount?.actual === influencerCount?.target
                        ? typeStatus?.complete?.label
                        : typeStatus[status]?.label}
                    </StyledButtonStatus>
                  }
                />
              )}
            </div>
          </StyleCardMissionDetail>
        )}
        {dataItemRequirement && (
          <Flex vertical gap={8}>
            <StyleDesc>Requirement : </StyleDesc>
            <StyleTextRequirment>{dataItemRequirement}</StyleTextRequirment>
          </Flex>
        )}
      </Flex>
    </StyledContentContainer>
  );
};

export default ContentJobDetail;
