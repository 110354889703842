/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import { Flex } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import styled from "styled-components";
import IconSocial from "../../../../../common/components/IconSocial";
import TextPreview from "../../../../../common/components/TextPreview";
import ProfileCard from "../../../../../common/components/ProfileCard";
import SocialButton from "../../../../../common/components/SocialButton";
import GroupSocial from "../../../../../common/components/GroupSocial";

/* 

 <CardEngagementReply
  data={{
    submissionDate: "01/31/24",
    groupSocial: ["facebook"],
    socialType: "facebook",
    textTitle: "textTitle",
    socialButtonType: "Reply",
    textPreview: "textPreview",
    status: "Done",
    textCard: "Pick",
    profileName: "profile Name",
    profileRole: "owner",
  }}
/>

*/

const StyledCardEngagementReply = styled(Flex)``;

const StyledText = styled(Flex)`
  width: ${(props) => props.width || "unset"};
  color: ${(props) => props.theme[props.color] || props.theme.colorTextButton};
  text-decoration: ${(props) => props.textDecoration || "none"};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.15px;

  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  padding: 3px 2px;
  line-height: 29px;
`;

const StyleTextContent = styled.a`
  font-size: 14px;
  color: ${(props) =>
    props.theme[props.color] || props.theme.colorTextButton} !important;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-decoration: none;
  line-height: 22px;
  cursor: default;
  padding: 3px 2px;
  line-height: 29px;

  ${(props) =>
    props?.href &&
    `
  text-decoration: underline !important;
  cursor: pointer;
  `}
`;

const CardEngagementReply = ({ data = {} }) => {
  const {
    submissionDate = "",
    groupSocial = [],
    socialType = "",
    socialButtonType = "",
    textTitle = "",
    textTitleUrl = "",
    textPreview = "",
    status = "",
    profileName = "",
    profileRole = "",
    handleOpenWindow,
    apiData,
  } = data || {};

  return (
    <StyledCardEngagementReply
      onClick={() => handleOpenWindow(apiData?.id)}
      justify="space-between"
      align="center"
      gap="16px"
    >
      <Flex
        gap="32px"
        align="center"
        style={{
          width: "100%",
        }}
      >
        {/* Col1 */}
        {groupSocial.length > 0 && <GroupSocial list={groupSocial} />}
        {socialType.length > 0 && <IconSocial type={socialType} />}

        {/* Col2 */}
        <Flex
          gap="32px"
          align="flex-start"
          vertical
          style={{
            width: "100%",
          }}
        >
          <Flex
            gap="16px"
            align="flex-start"
            vertical
            style={{
              width: "100%",
            }}
          >
            {/* <StyledText
              gap="8px"
              color="textInput"
              textDecoration={textTitleUrl ? "underline" : null}
              align="center"
            >
              {textTitle}
            </StyledText> */}
            <StyleTextContent
              href={textTitleUrl}
              target="_blank"
              color="textInput"
            >
              {textTitle}
            </StyleTextContent>
            <TextPreview value={textPreview} status={status} />
          </Flex>

          <Flex
            gap="32px"
            align="flex-end"
            justify="space-between"
            style={{
              width: "100%",
            }}
          >
            <Flex
              align="flex-end"
              gap="1.5rem"
              style={{
                width: "100%",
              }}
            >
              {/* {profileName && (
                <ProfileCard label={profileName} roleUser={profileRole} />
              )} */}

              <StyledText
                align="center"
                gap="8px"
                style={{
                  width: "max-content",
                  display: "flex",
                }}
              >
                Missions :
                {socialButtonType && <SocialButton type={socialButtonType} />}
              </StyledText>
            </Flex>

            {/* ----------------- 2024-05-24 19:15 ----------------- */}
            <Flex align="center">
              {submissionDate && (
                <StyledText gap="8px" width="max-content">
                  {/* Submission Date :{" "}
                  {dayjs(submissionDate).format("YYYY-MM-DD HH:mm")} */}
                  Date :{" "}
                  {dayjs(apiData.created_date).format("YYYY-MM-DD HH:mm")} -{" "}
                  {dayjs(apiData.end_date).format("YYYY-MM-DD HH:mm")}
                </StyledText>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </StyledCardEngagementReply>
  );
};

export default CardEngagementReply;
