import React from "react";
import styled from "styled-components";
import { Flex } from "antd";

import { backgroundTypeMission, iconSocial } from "../../utils/keyDatas";

const StyleSocialButton = styled(Flex)`
  background-color: ${(props) => props.theme[props?.background]};
  width: fit-content;
  border-radius: 120px;
  padding: 2px 10px;

  img {
    width: 12px;
    height: 12px;
  }
`;

const StyleText = styled.span`
  font-size: 12px !important;
  font-weight: 400;
  text-transform: capitalize;
`;

const showType = (type) => {
  if (type.includes("share_quote")) {
    return type.replace("share_quote", "quote");
  }
  return type;
};

const SocialButton = (props) => {
  const { type = "" } = props;

  return (
    <StyleSocialButton
      background={backgroundTypeMission[type]}
      align="center"
      gap="8px"
    >
      <img src={iconSocial[type]} alt="icon-social" />
      <StyleText>{showType(type)}</StyleText>
      {/* <StyleText>{type}</StyleText> */}
    </StyleSocialButton>
  );
};

export default SocialButton;
