/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { Flex } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ProfileCard from "../../../common/components/ProfileCard";
import SocialButton from "../../../common/components/SocialButton";
import IconSocial from "../../../common/components/IconSocial";
// import ProfileCard from "../../../../common/components/ProfileCard";

const left = window.screen.width / 2 - 800 / 2;
const top = window.screen.height / 2 - 688 / 2;

const StyleCardPostMission = styled.div`
  cursor: pointer;
`;

const StyleTitle = styled.span`
  font-size: 12px;
  color: ${(props) => props?.theme?.textInput};
  text-decoration: underline;
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  padding: 3px 2px;
  line-height: 29px;
`;

const StylePreviewDate = styled.div`
  width: 100%;
  color: ${(props) => props?.theme?.colorTextButton};
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  padding: 3px 2px;
  line-height: 29px;
`;

const PreviewDateWrap = styled.div`
  background-color: ${(props) =>
    props.status === "reject"
      ? `${props.theme.bgButtonDelete} !important`
      : props?.theme?.bgTablePage};
  border-radius: 10px;
  padding: 10px;
  border-bottom: 1px solid ${(props) => props?.theme?.actionBorder};
`;

const StyleRoleText = styled.span`
  font-size: 12px;
  color: ${(props) => props?.theme?.colorTertiary};
`;
const StyleUserNameText = styled.span`
  font-size: 14px;
`;
const StyleText = styled.span`
  font-size: 14px;
`;

const CardPostMission = ({ data, onUnload }) => {
  const {
    dateTime,
    socialType,
    textTitle,
    textPreview,
    handleClick = () => {},
    content_text: contentText,
    platform,
    type,
    created_date: createdDate,
    id,
    writer_name: writerName,
    role_name: roleName,
    status,
    newsTitle,
    poster_username: poseterUserName,
    roleUser,
    picked_date: pickedDate,
  } = data || {};
  return (
    <StyleCardPostMission>
      <Flex justify="flex-start">
        <Flex
          style={{
            padding: "20px 40px",
            width: "fit-content",
          }}
        >
          <IconSocial type={platform} />
        </Flex>
        <Flex
          vertical
          gap="10px"
          style={{
            width: "100%",
          }}
        >
          {/* <StyleTitle>{contentText}</StyleTitle> */}

          <StyleTitle>{newsTitle}</StyleTitle>
          <PreviewDateWrap status={status}>
            <StylePreviewDate status={status}>{contentText}</StylePreviewDate>
          </PreviewDateWrap>

          {/* footer */}
          <Flex justify="space-between">
            <Flex align="end" justify="start">
              <Flex align="flex-start">
                <IconSocial type={socialType} />
              </Flex>
              <Flex gap={30} align="end">
                {((roleUser === "influencer" && poseterUserName) ||
                  (roleUser !== "influencer" && writerName)) && (
                  <ProfileCard
                    img="https://f.ptcdn.info/625/079/000/row18j8xJPJlL8QW1V-o.jpg"
                    label={
                      roleUser === "influencer" ? poseterUserName : writerName
                    }
                    roleUser={
                      roleName?.includes("influencer") ||
                      roleUser === "influencer"
                        ? "influencer"
                        : "copywriter"
                    }
                  />
                )}

                <Flex vertical>
                  <Flex align="flex-end" gap="10px">
                    <Flex align="end" gap="10px">
                      <StyleText>Mission:</StyleText>
                      <SocialButton type={type} />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            {/* column 2 */}
            <Flex align="flex-end">
              <StyleText>
                {roleUser === "influencer"
                  ? poseterUserName &&
                    `Submission Date: ${dayjs(pickedDate).format("YYYY-MM-DD HH:mm")}`
                  : writerName &&
                    `Submission Date: ${dayjs(createdDate).format("YYYY-MM-DD HH:mm")}`}
              </StyleText>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </StyleCardPostMission>
  );
};

export default CardPostMission;
