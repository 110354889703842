import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Flex, Image } from "antd";
import { keysIcons } from "../../utils/keyDatas";
import IconClock from "../../../assets/icons/icon-clock.svg";

const StyleCardMissionDetail = styled(Flex)`
  border-radius: 10px;
  border: 1px solid ${(props) => props?.theme?.dividerLine};
  background: ${(props) => props?.theme?.white};
  padding: 16px;
`;

const StyleContent = styled.div``;

const StyleTextContent = styled.div`
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextButton};
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-decoration: none;
  line-height: 22px;
  font-family: "Sarabun";
  cursor: pointer;
  padding: 3px 2px;
  line-height: 29px;

  &:hover {
    text-decoration: none;
    color: ${(props) => props?.theme?.colorTextButton};
    cursor: default;
  }

  ${(props) =>
    props?.href &&
    `
  text-decoration: underline;
  color: ${props?.theme?.textHoverLink};
  
  `};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyleDate = styled(Flex)`
  font-size: 12px !important;

  p {
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  span {
    color: ${(props) => props?.theme?.colorTextButton};
  }
`;

const StyleTextStatus = styled(Flex)`
  .labelStatus {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  .status {
    text-transform: capitalize;
    color: ${(props) => props?.theme[props?.textStatus]};
    font-size: 14px;
    font-weight: 700;
  }
`;

const StyleCreateDate = styled(Flex)`
  font-size: 12px;
  color: ${(props) => props?.theme?.colorTextButton};
`;

const MissionDetail = (props) => {
  const {
    type = "",
    href = "",
    content = "",
    startDate = "",
    endDate = "",
    status = "",
    createDate = "",
  } = props;

  const getColorTextStatus = () => {
    let textStatus = "";
    switch (status) {
      case "running":
        textStatus = "colorRunning";
        break;
      case "completed":
        textStatus = "colorComplete";
        break;
      case "cancelled":
        textStatus = "colorCanceled";
        break;

      default:
        break;
    }

    return textStatus;
  };

  const windowWidth = 800;
  const windowHeight = 688;
  const leftPosition = window.screen.width / 2 - (windowWidth / 2 + 10);
  const topPosition = window.screen.height / 2 - (windowHeight / 2 + 50);
  const textStatus = getColorTextStatus();

  return (
    <StyleCardMissionDetail gap="8px">
      <Image
        src={keysIcons[type]}
        height={24}
        preview={false}
        style={{
          width: "24px",
        }}
      />

      <StyleContent>
        <StyleTextContent
          isLinkTitle={href}
          href={href}
          onClick={() =>
            window.open(
              href,
              "_blank",
              `height=${windowHeight},width=${windowWidth},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition}`,
            )
          }
        >
          {content}
        </StyleTextContent>

        <Flex
          align="center"
          justify={createDate && status ? "space-between" : "flex-end"}
          style={{
            paddingTop: "16px",
          }}
        >
          <Flex align="center" gap={8}>
            {createDate && (
              <StyleCreateDate gap={4}>
                <span>Created : </span>
                <span>{createDate}</span>
              </StyleCreateDate>
            )}

            {startDate && endDate && (
              <StyleDate align="center" gap="4px">
                <img src={IconClock} alt="icon-clock" />
                <p>Work on : </p>
                <Flex gap={8}>
                  <span>
                    {moment.utc(startDate).format("YYYY-MM-DD HH:mm")}
                  </span>
                  <span>to</span>
                  <span>{moment.utc(endDate).format("YYYY-MM-DD HH:mm")}</span>
                </Flex>
              </StyleDate>
            )}
          </Flex>

          {status && (
            <StyleTextStatus textStatus={textStatus} gap={8}>
              <span className="labelStatus">Status: </span>
              <span className="status">{status}</span>
            </StyleTextStatus>
          )}
        </Flex>
      </StyleContent>
    </StyleCardMissionDetail>
  );
};

export default MissionDetail;
