/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-relative-packages */
import { ConfigProvider } from "antd";
import WindowPostDetail from "features/missions/WindowPostDetail";
import PostMissionist from "features/missions/PostMissionList";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import CreateSocialMedia from "pages/createSocialMedia";
import WindowContentDetail from "features/submissionContentOwner/WindowSubmissionDetail";
import Mission from "pages/mission";
import WindowMissionDetail from "features/missions/WindowMissionDetail";
import MyPost from "../pages/myPosts";
import CreateEngagement from "../pages/createEngagement";
import theme from "../themes/theme";
import store from "./store";

import InsertTrends from "../common/components/InsertTrend";
import ModalError from "../common/components/ModalError";
import { ProvideAuth, useAuth } from "../common/hooks/useProviderAuth";
import { AxiosProvider } from "../common/hooks/useProviderAxios";
import { useCanAccess } from "../common/hooks/useProviderPermission";

import CampaignDashboard from "../pages/campaignsDashboard";
import DashBoard from "../pages/dashBoard";
import InputForm from "../pages/inputForm";
import ListTrend from "../pages/listTrend";
import Login from "../pages/loginpage";
import MainKeyword from "../pages/mainKeyword";
import NewsArchive from "../pages/newsArchive";
import UpdateTrendLatest from "../pages/updateTrendLatest";

import "../../node_modules/react-sortable-tree/style.css";
import Loading from "../common/components/LoadingComponent";
import TableEngagement from "../features/missions/owner/myEngagements";
import TableDetailByIdEngagement from "../features/missions/owner/myEngagements/detailById";
import CreateMission from "../pages/createMission";
import JobEngagement from "../pages/jobEngagement";
import SubmitEngagement from "../pages/submitEngagement";
import JobContent from "../pages/jobContent";
import SubmitContent from "../pages/submitContent";
import SocialMedia from "../pages/socialMedia";
import SubmissionOwner from "../pages/submissionOwner";
import TableWriterAndInfluencerSubmission from "../features/missions/copywriter&influencer/submission";
import NotFound from "../pages/notFound";
import WindowEngagementDetail from "../features/missions/WindowEngagementDetail";
import MissionOwner from "../pages/missionOwner";
import WindowPostDetailInfluencer from "../features/missions/WindowPostDetail/influencer";
import SocialMediaReview from "../pages/socialMediaReview";
import JobVerifiedList from "../pages/socialMediaVerified";
import SocialMediaDetailPage from "../pages/socialMediaDetail";
import SocialMediaBot from "../pages/socialMediaBot";
import WindowEngagementLikeShareQuoteDetail from "../features/missions/WindowEngagementDetail/engagementLikeShareQuote";

const PrivateRoute = ({ children, path }) => {
  const { accessToken, user } = useAuth();
  const canAccess = useCanAccess(path, user?.roles);
  // eslint-disable-next-line no-unused-vars

  if (!accessToken) return <Navigate to="/login" replace />;
  if (!canAccess && user?.roles) {
    if (user?.roles.includes("superadmin"))
      return <Navigate to="/social-media-bot" replace />;
    if (user?.roles.includes("admin"))
      return <Navigate to="/social-media-review" replace />;
    if (user?.roles.includes("user"))
      return <Navigate to="/dashboard" replace />;
    if (user?.roles.some((role) => ["writer", "influencer"].includes(role)))
      return <Navigate to="/mission-editor" replace />;
  }

  return children;
};

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.colorMagenta,
        },
        components: {
          Table: {
            headerBg: theme.colortitletable,
            headerColor: theme.white,
            borderColor: theme.actionBorder,
            colorBgContainer: theme.white,
            filterDropdownBg: theme.white,
            filterDropdownMenuBg: theme.white,
            headerSplitColor: theme.transparent,
          },
        },
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <ProvideAuth>
            <AxiosProvider>
              <ThemeProvider theme={theme}>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <DashBoard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <PrivateRoute path="/dashboard">
                        <DashBoard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/list-trend"
                    element={
                      <PrivateRoute path="/list-trend">
                        <ListTrend />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/add/trend"
                    element={
                      <PrivateRoute path="/add/trend">
                        <InputForm />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/edit/trend"
                    element={
                      <PrivateRoute path="/edit/trend">
                        <InputForm />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/manage-campaigns"
                    element={
                      <PrivateRoute path="/manage-campaigns">
                        <MainKeyword />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/mainpage"
                    element={
                      <PrivateRoute path="/mainpage">
                        <InsertTrends />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/update-trend"
                    element={
                      <PrivateRoute path="/update-trend">
                        <UpdateTrendLatest />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/news-archive"
                    element={
                      <PrivateRoute path="/news-archive">
                        <NewsArchive />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/campaign-dashboard"
                    element={
                      <PrivateRoute path="/campaign-dashboard">
                        <CampaignDashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-engagements"
                    element={
                      <PrivateRoute path="/my-engagements">
                        <TableEngagement />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-engagements/:id"
                    element={
                      <PrivateRoute path="/my-engagements/:id">
                        <TableDetailByIdEngagement />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-engagements/detail/:id"
                    element={
                      <PrivateRoute path="/my-engagements/detail/:id">
                        <WindowEngagementDetail />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-engagements/:type/detail/:id"
                    element={
                      <PrivateRoute path="/my-engagements/:type/detail/:id">
                        <WindowEngagementLikeShareQuoteDetail />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-engagements/detail/:role/:id"
                    element={
                      <PrivateRoute path="/my-engagements/detail/:role/:id">
                        <WindowEngagementDetail />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-posts"
                    element={
                      <PrivateRoute path="/my-posts">
                        <MyPost />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-posts/:id"
                    element={
                      <PrivateRoute path="/my-posts/:id">
                        <PostMissionist />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-posts/post-detail/:id"
                    element={
                      <PrivateRoute path="/post-detail/:id">
                        <WindowPostDetail />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/job-verified"
                    element={
                      <PrivateRoute path="/job-verified">
                        <JobVerifiedList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/job-verified/:id"
                    element={
                      <PrivateRoute path="/job-verified/:id">
                        <JobVerifiedList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/job-verified/job-verified-detail/:id"
                    element={
                      <PrivateRoute path="/job-verified-detail/:id">
                        <WindowPostDetail />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/my-posts/post-detail/influencer/:id"
                    element={
                      <PrivateRoute path="/post-detail/influencer/:id">
                        <WindowPostDetailInfluencer />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/create-mission"
                    element={
                      <PrivateRoute path="/create-mission">
                        <CreateMission />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/create-mission/:id"
                    element={
                      <PrivateRoute path="/create-mission/:id">
                        <CreateMission />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/create-engagement/:social/:id"
                    element={
                      <PrivateRoute path="/create-engagement/:social/:id">
                        <CreateEngagement />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/create-engagement/:social/:id/:mission"
                    element={
                      <PrivateRoute path="/create-engagement/:social/:id/:mission">
                        <CreateEngagement />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/create-social"
                    element={
                      <PrivateRoute path="/create-social">
                        <CreateSocialMedia />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/create-social/:socialId"
                    element={
                      <PrivateRoute path="/create-social/:socialId">
                        <CreateSocialMedia />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/submit-engagement"
                    element={
                      <PrivateRoute path="/submit-engagement">
                        <SubmitEngagement />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/job-engagement"
                    element={
                      <PrivateRoute path="/job-engagement">
                        <JobEngagement />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/job-content"
                    element={
                      <PrivateRoute path="/job-content">
                        <JobContent />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/submit-content/:contentId"
                    element={
                      <PrivateRoute path="/submit-content/:contentId">
                        <SubmitContent />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/mission-editor"
                    element={
                      <PrivateRoute path="/mission-editor">
                        <Mission />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/mission-detail/:id/:type"
                    element={
                      <PrivateRoute path="/mission-detail/:id/:type">
                        <WindowMissionDetail />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/submission"
                    element={
                      <PrivateRoute path="/submission">
                        <SubmitContent />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/submissions-editor"
                    element={
                      <PrivateRoute path="/submissions-editor">
                        <TableWriterAndInfluencerSubmission />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/social-media"
                    element={
                      <PrivateRoute path="/social-media">
                        <SocialMedia />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/submission-owner"
                    element={
                      <PrivateRoute path="/submission-owner">
                        <MissionOwner />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/submission-owner/:id/:type"
                    element={
                      <PrivateRoute path="/submission-owner">
                        <SubmissionOwner />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/submission-detail/:id"
                    element={
                      <PrivateRoute path="/submission-detail/:id">
                        <WindowContentDetail />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/social-media-review"
                    element={
                      <PrivateRoute path="/social-media-review">
                        <SocialMediaReview />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/social-media-verified"
                    element={
                      <PrivateRoute path="/social-media-verified">
                        <JobVerifiedList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/social-media-bot"
                    element={
                      <PrivateRoute path="/social-media-bot">
                        <SocialMediaBot />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/social-media-review/detail/:requestId/:method"
                    element={
                      <PrivateRoute path="/social-media-review/detail/:requestId/:method">
                        <SocialMediaDetailPage />
                      </PrivateRoute>
                    }
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <ModalError />
                <Loading />
              </ThemeProvider>
            </AxiosProvider>
          </ProvideAuth>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
