/* eslint-disable no-unused-vars */
// eslint-disable-next-line prettier/prettier
import {
  Button, DatePicker, Divider, Flex, Form, TimePicker
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useParams } from "react-router-dom";
import { useAuth } from "../../common/hooks/useProviderAuth";
import WindowComponent from "../../common/components/WindowComponent";
import TextAreaCommon from "../../common/components/TextArea/index";
import InputUnit from "../../common/components/InputUnit";
import GroupRadioSocial from "../../common/components/GroupRadioSocial";
import Audience from "./audience";
import CreateMissionProvider from "./provider/createMissionProvider";
import MissionsProvider from "../missions/provider/missionProvider";
import ModalConfirm from "../../common/components/ModalConfirm";
import { initialFormValue, typeInfluencer } from "./mockData";

const StyleForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: calc(100vh - 73px);
  overflow-y: auto;
  padding: 16px 32px;

  .ant-form-item {
    margin: 0px;
  }

  .require {
    font-size: 16px !important;
    font-family: Sarabun !important;
    font-weight: 400;
    color: ${(props) => props.theme.errMsg};
    margin-right: 4px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    font-family: Sarabun !important;
    color: ${(props) => props.theme.colorTextButton};
  }

  .sub-title {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.colorTextButton};
  }

  label[title="Total"],
  label[title="Time"],
  label[title="Start Date"],
  label[title="Stop Date"] {
    font-weight: 400;
    &::before {
      display: none !important;
    }
  }

  .sub-content {
    padding-left: 24px;
  }

  .ant-input-group-wrapper {
    width: 130px !important;
  }

  .ant-picker-outlined {
    width: 270px;
  }
  .ant-picker {
    width: 270px;
  }

  .ant-form-item-label {
    padding: 0px !important;
    font-size: 16px !important;
    font-weight: 700;
  }

  .ant-form-item-label > label {
    width: 100% !important;
    height: 32px;

    &::after {
      visibility: visible;
    }
  }

  .ant-form-item-control {
    width: auto !important;
  }

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    max-width: 800px;
  }

  .text-label-input-unit {
    font-size: 16px;
    padding-left: 16px;
  }

  #total_help {
    .ant-form-item-explain-error {
      padding-left: 66px;
    }
  }

  .fix-row {
    .ant-row {
      flex-direction: row;
    }

    .ant-form-item-label {
      flex: none !important;
    }
  }
`;

const StyleButton = styled(Flex)`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: red;
  width: 100%;
  padding: 16px;
  border-top: 1px solid ${(props) => props?.theme?.actionBorder};
  background-color: ${(props) => props?.theme?.white};

  .ant-btn-cancel {
    padding: 6px 16px;
    height: 100%;
    width: 180px;
    font-size: 16px;
    font-weight: 700;
    /* color: ${(props) => props?.theme?.colorTextDesc}; */
  }

  .ant-btn-primary {
    font-size: 16px;
    font-weight: 700;
    /* color: ${(props) => props?.theme?.white}; */
    padding: 6px 16px;
    height: 100%;
    width: 180px;
  }
`;

const StyleDivider = styled(Divider)`
  margin: 8px 0px;
`;

const FeatureCreateMission = () => {
  const [form] = Form.useForm();
  const genWatch = Form.useWatch("gen", form);
  const genderWatch = Form.useWatch("gender", form);
  const categoryWatch = Form.useWatch("category", form);
  const startDateWatch = Form.useWatch("startDate", form);
  const stopDateWatch = Form.useWatch("stopDate", form);
  const startTimeWatch = Form.useWatch("startTime", form);
  const stopTimeWatch = Form.useWatch("stopTime", form);
  const { user } = useAuth();
  const missionProvider = CreateMissionProvider();
  const missionApi = MissionsProvider();
  const [isOpenModalAlert, setIsOpenModalAlert] = useState(false);

  const getDetailMission = async (id) => {
    const res = await missionApi.getMissionById(id);
    return res;
  };
  const getParams = useParams();

  const onFinish = async (value) => {
    try {
      dayjs.extend(utc);

      const startDate = dayjs(value?.startDate).format("YYYY-MM-DD");
      let startTime = dayjs(value?.startTime).format("HH:mm");
      const stopDate = dayjs(value?.stopDate).format("YYYY-MM-DD");
      const stopTime = dayjs(value?.stopTime).format("HH:mm");
      // check now is over startTime
      if (dayjs().isAfter(dayjs(startTimeWatch))) {
        startTime = dayjs().format("HH:mm");
        form.setFieldsValue({
          startTime: dayjs(),
        });
      }

      const combineStartDate = dayjs(`${startDate} ${startTime}`).format(
        "YYYY-MM-DD HH:mm:ss",
      );
      const combineEndDate = dayjs(`${stopDate} ${stopTime}`).format(
        "YYYY-MM-DD HH:mm:ss",
      );

      const payload = {
        type: "post",
        remark: value?.requirement?.trim(),
        target: {
          posts: Number(value.total) || 0,
        },
        platforms: [value?.socialMedia],
        start_date: combineStartDate,
        end_date: combineEndDate,
        audiences_generation: value?.gen || [],
        audiences_gender: value?.gender || [],
        categories: value?.category || [],
        poster_types: user?.allowBot ? value?.posterTypes : ["organic"],
      };
      const response = await missionProvider.createMission(payload);
      if (response) {
        window.onbeforeunload = function () {
          return null;
        };
        window.close();
      }
    } catch (err) {
      const { code, message } = err.data;
      if (
        code === 400 &&
        message === "end_date must be at least 30 minutes after start_date"
      ) {
        setIsOpenModalAlert(!isOpenModalAlert);
        form.setFields([
          {
            name: "stopTime",
            errors: ["Please set a minimum duration of 30 minutes"],
          },
        ]);
      } else if (
        message === "start_date must be less than end_date" &&
        code === 400
      ) {
        setIsOpenModalAlert(!isOpenModalAlert);
        form.setFields([
          {
            name: "stopTime",
            errors: ["Please set more than start time"],
          },
        ]);
      }
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    try {
      const key = Object.keys(changedValues)[0];
      if (key === "gen" || key === "gender" || key === "category") {
        if (
          changedValues[key].length === 0 &&
          (genWatch.length === 1 ||
            genderWatch.length === 1 ||
            categoryWatch.length === 1)
        ) {
          form.setFieldsValue({
            [key]:
              // eslint-disable-next-line no-nested-ternary
              key === "gen"
                ? genWatch
                : key === "gender"
                  ? genderWatch
                  : categoryWatch,
          });
        }
      } else if (key === "startDate") {
        // form.setFieldsValue({
        //   stopDate: "",
        //   stopTime: "",
        //   [key]: dayjs(changedValues[key]),
        // });
      } else if (key === "startTime") {
        form.setFieldsValue({
          stopTime: "",
        });
      } else if (key === "stopDate") {
        // form.setFieldsValue({
        //   [key]: dayjs(changedValues[key]).set("hour", 0).set("minute", 0),
        // });
      } else {
        form.setFieldsValue(allValues);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (getParams?.id !== undefined) {
        const res = await getDetailMission(getParams?.id);
        handleValuesChange(
          {},
          {
            requirement: res.remark,
            total: res.target,
            socialMedia: res.platforms[0],
            startDate: dayjs(res.start_date),
            startTime: dayjs(res.start_date),
            // stopDate: dayjs(res.end_date),
            // stopTime: dayjs(res.end_date),
            audience:
              res.audiences_gen?.length > 0 ||
              res.audiences_generation?.length > 0 ||
              res.categories?.length > 0,
            category: res.categories.map((item) => item.id),
            gender: res.audiences_gender,
            gen: res.audiences_generation,
            posterTypes: res.poster_types,
          },
        );
      }
    })();
    return () => {};
  }, [getParams?.id]);

  const timeComp = ({
    name = "",
    message = "",
    placeholder = "00:00",
    required = true,
    format = " HH:mm",
  }) => {
    const isOverDate = dayjs(stopDateWatch).isAfter(dayjs(startDateWatch));

    const disabledTime = () => {
      const current = dayjs();
      const currentHour = current.hour();
      const currentMinute = current.minute();

      const getDisabledMinutes = (selectedHour) => {
        if (currentHour === selectedHour)
          return [...Array(currentMinute).keys()];
        return [];
      };

      return {
        disabledHours: () => [...Array(currentHour).keys()],
        disabledMinutes: (selectedHour) => getDisabledMinutes(selectedHour),
      };
    };

    return (
      <Form.Item
        className="fix-row"
        label="Time"
        name={name}
        rules={[
          {
            required,
            message,
          },
          {
            message: "date time is incorrect",
            validator: () => {
              try {
                if (
                  startDateWatch &&
                  stopDateWatch &&
                  startTimeWatch &&
                  stopTimeWatch
                ) {
                  const startDate = startDateWatch.format("YYYY-MM-DD");
                  const startTime = startTimeWatch.format("HH:mm");
                  const stopDate = stopDateWatch.format("YYYY-MM-DD");
                  const stopTime = stopTimeWatch.format("HH:mm");

                  const combineStartDate = `${startDate}T${startTime}Z`;
                  const combineEndDate = `${stopDate}T${stopTime}Z`;

                  if (dayjs(combineStartDate).isAfter(dayjs(combineEndDate))) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject();
                  }
                }
                return Promise.resolve();
              } catch (error) {
                console.log("error", error);
                return Promise.reject();
              }
            },
          },
        ]}
      >
        <TimePicker
          format={format}
          placeholder={placeholder}
          disabledTime={!isOverDate ? disabledTime : null}
        />
      </Form.Item>
    );
  };

  const dateComp = ({
    label = "",
    name = "",
    message = "Please select a Start Date",
  }) => {
    const isOverDate = dayjs(startDateWatch).isAfter(dayjs());

    const disabledDate = (current) => {
      if (startDateWatch && name === "stopDate" && isOverDate) {
        return current < startDateWatch;
      }
      return current < dayjs().startOf("day");
    };

    return (
      <Form.Item
        className="fix-row"
        label={label}
        name={name}
        rules={[
          {
            required: true,
            message,
          },
          {
            message: "date time is incorrect",
            validator: () => {
              try {
                if (
                  startDateWatch &&
                  stopDateWatch &&
                  startTimeWatch &&
                  stopTimeWatch
                ) {
                  const startDate = startDateWatch.format("YYYY-MM-DD");
                  const startTime = startTimeWatch.format("HH:mm");
                  const stopDate = stopDateWatch.format("YYYY-MM-DD");
                  const stopTime = stopTimeWatch.format("HH:mm");

                  const combineStartDate = `${startDate}T${startTime}Z`;
                  const combineEndDate = `${stopDate}T${stopTime}Z`;

                  if (dayjs(combineStartDate).isAfter(dayjs(combineEndDate))) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject();
                  }
                }
                return Promise.resolve();
              } catch (error) {
                console.log("error", error);
                return Promise.reject();
              }
            },
          },
        ]}
      >
        <DatePicker placeholder="Select Date" disabledDate={disabledDate} />
      </Form.Item>
    );
  };

  return (
    <WindowComponent>
      <StyleForm
        form={form}
        initialValues={initialFormValue}
        onFinish={onFinish}
        onValuesChange={handleValuesChange}
        layout="vertical"
      >
        {/* ============= Requirement ============= */}
        <Form.Item
          colon={false}
          label="Requirement"
          name="requirement"
          rules={[
            {
              required: true,
              message: "Please enter a Requirement",
              whitespace: true, // เพิ่มค่า whitespace: true เพื่อตรวจสอบว่าข้อมูลไม่ได้เป็นช่องว่าง
            },
          ]}
        >
          <TextAreaCommon
            placeholder="Please enter a Requirement"
            maxLength={299}
          />
        </Form.Item>

        {/* ============= Target ============= */}
        <Form.Item
          label="Target"
          colon={false}
          name="total"
          rules={[
            {
              required: true,
              message: "Please enter a Total Target",
            },
            {
              validator: (_, value) =>
                value === "0"
                  ? Promise.reject(
                      new Error(`Please enter a value 
                greater than 0.`),
                    )
                  : Promise.resolve(),
            },
          ]}
        >
          <InputUnit label="Total" />
        </Form.Item>

        {/* ============= Social media ============= */}
        <Form.Item
          colon={false}
          name="socialMedia"
          label="Social Media"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <GroupRadioSocial
            active={
              getParams?.id !== undefined
                ? form.getFieldValue("socialMedia")
                : null
            }
          />
        </Form.Item>

        <StyleDivider />

        {/* ============= Audience ============= */}
        <Form.Item>
          <Audience form={form} />
        </Form.Item>

        <StyleDivider />

        {/* ============= Period ============= */}
        <Flex gap={4}>
          <span className="require">*</span>
          <h1 className="title">Mission Period</h1>
        </Flex>

        <Form.Item>
          <Flex
            gap={16}
            wrap="wrap"
            style={{
              maxWidth: "800px",
            }}
          >
            {dateComp({
              name: "startDate",
              label: "Start Date",
              placeholder: "Select Date",
              message: "Please select a Start Date",
            })}
            {timeComp({
              name: "startTime",
              message: "Please select a Start Time",
            })}
          </Flex>
        </Form.Item>

        <Form.Item>
          <Flex gap={16} wrap="wrap">
            {dateComp({
              name: "stopDate",
              label: "Stop Date",
              message: "Please select a Stop Date",
            })}

            {timeComp({
              name: "stopTime",
              message: "Please select a Stop Time",
            })}
          </Flex>
        </Form.Item>

        <StyleButton align="center" justify="center" gap={16}>
          <Button type="cancel" onClick={() => window.close()}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Confirm
          </Button>
        </StyleButton>
      </StyleForm>

      <ModalConfirm
        isModalOpen={isOpenModalAlert}
        type="warning"
        title="An error has occurred."
        subTitle="Please try again."
        callbackBtnRight={() => setIsOpenModalAlert(!isOpenModalAlert)}
        callbackBtnLeft={() => setIsOpenModalAlert(!isOpenModalAlert)}
      />
    </WindowComponent>
  );
};

export default FeatureCreateMission;
