import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  submissionList: {
    data: [],
    total: 0,
  },
  submissionPaginationQuery: "limit=50&page=1",
};

export const influencerSubmissionSlice = createSlice({
  name: "influencerSubmission",
  initialState,
  reducers: {
    setInitialInfluencerSubmission: () => initialState,
    setFiledInfluencerSubmission: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { setInitialInfluencerSubmission, setFiledInfluencerSubmission } =
  influencerSubmissionSlice.actions;

export default influencerSubmissionSlice.reducer;
