/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Table, Button, Flex, Modal } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import queryString from "query-string";
import moment from "moment";
import { myNewWindow } from "../common/utils/helper";
import {
  IconWarning,
  StyledButtonStatus,
} from "../features/missions/MyPostsList";
import { editorStatusContent, statusContent } from "../common/utils/keyDatas";
import ContentMissionDetailCard from "../features/missions/ContentMissionDetailCard/ContentMissionDetailCard";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
  socialDefault,
} from "../common/utils/filterDatas";
import FilterData from "../common/components/FilterData";
import MainLayout from "../common/layouts/main";
import MissionsProvider from "../features/missions/provider/missionProvider";
import EmptyDefault from "../common/components/EmptyDefault";

const ButtonCustom = styled(Button)`
  /* border: ${(props) => `1px solid ${props.theme.colorMagenta}`}; */
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: 180px;
  height: 38.8px;
  /* background-color: ${(props) => props.theme.colorMagenta};
  color: ${(props) => props.theme.white}; */
`;

const MissionDiv = styled(Flex)`
  .render-empty {
    align-items: center;
    justify-content: center;
    height: 468px;
  }
  .describe-label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: ${(props) => props.theme.colorTertiary};
  }
`;

const Mission = () => {
  const missionApi = MissionsProvider();
  const [paginationQuery, setPaginationQuery] = useState("&limit=50&page=1");
  const [pageCurrent, setPageCurrent] = useState(1);
  const [dataMission, setDataMission] = useState();
  const { queryFilterRedux = "" } = useSelector((state) => state.filterData);
  const [win, setWin] = useState(null);
  const parseQuery = queryString.parse(queryFilterRedux);
  const [modal, contextHolder] = Modal.useModal();

  const closeWindow = async () => {
    // eslint-disable-next-line no-use-before-define
    fetchData();
  };

  const modalError = () => {
    modal.info({
      title: "No job available.",
      icon: <IconWarning />,
      content: "",
      centered: true,
      okText: "OK",
      onOk: () => closeWindow(),
    });
  };

  useEffect(() => {
    setPaginationQuery("&limit=50&page=1");
    setPageCurrent(1);
    return () => {};
  }, [queryFilterRedux]);

  const fetchData = async () => {
    try {
      const query = `${queryFilterRedux + paginationQuery}`;
      const res = await missionApi.getMissionAvailable(query);
      setDataMission(res);
    } catch (error) {
      return error;
    }
  };

  const fetchGetMissionById = async (missionId) => {
    try {
      const query = `${queryFilterRedux + paginationQuery}`;
      const res = await missionApi.getMissionById(missionId);
      return res;
    } catch (error) {
      return error;
    }
  };

  async function handleCloseWindowMission() {
    if (win?.location !== "about:blank") {
      fetchData();
    }
  }

  const handleOpenWindowCreateMission = (id, type) => {
    if (win) {
      win.close();
    }
    const windowRef = myNewWindow(`/mission-detail/${id}/${type}`, "");
    windowRef.onunload = handleCloseWindowMission;
    setWin(windowRef);
  };

  useEffect(() => {
    if (queryFilterRedux) {
      if ("platform" in parseQuery && "target" in parseQuery) {
        fetchData();
      }
    }
  }, [queryFilterRedux, paginationQuery]);

  const checkJobCardIsAvailable = async (id, type) => {
    const response = await fetchGetMissionById(id);
    const isActive = !["cancel", "complete"].includes(response.status);
    const isNotExpire = moment().isBefore(moment(response.end_date));
    const isNotComplete = response.actual.copywriter < response.target;

    if (isActive && isNotExpire && isNotComplete) {
      handleOpenWindowCreateMission(id, type);
    } else {
      modalError();
    }
  };

  const columns = [
    {
      title: "List",
      width: "80%",
      render: (record) => <ContentMissionDetailCard dataItem={record} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (status) => (
        <StyledButtonStatus color={editorStatusContent[status]?.color}>
          {/* {status} */}
          {editorStatusContent[status]?.label}
        </StyledButtonStatus>
      ),
    },
    {
      title: "",
      dataIndex: "key",
      key: "btn",
      render: (id, data) => (
        <Flex vertical gap={2} align="center">
          <ButtonCustom
            type="primary"
            style={{
              width: "100%",
            }}
            disabled={data.status === "cancel"}
            onClick={() => checkJobCardIsAvailable(id, data.type)}
            // onClick={() => handleOpenWindowCreateMission(id, data.type)}
          >
            Pick
          </ButtonCustom>
          <span className="describe-label">
            {data?.picked ? `Picked ${data?.picked}` : ""}{" "}
          </span>
        </Flex>
      ),
    },
  ];

  const onChangePagination = (page, limit) => {
    setPaginationQuery(`&page=${page}&limit=${limit}`);
    setPageCurrent(page);
  };

  return (
    <div>
      <MainLayout>
        <MissionDiv
          gap={16}
          style={{
            padding: "16px",
          }}
          vertical
        >
          <FilterData
            title="Jobs"
            labelSocial="Social Media :"
            labelDetail={`Total ${Math.max(0, dataMission?.total || 0)} Items`}
            labelSort="Sorting :"
            labelFilter="Show :"
            // labelButton="New"
            disabledButton={false}
            filter={{
              social: socialDefault,
              optionSortList: optionSortDefaultList,
              optionSortSelect: optionSortDefaultSelect,
              typeShow: "missionCopywriter",
            }}
          />
          <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {},
              };
            }}
            columns={columns || []}
            dataSource={
              dataMission?.data?.map((item) => ({
                pageType: "mission",
                key: item.id,
                type: item.target !== "engage" ? item?.target : "reply",
                // href: item.url,
                content: item.news_title || item.remark,
                startDate: item.start_date,
                endDate: item.end_date,
                createdDate: item.created_date,
                status: item.status,
                platforms: item.platforms,
                newsTitle: item.news_title,
                href: item.news_url,
                picked: item.picked,
              })) || []
            }
            locale={{
              emptyText: <EmptyDefault />,
            }}
            onChange={() => {}}
            pagination={{
              position: ["bottomRight"],
              defaultCurrent: 1,
              current: pageCurrent,
              pageSize: 50,
              total: dataMission?.total,
              showLessItems: false,
              showSizeChanger: false,
              showQuickJumper: false,
              hideOnSinglePage: false,
              onChange: (page, pageSize) => onChangePagination(page, pageSize),
            }}
          />
          {contextHolder}
        </MissionDiv>
      </MainLayout>
    </div>
  );
};

export default Mission;
