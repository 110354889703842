import React from "react";
import styled from "styled-components";
import { Flex } from "antd";
import { keysIcons } from "../../utils/keyDatas";

const StyleGroupSocial = styled(Flex)`
  /* height: fit-content; */
  /* width: 68px; */
  width: 38px;
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  img {
    width: 18px;
    height: 18px;
    color: transparent;
  }
`;

const GroupSocial = (props) => {
  const { list = [], paddingLeft = "32px", paddingRight = "32px" } = props;

  return (
    <StyleGroupSocial
      gap="4px"
      justify="center"
      align="center"
      wrap="wrap"
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
    >
      {list.filter(Boolean).map((item) => {
        if (!keysIcons[item]) {
          return null;
        }
        return <img src={keysIcons[item]} alt="icon-social" />;
      })}
    </StyleGroupSocial>
  );
};

export default GroupSocial;
