import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  engagementList: {
    data: [],
    total: 0,
  },
  submissionList: {
    data: [],
    total: 0,
  },
  engagementPaginationQuery: "limit=50&page=1",
};

export const engagementSlice = createSlice({
  name: "engagement",
  initialState,
  reducers: {
    setInitialEngagement: () => initialState,
    setFiledEngagement: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { setInitialEngagement, setFiledEngagement } =
  engagementSlice.actions;

export default engagementSlice.reducer;
