/* eslint-disable no-unused-vars */
/* eslint-disable object-curly-newline */
import { Button, Flex, Form, Card } from "antd";
import styled from "styled-components";
import { ThemeStyledInput } from "../styled";

export const ThemeStyledButton = styled(Button)`
  width: 180px;
  background: #7d5de8;
`;

const InputKeyword = ({ formKeyword, handleAdd }) => {
  const handleSubmit = (value) => {
    if (value.mainKeyword.trim().length > 0) {
      handleAdd(value.mainKeyword.trim());
      formKeyword.setFieldValue("mainKeyword", "");
    }
  };

  return (
    <Card title="New Campaign" bordered={false}>
      <Form form={formKeyword} onFinish={handleSubmit}>
        <div style={{ padding: "16px 0" }}>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            Campaign Name
          </span>
          <span style={{ fontSize: "12px" }}> (Limit 500 Characters)</span>
          <Form.Item
            name="mainKeyword"
            rules={[
              {
                required: true,
                message: "Please enter main keyword",
              },
            ]}
            style={{
              marginBottom: "16px",
            }}
          >
            <ThemeStyledInput placeholder="Please fill in" id="TEST" />
          </Form.Item>
        </div>
        <Form.Item>
          <Flex justify="center">
            <ThemeStyledButton htmlType="submit" type="primary">
              ADD
            </ThemeStyledButton>
          </Flex>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default InputKeyword;
