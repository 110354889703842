/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { Button, Table, Flex, Modal } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useNavigate, useParams } from "react-router-dom";
import { myNewWindow } from "../../../common/utils/helper";
import { setInitialFilterData } from "../../filterData/filterDataSlice";
import { myPostStatus } from "../../../common/utils/keyDatas";
import CardSubmission from "../CardSubmission";
import IconSocial from "../../../common/components/IconSocial";
import FilterData from "../../../common/components/FilterData";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
  socialDefault,
} from "../../../common/utils/filterDatas";
import ModalConfirm from "../../../common/components/ModalConfirm";
import SubmissionOwnerProvider from "../provider/submissionOwnerProvider";
import EmptyDefault from "../../../common/components/EmptyDefault";
import ContentJobDetail from "../../../common/components/ContentJobDetail";
import { IconWarning } from "../../missionOwner";
import { ReactComponent as IconArrow } from "../../../assets/icons/icon-dropdown.svg";

const StyledTableSubmissionOwner = styled(Flex)`
  /* overflow-y: scroll; */
  padding: 1rem;
  min-height: calc(100vh - 80px);
  background: ${(props) => props.theme.bgTablePage};

  .ant-table-wrapper
    .ant-table-tbody
    .ant-table-row.ant-table-row-selected
    > .ant-table-cell {
    background: ${(props) => props.theme.white};
  }

  .render-empty {
    align-items: center;
    justify-content: center;
    height: 468px;
  }

  button {
    background: ${(props) => props.theme.colorMagenta};

    &.ant-btn-primary:not(:disabled):hover {
      background: ${(props) => props.theme.hoverPrimaryBtn};
    }
  }

  .ant-table-content {
    overflow: auto;
  }

  .card {
    cursor: pointer;
  }
`;

const TableSubmissionContent = () => {
  const { id: idMission, type: typeMission } = useParams();
  const navigate = useNavigate();
  const submissionOwnerProvider = SubmissionOwnerProvider();
  const [submissionOwnerData, setSubmissionOwnerData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [engagementById, setEngagementById] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [paginationQuery, setPaginationQuery] = useState("&limit=50&page=1");
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 50;
  const [newWindow, setNewWindow] = useState(null);
  const [modalApprove, setModalApprove] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalErrorTarget, setModalErrorTarget] = useState(false);
  const { queryFilterRedux = "" } = useSelector((state) => state.filterData);
  const [modal, contextHolder] = Modal.useModal();
  const parseQuery = queryString.parse(queryFilterRedux);
  const dispatch = useDispatch();
  const {
    news_title: newsTitle,
    requirement,
    targets,
    actual,
    mission_end_date: missionEndDate,
    mission_start_date: missionStartDate,
    mission_status: missionStatus,
    mission_created_date: missionCreateDate,
    pending,
    approve,
    complete,
    platform,
  } = detail || {};

  // const fetchSubmissionOwner = async () => {
  //   try {
  //     const query = `${
  //       queryFilterRedux
  //         .replace(/platform=/g, "platforms=")
  //         .replace(/target=reply/g, "")
  //         .replace(/target=post/g, "") + paginationQuery
  //     }&status=pending&type=${
  //       typeMission === "engage" ? "reply" : typeMission
  //     }`;
  //     const res = await submissionOwnerProvider.getSubmissionOwner(query);
  //     setSubmissionOwnerData(res);
  //   } catch (error) {
  //     return error;
  //   }
  // };

  const fetchEngagementById = async () => {
    try {
      const res = await submissionOwnerProvider.getEngagementById(idMission);
      setEngagementById(res);
    } catch (error) {
      return error;
    }
  };

  const fetchSubmissionContents = async () => {
    try {
      const query = `${
        queryFilterRedux
          .replace(/platform=/g, "platforms=")
          .replace(/target=reply/g, "")
          .replace(/target=post/g, "") + paginationQuery
      }&status=pending&type=${typeMission === "engage" ? "reply" : typeMission}`;
      const res = await submissionOwnerProvider.getSubMissionContent({
        id: idMission,
        query,
      });
      setDetail(res);
      if (selectedRowKeys.length > 1) {
        const newKey = res.data.map((item) => item.id);
        const commonKey = newKey.filter((item) =>
          selectedRowKeys.includes(item),
        );
        setSelectedRowKeys(commonKey);
      }
    } catch (error) {
      return error;
    }
  };

  function handleCloseWindow() {
    if (newWindow?.location !== "about:blank") {
      // fetchSubmissionOwner();
      fetchSubmissionContents();
    }
  }

  const handleOpenWindow = (contentId) => {
    if (newWindow) {
      newWindow.close();
    }
    const windowRef = myNewWindow(`/submission-detail/${contentId}`, "");
    windowRef.onunload = handleCloseWindow;
    setNewWindow(windowRef);
  };

  const fetchApproveSubmission = async () => {
    try {
      const promises = selectedRowKeys.map(async (key) => {
        await submissionOwnerProvider.updateSubmissionStatusSubmit({
          id: key,
          status: "approve",
        });
      });
      await Promise.all(promises);
      // fetchSubmissionOwner();
      fetchSubmissionContents();
    } catch (error) {
      setModalError(!modalError);
      return error;
    }
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
    setPaginationQuery(`&page=${page}&limit=${limit}`);
  };

  useEffect(() => {
    onChangePagination(1);
  }, [queryFilterRedux]);

  useEffect(() => {
    if (queryFilterRedux) {
      if ("platform" in parseQuery) {
        fetchSubmissionContents();
      }
      if (typeMission === "engage" || typeMission === "reply") {
        fetchEngagementById();
      }
      // fetchSubmissionOwner();
    }
    return () => {};
  }, [queryFilterRedux, paginationQuery]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const getCheckboxProps = (record) => {
    if (selectedRowKeys.length + complete + approve === targets) {
      return {
        disabled: selectedRowKeys.includes(record.key) === false,
      };
    }
    return {};
  };

  const hasSelected = selectedRowKeys.length > 0;

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps,
  };

  const columns = [
    {
      title: "Task",
      dataIndex: "platform",
      key: "id",
      width: "80px",
      render: (iconPlatform) => (
        <Flex justify="center">
          <IconSocial type={iconPlatform} />
        </Flex>
      ),
    },
    {
      title: " ",
      render: (record) => (
        <div
          className="card"
          role="button"
          tabIndex={0}
          onClick={() => handleOpenWindow(record.key)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleOpenWindow(record.key);
            }
          }}
        >
          <CardSubmission
            dataItem={{
              ...record,
              message: newsTitle,
              href: engagementById?.url,
            }}
          />
        </div>
      ),
    },
  ];

  const handleApproveSubmissions = () => {
    fetchApproveSubmission();
    setSelectedRowKeys([]);
    setModalApprove(!modalApprove);
  };

  const confirm = (textErrorTarget, callback) => {
    modal.confirm({
      title: textErrorTarget.title,
      icon: <IconWarning />,
      centered: true,
      content: textErrorTarget.content,
      okText: "OK",
      onOk: () => {
        callback();
      },
      cancelText: "Cancel",
    });
  };

  const handleApprove = () => {
    if (complete + approve + selectedRowKeys.length > targets) {
      setModalErrorTarget(!modalErrorTarget);
      confirm(
        {
          title: "An error has occurred.",
          content: "You have exceeded the specified target Please try again.",
        },
        () => setSelectedRowKeys([]),
      );
    } else if (
      selectedRowKeys.length + complete + approve === targets &&
      detail?.total - selectedRowKeys.length > 0
    ) {
      confirm(
        {
          title: `There are ${detail?.total} jobs waiting to be reviewed.`,
          content: `Press the Ok button to approve this job and rejected ${
            detail?.total - selectedRowKeys.length
          } jobs . Press the Cancel button to review other jobs.`,
        },
        handleApproveSubmissions,
      );
    } else {
      setModalApprove(!modalApprove);
      confirm(
        {
          title: "Are you sure you want to do this?",
          content: "Would you like to confirm?",
        },
        handleApproveSubmissions,
      );
    }
  };

  return (
    <StyledTableSubmissionOwner vertical gap="16px">
      <Flex
        direction="row"
        gap={5}
        align="start"
        onClick={() => navigate("/submission-owner")}
        style={{
          cursor: "pointer",
        }}
      >
        <IconArrow /> Back
      </Flex>
      <FilterData
        title="Submissions"
        labelSocial=""
        labelSort="Sorting :"
        labelButton={`Approve ${
          selectedRowKeys.length > 0 ? `(${selectedRowKeys.length})` : ""
        }`}
        labelDetail={`Total ${detail?.total} Items`}
        onClickButton={() => handleApprove()}
        typeBtn="primary"
        disabledButton={!hasSelected}
        filter={{
          social: socialDefault,
          optionSortList: optionSortDefaultList,
          optionSortSelect: optionSortDefaultSelect,
        }}
        // />
      >
        <ContentJobDetail
          dataItem={{
            content: newsTitle,
            startDate: missionStartDate,
            endDate: missionEndDate,
            type: platform,
            createdDate: new Date(missionCreateDate),
            status: missionStatus,
            typeStatus: myPostStatus,
            href: engagementById?.url,
            copywriterCount: {
              actual: complete + approve,
              target: targets,
            },
            showLabel: false,
            // influencerCount: {
            //   actual: complete,
            //   target: targets,
            // },
          }}
        />
      </FilterData>

      <Table
        rowSelection={rowSelection}
        locale={{
          emptyText: <EmptyDefault />,
        }}
        columns={columns || []}
        dataSource={
          detail?.data?.map((item) => ({
            key: item.id,
            profileName: item.writer_name,
            profileRole: item.role_name,
            missionType: item.type,
            href: null,
            startDate: item.start_date,
            endDate: item.end_date,
            content: item.content_text,
            status: item.status,
            message: item.requirement,
            platform: item.platform,
            createDate: item.created_date,
          })) || []
        }
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: 1,
          current: currentPage,
          pageSize: limit,
          total: detail?.total,
          showLessItems: false,
          showSizeChanger: false,
          showQuickJumper: false,
          hideOnSinglePage: false,
          onChange: (page, pageSize) => onChangePagination(page, pageSize),
        }}
      />

      <ModalConfirm
        isModalOpen={modalError}
        type="warning"
        title="An error has occurred."
        subTitle="Please try again."
        callbackBtnRight={() => setModalError(!modalError)}
        callbackBtnLeft={() => setModalError(!modalError)}
      />
      {contextHolder}
    </StyledTableSubmissionOwner>
  );
};
export default TableSubmissionContent;
