/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { Card, Flex, Row, Col } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import WordCloud from "../../chartSocial/WordCloud";
import ChartProvider from "../../provider/chartProvider";
import TitleCampaign from "../TitleCampaign";

const TopKeywordsTab = ({ data, showColumn }) => {
  const chartProvider = ChartProvider();
  const { filter } = useSelector((state) => state.socialCard);
  const [wordClud, setWordClud] = useState([]);

  const fetchWordCloudData = async (params) => {
    try {
      const wordCloudData = await chartProvider.getWordCloud(params);
      setWordClud(wordCloudData || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    const filterPlatform = Object.keys(filter).filter((key) => filter[key]);

    const platformString = filterPlatform
      .map((platform) => `platform=${platform}`)
      .join("&");

    const params = `?&${platformString}`;

    fetchWordCloudData(params);
  }, [filter, showColumn]);

  return (
    <Row gutter={[16, 16]}>
      {data &&
        data.map((e, index) => (
          <Col span={showColumn} key={index}>
            <Card>
              <Flex gap={16} vertical>
                <TitleCampaign
                  title={e.campaign_name}
                  date={moment(e.created_date).format("DD MMM YYYY")}
                />
                <WordCloud data={wordClud} idChart={index} />
              </Flex>
            </Card>
          </Col>
        ))}
    </Row>
  );
};

export default TopKeywordsTab;
