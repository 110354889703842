import { Flex } from "antd";
import styled from "styled-components";
import CreateBy from "./CreateBy";

const StyledContentContainer = styled.div`
  font-family: "Sarabun";
  .content-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }
`;

const StyledContent = styled.div`
  background: #f4f4f4;
  padding: 10px;
  border-radius: 10px;
  height: 200px;
  min-height: 200px;
  /* overflow: scroll; */
  overflow-x: hidden;
`;

const StyledImageCard = styled.img`
  width: 110px;
  height: 110px;
`;

/* const mockData = {
  message: "ตือนหากรัฐบาลดันทุรังทำเงินดิจิทัล เดาไม่ออกจะจบตรงไหน",
  imageList: [
    "https://fastly.picsum.photos/id/237/536/354.jpg?hmac=i0yVXW1ORpyCZpQ-CknuyV-jbtU7_x9EBQVhvT5aRr0",
    "https://fastly.picsum.photos/id/866/536/354.jpg?hmac=tGofDTV7tl2rprappPzKFiZ9vDh5MKj39oa2D--gqhA",
    "https://fastly.picsum.photos/id/237/536/354.jpg?hmac=i0yVXW1ORpyCZpQ-CknuyV-jbtU7_x9EBQVhvT5aRr0",
    "https://fastly.picsum.photos/id/866/536/354.jpg?hmac=tGofDTV7tl2rprappPzKFiZ9vDh5MKj39oa2D--gqhA",
    "https://fastly.picsum.photos/id/237/536/354.jpg?hmac=i0yVXW1ORpyCZpQ-CknuyV-jbtU7_x9EBQVhvT5aRr0",
    "https://fastly.picsum.photos/id/866/536/354.jpg?hmac=tGofDTV7tl2rprappPzKFiZ9vDh5MKj39oa2D--gqhA",
    "https://fastly.picsum.photos/id/237/536/354.jpg?hmac=i0yVXW1ORpyCZpQ-CknuyV-jbtU7_x9EBQVhvT5aRr0",
    "https://fastly.picsum.photos/id/866/536/354.jpg?hmac=tGofDTV7tl2rprappPzKFiZ9vDh5MKj39oa2D--gqhA",
  ],
}; */

const ContentDetail = ({ message = "", imageList = [], createdBy = "" }) => {
  return (
    <StyledContentContainer>
      <Flex vertical gap="5px">
        <div>
          <span className="content-header">Content</span>{" "}
          {createdBy && <CreateBy createdBy={createdBy} />}
        </div>

        <StyledContent>
          <Flex vertical gap="10px">
            <Flex vertical={false} gap="10px">
              {imageList &&
                imageList.map((imagePath) => (
                  <StyledImageCard
                    key={`imagePath-${Math.random(9999)}`}
                    src={imagePath}
                  />
                ))}
            </Flex>
            <span className="message">{message}</span>
          </Flex>
        </StyledContent>
      </Flex>
    </StyledContentContainer>
  );
};

export default ContentDetail;
