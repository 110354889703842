/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Button, Flex, Table } from "antd";
import "dayjs/locale/en";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useEffect } from "react";
import EmptyDefault from "../../../../common/components/EmptyDefault";
import FilterData from "../../../../common/components/FilterData";
import Tabs from "../../../../common/components/Tabs";
import MainLayout from "../../../../common/layouts/main";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
  socialDefault,
  tapSubmissionWriterAndInfluencer,
} from "../../../../common/utils/filterDatas";
import { PaginationQuery } from "../../../../common/utils/queryStr";
import { setFiledInfluencerSubmission } from "./influencerSubmissionSlice";
import CardStatus from "../../owner/myEngagements/CardStatus";
import CardEngagementReply from "../../owner/myEngagements/CardEngagementReply";
import useFetchData from "./useFetchData";

const StyledTableWriterAndInfluencerSubmission = styled(Flex)`
  /* overflow-y: scroll; */
  padding: 1rem;
  /* height: calc(100vh - 100px); */
  background: ${(props) => props.theme.bgTablePage};
`;

const StyledTableContainer = styled(Table)`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.bgTablePage};
`;

const TableWriterAndInfluencerSubmission = () => {
  const dispatch = useDispatch();
  const {
    data,
    total,
    selectTabIndex = "1",
    setSelectTabIndex,
    handleOpenWindow,
    currentPage,
    setCurrentPage,
    queryFilterRedux,
  } = useFetchData();

  const statusCheck = {
    active: "Running",
    cancel: "Cancelled",
    complete: "Complete",
  };

  /* Mock */
  const dataSourceTable = [
    [
      ...[...data].map((item, index) => {
        return {
          apiData: {
            ...item,
          },
          submissionDate: item.created_date, // item.created_date,
          groupSocial: Array(item?.platform), // "facebook",
          textTitle: item.news_title || item.requirement, // "textTitle",
          textPreview: item.content_text, // "textPreview",
          socialButtonType: item.type, // "Reply",
          status: item.status, // "Done",
          profileName: item.writer_name, // "profile Name",
          profileRole: item.writer_poster_name ? "influencer" : "copywriter",
          textTitleUrl: item.news_url,
        };
      }),
    ],
  ];

  const columnsTable = [
    [
      {
        align: "left",
        width: "450px",
        title: "List",
        dataIndex: "list",
        key: "list",
        render: (_, items) => {
          // return null;
          return (
            <CardEngagementReply
              data={{
                ...items,
                handleOpenWindow: (id) => handleOpenWindow(id),
              }}
            />
          );
        },
      },
      {
        align: "center",
        width: "130px",
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, items) => {
          // return null;
          return (
            <CardStatus type="cardEngagementReply" status={items?.status} />
          );
        },
      },
      {
        align: "center",
        width: "100px",
        title: "",
        dataIndex: "list",
        key: "list",
        render: (_, items) => {
          // return null;
          return (
            <Button
              type="primary"
              style={{
                fontWeight: 700,
              }}
              disabled
            >
              Pick
            </Button>
          );
        },
      },
    ],
  ];

  /* Mock */

  const onChangePagination = (page, limit) => {
    setCurrentPage(page);
    dispatch(
      setFiledInfluencerSubmission({
        key: "submissionPaginationQuery",
        value: PaginationQuery(page, limit),
      }),
    );
  };

  const onChangeTab = (e) => {
    setSelectTabIndex(String(e));
  };

  useEffect(() => {
    setCurrentPage(1);
    return () => {};
  }, [queryFilterRedux, selectTabIndex]);

  return (
    <MainLayout>
      <StyledTableWriterAndInfluencerSubmission vertical gap="1rem">
        {/* Header */}
        {/* Filter */}
        <FilterData
          title="Jobs"
          labelSocial="Social Media : "
          labelDetail={`Total ${Math.max(0, total)} Items`}
          labelSort="Sorting :"
          labelFilter="Show :"
          disabledButton={false}
          filter={{
            social: socialDefault,
            optionSortList: optionSortDefaultList,
            optionSortSelect: optionSortDefaultSelect,
            typeShow: "submissionCopywriterAndInfluencer",
          }}
        />
        {/* Filter */}

        {/* Header */}

        <Tabs
          tabActive={selectTabIndex}
          tabList={[...tapSubmissionWriterAndInfluencer].map((item) => {
            return {
              ...item,
              children: (
                <StyledTableContainer
                  locale={{
                    emptyText: <EmptyDefault />,
                  }}
                  style={{
                    height: "max-content",
                  }}
                  columns={columnsTable[0]}
                  dataSource={dataSourceTable[0]}
                  pagination={{
                    position: ["bottomRight"],
                    defaultCurrent: 1,
                    pageSize: 50,
                    current: currentPage,
                    total,
                    showLessItems: false,
                    showSizeChanger: false,
                    showQuickJumper: false,
                    hideOnSinglePage: false,
                    // showPrevNextJumpers: false,
                    onChange: (page, pageSize) =>
                      onChangePagination(page, pageSize),
                  }}
                  // bordered
                  // scroll={{
                  //   x: "max-content",
                  //   y: "55.5vh",
                  // }}
                  // showHeader={false}
                />
              ),
            };
          })}
          handleClickTabs={onChangeTab}
        />
      </StyledTableWriterAndInfluencerSubmission>
    </MainLayout>
  );
};

export default TableWriterAndInfluencerSubmission;

/* ALL: /sub-mission-contents/writer */
