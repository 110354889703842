import dayjs from "dayjs";

const gen = [
  {
    label: "B",
    value: "b",
  },
  {
    label: "X",
    value: "x",
  },
  {
    label: "Y",
    value: "y",
  },
  {
    label: "Z",
    value: "z",
  },
  {
    label: "Alpha",
    value: "alpha",
  },
];

const gender = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

const category = [
  {
    label: "Beauty",
    value: 1,
  },
  {
    label: "Fashion",
    value: 2,
  },
  {
    label: "Photographer",
    value: 3,
  },
  {
    label: "Blogger Vlogger",
    value: 4,
  },
  {
    label: "Food beverage",
    value: 5,
  },
  {
    label: "Sports fitness",
    value: 6,
  },
  {
    label: "Entertainment",
    value: 7,
  },
  {
    label: "Gamer",
    value: 8,
  },
  {
    label: "Technology",
    value: 9,
  },
  {
    label: "Environment",
    value: 10,
  },
  {
    label: "Parenting",
    value: 11,
  },
  {
    label: "Travel",
    value: 12,
  },
];

const initialFormValue = {
  requirement: "",
  total: "1",
  socialMedia: "facebook",
  startDate: dayjs(new Date()),
  startTime: dayjs(),
  stopDate: "",
  stopTime: "",
  gen: ["b", "x", "y", "z", "alpha"],
  gender: ["male", "female"],
  // category: [39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
  category: [],
  audience: false,
  config: ["web_cookie"],
};

export { gen, gender, category, initialFormValue };
