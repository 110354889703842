import React from "react";
import JobVerifiedList from "../features/socialMediaVerified";
import MainLayout from "../common/layouts/main";

const SocialMediaVerified = () => {
  return (
    <MainLayout>
      <JobVerifiedList />
    </MainLayout>
  );
};

export default SocialMediaVerified;
