import React from "react";
import styled from "styled-components";

const StyleTextPreview = styled.div`
  width: 100%;
  min-height: 63px;
  padding: 12px 16px;
  background-color: ${(props) =>
    props?.status === "cancelled" ||
    props?.status === "reject" ||
    props?.status === "rejected"
      ? props?.theme?.bgButtonDelete
      : props?.theme?.backgroundFilter};
  border-radius: 10px;
  border-bottom: 1px solid ${(props) => props?.theme?.actionBorder};
  font-size: 14px;
  font-family: "Sarabun";
  color: ${(props) => props?.theme?.colorTextButton};

  span {
    display: -webkit-box;
    overflow: hidden;
    line-clamp: ${(props) => props.lineClamp};
    -webkit-line-clamp: ${(props) => props.lineClamp};
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    padding: 3px 2px;
    line-height: 29px;
  }
`;

const TextPreview = (props) => {
  const { value = "", lineClamp = 2, status = "" } = props;

  return (
    <StyleTextPreview lineClamp={lineClamp} status={status}>
      <span>{value}</span>
    </StyleTextPreview>
  );
};

export default TextPreview;
