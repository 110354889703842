/* eslint-disable object-curly-newline */
import styled from "styled-components";
import { InputNumber, Select, Button, Pagination, Tabs } from "antd";
import TextArea from "antd/es/input/TextArea";

export const StyleInputForm = styled.div`
  background-color: ${(props) => props.theme.backgroundDashBoard};
  height: calc(100dvh - ${(props) => props.theme.navbarHeight} - 30px);
  padding: 4px 16px;

  #input-form {
    background-color: ${(props) => props.theme.white};
    border-radius: 10px;
    padding: 4px 16px;
    overflow: auto;
    height: calc(100dvh - 92px);
  }

  h2 {
    padding-bottom: 16px;
    border-bottom: 1px solid ${(props) => props.theme.colorBorderCardSocial};
  }
  .sentiment {
    .ant-radio-wrapper {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 11px;

      span.ant-radio + * {
        text-align: center;
      }
    }
  }
`;

export const ThemeStyledTextArea = styled(TextArea)`
  &:hover {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
  &:focus {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
`;

export const ThemeStyledInputNumber = styled(InputNumber)`
  width: 100%;

  &:hover {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
  &:focus {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
  &:focus-within {
    border-color: ${(props) => props.theme.colorcheckbox};
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1);
  }
`;

export const ThemeStyledSelect = styled(Select)`
  .ant-select-selector:hover,
  .ant-select-selector:focus-within {
    border: 1px solid ${(props) => props.theme.colorcheckbox} !important;
    box-shadow: 0 0 0 2px rgba(143, 121, 215, 0.1) !important;
  }
  .ant-select-selection-search-input {
    display: none;
  }
`;

export const StyledButtonIcon = styled(Button)`
  background: transparent !important;
  color: ${(props) => props.theme.colorcheckbox} !important;

  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  .ant-pagination-item:hover {
    border-color: #007dff80;
    background-color: #ffffff;
  }
  .ant-pagination-item:hover a {
    color: #ff2643;
  }
  .ant-pagination-item-active {
    border-color: #0000003b;
  }
  .ant-pagination-item-active a {
    color: #000000de;
  }
`;

export const TextSelect = styled.span`
  color: rgba(0, 0, 0, 0.54);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.15px;
`;

export const StyledButtonAdd = styled(Button)`
  display: flex;
  background-color: ${(props) => props.theme.colorButtonBoost};
  &:hover {
    background: ${(props) => props.theme.colorHoverButtonBoost} !important;
  }
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
`;

export const StyledTabs = styled(Tabs)`
  background: ${(props) => props.theme.white};

  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-content-holder {
    height: calc(100dvh - 132px);
    overflow-y: auto;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colorMagenta};
  }

  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colorMagenta};
  }

  .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: ${(props) => props.theme.colorMagenta};
  }
`;
