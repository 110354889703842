import styled from "styled-components";
import { Button, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import TabCampaignDashboard from "../features/dashboard/campaignDashboard";
import FilterSocial from "../features/dashboard/filterPlatform";
import MainLayout from "../common/layouts/main";

const StyleDashBoard = styled(Flex)`
  padding: 16px;
  background: ${(props) => props.theme.backgroundDashBoard};
`;

const StyleButton = styled(Button)`
  background-color: ${(props) => props.theme.colorButtonBoost};
`;
const CampaignsDashboard = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <StyleDashBoard id="dashboard" gap={16} vertical>
        <FilterSocial title="Campaigns Dashboard">
          <StyleButton
            type="primary"
            onClick={() => navigate("/manage-campaigns")}
          >
            Create Campaingn
          </StyleButton>
        </FilterSocial>
        <TabCampaignDashboard />
      </StyleDashBoard>
    </MainLayout>
  );
};

export default CampaignsDashboard;
