/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Flex, Modal, Tooltip } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import queryString from "query-string";
import {
  ExclamationCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { myNewWindow } from "../../common/utils/helper";
import EmptyDefault from "../../common/components/EmptyDefault";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
  socialDefault,
} from "../../common/utils/filterDatas";
import ModalConfirm from "../../common/components/ModalConfirm";
import { myPostStatus } from "../../common/utils/keyDatas";
import { ButtonStatus } from "../flowJob/HeaderWindowDetail";
import { queryStringFormat } from "../../common/utils/format";
import GroupSocial from "../../common/components/GroupSocial";
import ButtonBooster from "../../common/components/ButtonBooster";
import FilterData from "../../common/components/FilterData";
import CardPost from "../../common/components/CardPost";
import IconSocial from "../../common/components/IconSocial";

import verifiedProvider from "./Provider/verifiedProvider";

const left = window.screen.width / 2 - 800 / 2;
const top = window.screen.height / 2 - 688 / 2;

export const StyledButtonStatus = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
  text-transform: capitalize;
  color: ${(props) => props.theme[props.color]};
`;
const StyledEditButton = styled(Button)`
  &&& {
    width: 80px;
    /* background-color: white; */
    /* color: #722ed1; */
    font-weight: 700;
    /* border: 0.5px solid; Make sure to specify a solid border */
    /* box-shadow: 0px 4px 8px rgba(114, 46, 209, 0.2); */
  }
`;

const StyledCancelButton = styled(Button)`
  &&& {
    width: 80px;
    /* background-color: #ed1e46; */
    /* border-color: #ed1e46; */
    /* color: white; */
    font-weight: 700;
  }
`;
const TablePostList = styled(Table)`
  margin-top: 15px;

  .ant-table-cell-row-hover {
    cursor: pointer;
    background: transparent !important;
  }
  .ant-table-filter-column {
    .ant-table-column-title {
      color: ${(props) => props.theme.white} !important;
    }
    .ant-table-filter-trigger {
      svg {
        path {
          color: ${(props) => props.theme.white} !important;
        }
      }
    }
  }
  .ant-table-thead {
    ::before {
      display: none !important;
    }
    th {
      background-color: ${(props) => props.theme.colortitletable} !important;
      color: ${(props) => props.theme.white} !important;
    }
    td {
      background-color: ${(props) => props.theme.colortitletable} !important;
      color: ${(props) => props.theme.white} !important;
    }
  }
  .ant-table-column-title {
    /* color: #2c3e50; */
  }
`;

const PostListContainer = styled.div`
  padding: 16px;
`;

export const IconWarning = styled(ExclamationCircleOutlined)`
  path {
    color: ${(props) => props.theme.colorTextButton};
  }
`;

const GroupSocialCustom = styled(GroupSocial)`
  padding: 32px;
`;
const modalConfirmDetails = {
  confirm: {
    title: "Do you want to do this!",
    subTitle: "",
    callbackBtnRight: () => setIsModalConfirmOpen(false),
    callbackBtnLeft: () => setIsModalConfirmOpen(false),
  },
};

const useJobVerifiedList = () => {
  const navigate = useNavigate();
  const [listVerified, setListVerified] = useState({
    data: [],
    total: 0,
  });
  const newWindow = useRef(null);
  const [type, setType] = useState(["post"]);
  const [currentPage, setCurrentPage] = useState(1);

  const limit = 50;
  const VerifiedApi = verifiedProvider();
  const { data: VerifiedItem } = listVerified;
  const { queryFilterRedux } = useSelector((state) => state.filterData) || {};
  const parseQuery = queryString.parse(queryFilterRedux);
  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();

  const getVerified = async (page = 1, limit = 50) => {
    try {
      const query = {
        ...parseQuery,
        type,
        page: page || 1,
        limit,
      };
      const path = queryString.stringify(query);

      const res = await VerifiedApi.getVerified({
        filter: queryFilterRedux,
        page,
        limit,
      });

      if (res?.data) {
        setListVerified({
          data: res.data,
          total: res.total,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    await getVerified();
  };
  const updateStatusSocialMediaBot = async (id, status) => {
    try {
      const res = await VerifiedApi.updateStatusSocialMediaBot(id, status);
      if (res?.message === "success") {
        await getVerified();
      }
    } catch (error) {
      console.log(error);
    }
  };
  // function ปิด window
  function handleCloseWindow() {
    if (
      newWindow?.current?.location &&
      newWindow?.current?.location !== "about:blank"
    ) {
      // eslint-disable-next-line no-use-before-define
      fetchData();
    }
  }
  // function เปิด window
  const handleOpenWindow = (record) => {
    if (newWindow?.current) newWindow?.current?.close();
    const windowRef = myNewWindow(
      `social-media-review/detail/${record?.id}/edit`,
      "_blank",
    );
    windowRef.onunload = handleCloseWindow;
    newWindow.current = windowRef;
  };

  const handleCancel = async (id) => {
    try {
      modal.confirm({
        title: "Are you sure you want to do this?",
        content: "Would you like to confirm?",
        icon: <IconWarning />,
        centered: true,
        okText: "OK",
        onOk: async () => {
          const status = "cancel";
          await VerifiedApi.deleteVerified(id, status);
          await getVerified();
        },
        cancelText: "Cancel",
      });
    } catch (error) {
      console.log("Error cancelling:", error);
    }
  };

  async function handleCloseWindowVerified() {
    if (win.location !== "about:blank") {
      await getVerified();
    }
  }

  const handleOpenWindowCreateVerified = (data) => {
    win = myNewWindow(
      data === undefined ? "/create-Verified" : `/create-Verified/${data?.id}`,
      data?.id || "",
    );
    win.onunload = handleCloseWindowVerified;
  };

  const onChangePagination = async (page, pageSize) => {
    setCurrentPage(page);
    // setLimitList(limit);
    // setPageList(page);
    await getVerified(page, pageSize);
  };

  useEffect(() => {
    setCurrentPage(1);
    if (queryFilterRedux) {
      getVerified();
    }
  }, [queryFilterRedux]);

  const columns = [
    {
      title: "No.",
      dataIndex: "index",
      render: (text, record, index) => (
        <span>{(currentPage - 1) * limit + index + 1}</span>
      ),
    },
    {
      title: "Social",
      dataIndex: "platform",
      align: "left",
      width: "50px",
      key: "platform",
      render: (platform) => <IconSocial type={platform} />,
    },
    {
      title: "Profile URL",
      dataIndex: "profile_url",
      width: "100px",
      render: (profileURL) => {
        const displayURL =
          profileURL.length > 40
            ? `${profileURL.substring(0, 40)}`
            : profileURL;
        return (
          <a href={profileURL} target="_blank" rel="noreferrer">
            {displayURL}
          </a>
        );
      },
    },
    {
      align: "left",
      title: "UserID",
      dataIndex: "profile_id",
      render: (profile_id) => (
        <Tooltip title={profile_id}>
          {profile_id.length > 10
            ? `${profile_id.substring(0, 10)}`
            : profile_id}
        </Tooltip>
      ),
    },
    {
      align: "left",
      title: "Name",
      dataIndex: "name",
      render: (name) => (
        <Tooltip title={name}>
          {name.length > 10 ? `${name.substring(0, 10)}` : name}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: "Verified Date",
      dataIndex: "approved_date",
      render: (_, record) => (
        <span>{dayjs(record?.requested_date).format("YYYY-MM-DD HH:mm")}</span>
      ),
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      render: (text, data) => (
        <StyledButtonStatus color={myPostStatus[data.status]?.color}>
          <CheckCircleFilled /> {myPostStatus[data.status]?.label}
        </StyledButtonStatus>
      ),

      width: "10%",
    },
    {
      title: "",
      dataIndex: "edit",
      render: (_, record) => (
        <StyledEditButton
          type="cancel"
          disabled={
            record?.status === "done" ||
            dayjs(record?.end_date).isBefore(dayjs())
          }
          onClick={() => handleOpenWindow(record)}
          size="large"
        >
          Edit
        </StyledEditButton>
      ),
    },
    {
      title: "",
      dataIndex: "cancel",
      render: (text, data) => (
        <StyledCancelButton
          type="reject"
          danger
          onClick={() => {
            handleCancel(data.id);
          }}
          size="large"
        >
          Cancel
        </StyledCancelButton>
      ),
      align: "center",
      width: "10%",
    },
  ];

  return {
    columns,
    VerifiedItem,
    listVerified,
    onChangePagination,
    handleOpenWindowCreateVerified,
    handleCloseWindowVerified,
    navigate,
    contextHolder,
    currentPage,
    limit,
  };
};

const JobVerifiedList = () => {
  const {
    columns,
    onChange,
    listVerified,
    VerifiedItem,
    onChangePagination,
    handleOpenWindowCreateVerified,
    navigate,
    contextHolder,
    currentPage,
    limit,
    deleteSocialMediaBotVerified,
  } = useJobVerifiedList();

  const { data, total, actual } = listVerified;
  const [modalConfirmType, setModalConfirmType] = useState("confirm");
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);

  return (
    <PostListContainer>
      <FilterData
        title="Jobs"
        labelDetail={`Total ${total} ${total > 1 ? "Items" : "Item"}`}
        labelSort="Sorting :"
        labelSocial="Social Media : "
        onClickButton={() => handleOpenWindowCreateVerified()}
        disabledButton={false}
        filter={{
          social: socialDefault,
          optionSortList: optionSortDefaultList,
          optionSortSelect: optionSortDefaultSelect,
        }}
      />
      <TablePostList
        columns={columns}
        dataSource={VerifiedItem}
        onChange={onChange}
        locale={{
          emptyText: <EmptyDefault />,
        }}
        pagination={{
          position: ["bottomRight"],
          defaultCurrent: 1,
          pageSize: limit,
          current: currentPage,
          total,
          showLessItems: false,
          showSizeChanger: false,
          showQuickJumper: false,
          hideOnSinglePage: false,
          onChange: onChangePagination,
        }}
      />
      <ModalConfirm
        type="warning"
        title={modalConfirmDetails[modalConfirmType]?.title}
        subTitle={modalConfirmDetails[modalConfirmType]?.subTitle}
        callbackBtnRight={
          modalConfirmDetails[modalConfirmType]?.callbackBtnRight
        }
        callbackBtnLeft={modalConfirmDetails[modalConfirmType]?.callbackBtnLeft}
        textBtnLeft="Cancel"
        textBtnRight="OK"
        isModalOpen={isModalConfirmOpen}
      />
      {contextHolder}
    </PostListContainer>
  );
};

export default JobVerifiedList;
