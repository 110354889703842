import styled from "styled-components";
import { useState } from "react";
import Tabs from "../../common/components/Tabs";
import TableSubmissionContent from "./tableJobContent";
import FilterData from "../../common/components/FilterData";
import {
  optionSortDefaultList,
  optionSortDefaultSelect,
  socialOptions,
} from "../../common/utils/filterDatas";

const StyleJobContent = styled.div`
  padding: 20px;
  height: 90vh;
`;

const TabStatus = [
  {
    key: "all",
    label: "All",
  },
  {
    key: "approve",
    label: "Running",
  },
  {
    key: "complete",
    label: "Done",
  },
];

const JobContent = () => {
  const [tabSelected, setTabSelected] = useState("all");
  const [total, setTotal] = useState(0);

  const handleSetData = (data) => {
    setTotal(data?.total);
  };
  return (
    <StyleJobContent>
      <FilterData
        title="Jobs"
        labelDetail={`Total ${total} items`}
        labelSort="Sorting :"
        labelFilter="Show :"
        labelSocial="Social Media : "
        callBackQueryFilter={(e) => console.log("callBackQueryFilter e:", e)}
        onClickButton={() => console.log("onClickButton")}
        disabledButton={false}
        filter={{
          social: socialOptions,
          optionSortList: optionSortDefaultList,
          optionSortSelect: optionSortDefaultSelect,
          typeShow: "post_replyCopywriterAndInfluencer",
        }}
      />
      <Tabs
        tabActive={tabSelected}
        tabList={TabStatus}
        handleClickTabs={(key) => setTabSelected(key)}
      />
      <TableSubmissionContent status={tabSelected} callBack={handleSetData} />
    </StyleJobContent>
  );
};

export default JobContent;
