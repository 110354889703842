import { Flex } from "antd";
import styled from "styled-components";

const StyledRequirementDetail = styled.div`
  background-color: white;
  padding: 10px;
  font-size: 14px;
  height: 113px;
  overflow: scroll;

  .requirement-header {
    color: ${(props) => props.theme.colorTextDesc};
  }

  .requirement-content {
    color: ${(props) => props.theme.colorTextButton};
  }
`;

const RequirementDetail = ({ content = "" }) => {
  return (
    <StyledRequirementDetail>
      <Flex vertical gap="5px">
        <span className="requirement-header">Requirement :</span>
        <span className="requirement-content">{content}</span>
      </Flex>
    </StyledRequirementDetail>
  );
};

export default RequirementDetail;
