/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Flex, Radio } from "antd";
import styled from "styled-components";

const StyledRadio = styled(Flex)`
  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.colorMagenta} !important ;
    background-color: ${(props) => props.theme.white};
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${(props) => props.theme.colorMagenta};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${(props) => props.theme.colorMagenta};
  }

  > span {
    font-weight: 700;
    font-size: 16;
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  gap: 40px;
`;

const RadioSubMission = ({
  value,
  onChange,
  socialList = ["Post", "Reply", "Share", "Quote", "Like"],
}) => {
  const provideOptions = (key) => {
    return key
      ? socialList.map((item) => {
          return key === item
            ? {
                label: item,
                value: item,
              }
            : {
                label: item,
                value: item,
                disabled: true,
              };
        })
      : socialList.map((item) => ({
          label: item,
          value: item,
        }));
  };

  return (
    <StyledRadio vertical gap={8}>
      <span>Type</span>
      <StyledRadioGroup
        options={provideOptions(value)}
        onChange={onChange}
        value={value}
      />
    </StyledRadio>
  );
};
export default RadioSubMission;
