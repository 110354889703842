import { Flex } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import SocialButton from "../SocialButton";
import TextPreview from "../TextPreview";

const StyleCardJobTable = styled(Flex)`
  padding: 8px;
  background-color: ${(props) => props?.theme?.white};
  max-width: 900px;

  a {
    /* padding: 0 1px !important;
    color: ${(props) => props.theme.colorTextButton} !important;
    text-decoration: underline !important;
    white-space: unset;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
    cursor: default !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    white-space: nowrap;
    max-width: 50vw;
    color: ${(props) => props.theme.colorTextButton} !important;
    padding: 3px 2px;
    line-height: 29px;
    ${(props) =>
      props?.href &&
      `
      text-decoration: underline;
      cursor: pointer !important;
      `}
  }

  .content {
    // display: -webkit-box;
    overflow: hidden;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 22px;
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextButton};
    cursor: default !important;
    padding: 3px 2px;
    line-height: 29px;

    .ant-anchor-link-title {
      cursor: default !important;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      white-space: nowrap;
      max-width: 50vw;

      ${(props) =>
        props?.href &&
        `
      text-decoration: underline;
      cursor: pointer !important;
      `}
    }

    .ant-anchor {
      width: fit-content !important;
    }

    ${(props) =>
      props?.status !== "ready" &&
      `
      color: ${props?.theme?.textInput};
    `}
  }

  .text-date {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  .date {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextButton};
  }
`;

const StyleMission = styled(Flex)`
  span {
    font-size: 14px;
  }
`;

const StyleSpanTitle = styled.span`
  display: inline-block;
  width: 800px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

const CardJobTable = (props) => {
  const {
    dataItem = {
      missionType: "",
      href: "",
      startDate: "",
      endDate: "",
      content: "",
      status: "",
      message: "",
    },
  } = props;

  const {
    missionType = "",
    startDate = "",
    endDate = "",
    content = "",
    href = "",
    status = "",
    message = "",
  } = dataItem;

  return (
    <StyleCardJobTable gap={16} vertical href={href} status={status}>
      {href ? (
        <a href={href} target="_blank" rel="noreferrer">
          {content}
        </a>
      ) : (
        // <Anchor
        //   className="content"
        //   items={[
        //     {
        //       href,
        //       title: content,
        //       target: "_blank",
        //     },
        //   ]}
        // />
        <StyleSpanTitle className="content">{content}</StyleSpanTitle>
      )}

      {status && status !== "ready" && (
        <TextPreview value={message} status={status} />
      )}

      <Flex
        wrap="wrap"
        justify="space-between"
        align="center"
        style={{
          paddingTop: "8px",
        }}
      >
        {missionType && (
          <StyleMission align="center" gap="8px">
            <span>Missions :</span>
            <SocialButton type={missionType} />
          </StyleMission>
        )}

        {startDate && endDate && (
          <Flex gap={4}>
            <span className="text-date">Date: </span>
            <span className="date">
              {startDate && moment(startDate).format("YYYY-MM-DD HH:mm")} -{" "}
              {endDate && moment(endDate).format("YYYY-MM-DD HH:mm")}
            </span>
          </Flex>
        )}
      </Flex>
    </StyleCardJobTable>
  );
};

export default CardJobTable;
