import React, { useState, useEffect } from "react";
import styled from "styled-components";

const WindowContainer = styled.div`
  height: ${(props) => props.height}px;
  /* overflow: scroll; */
  overflow-x: hidden;
`;

const WindowComponent = ({ children, minusHeight }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const handleResize = () => {
    setWindowHeight(window.innerHeight - (minusHeight || 0));
  };

  useEffect(() => {
    setWindowHeight(window.innerHeight - (minusHeight || 0));

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [minusHeight]);

  return <WindowContainer height={windowHeight}>{children}</WindowContainer>;
};

export default WindowComponent;
