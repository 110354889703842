/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Flex, Image, Typography } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import styled from "styled-components";
import moment from "moment";
import { myNewWindow } from "../../../../../common/utils/helper";
import { CustomCreateDate } from "../../../../../common/components/CardPost";
import { StyledButtonStatus } from "../../../MyPostsList";
import CardStatusMission from "../../../CardShowStatus";
import IconClock from "../../../../../assets/icons/icon-clock.svg";
import GroupSocial from "../../../../../common/components/GroupSocial";
import { keysIcons } from "../../../../../common/utils/keyDatas";
import IconCreated from "../../../../../assets/icons/icon-created.svg";

const { Text } = Typography;

const StyleCardCardDetailEngagement = styled(Flex)`
  border-radius: 10px;
  border: 1px solid ${(props) => props?.theme?.dividerLine};
  background: ${(props) => props?.theme?.white};
  padding: 16px;
`;

const StyleContent = styled(Flex)`
  width: 100%;
`;

const StyleTextContent = styled.div`
  text-wrap: pretty;
  font-size: 14px;
  color: ${(props) => props?.theme?.colorTextButton};
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-decoration: none;
  line-height: 22px;
  font-family: "Sarabun";
  cursor: pointer;
  padding: 3px 2px;
  line-height: 29px;

  &:hover {
    text-decoration: none;
    color: ${(props) => props?.theme?.colorTextButton};
    cursor: default;
  }

  ${(props) =>
    props?.href &&
    `
  text-decoration: underline;
  color: ${props?.theme?.colorTextButton};
  `};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyleDate = styled(Flex)`
  font-size: 16px !important;
  width: max-content;

  p {
    width: max-content;
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  label {
    width: max-content;
    color: ${(props) => props?.theme?.colorTextButton};
  }
`;

const StyledDateWrap = styled(Flex)`
  font-size: 12px;
`;

// const StyleTextStatus = styled(Flex)`
//   .labelStatus {
//     font-size: 14px;
//     color: ${(props) => props?.theme?.colorTextDesc};
//   }

//   .status {
//     text-transform: capitalize;
//     color: ${(props) => props?.theme[props?.textStatus]};
//     font-size: 14px;
//     font-weight: 700;
//   }
// `;

const StyleCreateDate = styled(Flex)`
  font-size: 12px;
  color: ${(props) =>
    props.color ? props.theme[props.color] : props?.theme?.colorTextButton};
  width: max-content;
`;

/* <CardDetailEngagement
  groupSocial=["twitter"]
  type="twitter"
  href="https://twitter.com/PPTVHD36/status/1763484738210201726"
  content="โพสถึง Ais ในทิศทางบวก รูปที่ใช้ในการโพสขนาด 1200 x 600 px โพสที่ Social media ตามที่กำหนด แล้วให้โปรโมการใช้งานอินเตอ์เน็ต เกี่ยวกับการไปปดูคอนเสริต์ ตัวอย่างการโพส เน็ตดีมาก ดูคอนเสริต์พี่ Ed sheeran ที่ราชมังคือดี จึ้งสุดๆ ส่งรูปวิดีโอไม่มีสะดุด ไม่ว่าจะส่งให้ใครก็เร็วแรง คมชัด วิดีโอคอลหาที่บ้านสัญญาณคือชัดเเจ๋ว ไม่มีกระตุก เริ่ดมากกก สัญญาณ ปังสุด ยกให้เป็นที่ 1"
  startDate="2024-05-20 11:30"
  endDate="2024-05-30 11:30"
  link="https://twitter.com/PPTVHD36/status/1763484738210201726"
  campaign=""
/> */

const CardDetailEngagement = (props) => {
  const {
    groupSocial = [],
    type = "",
    href = "",
    content = "",
    startDate = "",
    endDate = "",
    status = "",
    createDate = "",
    copywriterCount = "",
    influencerCount = "",
    missionStatus = "",
    typeStatus = "",
    cancelDate = "",
    completeDate = "",
  } = props;

  // const getColorTextStatus = () => {
  //   let textStatus = "";
  //   switch (status.toLowerCase()) {
  //     case "running":
  //       textStatus = "colorRunning";
  //       break;
  //     case "completed":
  //       textStatus = "colorComplete";
  //       break;
  //     case "cancelled":
  //       textStatus = "colorCanceled";
  //       break;

  //     default:
  //       break;
  //   }

  //   return textStatus;
  // };

  // const textStatus = getColorTextStatus();

  return (
    <StyleCardCardDetailEngagement gap="8px">
      {groupSocial.length > 0 && <GroupSocial list={groupSocial} />}
      {type.length > 0 && (
        <Image
          src={keysIcons[type]}
          height={24}
          preview={false}
          style={{
            width: "24px",
          }}
        />
      )}

      <StyleContent justify="space-between" gap="1rem">
        <Flex
          vertical
          justify="space-between"
          style={{
            whiteSpace: "pre",
          }}
          gap="8px"
        >
          <StyleTextContent
            isLinkTitle={href}
            href={href}
            onClick={() => myNewWindow(href, "_blank")}
          >
            <div>{content}</div>
          </StyleTextContent>

          <Flex direction="row" gap="8px" align="center">
            {createDate && (
              <StyleCreateDate gap={4}>
                {/* <span>Created : </span> */}
                <img src={IconCreated} alt="icon-created" /> Created :
                <span>{dayjs(createDate).format("YYYY-MM-DD HH:mm")}</span>
              </StyleCreateDate>
            )}

            {(startDate || endDate) && (
              <StyleDate align="center" gap="4px" wrap="wrap">
                <img src={IconClock} alt="icon-clock" />
                <StyledDateWrap gap={4} wrap="wrap" align="center">
                  <p>Work on : </p>
                  {dayjs(startDate).format("YYYY-MM-DD HH:mm")}
                  {endDate && (
                    <StyleCreateDate>
                      to {dayjs(endDate).format("YYYY-MM-DD HH:mm")}
                    </StyleCreateDate>
                  )}
                  {cancelDate && (
                    <Text type="danger">
                      <StyleCreateDate color="errMsg">
                        (Cancelled :{" "}
                        {moment(cancelDate).format("YYYY-MM-DD HH:mm")})
                      </StyleCreateDate>
                    </Text>
                  )}
                  {!completeDate &&
                    !cancelDate &&
                    moment().isAfter(endDate) && (
                      <Text type="danger">
                        <StyleCreateDate color="errMsg">
                          (Expired :{" "}
                          {moment(endDate).format("YYYY-MM-DD HH:mm")})
                        </StyleCreateDate>
                      </Text>
                    )}
                  {completeDate && (
                    <CustomCreateDate>
                      <StyleCreateDate>
                        (Completed :{" "}
                        {moment(completeDate).format("YYYY-MM-DD HH:mm")})
                      </StyleCreateDate>
                    </CustomCreateDate>
                  )}
                </StyledDateWrap>
              </StyleDate>
            )}
          </Flex>
        </Flex>

        <Flex
          wrap="wrap"
          gap="1rem"
          align="center"
          justify={createDate && status ? "space-between" : "flex-end"}
        >
          <Flex align="center" gap={8} wrap="wrap" />

          {/* {status && (
            <StyleTextStatus textStatus={textStatus} gap={8}>
              <span className="labelStatus">Status: </span>
              <span className="status">{status}</span>
            </StyleTextStatus>
          )} */}
        </Flex>
        <Flex gap="8px">
          {copywriterCount?.target ? (
            <CardStatusMission
              label="Copywriter Submissions"
              value={`${copywriterCount?.actual?.toLocaleString()}/${copywriterCount?.target?.toLocaleString()}`}
              status={
                <StyledButtonStatus
                  color={
                    (copywriterCount?.actual === copywriterCount?.target &&
                      missionStatus !== "cancel") ||
                    missionStatus === "complete"
                      ? typeStatus?.complete?.color
                      : typeStatus[status].color
                  }
                >
                  {(copywriterCount?.actual === copywriterCount?.target &&
                    missionStatus !== "cancel") ||
                  missionStatus === "complete"
                    ? typeStatus?.complete?.label
                    : typeStatus[status].label}
                </StyledButtonStatus>
              }
            />
          ) : (
            ""
          )}
          {influencerCount?.target ? (
            <CardStatusMission
              label="Influencer Submissions"
              value={`${influencerCount?.actual?.toLocaleString()}/${influencerCount?.target?.toLocaleString()}`}
              status={
                <StyledButtonStatus
                  color={
                    influencerCount?.actual === influencerCount?.target ||
                    missionStatus === "complete"
                      ? typeStatus?.complete?.color
                      : typeStatus[status]?.color
                  }
                >
                  {influencerCount?.actual === influencerCount?.target ||
                  missionStatus === "complete"
                    ? typeStatus?.complete?.label
                    : typeStatus[status]?.label}
                </StyledButtonStatus>
              }
            />
          ) : (
            ""
          )}
        </Flex>
      </StyleContent>
    </StyleCardCardDetailEngagement>
  );
};

export default CardDetailEngagement;
