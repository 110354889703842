/* eslint-disable no-unused-vars */
import { Anchor, Flex } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
// import ProfileCard from "../../../common/components/ProfileCard";
import SocialButton from "../../../common/components/SocialButton";
import TextPreview from "../../../common/components/TextPreview";
import { FirstLabel } from "../../../common/utils/firstLabel";
import ProfileCard, { Role } from "../../../common/components/ProfileCard";

const StyleCardSubmission = styled(Flex)`
  padding: 8px;
  border-radius: 10px;
  /* background-color: ${(props) => props?.theme?.white}; */
  background-color: transparent;

  .role-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: ${(props) => props.theme.colorTertiary};
  }

  .profile-name {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: ${(props) => props.theme.colorTextButton};
  }

  .content {
    display: -webkit-box;
    overflow: hidden;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    line-height: 22px;
    font-size: 14px;
    font-family: Sarabun;
    color: ${(props) => props?.theme?.colorTextButton};
    cursor: default !important;
    padding: 3px 2px;
    line-height: 29px;

    .ant-anchor-link-title {
      cursor: default !important;

      ${(props) =>
        props?.href &&
        `
      text-decoration: underline;
      cursor: pointer !important;
      `}
    }

    .ant-anchor {
      width: fit-content !important;
    }

    ${(props) =>
      props?.status !== "ready" &&
      `
      color: ${props?.theme?.textInput};
    `}
  }

  .text-date {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextDesc};
  }

  .date {
    font-size: 14px;
    color: ${(props) => props?.theme?.colorTextButton};
  }
`;

const StyleMission = styled(Flex)`
  span {
    font-size: 14px;
  }
`;

const StyledProfileName = styled(Flex)`
  aspect-ratio: 1/1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.white};
  font-size: 6.896px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.723px; /* 170% */
  background-color: ${(props) => props.theme.profileColor(props?.index)};
`;

const StyledTitle = styled.span`
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 22px;
  font-size: 14px;
  font-family: Sarabun;
  color: ${(props) => props?.theme?.colorTextButton};
  cursor: default !important;
  padding: 3px 2px;
  line-height: 29px;

  ${(props) =>
    props?.href &&
    `
      text-decoration: underline;
      cursor: pointer !important;
      `}
`;

const CardSubmission = (props) => {
  const {
    dataItem = {
      profileName: "",
      profileRole: "",
      missionType: "",
      href: "",
      startDate: "",
      endDate: "",
      content: "",
      status: "",
      message: "",
      createDate: "",
    },
  } = props;

  const {
    profileName = "",
    profileRole = "",
    missionType = "",
    startDate = "",
    endDate = "",
    content = "",
    href = "",
    status = "",
    message = "",
    createDate = "",
  } = dataItem;

  const customColorByText = String(FirstLabel(profileName).charCodeAt(0))
    .split("")
    .reduce(function (prev, curr) {
      return (Number(prev) || 0) + (Number(curr) || 0);
    });

  return (
    <StyleCardSubmission gap={16} vertical href={href} status={status}>
      {href ? (
        <StyledTitle className="content" href={href}>
          {message}
        </StyledTitle>
      ) : (
        <span className="content">{message}</span>
      )}
      {status && status !== "ready" && (
        <TextPreview value={content} status={status} />
      )}

      <Flex
        wrap="wrap"
        justify="space-between"
        align="center"
        style={{
          paddingTop: "8px",
        }}
      >
        <Flex gap="32px" align="start">
          {/* <Flex gap={8} align="baseline">
            <StyledProfileName
              justify="center"
              align="center"
              index={Math.min(15, customColorByText)}
            >
              {FirstLabel(profileName)}
            </StyledProfileName>

            <Flex align="flex-start" vertical>
              {!!profileRole && <p className="profile-name">{profileName}</p>}
              {!!profileRole && (
                <p className="role-text">{Role(profileRole)}</p>
              )}
            </Flex>
          </Flex> */}
          <ProfileCard
            img="https://d3h1lg3ksw6i6b.cloudfront.net/media/image/2019/04/19/a67f753a5ce74ac296d7fb792289dd8c_refreshingsummertreat.jpg"
            label={profileName}
            roleUser={
              profileRole.includes("influencer") ? "influencer" : "copywriter"
            }
          />
          {missionType && (
            <StyleMission align="center" gap="8px">
              <span>Missions :</span>
              <SocialButton type={missionType} />
            </StyleMission>
          )}
        </Flex>

        {createDate && (
          <Flex gap={4}>
            <span className="text-date">Submission Date : </span>
            <span className="date">
              {createDate && moment(createDate).format("YYYY-MM-DD HH:mm")}
              {/* {endDate && moment.utc(endDate).format("YYYY-MM-DD HH:mm")} */}
            </span>
          </Flex>
        )}
      </Flex>
    </StyleCardSubmission>
  );
};

export default CardSubmission;
