/* eslint-disable object-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */

const calculatedTime = (timeUpdated) => {
  const currentTime = new Date();
  const updateTime = new Date(timeUpdated);
  const timeDiff = (currentTime - updateTime) / (1000 * 60 * 60);
  if (Math.floor(timeDiff) < 1) {
    return "ล่าสุด";
  }
  if (Math.floor(timeDiff) < 24) {
    return `${Math.floor(timeDiff)} ชั่วโมง`;
  }
  if (Math.floor(timeDiff / 24) < 30) {
    return `${Math.floor(timeDiff / 24)} วัน`;
  }
  if (Math.floor(timeDiff / 24 / 30) < 12) {
    return `${Math.floor(timeDiff / 24 / 30)} เดือน`;
  }
  return `${Math.floor(timeDiff / 24 / 30 / 12)} ปี`;
};

const formatTime = (timeUpdated) => {
  const updateTime = new Date(timeUpdated);

  const dateFormatter = new Intl.DateTimeFormat("th-TH", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const formattedDate = dateFormatter.format(updateTime);
  const timeFormatter = new Intl.DateTimeFormat("th-TH", {
    hour: "numeric",
    minute: "numeric",
  });
  const formattedTime = timeFormatter.format(updateTime);

  return `${formattedDate} ${formattedTime}`;
};

const formatNumberUppercase = (number) => {
  if (number < 1e3) {
    return number.toString();
  }
  if (number < 1e6) {
    return `${(number / 1e3).toFixed(1)}K`;
  }
  if (number < 1e9) {
    return `${(number / 1e6).toFixed(1)}M`;
  }
  return `${(number / 1e9).toFixed(1)}B`;
};

const formatNumberLowercase = (number) => {
  if (number < 1e3) {
    return number.toString();
  }
  if (number < 1e6) {
    return `${(number / 1e3).toFixed(1)}k`;
  }
  if (number < 1e9) {
    return `${(number / 1e6).toFixed(1)}m`;
  }
  return `${(number / 1e9).toFixed(1)}b`;
};

const queryStringFormat = (query) => {
  const queryString = Object.keys(query)
    .map((key) => {
      if (Array.isArray(query[key])) {
        return query[key].map((value) => `${key}=${value}`).join("&");
      }
      return `${key}=${query[key]}`;
    })
    .join("&");
  return queryString;
};

export {
  calculatedTime,
  formatTime,
  formatNumberUppercase,
  formatNumberLowercase,
  queryStringFormat,
};
