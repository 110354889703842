import { Modal } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  setDescModalError,
  setIsOpenModalError,
  setTitleModalError,
} from "./modalErrorSlice";

const StyledModalError = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: center;
  }

  .ant-btn-primary {
    background: ${(props) => props.theme.colorButtonBoomb} !important;
  }

  .ant-btn-default {
    display: none;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
  }
`;

const ModalError = () => {
  const dispatch = useDispatch();
  const { isOpenModalError, title, desc } = useSelector(
    (state) => state.modalError,
  );

  const handleCloseModal = () => {
    dispatch(
      setIsOpenModalError({
        value: false,
      }),
    );
    dispatch(
      setTitleModalError({
        value: "",
      }),
    );
    dispatch(
      setDescModalError({
        value: "",
      }),
    );
  };

  useEffect(() => {
    // if (isOpenModalError) {
    //   setTimeout(() => {
    //     handleCloseModal();
    //   }, 5000);
    // }
    if (!isOpenModalError) {
      handleCloseModal();
    }
  }, [isOpenModalError]);

  return (
    <StyledModalError
      open={isOpenModalError}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
    >
      {title && <p className="title">{title}</p>}
      {desc && <p className="desc">{desc}</p>}
    </StyledModalError>
  );
};

export default ModalError;
