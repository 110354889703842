/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useRef } from "react";
import { Button, Flex } from "antd";
import styled from "styled-components";
import Spreadsheet from "react-spreadsheet";

const StayleBody = styled(Flex)`
  width: calc(100% - 30px);
  height: 457.372px;
  .Spreadsheet {
    overflow: auto;
  }

  .header-row {
    background-color: black;
    color: white;
    text-align: center;
  }

  .body-cell {
    color: black;
  }

  .link-cell {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .unlink-cell {
    color: #000;
  }
`;

const ExcelViewer = ({ data, setData }) => {
  const [localData, setLocalData] = useState(data);
  const prevDataRef = useRef(data);

  const setDataWithoutRerender = (newData) => {
    prevDataRef.current = newData;
    setLocalData(newData);
  };

  return (
    <StayleBody justify="center">
      <Spreadsheet data={data} onChange={(e) => setData(e)} />
    </StayleBody>
  );
};

export default ExcelViewer;
