/* eslint-disable object-curly-newline */
/* eslint-disable no-throw-literal */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Flex, Radio, Form, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useAuth } from "../../../common/hooks/useProviderAuth";
import {
  myPostInfluencerStatusCancel,
  myPostListMissionStatus,
  myPostListMissionStatusCancel,
} from "../../../common/utils/keyDatas";
import ContentJobDetail from "../../../common/components/ContentJobDetail";
import WindowComponent from "../../../common/components/WindowComponent";
import ContentDetail from "../../../common/components/ContentDetail";
import RequirementDetail from "../../../common/components/RequirementDetail";
import MissionDetail from "../../../common/components/MissionDetail";
import HeaderWindowDetail from "../../flowJob/HeaderWindowDetail";
import Icon from "../../../assets/icons/icon-copywriter2.svg";
import mockPost from "./mock";
import missionProvider from "../provider/missionProvider";
import { usePostSubMissionList } from "../PostMissionList";
import { IconWarning } from "../MyPostsList";

const PostDetailWrap = styled.div`
  padding: 16px 32px;
  /* margin-bottom: ${(props) =>
    props.status === "waiting" ? "162px" : "50px"}; */

  .ant-btn-primary {
    color: #fff;
    background: red;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  }
`;

const H6 = styled.h6`
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
`;

const ButtonCustom = styled(Button)`
  border: ${(props) =>
    props.typeBtn === "submit"
      ? "none"
      : `1px solid ${props.theme.colorMagenta}`};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  width: 180px;
  height: 38.8px;
  background-color: ${(props) =>
    props.typeBtn === "submit" ? props.theme.colorMagenta : props.theme.white};
  color: ${(props) =>
    props.typeBtn === "submit" ? props.theme.white : props.theme.colorMagenta};
`;

const FooterWrap = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${(props) => props.theme.white};
`;

const useWindowPostDetail = () => {
  const [data, setData] = useState(null);
  const [statusContent, setStatusContent] = useState("reject");
  const [detail, setDetail] = useState(null);
  const missionApi = missionProvider();
  const { id: missionId } = useParams();
  const [modal, contextHolder] = Modal.useModal();

  const getMissionDetail = async () => {
    const res = await missionApi.getSubMissionDetail(missionId);
    setDetail(res);
  };

  useEffect(() => {
    (async () => {
      await getMissionDetail();
    })();
    return () => {};
  }, []);

  // useEffect(() => {
  //   setData(mockPost[0]);
  //   return () => {};
  // }, []);
  const closeWindow = async () => {
    window.onbeforeunload = function () {
      return null;
    };
    window.close();
  };

  const modalError = () => {
    Modal.confirm({
      title: "An error has occurred.",
      content: "Please try again.",
      onCancel: () => closeWindow(),
      onOk: () => closeWindow(),
      icon: <IconWarning />,
      color: "green",
    });
  };

  const updateApprove = async (id) => {
    try {
      const res = await missionApi.updateStatusSubmit({
        id,
        status: statusContent,
      });
      if (res.status === "success") {
        closeWindow();
        return res;
      }
    } catch (error) {
      if (error.code === 422 && error.message === "Incorrect submission") {
        modalError();
      } else {
        modalError();
      }
      // alert("error");
    }
  };

  const modalConfirm = (id) => {
    Modal.confirm({
      title: `Are you sure you want to do this?`,
      content: `Would you like to confirm?`,
      // onCancel: () => closeWindow(),
      onOk: () => updateApprove(id),
      icon: <IconWarning />,
      color: "green",
      centered: true,
    });
  };

  const modalConfirmUpdate = (pending, id) => {
    Modal.confirm({
      title: `There are ${pending} jobs waiting to be reviewed.`,
      content: `Press the Ok button to approve this job and rejected ${pending - 1} jobs . Press the Cancel button to review other jobs.`,
      // onCancel: () => closeWindow(),
      onOk: () => updateApprove(id),
      icon: <IconWarning />,
      color: "green",
      centered: true,
    });
  };

  const handleCheckPendingAvailable = async (id) => {
    const { pending, targets, approve } = detail;
    if (
      approve + 1 === targets &&
      pending - 1 > 0 &&
      statusContent !== "reject"
    ) {
      modalConfirmUpdate(pending, id);
    } else {
      await modalConfirm(id);
    }
  };

  const isShowApproveButton = (user, missionStatus, status, endDate) => {
    if (
      user?.roles?.includes("user") &&
      (status === "waiting" || status === "pending") &&
      moment().isBefore(endDate) &&
      missionStatus !== "cancel"
    ) {
      return true;
    }
    return false;
  };

  return {
    data,
    statusContent,
    setStatusContent,
    updateApprove,
    closeWindow,
    detail,
    modal,
    contextHolder,
    isShowApproveButton,
    handleCheckPendingAvailable,
  };
};

const WindowPostDetail = () => {
  const [form] = useForm();
  const {
    data,
    statusContent,
    setStatusContent,
    updateApprove,
    closeWindow,
    detail,
    modal,
    contextHolder,
    isShowApproveButton,
    handleCheckPendingAvailable,
  } = useWindowPostDetail();
  const {
    status,
    created_date: createdDate,
    type,
    writer_name: writerName,
    writer_poster_name: writerPosterName,
    requirement,
    content_text: contentText,
    start_date: startDate,
    end_date: endDate,
    id,
    platform,
    canceled_date: cancelDate,
    mission_status: missionStatus,
    role_name: roleName,
  } = detail || {};
  const { user } = useAuth();
  return (
    <WindowComponent
      minusHeight={
        isShowApproveButton(user, missionStatus, status, endDate) ? 200 : 0
      }
    >
      <PostDetailWrap status={status}>
        <HeaderWindowDetail
          imgProfile={Icon}
          name={writerName}
          // eslint-disable-next-line jsx-a11y/aria-role
          roleUser={
            roleName?.includes("influencer") ? "influencer" : "copywriter"
          }
          title="Post Content"
          tagContent={type}
          status={status}
          date={createdDate}
          typeStatus={
            missionStatus === "cancel" ||
            missionStatus === "complete" ||
            cancelDate ||
            moment().isAfter(endDate)
              ? myPostListMissionStatusCancel
              : myPostListMissionStatus
          }
        />

        <br />
        <hr />
        <br />

        <Flex vertical>
          {/* <H6>Mission</H6> <br />
          <MissionDetail
            type="twitter"
            href="https://twitter.com/PPTVHD36/status/1763484738210201726"
            content="โพสถึง Ais ในทิศทางบวก รูปที่ใช้ในการโพสขนาด 1200 x 600 px โพสที่ Social media ตามที่กำหนด แล้วให้โปรโมการใช้งานอินเตอ์เน็ต เกี่ยวกับการไปปดูคอนเสริต์ ตัวอย่างการโพส เน็ตดีมาก ดูคอนเสริต์พี่ Ed sheeran ที่ราชมังคือดี จึ้งสุดๆ ส่งรูปวิดีโอไม่มีสะดุด ไม่ว่าจะส่งให้ใครก็เร็วแรง คมชัด วิดีโอคอลหาที่บ้านสัญญาณคือชัดเเจ๋ว ไม่มีกระตุก เริ่ดมากกก สัญญาณ ปังสุด ยกให้เป็นที่ 1"
            startDate="2024-05-20 11:30"
            endDate="2024-05-30 11:30"
            link="https://twitter.com/PPTVHD36/status/1763484738210201726"
            campaign=""
          />
          <RequirementDetail content="โพสถึง Ais ในทิศทางบวก รูปที่ใช้ในการโพสขนาด 1200 x 600 px โพสที่ Social media ตามที่กำหนด แล้วให้โปรโมการใช้งานอินเตอ์เน็ตเกี่ยวกับการไปปดูคอนเสริต์ ตัวอย่างการโพส เน็ตดีมาก ดูคอนเสริต์พี่ Ed sheeran ที่ราชมังคือดี จึ้งสุดๆ ส่งรูปวิดีโอไม่มีสะดุด ไม่ว่าจะส่งให้ใครก็เร็วแรง คมชัด วิดีโอคอลหาที่บ้านสัญญาณคือชัดเเจ๋ว ไม่มีกระตุก เริ่ดมากกก สัญญาณปังสุด ยกให้เป็นที่ 1 และการพูดถึง Ais ต้อง ไม่เสียดสีค่ายอื่นว่าเป็นอย่างไร และการโพส ต้องติด Hashtag #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส #เน็ตแรง #AIS #AIS5G #เอไอเอส#เน็ตแรง #" /> */}
          <ContentJobDetail
            dataItem={{
              content: requirement,
              startDate,
              endDate,
              // platform,
              status,
              type: platform,
            }}
            dataItemRequirement={requirement}
          />
          <ContentDetail
            message={contentText}
            imageList={[]}
            createdBy={writerName}
          />
        </Flex>
      </PostDetailWrap>
      {isShowApproveButton(user, missionStatus, status, endDate) && (
        <FooterWrap>
          <Form
            form={form}
            name="postDetail"
            // onFinish={() => updateApprove(id)}
            onFinish={() => handleCheckPendingAvailable(id)}
          >
            <Form.Item
              label=""
              name="statusContent"
              initialValue={statusContent}
              rules={[
                {
                  required: true,
                  message: "Please select a status!",
                },
              ]}
            >
              <Flex
                justify="center"
                style={{
                  padding: "16px",
                }}
              >
                <Radio.Group
                  onChange={(e) => setStatusContent(e.target.value)}
                  defaultValue="reject"
                >
                  <Radio value="reject">Reject</Radio>
                  <Radio value="approve">Approve</Radio>
                </Radio.Group>
              </Flex>
            </Form.Item>

            <hr />

            <Form.Item>
              <Flex
                gap={15}
                justify="center"
                style={{
                  padding: "16px",
                }}
              >
                <ButtonCustom
                  type="cancel"
                  htmlType="button"
                  onClick={closeWindow}
                >
                  Cancel
                </ButtonCustom>
                <ButtonCustom typeBtn="submit" type="primary" htmlType="submit">
                  Confirm
                </ButtonCustom>
              </Flex>
            </Form.Item>
          </Form>
        </FooterWrap>
      )}
      {contextHolder}
    </WindowComponent>
  );
};

export default WindowPostDetail;
